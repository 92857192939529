import React from 'react'
import Select from 'react-select'
import { withRouter } from 'react-router-dom'

//import styles
import styles from './styles.module.scss'

const CompanyName = ({
	multiCustomerArray,
	handleSelected,
	selectedOption,
	location
}) => {
	const customStyles = {
		option: (styles, state) => ({
			...styles,
			cursor: 'pointer',
			minWidth: '200px',
			marginRight: '1rem'
		}),
		control: (styles) => ({
			...styles,
			cursor: 'pointer',
			minWidth: '200px',
			marginRight: '1rem'
		})}

	
	const renderView = () => {
		
		let multiOrSingleView = null
		let urlSplit = location.pathname.split('/')
		if (multiCustomerArray.length > 1 && !urlSplit.includes('load')) {
			multiOrSingleView = (
				<div className={styles.dropdownButton}>
					<Select
						styles={customStyles}
						defaultValue={selectedOption}
						options={multiCustomerArray}
						onChange={handleSelected}
					></Select>
					
				</div>
			)
		} else if (multiCustomerArray.length === 1 || urlSplit.includes('load')) {
			multiOrSingleView = (
				<span className={styles.companyName}> {selectedOption.label} </span>
			)
		} else {
			multiOrSingleView = (
				<span className={styles.companyName}> No Customer </span>
			)
		}
		return multiOrSingleView
	}

	return <div className={styles.selectContainer}>{renderView()}</div>
}

export default withRouter(CompanyName)
