import React from 'react'
import PropTypes from 'prop-types'

//import helpers
import getColorFromAge from 'helpers/getColorFromAge'

//import css
import styles from './styles.module.scss'

const AgeCell = ({
  age
}) => {
  let color = getColorFromAge(age)

  return (
    <div className={styles.container} style={{backgroundColor: color}}>
      {age}
    </div>
  )
}

AgeCell.propTypes = {
  age: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
}

export default AgeCell