import { createAction } from 'redux-actions'

// Actions
export const SET_IS_LOADING = 'customerPortal/app/SET_IS_LOADING'
export const UNSET_IS_LOADING = 'customerPortal/app/UNSET_IS_LOADING'
export const SET_MENU_IS_OPEN = 'customerPortal/app/SET_MENU_IS_OPEN'
export const UNSET_MENU_IS_OPEN = 'customerPortal/app/UNSET_MENU_IS_OPEN'
export const TOGGLE_MENU_IS_OPEN = 'customerPortal/app/TOGGLE_MENU_IS_OPEN'
export const SET_SHOW_FILTERS = 'customerPortal/app/SET_SHOW_FILTERS'
export const UNSET_SHOW_FILTERS = 'customerPortal/app/UNSET_SHOW_FILTERS'
export const TOGGLE_SHOW_FILTERS = 'customerPortal/app/TOGGLE_SHOW_FILTERS'
export const SET_PAGE_TITLE = 'customerPortal/app/SET_PAGE_TITLE'
export const LOGIN_USER = 'cupo/app/LOGIN_USER'
export const SET_ACCESS_TOKEN = 'cupo/app/SET_ACCESS_TOKEN'
export const SET_REFRESH_TOKEN = 'cupo/app/SET_REFRESH_TOKEN'
export const LOADING = 'cupo/app/LOADING'
export const LOGIN_FAILED = 'cupo/app/LOGIN_FAILED'
export const LOGIN_SUCCESS = 'cupo/app/LOGIN_SUCCESS'
export const RESET = 'customerPortal/app/RESET'

const initialState = {
  menuIsOpen: false,
  showFilters: false,
  pageTitle: ``,
  userLoggedIn: false,
  accessToken: null,
  refreshToken: null,
  loginFailed: false,
  loginSuccess: false,
  isAppLoading: false,
  loading: false,
  loginFailedMessage: ''
}
// Reducer
export default function app (state = initialState, action) {
  switch (action.type) {
    case SET_IS_LOADING:
      return {
        ...state,
        isAppLoading: true
      }
    case UNSET_IS_LOADING:
      return {
        ...state,
        isAppLoading: false
      }
    case SET_MENU_IS_OPEN:
      return {
        ...state,
        menuIsOpen: true
      }
    case UNSET_MENU_IS_OPEN:
      return {
        ...state,
        menuIsOpen: false
      }
    case TOGGLE_MENU_IS_OPEN:
      return {
        ...state,
        menuIsOpen: !state.menuIsOpen
      }
    case SET_SHOW_FILTERS:
      return {
        ...state,
        showFilters: true
      }
    case UNSET_SHOW_FILTERS:
      return {
        ...state,
        showFilters: false
      }
    case TOGGLE_SHOW_FILTERS:
      return {
        ...state,
        showFilters: !state.showFilters
      }
    case SET_PAGE_TITLE:
      return {
        ...state,
        pageTitle: action.payload
      }
    case LOGIN_USER:
      return {
        ...state,
        userLoggedIn: action.payload
      }
    case SET_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: action.payload
      }
    case SET_REFRESH_TOKEN:
      return {
        ...state,
        refreshToken: action.payload
      }
    case LOADING:
      return {
        ...state,
        loading: action.payload
      }
    case LOGIN_FAILED:
      return {
        ...state,
        loginFailed: action.payload.success,
        loginFailedMessage: action.payload.message
      }
    case LOGIN_SUCCESS:
      return {
        ...state,
        loginSuccess: action.payload
      }
    case RESET:
      return initialState
    default:
      return state
  }
}

// Action Creators
export const setIsLoadingApp = createAction(SET_IS_LOADING)
export const unsetIsLoadingApp = createAction(UNSET_IS_LOADING)
export const setMenuIsOpenApp = createAction(SET_MENU_IS_OPEN)
export const unsetMenuIsOpenApp = createAction(UNSET_MENU_IS_OPEN)
export const toggleMenuIsOpenApp = createAction(TOGGLE_MENU_IS_OPEN)
export const setShowFiltersApp = createAction(SET_SHOW_FILTERS)
export const unsetShowFiltersApp = createAction(UNSET_SHOW_FILTERS)
export const toggleShowFiltersApp = createAction(TOGGLE_SHOW_FILTERS)
export const setPageTitle = createAction(SET_PAGE_TITLE)
export const loginUser = createAction(LOGIN_USER)
export const setAccessToken = createAction(SET_ACCESS_TOKEN)
export const setRefreshToken = createAction(SET_REFRESH_TOKEN)
export const setLoading = createAction(LOADING)
export const setLoginFailed = createAction(LOGIN_FAILED)
export const setLoginSuccess = createAction(LOGIN_SUCCESS)
export const resetApp = createAction(RESET)