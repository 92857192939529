import { createAction } from 'redux-actions'

// Actions
export const SET_DATA = 'customerPortal/payHistoryPage/UPDATE'
export const RESET = 'customerPortal/payHistoryPage/RESET'
export const UPDATE_DATA = 'customerPortal/payHistoryPage/UPDATE_DATA'
export const UPDATE_TOTAL = 'customerPortal/payHistoryPage/UPDATE_TOTAL'
export const UPDATE_PAGES = 'customerPortal/payHistoryPage/UPDATE_PAGES'
export const UPDATE_CURRENT_PAGE = 'customerPortal/payHistoryPage/UPDATE_CURRENT_PAGE'
export const UNSET_LOADING = 'customerPortal/payHistoryPage/UNSET_LOADING'
export const SET_LOADING = 'customerPortal/payHistoryPage/SET_LOADING'
export const CSV_SET_LOADING = 'customerPortal/payHistoryPage/CSV_SET_LOADING'
export const UNSET_LOADING_CSV = 'customerPortal/payHistoryPage/UNSET_LOADING_CSV'
export const UPDATE_SORT_FIELD = 'customerPortal/payHistoryPage/UPDATE_SORT_FIELD'
export const SET_SORT_DIRECTION = 'customerPortal/payHistoryPage/SET_SORT_DIRECTION'
export const UPDATE_SORT_PARAMS = 'customerPortal/payHistoryPage/UPDATE_SORT_PARAMS'
export const UPDATE_PARAMS = 'customerPortal/payHistoryPage/UPDATE_PARAMS'
export const UPDATE_FILTER_PARAMS = 'customerPortal/payHistoryPage/UPDATE_FILTER_PARAMS'
export const SET_ACTIVE_FILTERS = 'customerPortal/payHistoryPage/SET_ACTIVE_FILTERS'
export const UPDATE_ACTIVE_FILTERS = 'customerPortal/payHistoryPage/UPDATE_ACTIVE_FILTERS'
export const SET_PENDING_FILTERS = 'customerPortal/payHistoryPage/SET_PENDING_FILTERS'
export const SUBMIT_PENDING_FILTERS = 'customerPortal/payHistoryPage/SUBMIT_PENDING_FILTERS'
export const TOGGLE_SORT_DIRECTION = 'customerPortal/payHistoryPage/TOGGLE_SORT_DIRECTION'

const initialState = {
  schema: [
    {text: `Customer PO`, val: `customerpopreview`, appValue: `customerPo`, sort: true,filter: {
      show: true
    }
  },
    {text: `IEL PO Number`, val: `ponumber`, appValue: `ielPo`, sort: true, filter: {
      show: true
    }
  },
    {text: `Invoice Date`, val: `invoicedate`, appValue: `invoiceDate`, sort: true, filter: {
      show: true,
      type: `date`
    }
  },
    {text: `Date Paid`, val: `paiddate`, appValue: `datePaid`, sort: true, filter: {
      show: true,
      type: `date`
    }
  },
    {text: `Amount Paid`, val: `invoicepayment`, appValue: `amountPaid`, sort: true, filter: {
      show: true
    }
  },
    
  ],
  data: [],
  pages: 0,
  currentPage: 0,
  total: 0,
  loading: true,
  displayLoadingMessageCSV: false,
  sortField: ``,
  direction: `asc`,
  params: ``,
  sortParams: ``,
  filterParams: ``,
  activeFilters: [],
  pendingFilters: [],
}

// Reducer
export default function payHistoryPage (state = initialState, action) {
  switch (action.type) {
    case SET_DATA:
      return {
        ...state,
        data: action.payload
      }
    case UPDATE_DATA:
      return {
        ...state,
        data: [
          ...state.data,
          ...action.payload
        ]
      }
    case UPDATE_TOTAL: 
      return {
        ...state,
        total: action.payload
      }
    case UPDATE_PAGES:
      return {
        ...state,
        pages: action.payload
      }
    case UPDATE_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload
      }
    case UNSET_LOADING:
      return {
        ...state,
        loading: false
      }
    case SET_LOADING:
      return {
        ...state,
        loading: true
      }
    case CSV_SET_LOADING:
      return {
        ...state,
        displayLoadingMessageCSV: true
      }
    case UNSET_LOADING_CSV:
      return {
        ...state,
        displayLoadingMessageCSV: false
      }
    case UPDATE_SORT_FIELD:
      return {
        ...state,
        sortField: action.payload,
        direction: `asc`
      }
    case SET_SORT_DIRECTION:
      return {
        ...state,
        direction: action.payload
      }
    case UPDATE_SORT_PARAMS:
      return {
        ...state,
        sortParams: action.payload
      }
    case TOGGLE_SORT_DIRECTION:
      return {
        ...state,
        direction: state.direction === `asc` ? `desc` : `asc`
      }
    case UPDATE_PARAMS:
      return {
        ...state,
        params: action.payload
      }
    case UPDATE_FILTER_PARAMS:
      return {
        ...state,
        filterParams: action.payload
      }
    case SET_ACTIVE_FILTERS:
      return {
        ...state,
        activeFilters: action.payload
      }
    case UPDATE_ACTIVE_FILTERS:
      return {
        ...state,
        activeFilters: [
          ...state.activeFilters
        ]
      }
    case SET_PENDING_FILTERS:
      return {
        ...state,
        pendingFilters: action.payload
      }
    case SUBMIT_PENDING_FILTERS:
      return {
        ...state,
        activeFilters: [
          ...state.activeFilters,
          ...state.pendingFilters
        ],
        pendingFilters: []
      }
    case RESET:
      return initialState
    default:
      return state
  }
}

// Action Creators
export const setPayHistoryPageData = createAction(SET_DATA)
export const updatePayHistoryPageData = createAction(UPDATE_DATA)
export const updatePayHistoryPageTotal = createAction(UPDATE_TOTAL)
export const updatePayHistoryPagePages = createAction(UPDATE_PAGES)
export const updatePayHistoryPageCurrentPage = createAction(UPDATE_CURRENT_PAGE)
export const updatePayHistoryPageLoading = createAction(UNSET_LOADING)
export const setPayHistoryPageLoading = createAction(SET_LOADING)
export const setPayHistoryPageLoadingCsv = createAction(CSV_SET_LOADING)
export const unSetPayHistoryPageLoadingCsv = createAction(UNSET_LOADING_CSV)
export const updateSortFieldPayHistory = createAction(UPDATE_SORT_FIELD)
export const setDirectionPayHistory = createAction(SET_SORT_DIRECTION)
export const updateSortParamsPayHistory = createAction(UPDATE_SORT_PARAMS)
export const updateParamsPayHistory = createAction(UPDATE_PARAMS)
export const updateFilterParamsPayHistory = createAction(UPDATE_FILTER_PARAMS)
export const setActiveFiltersPayHistory = createAction(SET_ACTIVE_FILTERS)
export const updateActiveFiltersPayHistory = createAction(UPDATE_ACTIVE_FILTERS)
export const setPendingFiltersPayHistory = createAction(SET_PENDING_FILTERS)
export const submitPendingFiltersPayHistory = createAction(SUBMIT_PENDING_FILTERS)
export const toggleSortDirectionPayHistory = createAction(TOGGLE_SORT_DIRECTION)

export const resetInvoice = createAction(RESET)