import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@intxlog/iuilib'
import { Link } from 'react-router-dom'

//import css
import styles from './styles.module.scss'

const LoadLineButton = ({
  id
}) => {
  return (
    <Button
      className={styles.button}
      text={`View Details`}
      size={`small`}
      linkComponent={Link}
      elementType={`routedLink`}
      linkTo={`/load/${id}`}
    ></Button>
  )
}

LoadLineButton.propTypes = {
  id: PropTypes.number
}

export default LoadLineButton