import { createAction } from 'redux-actions'

// Actions
export const UPDATE = 'changelog/latestChangelog/UPDATE'
export const RESET = 'changelog/latestChangelog/RESET'

const initialState = []

// Reducer
export default function latestChangelog (state = initialState, action) {
  switch (action.type) {
    case UPDATE:
      return [
        ...action.payload
      ]
    case RESET:
      return initialState
    default:
      return state
  }
}

// Action Creators
export const updateLatestChangelog = createAction(UPDATE)
export const resetLatestChangelog = createAction(RESET)