import IELAPIServiceFactory from "./IELAPIServiceFactory";
import store from "store";

// import modules
import { updateCustomerInfo } from "modules/customerInfo";
import { updateStatsAR } from "modules/statsAR";
import { unsetGraphIsLoadingBillingPage } from "modules/billingPage";
import {
  setPurchaseOrders,
  updatePurchaseOrders,
  setPagesPurchaseOrders,
  setCurrentPagePurchaseOrders,
  setLoadingPurchaseOrders,
  unsetLoadingPurchaseOrders,
} from "modules/purchaseOrders";
import {
  updateOutstandingInvoicesData,
  unSetLoading,
  setLoading,
} from "modules/outstandingInvoices";
import {
  setPayHistoryPageData,
  updatePayHistoryPageData,
  updatePayHistoryPageTotal,
  updatePayHistoryPagePages,
  updatePayHistoryPageCurrentPage,
  updatePayHistoryPageLoading,
  setPayHistoryPageLoading,
} from "modules/payHistoryPage";

// import helpers
import currency from "helpers/currency";
import getNumberOfDaysOldInvoice from "helpers/getNumberOfDaysOldInvoice";

export default class IELAPICustomerService {
  constructor(props) {
    this.service = IELAPIServiceFactory.new();
  }

  getCustomerInfo(name, customerID) {
    return this.service
      ._withToken()
      .get(`nexus/customer/${customerID}`, {})
      .then((result) => {
        const resObj = result.data;
        const customerObj = {
          name: resObj[0].customername,
          id: resObj[0].id,
        };
        store.dispatch(updateCustomerInfo(customerObj));
      })
      .catch((error) => {
        error.customer = name;
        this.service.handleError(error, false);
      });
  }

  getARSummary(customerID) {
    return this.service
      ._withToken()
      .get(`nexus/customer/${customerID}/arsummary`, {})
      .then((result) => {
        const customerObj = result.data.customerinfo;
        const displayName = !customerObj.externalcustomername
          ? customerObj.customername
          : customerObj.externalcustomername;
        const statsObj = result.data.arstats;

        //map the customer data
        store.dispatch(
          updateCustomerInfo({
            id: customerObj.id,
            name: displayName,
            address1: customerObj.address1,
            address2: customerObj.address2,
            city: customerObj.city,
            state: customerObj.state,
            zip: customerObj.zip,
            phone: customerObj.phonenumber,
            fax: customerObj.faxnumber,
            contact: customerObj.contact,
            email: customerObj.email,
            externalcustomername: customerObj.externalcustomername,
          })
        );

        //add note for testing
        //map the AR stats data
        store.dispatch(
          updateStatsAR({
            payTerm: statsObj.payterms,
            totalBilled: statsObj.totalARBilled,
            totalUnbilled: statsObj.totalARUnbilled,
            totalUsed: statsObj.totalARUsed,
            totalBilledFormatted: statsObj.totalARBilledFormatted,
            totalUnbilledFormatted: statsObj.totalARUnbilledFormatted,
            totalUsedFormatted: statsObj.totalARUsedFormatted,
            countUnbilled: parseInt(statsObj.Unbilled.count),
            count0to30: parseInt(statsObj[`0to30`].count),
            amount0to30: statsObj[`0to30`].amount,
            amount0to30Formatted: statsObj[`0to30`].amountFormatted,
            count31to60: parseInt(statsObj[`31to60`].count),
            amount31to60: statsObj[`31to60`].amount,
            amount31to60Formatted: statsObj[`31to60`].amountFormatted,
            count61to90: parseInt(statsObj[`61to90`].count),
            amount61to90: statsObj[`61to90`].amount,
            amount61to90Formatted: statsObj[`61to90`].amountFormatted,
            countOver90: parseInt(statsObj.over90.count),
            amountOver90: statsObj.over90.amount,
            amountOver90Formatted: statsObj.over90.amountFormatted,
          })
        );

        store.dispatch(unsetGraphIsLoadingBillingPage());
      })
      .catch((error) => {
        error.customer = customerID;
        this.service.handleError(error, false);
      });
  }

  getPurchaseOrders(
    { type, params = ``, page = 1, update = false },
    customerID
  ) {
    let parameters = params !== "" ? `${params}&` : "";

    store.dispatch(setLoadingPurchaseOrders());

    let callName;
    switch (type) {
      case `billed`:
        callName = `billedardetail`;
        break;
      case `unbilled`:
        callName = `unbilledardetail`;
        break;
      default:
        break;
    }

    return this.service
      ._withToken()
      .get(
        `nexus/customer/${customerID}/${callName}?${parameters}limit=200&page=${page}`,
        {}
      )
      .then((result) => {
        // modify the data so we have a consistant schema
        const poArray = result.data.data.map((po) => ({
          id: po.id,
          poNumber: po.ponumber,
          customerPO: po.customerpo || po.customerpopreview,
          deliveryDate: po.deliverydate,
          amount: po.amountFormatted,
          contact: po.broker || `${po.firstname} ${po.lastname}`,
          debits: po.debitsFormatted,
          credits: po.appliedcreditsFormatted,
          amountPaid: po.paidamountFormatted,
          balance: po.balanceFormatted,
          dateBilled: po.invoicedate,
          age: po.age,
        }));

        const pages = result.last_page;
        const currentPage = result.current_page;
        store.dispatch(
          update ? updatePurchaseOrders(poArray) : setPurchaseOrders(poArray)
        );
        store.dispatch(setPagesPurchaseOrders(pages));
        store.dispatch(setCurrentPagePurchaseOrders(currentPage));
        store.dispatch(unsetLoadingPurchaseOrders());
      })
      .catch((error) => {
        error.customer = customerID;
        this.service.handleError(error, false);
      });
  }

  getOutstandingInvoice(customerID, params = ``) {
    store.dispatch(setLoading());

    let parameters = params !== "" ? `&${params}` : "";

    return this.service
      ._withToken()
      .get(
        `nexus/customer/${customerID}/billedardetail?limit=-1${parameters}`,
        {}
      )
      .then((result) => {
        const invoiceArray = Object.keys(result.data.data).map((key) => {
          const obj = result.data.data[key];
          let payStat = "Unpaid";
          if (obj.pending_amount !== null) {
            payStat = `Pending $${currency(obj.pending_amount)}`;
          } else if (
            Number(obj.balance) > 0 &&
            Number(obj.balance) < Number(obj.amount)
          ) {
            payStat = "Partial Payment";
          }
          let ageOld = getNumberOfDaysOldInvoice(obj.invoicedate);

          return {
            id: obj.id,
            totalBalance: Number(obj.amount),
            outstandingBalance: Number(obj.balance),
            amountPaid: Number(obj.paidamount),
            invoiceDate: obj.invoicedate,
            customerPO: obj.customerpo,
            IELPONumber: obj.ponumber,
            paymentStatus: payStat,
            age: ageOld.toFixed(),
          };
        });

        // Status Sorting
        if (parameters === "&sort=status") {
          invoiceArray.sort(function (a, b) {
            let keyA = a.paymentStatus;
            let keyB = b.paymentStatus;
            if (keyA < keyB) return -1;
            if (keyA > keyB) return 1;
            return 0;
          });
        }
        if (parameters === "&sort=-status") {
          invoiceArray.sort(function (a, b) {
            let keyA = a.paymentStatus;
            let keyB = b.paymentStatus;
            if (keyA < keyB) return 1;
            if (keyA > keyB) return -1;
            return 0;
          });
        }

        // PO Number Sorting
        if (parameters === "&sort=ponumber") {
          invoiceArray.sort(function (a, b) {
            let keyA = a.IELPONumber;
            let keyB = b.IELPONumber;
            if (keyA.match("^[0-9]+.[A-Z]$")) {
              keyA = keyA.substring(0, keyA.length - 1);
            }
            if (keyB.match("^[0-9]+.[A-Z]$")) {
              keyB = keyB.substring(0, keyB.length - 1);
            }
            if (keyA === keyB) {
              if (a.IELPONumber.slice(-1) < b.IELPONumber.slice(-1)) {
                keyA--;
              } else {
                keyB--;
              }
            }
            return keyA - keyB;
          });
        }
        if (parameters === "&sort=-ponumber") {
          invoiceArray.sort(function (a, b) {
            let keyA = a.IELPONumber;
            let keyB = b.IELPONumber;
            if (keyA.match("^[0-9]+.[A-Z]$")) {
              keyA = keyA.substring(0, keyA.length - 1);
            }
            if (keyB.match("^[0-9]+.[A-Z]$")) {
              keyB = keyB.substring(0, keyB.length - 1);
            }
            if (keyA === keyB) {
              if (a.IELPONumber.slice(-1) < b.IELPONumber.slice(-1)) {
                keyA--;
              } else {
                keyB--;
              }
            }
            return keyB - keyA;
          });
        }

        store.dispatch(updateOutstandingInvoicesData(invoiceArray));
        store.dispatch(unSetLoading());
      })
      .catch((error) => {
        error.customer = customerID;
        this.service.handleError(error, false);
      });
  }

  getPayHistory(customerID, page = 1, update = false, params) {
    let parameters = params !== `` ? `${params}` : "";
    store.dispatch(setPayHistoryPageLoading());

    return this.service
      ._withToken()
      .get(
        `nexus/customer/${customerID}/payhistorydetails?${parameters}&page=${page}`,
        {}
      )
      .then((result) => {
        const payHistoryArray = Object.keys(result.data.data).map((key) => {
          const obj = result.data.data[key];
          return {
            ielPo: obj.ponumber.toFixed(0),
            customerPo: obj.customerpopreview,
            invoiceDate: obj.invoicedate,
            datePaid: obj.paiddate,
            amountPaid: obj.invoicepayment,
          };
        });
        const totalItems = result.total;
        const totalPages = result.last_page;
        const currentPage = result.current_page;
        store.dispatch(
          update
            ? updatePayHistoryPageData(payHistoryArray)
            : setPayHistoryPageData(payHistoryArray)
        );
        store.dispatch(updatePayHistoryPageTotal(totalItems));
        store.dispatch(updatePayHistoryPagePages(totalPages));
        store.dispatch(updatePayHistoryPageCurrentPage(currentPage));
        store.dispatch(updatePayHistoryPageLoading());
      })
      .catch((error) => {
        error.customer = customerID;
        this.service.handleError(error, false);
      });
  }
}
