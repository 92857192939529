// import React from 'react'
import React, { useState } from "react";
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Button } from '@intxlog/iuilib'

import WrapperBoxWithTitle from 'components/WrapperBoxWithTitle'

//import components
import TextWithLabelEditable from 'components/TextWithLabelEditable'
import ConfirmationModal from 'components/ConfirmationModal'

//import css
import styles from './styles.module.scss'
import ToggleSwitch from '../ToggleSwitch'

const EditUserPage = (({
    switchArray,
    selectedUser,
    isDisabled,
    bgContrast,
    saveUserProfile,
    saveUserPermissions,
    disableUser,
    enableUser,
    updateSwitchArray,
    selectAll,
    clearAll,
    reset
}) => {
    // Constants for modal stuff
    let profile = "profile"
    let permissions = "permissions"
    let disable = "disable"
    let enable = "enable"

    // Current user stuff, this is used when the admin wants to edit the user profile
    var firstName = selectedUser ? selectedUser.firstname : ""
    var lastName = selectedUser ? selectedUser.lastname : ""
    var email = selectedUser ? selectedUser.email : ""

    // User profile stuff
    const [isEditingUserProfile, setIsEditingUserProfile] = useState(false);

    // Modal stuff
    const [displayModal, setModal] = useState(false);
    const [modalHeader, setModalHeader] = useState("");
    const [modalDescription, setModalDescription] = useState("");
    const [modalYesAction, setModalYesAction] = useState(() => { });
    const [modelCancelAction, setModalCancelAction] = useState(() => { });

    // Update the switch array with the current values of sliders
    const handleSwitchChange = (id, nextChecked) => {
        var perm = { [id]: nextChecked }

        updateSwitchArray(perm);
    }

    const handleModal = (focus) => {
        var currentUser = {
            id: selectedUser.api_user_id,
            name: firstName + " " + lastName,
            email: email
        }

        switch (focus) {
            case "profile":
                setModalHeader("Save User Profile")
                setModalDescription("You are about to save user " + currentUser.name + " with email " + currentUser.email + ".");
                setModalYesAction(() => () => { setModal(false); saveUserProfile(currentUser); setIsEditingUserProfile(false); });
                setModalCancelAction(() => () => { setModal(false); setIsEditingUserProfile(false) });
                setModal(true)
                break;

            case "permissions":
                var userWithPermissions = { ...currentUser, permissions: switchArray };

                setModalHeader("Save User Permissions")
                setModalDescription("You are about to save permissions for user " + currentUser.name + ".");
                setModalYesAction(() => () => { setModal(false); saveUserPermissions(userWithPermissions); });
                setModalCancelAction(() => () => { setModal(false); });
                setModal(true)

                break;

            case "disable":
                setModalHeader("Disable User")
                setModalDescription("You are about to disable user " + currentUser.name + ". If you disable this user, you will be able to restore their access to Nexus later by clicking 'Enable' at the bottom of the page.");
                setModalYesAction(() => () => { setModal(false); disableUser(currentUser); });
                setModalCancelAction(() => () => { setModal(false); });
                setModal(true)

                break;

            case "enable":
                setModalHeader("Enable User")
                setModalDescription("You are about to enable user " + currentUser.name + ". This will give them access to Nexus.");
                setModalYesAction(() => () => { setModal(false); enableUser(currentUser); });
                setModalCancelAction(() => () => { setModal(false); });
                setModal(true)

                break;

            default:
                break;
        }
    }

    return (
        <div className={styles.container}>
            <div className={classNames(
                bgContrast === 'light' ? styles.light : null
            )}>

                {/* Begin user info */}
                <div className={styles.userInfoContainer}>
                    <div className={selectedUser ? null : styles.disabledContainer}>
                        <WrapperBoxWithTitle
                            padding
                        >
                            <div className={styles.container}>
                                <div className={styles.infoWrapper}>
                                    <div className={styles.nameWrapper}>User Profile
                                        <Button
                                            disabled={isDisabled}
                                            className={styles.topRightButtons}
                                            size="small"
                                            text={isEditingUserProfile ? "Save Profile" : "Edit Profile"}
                                            onClick={() => {
                                                if (isEditingUserProfile) {
                                                    handleModal(profile)
                                                } else {
                                                    setIsEditingUserProfile(true)
                                                }
                                            }}
                                        ></Button>

                                    </div>
                                </div>
                                <div className={styles.userInfoWrapper}>
                                    <TextWithLabelEditable
                                        idForInput="firstNameInput"
                                        onChange={(e) => { firstName = e }}
                                        text={selectedUser ? selectedUser.firstname : "None"}
                                        labelText={`First Name:`}
                                        editing={isEditingUserProfile}
                                    ></TextWithLabelEditable>
                                    <TextWithLabelEditable
                                        idForInput="lastNameInput"
                                        onChange={(e) => { lastName = e }}
                                        text={selectedUser ? selectedUser.lastname : "None"}
                                        labelText={`Last Name:`}
                                        editing={isEditingUserProfile}
                                    ></TextWithLabelEditable>
                                    <TextWithLabelEditable
                                        idForInput="emailInput"
                                        onChange={(e) => { email = e }}
                                        text={selectedUser ? selectedUser.email : "None"}
                                        labelText={`Email:`}
                                        editing={false} //Hardcoded to false since this is a non editable field
                                    ></TextWithLabelEditable>
                                    <TextWithLabelEditable
                                        text={selectedUser ? selectedUser.lastactivedt : "None"}
                                        labelText={`Last Active Date:`}
                                        editing={false} //Hardcoded to false since this is a non editable field
                                    ></TextWithLabelEditable>
                                </div>
                            </div>
                        </WrapperBoxWithTitle>
                    </div>
                </div>
                {/* End user info */}

                {/* Begin permissions container */}
                <div className={styles.permissionsContainer}>
                    <div className={selectedUser ? null : styles.disabledContainer}>
                        <WrapperBoxWithTitle
                            padding
                        >
                            <div className={styles.container}>
                                <div className={styles.infoWrapper}>
                                    <div className={styles.nameWrapper}>Permissions
                                        <Button
                                            className={styles.topRightButtons}
                                            size="small"
                                            text="Select All"
                                            onClick={() => { selectAll() }}
                                            disabled={isDisabled}
                                        ></Button>
                                        <Button
                                            className={styles.topRightButtons}
                                            size="small"
                                            text="Clear All"
                                            onClick={() => { clearAll() }}
                                            disabled={isDisabled}
                                        ></Button>
                                        <Button
                                            className={styles.topRightButtons}
                                            size="small"
                                            text="Reset"
                                            onClick={() => { reset() }}
                                            disabled={isDisabled}
                                        ></Button>
                                    </div>
                                </div>
                                <div className={styles.permissionsWrapper}>

                                    {/* Nexus idocs perms */}
                                    <ToggleSwitch
                                        id="IDOCS_SUBMIT"
                                        text="Allow Document Submit"
                                        isChecked={switchArray.IDOCS_SUBMIT}
                                        onChange={(id, nextChecked) => { handleSwitchChange(id, nextChecked) }}
                                        disabled={isDisabled}
                                        title="Allow the user to submit files into the Documents section for a load"
                                    />
                                    <ToggleSwitch
                                        id="IDOCS_DOWNLOAD"
                                        text="Allow Document Download"
                                        isChecked={switchArray.IDOCS_DOWNLOAD}
                                        onChange={(id, nextChecked) => { handleSwitchChange(id, nextChecked) }}
                                        disabled={isDisabled}
                                        title="Allow the user to download files from the Documents section for a load"
                                    />

                                    {/* load request */}
                                    <ToggleSwitch
                                        id="LOAD_REQUEST_GENERATE"
                                        text="Allow Generate Load Request"
                                        isChecked={switchArray.LOAD_REQUEST_GENERATE}
                                        onChange={(id, nextChecked) => { handleSwitchChange(id, nextChecked) }}
                                        disabled={isDisabled}
                                        title="Allow the user to generate a load request"
                                    />

                                    {/* pay now */}
                                    <ToggleSwitch
                                        id="PAY_NOW"
                                        text="Allow Pay Now"
                                        isChecked={switchArray.PAY_NOW}
                                        onChange={(id, nextChecked) => { handleSwitchChange(id, nextChecked) }}
                                        disabled={isDisabled}
                                        title="Allow the user to Prepay loads and Pay invoices"
                                    />

                                    {/* subscribe */}
                                    <ToggleSwitch
                                        id="SUBSCRIBE_TO_LOAD"
                                        text="Allow Subscribe to Load"
                                        isChecked={switchArray.SUBSCRIBE_TO_LOAD}
                                        onChange={(id, nextChecked) => { handleSwitchChange(id, nextChecked) }}
                                        disabled={isDisabled}
                                        title="Allow the user to subscribe to a load (receive updates)"
                                    />

                                     {/* pay history */}
                                     <ToggleSwitch
                                        id="PAY_HISTORY"
                                        text="Allow Pay History"
                                        isChecked={switchArray.PAY_HISTORY}
                                        onChange={(id, nextChecked) => { handleSwitchChange(id, nextChecked) }}
                                        disabled={isDisabled}
                                        title="Allow the user to view payment history"
                                    />

                                    {/* ar view only vs export */}
                                    <ToggleSwitch
                                        id="AR_VIEW"
                                        text="Allow View AR"
                                        isChecked={switchArray.AR_VIEW}
                                        onChange={(id, nextChecked) => { handleSwitchChange(id, nextChecked) }}
                                        disabled={isDisabled}
                                        title="Allow the user to view AR information (A/R billed and unbilled loads)"
                                    />
                                    <ToggleSwitch
                                        id="AR_EXPORT"
                                        text="Allow Export AR"
                                        isChecked={switchArray.AR_EXPORT}
                                        onChange={(id, nextChecked) => { handleSwitchChange(id, nextChecked) }}
                                        disabled={isDisabled}
                                        title="Allow the user to export AR data to CSV"
                                    />

                                    {/* load history view only view export */}
                                    <ToggleSwitch
                                        id="LOAD_HISTORY_VIEW"
                                        text="Allow View Load History"
                                        isChecked={switchArray.LOAD_HISTORY_VIEW}
                                        onChange={(id, nextChecked) => { handleSwitchChange(id, nextChecked) }}
                                        disabled={isDisabled}
                                        title="Allow the user to view load request history"
                                    />
                                    <ToggleSwitch
                                        id="LOAD_HISTORY_EXPORT"
                                        text="Allow Export Load History"
                                        isChecked={switchArray.LOAD_HISTORY_EXPORT}
                                        onChange={(id, nextChecked) => { handleSwitchChange(id, nextChecked) }}
                                        disabled={isDisabled}
                                        title="Allow the user to export load request history to CSV"
                                    />

                                    {/* access to today, in transit etc */}
                                    <ToggleSwitch
                                        id="TODAYS_LOADS_VIEW"
                                        text="Allow View Today's Loads"
                                        isChecked={switchArray.TODAYS_LOADS_VIEW}
                                        onChange={(id, nextChecked) => { handleSwitchChange(id, nextChecked) }}
                                        disabled={isDisabled}
                                        title="Allow the user to view Today's Loads"
                                    />
                                    <ToggleSwitch
                                        id="IN_TRANSIT_VIEW"
                                        text="Allow View In Transit"
                                        isChecked={switchArray.IN_TRANSIT_VIEW}
                                        onChange={(id, nextChecked) => { handleSwitchChange(id, nextChecked) }}
                                        disabled={isDisabled}
                                        title="Allow the user to view In Transit Loads"
                                    />
                                    <ToggleSwitch
                                        id="DELIVERED_VIEW"
                                        text="Allow View Delivered"
                                        isChecked={switchArray.DELIVERED_VIEW}
                                        onChange={(id, nextChecked) => { handleSwitchChange(id, nextChecked) }}
                                        disabled={isDisabled}
                                        title="Allow the user to view Delivered Loads"
                                    />
                                    <ToggleSwitch
                                        id="FUTURE_VIEW"
                                        text="Allow View Future"
                                        isChecked={switchArray.FUTURE_VIEW}
                                        onChange={(id, nextChecked) => { handleSwitchChange(id, nextChecked) }}
                                        disabled={isDisabled}
                                        title="Allow the user to view Future Loads"
                                    />
                                </div>

                                {/* Bottom right button */}
                                <div className={styles.infoWrapper}>
                                    <Button
                                        id="savePerms"
                                        className={styles.bottomRightButtons}
                                        size="small"
                                        text="Save Permissions"
                                        type="primary"
                                        disabled={isDisabled}
                                        onClick={() => { handleModal(permissions) }}
                                    ></Button>
                                </div>
                            </div>
                        </WrapperBoxWithTitle>
                    </div>
                </div>
                {/* End permissions container */}

                {/* Begin actions container */}
                <div className={styles.actionsContainer}>
                    <div className={selectedUser ? null : styles.disabledContainer}>
                        <WrapperBoxWithTitle
                            padding
                        >
                            <div className={styles.container}>
                                <div className={styles.infoWrapper}>
                                    <div className={styles.nameWrapper}>Actions</div>
                                </div>
                                <div className={styles.contactWrapper}>
                                    <div className={styles.buttons}>
                                        <Button
                                            className={styles.buttonDisable}
                                            text={isDisabled ? 'Enable' : `Disable`}
                                            size={`small`}
                                            onClick={() => { handleModal(isDisabled ? enable : disable) }}
                                        ></Button>
                                    </div>
                                </div>
                            </div>
                        </WrapperBoxWithTitle>
                    </div>
                </div>
                {/* End actions container */}
            </div>
            {displayModal ? <ConfirmationModal
                openModal={() => { setModal(true); }}
                closeModal={modelCancelAction}
                selectedCancel={modelCancelAction}
                selectedYes={modalYesAction}
                headerText={modalHeader}
                descriptionText={modalDescription}
            /> : ""}
        </div>
    )
})

EditUserPage.propTypes = {
    userFullName: PropTypes.string,
    addPadding: PropTypes.bool,
    saveUserProfile: PropTypes.func,
    saveUserPermissions: PropTypes.func,
    disableUser: PropTypes.func,
    enableUser: PropTypes.func
}

EditUserPage.defaultProps = {
    bgContrast: 'light'
}

export default EditUserPage