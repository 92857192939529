import { createAction } from "redux-actions";

// Actions
export const UPDATE_DATA = "customerPortal/outstandingInvoices/UPDATE";
export const UPDATE_MODIFIED_DATA =
  "customerPortal/outstandingInvoices/UPDATE_MODIFIED_DATA";
export const ADD_INPUT_ARRAY =
  "customerPortal/outstandingInvoices/ADD_INPUT_ARRAY";
export const REMOVE_VALUE_INPUT_ARRAY =
  "customerPortal/outstandingInvoices/REMOVE_VALUE_INPUT_ARRAY";
export const UPDATE_VALUE_INPUT_ARRAY =
  "customerPortal/outstandingInvoices/UPDATE_VALUE_INPUT_ARRAY";
export const UPDATE_SORT_FIELD =
  "customerPortal/outstandingInvoices/UPDATE_SORT_FIELD";
export const TOGGLE_SORT_DIRECTION =
  "customerPortal/outstandingInvoices/TOGGLE_SORT_DIRECTION";
export const SET_SORT_DIRECTION =
  "customerPortal/outstandingInvoices/SET_SORT_DIRECTION";
export const UPDATE_INVOICE_AMOUNT =
  "customerPortal/outstandingInvoices/UPDATE_INVOICE_AMOUNT";
export const UPDATE_EXCEPTION_INPUT_ARRAY =
  "customerPortal/outstandingInvoices/UPDATE_EXCEPTION_INPUT_ARRAY";
export const ADD_ERROR_IF_TEXT_BOX_EMPTY =
  "customerPortal/outstandingInvoices/ADD_ERROR_IF_TEXT_BOX_EMPTY";
export const REMOVE_ERROR_IF_TEXT_BOX_EMPTY =
  "customerPortal/outstandingInvoices/REMOVE_ERROR_IF_TEXT_BOX_EMPTY";
export const UNSET_LOADING = "customerPortal/outstandingInvoices/UNSET_LOADING";
export const SET_LOADING = "customerPortal/outstandingInvoices/SET_LOADING";

// New serversortandfilter calls
export const CSV_SET_LOADING = 'customerPortal/outstandingInvoices/CSV_SET_LOADING'
export const UNSET_LOADING_CSV = 'customerPortal/outstandingInvoices/UNSET_LOADING_CSV'
export const UPDATE_SORT_PARAMS = 'customerPortal/outstandingInvoices/UPDATE_SORT_PARAMS'
export const UPDATE_PARAMS = 'customerPortal/outstandingInvoices/UPDATE_PARAMS'
export const UPDATE_FILTER_PARAMS = 'customerPortal/outstandingInvoices/UPDATE_FILTER_PARAMS'
export const SET_ACTIVE_FILTERS = 'customerPortal/outstandingInvoices/SET_ACTIVE_FILTERS'
export const UPDATE_ACTIVE_FILTERS = 'customerPortal/outstandingInvoices/UPDATE_ACTIVE_FILTERS'
export const SET_PENDING_FILTERS = 'customerPortal/outstandingInvoices/SET_PENDING_FILTERS'
export const SUBMIT_PENDING_FILTERS = 'customerPortal/outstandingInvoices/SUBMIT_PENDING_FILTERS'

export const RESET = "customerPortal/outstandingInvoices/RESET";

const initialState = {
  schema: [
    {
      text: `IEL PO Number`,
      val: `ponumber`,
      appValue: `IELPONumber`,
      sort: true,
    },
    {
      text: `Customer PO`,
      val: `customerpo`,
      appValue: `customerPO`,
      sort: true,
    },
    {
      text: `Invoice Date`,
      val: `invoicedate`,
      appValue: `invoiceDate`,
      sort: true,
    },
    { text: `Days Old`, val: `age`, appValue: `age`, sort: true },
    {
      text: `Total Balance`,
      val: `amount`,
      appValue: `totalBalance`,
      sort: true,
    },
    {
      text: `Amount Paid`,
      val: `paidamount`,
      appValue: `amountPaid`,
      sort: true,
    },
    {
      text: `Outstanding Balance`,
      val: `balance`,
      appValue: `outstandingBalance`,
      sort: true,
    },
    { text: `Status`, val: `status`, appValue: `paymentStatus`, sort: true },
    { text: `Amount to Pay`, val: ``, appValue: `invoiceBoxes`, custom: true },
  ],
  data: [],
  modifiedData: [],
  sortField: ``,
  direction: ``,
  inputArray: [],
  total: 0,
  numberOfBlankTextBoxes: 0,
  loading: true,

  // New serversortandfilter calls
  displayLoadingMessageCSV: false,
  params: ``,
  sortParams: ``,
  filterParams: ``,
  activeFilters: [],
  pendingFilters: [],
};

// Reducer
export default function outstandingInvoices(state = initialState, action) {
  switch (action.type) {
    case UPDATE_DATA:
      return {
        ...state,
        data: action.payload,
      };
    case UPDATE_MODIFIED_DATA:
      return {
        ...state,
        modifiedData: action.payload,
      };
    case ADD_INPUT_ARRAY:
      const dataObj = {
        id: action.payload.id,
        IELPONumber: action.payload.IELPONumber,
        PO: action.payload.po,
        val: action.payload.val,
        error: action.payload.error,
        exception: action.payload.exception,
        textBoxError: action.payload.textBoxError,
      };
      return {
        ...state,
        inputArray: [...state.inputArray, dataObj],
      };
    case REMOVE_VALUE_INPUT_ARRAY:
      //add new key userValue to inputArray
      const inputArray = [...state.inputArray];
      const dataArray = [...state.data];
      const dataIndex = dataArray.findIndex((x) => x.id === action.payload);
      const inputIndex = inputArray.findIndex((x) => x.id === action.payload);
      //copy uservalue from inputArray
      dataArray[dataIndex].userValue = inputArray[inputIndex]["val"];
      inputArray.splice(inputIndex, 1);
      return {
        ...state,
        inputArray: inputArray,
      };
    case UPDATE_VALUE_INPUT_ARRAY:
      const updateArray = [...state.inputArray];
      const updateIndex = updateArray.findIndex(
        (x) => x.id === action.payload.id
      );
      const updateObj = { ...updateArray[updateIndex] };
      const dataArr = [...state.data];
      const dataIndexUPDATE = updateArray.findIndex(
        (x) => x.id === action.payload.id
      );
      //update uservalue with current value
      dataArr[dataIndexUPDATE].userValue = action.payload.val;
      updateObj.val = action.payload.val;
      updateObj.error = action.payload.error;
      updateArray[updateIndex] = updateObj;
      return {
        ...state,
        inputArray: [...updateArray],
      };
    case UPDATE_SORT_FIELD:
      return {
        ...state,
        sortField: action.payload,
        direction: `asc`,
      };
    case TOGGLE_SORT_DIRECTION:
      return {
        ...state,
        direction: state.direction === `asc` ? `desc` : `asc`,
      };
    case SET_SORT_DIRECTION:
      return {
        ...state,
        direction: action.payload,
      };
    case UPDATE_INVOICE_AMOUNT:
      return {
        ...state,
        total: action.payload,
      };
    case UPDATE_EXCEPTION_INPUT_ARRAY:
      const updateExceptionArray = [...state.inputArray];
      const updateExceptionIndex = updateExceptionArray.findIndex(
        (x) => x.id === action.payload.id
      );
      const updateExceptionObj = {
        ...updateExceptionArray[updateExceptionIndex],
      };
      updateExceptionObj.exception = action.payload.exception;
      updateExceptionArray[updateExceptionIndex] = updateExceptionObj;
      return {
        ...state,
        inputArray: [...updateExceptionArray],
      };
    case ADD_ERROR_IF_TEXT_BOX_EMPTY:
      const updateTextBoxErrorArray = [...state.inputArray];
      const updateTextBoxIndex = updateTextBoxErrorArray.findIndex(
        (x) => x.id === action.payload.id
      );
      const updateTextBoxObj = {
        ...updateTextBoxErrorArray[updateTextBoxIndex],
      };
      updateTextBoxObj.textBoxError = action.payload.textBoxError;
      updateTextBoxErrorArray[updateTextBoxIndex] = updateTextBoxObj;
      return {
        ...state,
        inputArray: [...updateTextBoxErrorArray],
      };
    case REMOVE_ERROR_IF_TEXT_BOX_EMPTY:
      const removeTextBoxErrorArray = [...state.inputArray];
      const removeTextBoxIndex = removeTextBoxErrorArray.findIndex(
        (x) => x.id === action.payload.id
      );
      const removeTextBoxObj = {
        ...removeTextBoxErrorArray[removeTextBoxIndex],
      };
      removeTextBoxObj.textBoxError = action.payload.textBoxError;
      removeTextBoxErrorArray[removeTextBoxIndex] = removeTextBoxObj;
      return {
        ...state,
        inputArray: [...removeTextBoxErrorArray],
      };
    case UNSET_LOADING:
      return {
        ...state,
        loading: false,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };

    case CSV_SET_LOADING:
      return {
        ...state,
        displayLoadingMessageCSV: true,
      };
    case UNSET_LOADING_CSV:
      return {
        ...state,
        displayLoadingMessageCSV: false,
      };
    case UPDATE_SORT_PARAMS:
      return {
        ...state,
        sortParams: action.payload,
      };
    case UPDATE_PARAMS:
      return {
        ...state,
        params: action.payload,
      };
    case UPDATE_FILTER_PARAMS:
      return {
        ...state,
        filterParams: action.payload,
      };
    case SET_ACTIVE_FILTERS:
      return {
        ...state,
        activeFilters: action.payload,
      };
    case UPDATE_ACTIVE_FILTERS:
      return {
        ...state,
        activeFilters: [...state.activeFilters],
      };
    case SET_PENDING_FILTERS:
      return {
        ...state,
        pendingFilters: action.payload,
      };
    case SUBMIT_PENDING_FILTERS:
      return {
        ...state,
        activeFilters: [...state.activeFilters, ...state.pendingFilters],
        pendingFilters: [],
      };
    case RESET:
      return initialState;
    default:
      return state;
  }
}

// Action Creators
export const updateOutstandingInvoicesData = createAction(UPDATE_DATA);
export const addAmountToTotalOutstandingInvoice = createAction(ADD_INPUT_ARRAY);
export const removeAmountFromTotalOutstandingInvoice = createAction(
  REMOVE_VALUE_INPUT_ARRAY
);
export const updateAmountInTotalOutstandingInvoice = createAction(
  UPDATE_VALUE_INPUT_ARRAY
);
export const updateModifiedOutstandingInvoicesData =
  createAction(UPDATE_MODIFIED_DATA);
export const updateSortFieldOutstandingInvoicesData =
  createAction(UPDATE_SORT_FIELD);
export const toggleSortDirectionOutstandingInvoicesData = createAction(
  TOGGLE_SORT_DIRECTION
);
export const setSortDirectionOutstandingInvoicesData =
  createAction(SET_SORT_DIRECTION);
export const updateInvoiceAmount = createAction(UPDATE_INVOICE_AMOUNT);
export const updateExceptionInputArray = createAction(
  UPDATE_EXCEPTION_INPUT_ARRAY
);
export const setErrorTrueIfTextBoxEmpty = createAction(
  ADD_ERROR_IF_TEXT_BOX_EMPTY
);
export const setErrorFalseIfTextBoxNotEmpty = createAction(
  REMOVE_ERROR_IF_TEXT_BOX_EMPTY
);
export const unSetLoading = createAction(UNSET_LOADING);
export const setLoading = createAction(SET_LOADING);

// New serversortandfilter calls
export const setOutstandingInvoicesPageLoadingCsv = createAction(CSV_SET_LOADING)
export const unSetOutstandingInvoicesPageLoadingCsv = createAction(UNSET_LOADING_CSV)
export const updateSortFieldOutstandingInvoices = createAction(UPDATE_SORT_FIELD)
export const setDirectionOutstandingInvoices = createAction(SET_SORT_DIRECTION)
export const updateSortParamsOutstandingInvoices = createAction(UPDATE_SORT_PARAMS)
export const updateParamsOutstandingInvoices = createAction(UPDATE_PARAMS)
export const updateFilterParamsOutstandingInvoices = createAction(UPDATE_FILTER_PARAMS)
export const setActiveFiltersOutstandingInvoices = createAction(SET_ACTIVE_FILTERS)
export const updateActiveFiltersOutstandingInvoices = createAction(UPDATE_ACTIVE_FILTERS)
export const setPendingFiltersOutstandingInvoices = createAction(SET_PENDING_FILTERS)
export const submitPendingFiltersOutstandingInvoices = createAction(SUBMIT_PENDING_FILTERS)
export const toggleSortDirectionOutstandingInvoices = createAction(TOGGLE_SORT_DIRECTION)


export const resetInvoice = createAction(RESET);
