import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import SweetAlert from 'sweetalert2-react';
import Cookies from 'js-cookie'

//import components
import CompanyName from 'components/CompanyName'

//import modules
import {
	saveSelectedCustomerId,
	saveSelectedOption
} from 'modules/multiCustomers'

export class CompanyNameContainer extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			selectedOption: null,
			showModal: false
		}
	}

	componentDidMount() {
		const { multiCustomerArray, selectedOptionFromStore } = this.props
		const { selectedOption } = this.state

		if (multiCustomerArray.length > 0 && selectedOption === null) {
			this.setState({
				selectedOption: selectedOptionFromStore
					? selectedOptionFromStore
					: multiCustomerArray[0]
			})
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const { multiCustomerArray, selectedOptionFromStore } = this.props
		const { selectedOption } = this.state
		if (multiCustomerArray.length > 0 && selectedOption === null) {
			this.setState({
				selectedOption: selectedOptionFromStore
					? selectedOptionFromStore
					: multiCustomerArray[0]
			})
		}
		if (prevProps.selectedOption !== this.state.selectedOption && selectedOption !== null) {
			this.handleSetCustomer()
		}
	}

	handleSelected = selectedOption => {
		this.setState({ selectedOption })
		const { selectedOptionFromStore } = this.props
		if (selectedOptionFromStore.value !== selectedOption.value) {
			this.setState({
				showModal: true
			})
			setTimeout(function(){
				this.setState({showModal: false})
			}.bind(this), 1500
			)
		}
	}

	cookieCreator = () => {
		const { selectedOption } = this.state
		Cookies.set('selectedOption', { label: `${selectedOption.label}`, value: selectedOption.value })
		Cookies.set('user', this.props.email)
	}

	handleSetCustomer = () => {
		this.cookieCreator()
		this.props.saveSelectedOption(this.state.selectedOption)
		this.props.saveSelectedCustomerId(this.state.selectedOption.value)
	}

	renderAfter = () => {
		if (this.props.selectedOptionFromStore === null) {
			this.props.saveSelectedOption(this.state.selectedOption)
		}
		const { selectedOption } = this.state
		const { multiCustomerArray, name } = this.props
		if (multiCustomerArray.length > 0 && selectedOption !== null) {
			return (
				<div>
					<SweetAlert
						show={this.state.showModal}
						title={'Success'}
						text={'Customer changed'}
						showConfirmButton={false}
					></SweetAlert>

					<CompanyName
						selectedOption={selectedOption}
						name={name}
						multiCustomerArray={multiCustomerArray}
						handleSelected={this.handleSelected}
						handleSetCustomer={this.handleSetCustomer}
						/>
					</div>
			)
		} else {
			return null
		}
	}
	render() {
		return this.renderAfter()
	}
}

const mapStateToProps = state => ({
	name: state.customerInfo.name,
	multiCustomerArray: state.multiCustomer.multiCustomerArray,
	selectedOptionFromStore: state.multiCustomer.selectedOption,
	email: state.userInfo.email
})

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			saveSelectedCustomerId,
			saveSelectedOption
		},
		dispatch
	)

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CompanyNameContainer)
