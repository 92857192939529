import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import PageWithTitle from 'components/PageWithTitle'
import PayHistory from 'components/PayHistory'
import PayHistoryLine from 'components/PayHistoryLine'
import ServerSortAndFilter from 'components/ServerSortAndFilter'
import WrapperBoxWithTitle from 'components/WrapperBoxWithTitle'

import DataViewContainer from 'containers/DataViewContainer'

import {
  setPayHistoryPageLoadingCsv,
  updateSortFieldPayHistory,
  setDirectionPayHistory,
  updateSortParamsPayHistory,
  updateParamsPayHistory,
  updateFilterParamsPayHistory,
  setActiveFiltersPayHistory,
  updateActiveFiltersPayHistory,
  setPendingFiltersPayHistory,
  submitPendingFiltersPayHistory,
  toggleSortDirectionPayHistory
} from 'modules/payHistoryPage'

//import services
import IELAPICustomerService from '../../services/IELAPICustomerService'
import IELAPIDocumentService from '../../services/IELAPIDocumentService'

import store from '../../store'

export class PayHistoryContainer extends React.Component {

  componentDidMount() {
    this.customerService = new IELAPICustomerService();
    this.docService = new IELAPIDocumentService();

    if (this.props.multiCustomerSelectedId !== null) {
      this.customerService.getPayHistory(this.props.multiCustomerSelectedId, 1, false, `${this.props.params}`)
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.params !== this.props.params && this.props.customerID !== null) {
      this.customerService.getPayHistory( this.props.multiCustomerSelectedId, 1, false, `${this.props.params}`)
    }
		if (prevProps.multiCustomerSelectedId !== this.props.multiCustomerSelectedId) {
			this.customerService.getPayHistory(this.props.multiCustomerSelectedId, 1, false)
		}
  }
  
  getMorePayHistory = () => {
    if (this.props.currentPage < this.props.pages) {
      this.customerService.getPayHistory(this.props.multiCustomerSelectedId, this.props.currentPage + 1, true, `${this.props.params}`)
    }
  }

  exportFile = () => {
    this.docService.exportPayHistoryCsv(this.props.multiCustomerSelectedId)  
  }

  render() {
    let layout = null

    const LinesPayHistory = this.props.data.map(data => (
			<PayHistoryLine key={data.id} data={data}></PayHistoryLine>
		))

    const table = (
      <>
        <DataViewContainer
          schema={this.props.schema}
          useTableView={this.props.viewportWidth > 1100}
          rawData={this.props.data}
          lines={LinesPayHistory}
          getMoreData={this.getMorePayHistory}
          loading={this.props.loading}
          sortKey={`val`}
          lazyLoad
          sortField={this.props.sortField}
          showFilters
          direction={this.props.direction}
          toggleDirection={this.props.toggleSortDirectionPayHistory}
          updateSortField={this.props.updateSortFieldPayHistory}
          setDirection={this.props.setDirectionPayHistory}
          activeFilters={this.props.activeFilters}
          pendingFilters={this.props.pendingFilters}
          submitPendingFilters={this.props.submitPendingFiltersPayHistory}
          setActiveFilters={this.props.setActiveFiltersPayHistory}
          setPendingFilters={this.props.setPendingFiltersPayHistory}
        ></DataViewContainer>        
        <ServerSortAndFilter
          sortByField={this.props.sortField}
          filters={this.props.activeFilters}
          direction={this.props.direction}
          sortParams={this.props.sortParams}
          filterParams={this.props.filterParams}
          updateParams={this.props.updateParamsPayHistory}
          updateSortParams={this.props.updateSortParamsPayHistory}
          updateFilterParams={this.props.updateFilterParamsPayHistory}
        ></ServerSortAndFilter>
      </> 
    )
  
    if (store.getState().userInfo.permissions ? store.getState().userInfo.permissions.PAY_HISTORY : false) {
      layout = <PayHistory
        table={table}
        exportFile={this.exportFile}
        displayLoadingMessage={this.props.isLoading}
      ></PayHistory>
    } else {
      layout = <WrapperBoxWithTitle padding textAlignCenter>
        <div>You do not have permission to view Payment History.</div>
      </WrapperBoxWithTitle>
    }
   
    return (
      <>
        <PageWithTitle 
          title={this.props.pageTitle}
          bgContrast={'dark'}
        >
          {layout}
        </PageWithTitle>
      </>
    )
  }
}

const mapStateToProps = state => ({
  ...state.payHistoryPage,
  pageTitle: state.app.pageTitle,
  schema: state.payHistoryPage.schema,
  viewportWidth: state.viewport.width,
  multiCustomerSelectedId: state.multiCustomer.selectedID,
  data: state.payHistoryPage.data,
  isLoading: state.payHistoryPage.displayLoadingMessageCSV
})

const mapDispatchToProps = dispatch => bindActionCreators({
  setPayHistoryPageLoadingCsv,
  updateSortFieldPayHistory,
  setDirectionPayHistory,
  updateSortParamsPayHistory,
  updateParamsPayHistory,
  updateFilterParamsPayHistory,
  setActiveFiltersPayHistory,
  updateActiveFiltersPayHistory,
  setPendingFiltersPayHistory,
  submitPendingFiltersPayHistory,
  toggleSortDirectionPayHistory
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PayHistoryContainer)

