import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Button, Modal } from '@intxlog/iuilib'

//import containers
import DataViewContainer from 'containers/DataViewContainer'

//import components
import ServerSortAndFilter from 'components/ServerSortAndFilter'
import PageWithTitle from 'components/PageWithTitle'
import LoadLine from 'components/LoadLine'
import LoadLineButton from 'components/LoadLineButton'
import WrapperBoxWithTitle from 'components/WrapperBoxWithTitle'
import ReceiveUpdatesButton from 'components/ReceiveUpdatesButton'
import DocumentFilters from 'components/DocumentFilters'
import ReceiveUpdatesModalContainer from 'containers/ReceiveUpdatesModalContainer'

import IELAPILoadService from '../../services/IELAPILoadService'

//import actions
import {
  updateSortFieldLoads,
  toggleSortDirectionLoads,
  setSortDirectionLoads,
  updateParamsLoads,
  updateSortParamsLoads,
  updateFilterParamsLoads,
  setActiveFiltersLoads,
  updateActiveFiltersLoads,
  setPendingFiltersLoads,
  submitPendingFiltersLoads,
  resetLoads
} from 'modules/loads'

export class LoadSearchContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showDocFilters: false,
      filters: {
        bol: {
          value: false,
          disabled: false
        },
        invoice: {
          value: false,
          disabled: false
        },
        none: {
          value: false,
          disabled: false
        }
      },
      lines: [],
      customTableValues: {
        button: {
          component: LoadLineButton,
        },
        subscribe: {
          component: ReceiveUpdatesButton
        }
      }
    }
  }

  componentDidMount = () => {
    this.loadService = new IELAPILoadService();

    let activeFilters = this.props.activeFilters
    if(this.props.customerID !== null) {
      if (activeFilters.length === 0) {
        this.loadService.getLoads('', '', 1, false, this.props.customerID)
      } else {
        this.loadService.getLoads('', `${this.props.params}`, 1, false, this.props.customerID)
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.params !== this.props.params && this.props.customerID !== null) {
      this.loadService.getLoads('', `${this.props.params}`, 1, false, this.props.customerID)
    }
    if (prevProps.customerID !== this.props.customerID && this.props.customerID !== null) {
      this.loadService.getLoads('', `${this.props.params}`, 1, false, this.props.customerID)
    }

    if (this.props.loads !== prevProps.loads || this.props.showModal !== prevProps.showModal) {
      const loads = this.props.loads
      const lines = loads.map(load =>
        <LoadLine
          key={load.id}
          showModal={this.props.showModal}
          loadID={this.props.id}
          {...load}
        ></LoadLine>
      )
      this.setState({
        lines
      })
    }
  }

  getMoreLoads = () => {
    if (this.props.currentPage < this.props.pages) {
      this.loadService.getLoads(null, this.props.params, this.props.currentPage + 1, true, this.props.customerID)
    }
  }

  // Document filter stuff
  openCustomFilters = () => {
    this.setState({showDocFilters: true});
  }

  closeCustomFilters = () => {
    this.setState({showDocFilters: false});

    //If there are no active filters lets clear the modal
    //This is ugly but it works for now
    if (this.props.activeFilters.length === 0) {
      this.setState({
        filters: {
          bol: {
            value: false,
            disabled: false
          },
          invoice: {
            value: false,
            disabled: false
          },
          none: {
            value: false,
            disabled: false
          }
        }
      });
    }

    //We also need to check if there are filters but not any document filters.
    //Ex. We could have other filters active but not any document filters,
    //if so we still want to clear out the box if no document filters are active
    let hasDocCategoryFilters = false;
    for (let i = 0; i < this.props.activeFilters.length; i++) {
      if (this.props.activeFilters[i]['id'] === 'documentcategory') {
        //If we have doc cate
        hasDocCategoryFilters = true;
      }
    }

    if (!hasDocCategoryFilters) {
      this.setState({
        filters: {
          bol: {
            value: false,
            disabled: false
          },
          invoice: {
            value: false,
            disabled: false
          },
          none: {
            value: false,
            disabled: false
          }
        }
      });
    }
  }

  customButtons = () => {
    return (
          <Button
            text={`Document Filters`}
            size={`small`}
            onClick={() => this.openCustomFilters()}
          ></Button>
    );
  }

  documentFilters = () => {
    return (
      <div>
        <div>
          <DocumentFilters
            activeFilters={this.props.activeFilters}
            pendingFilters={this.props.pendingFilters}
            setActiveFilters={this.props.setActiveFiltersLoads}
            setPendingFilters={this.props.setPendingFiltersLoads}
            submitPendingFilters={this.props.submitPendingFiltersLoads}
            filters={this.state.filters}
          ></DocumentFilters>
        </div>
      </div>
    );
  }

  // End document filter stuff
  
  render() {
    const showModal = this.props.showModal && this.props.id && this.props.viewportWidth >= 1102 && this.props.pageTitle === 'Load Search' ? <ReceiveUpdatesModalContainer></ReceiveUpdatesModalContainer> : null

    return (
      <PageWithTitle
        title={this.props.pageTitle}
        addPadding
        customButtons={this.customButtons()}
      >
        <WrapperBoxWithTitle>
          <DataViewContainer
            lines={this.state.lines}
            schema={this.props.schema}
            rawData={this.props.loads}
            customTableValues={this.state.customTableValues}
            sortKey={`val`}
            sortField={this.props.sortField}
            direction={this.props.direction}
            toggleDirection={this.props.toggleSortDirectionLoads}
            setDirection={this.props.setSortDirectionLoads}
            updateSortField={this.props.updateSortFieldLoads}
            activeFilters={this.props.activeFilters}
            pendingFilters={this.props.pendingFilters}
            submitPendingFilters={this.props.submitPendingFiltersLoads}
            setActiveFilters={this.props.setActiveFiltersLoads}
            setPendingFilters={this.props.setPendingFiltersLoads}
            showFilters
            useLocalSorting={false}
            loading={this.props.loading}
            getMoreData={this.getMoreLoads}
            showModal={this.props.showModal}
            id={this.props.id}
            lazyLoad
          ></DataViewContainer>
          <ServerSortAndFilter
            sortByField={this.props.sortField}
            filters={this.props.activeFilters}
            direction={this.props.direction}   
            sortParams={this.props.sortParams}
            filterParams={this.props.filterParams}
            updateParams={this.props.updateParamsLoads}
            updateSortParams={this.props.updateSortParamsLoads}
            updateFilterParams={this.props.updateFilterParamsLoads}
          ></ServerSortAndFilter>
          <Modal
            isOpen={this.state.showDocFilters}
            closeFunc={this.closeCustomFilters}
            customContent={this.documentFilters()}
          ></Modal>
        </WrapperBoxWithTitle>
        {showModal}
      </PageWithTitle>
    )
  }
}

const mapStateToProps = state => ({
  ...state.loads,
  pageTitle: state.app.pageTitle,
  viewportWidth: state.viewport.width,
  showModal: state.receiveUpdates.showModal,
  id: state.receiveUpdates.id,
  customerID: state.multiCustomer.selectedID
})

const mapDispatchToProps = dispatch => bindActionCreators({
  updateSortFieldLoads,
  toggleSortDirectionLoads,
  setSortDirectionLoads,
  updateParamsLoads,
  updateSortParamsLoads,
  updateFilterParamsLoads,
  setActiveFiltersLoads,
  updateActiveFiltersLoads,
  setPendingFiltersLoads,
  submitPendingFiltersLoads,
  resetLoads
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoadSearchContainer)