import { createAction } from 'redux-actions'

// Actions
export const SET_LOADS = 'customerPortal/loads/SET_LOADS'
export const UPDATE_LOADS = 'customerPortal/loads/UPDATE_LOADS'
export const UPDATE_TOTAL = 'customerPortal/loads/UPDATE_TOTAL'
export const UPDATE_PAGES = 'customerPortal/loads/UPDATE_PAGES'
export const FETCH_TRAILER_TYPES = 'customerPortal/loads/FETCH_TRAILER_TYPES'
export const UPDATE_CURRENT_PAGE = 'customerPortal/loads/UPDATE_CURRENT_PAGE'
export const UPDATE_SORT_FIELD = 'customerPortal/loads/UPDATE_SORT_FIELD'
export const TOGGLE_SORT_DIRECTION = 'customerPortal/loads/TOGGLE_SORT_DIRECTION'
export const SET_SORT_DIRECTION = 'customerPortal/loads/SET_SORT_DIRECTION'
export const UPDATE_PARAMS = 'customerPortal/loads/UPDATE_PARAMS'
export const UPDATE_SORT_PARAMS = 'customerPortal/loads/UPDATE_SORT_PARAMS'
export const UPDATE_FILTER_PARAMS = 'customerPortal/loads/UPDATE_FILTER_PARAMS'
export const SET_ACTIVE_FILTERS = 'customerPortal/loads/SET_ACTIVE_FILTERS'
export const UPDATE_ACTIVE_FILTERS = 'customerPortal/loads/UPDATE_ACTIVE_FILTERS'
export const SET_PENDING_FILTERS = 'customerPortal/loads/SET_PENDING_FILTERS'
export const SUBMIT_PENDING_FILTERS = 'customerPortal/loads/SUBMIT_PENDING_FILTERS'
export const SET_LOADING = 'customerPortal/loads/SET_LOADING'
export const UNSET_LOADING = 'customerPortal/loads/UNSET_LOADING'
export const RESET = 'customerPortal/loads/RESET'

const initialState = {
  loads: [],
  total: 0,
  pages: 0,
  currentPage: 0,
  schema: [
    {
      text: `PO/Order`, val: `customerpopreview`, appValue: `customerPO`, sort: true, filter: {
        show: true
      }
    },
    {
      text: `IEL PO`, val: `ponumber`, appValue: `poNumber`, sort: true, filter: {
        show: true
      }
    },
    {
      text: `Pickup Date`, val: `loaddate`, appValue: `pickupDate`, sort: true, filter: {
        show: true,
        type: `date`
      }
    },
    {
      text: `Pickup City`, val: `origincity`, appValue: `pickupCity`, sort: true, filter: {
        show: true
      }
    },
    {
      text: `Pickup State`, val: `originstate`, appValue: `pickupState`, sort: true, filter: {
        show: true,
        type: `selectState`
      }
    },
    {
      text: `Delivery Date`, val: `deliverydate`, appValue: `delDate`, sort: true, filter: {
        show: true,
        type: `date`
      }
    },
    {
      text: `Delivery City`, val: `destinationcity`, appValue: `delCity`, sort: true, filter: {
        show: true
      }
    },
    {
      text: `Delivery State`, val: `destinationstate`, appValue: `delState`, sort: true, filter: {
        show: true,
        type: `selectState`
      }
    },
    {
      text: `Total Billed`, val: `bill_amount`, appValue: `billedTotal`, sort: true 
    },
    {
      text: `Status`, val: `custom_status`, appValue: `status`, sort: true, filter: {
        show: true,
        type: `selectStatus`
      }
    },
    { text: `View Details`, appValue: `button`, custom: true },
    { text: `Receive Updates`, appValue: `subscribe`, custom: true },
  ],
  sortField: ``,
  activeFilters: [],
  pendingFilters: [],
  trailerTypes: [],
  direction: `asc`,
  params: ``,
  sortParams: ``,
  filterParams: ``,
  loading: true
}

// Reducer
export default function loads (state = initialState, action) {
  switch (action.type) {
    case SET_LOADS:
      return {
        ...state,
        loads: action.payload
      }
    case UPDATE_LOADS:
      return {
        ...state,
        loads: [
          ...state.loads,
          ...action.payload
        ]
      }
    case UPDATE_TOTAL:
      return {
        ...state,
        total: action.payload
      }
    case UPDATE_PAGES:
      return {
        ...state,
        pages: action.payload
      }
    case FETCH_TRAILER_TYPES:
      return {
        ...state,
        trailerTypes: action.payload
      }
    case UPDATE_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload
      }
    case UPDATE_SORT_FIELD:
      return {
        ...state,
        sortField: action.payload,
        direction: `asc`
      }
    case TOGGLE_SORT_DIRECTION:
      return {
        ...state,
        direction: state.direction === `asc` ? `desc` : `asc`
      }
    case SET_SORT_DIRECTION:
      return {
        ...state,
        direction: action.payload
      }
    case UPDATE_PARAMS:
      return {
        ...state,
        params: action.payload
      }
    case UPDATE_SORT_PARAMS:
      return {
        ...state,
        sortParams: action.payload
      }
    case UPDATE_FILTER_PARAMS:
      return {
        ...state,
        filterParams: action.payload
      }
    case SET_ACTIVE_FILTERS:
      return {
        ...state,
        activeFilters: action.payload
      }
    case UPDATE_ACTIVE_FILTERS:
      return {
        ...state,
        activeFilters: [
          ...state.activeFilters
        ]
      }
    case SET_PENDING_FILTERS:
      return {
        ...state,
        pendingFilters: action.payload
      }
    case SUBMIT_PENDING_FILTERS:
      return {
        ...state,
        activeFilters: [
          ...state.activeFilters,
          ...state.pendingFilters
        ],
        pendingFilters: []
      }
    case SET_LOADING:
      return {
        ...state,
        loading: true
      }
    case UNSET_LOADING:
      return {
        ...state,
        loading: false
      }
    case RESET:
      return initialState
    default:
      return state
  }
}

// Action Creators
export const setLoads = createAction(SET_LOADS)
export const updateLoads = createAction(UPDATE_LOADS)
export const updateTotalLoads = createAction(UPDATE_TOTAL)
export const updatePagesLoads = createAction(UPDATE_PAGES)
export const getTrailerTypes = createAction(FETCH_TRAILER_TYPES)
export const updateCurrentPageLoads = createAction(UPDATE_CURRENT_PAGE)
export const updateSortFieldLoads = createAction(UPDATE_SORT_FIELD)
export const toggleSortDirectionLoads = createAction(TOGGLE_SORT_DIRECTION)
export const setSortDirectionLoads = createAction(SET_SORT_DIRECTION)
export const updateParamsLoads = createAction(UPDATE_PARAMS)
export const updateSortParamsLoads = createAction(UPDATE_SORT_PARAMS)
export const updateFilterParamsLoads = createAction(UPDATE_FILTER_PARAMS)
export const setActiveFiltersLoads = createAction(SET_ACTIVE_FILTERS)
export const updateActiveFiltersLoads = createAction(UPDATE_ACTIVE_FILTERS)
export const setPendingFiltersLoads = createAction(SET_PENDING_FILTERS)
export const submitPendingFiltersLoads = createAction(SUBMIT_PENDING_FILTERS)
export const setLoadingLoads = createAction(SET_LOADING)
export const unsetLoadingLoads = createAction(UNSET_LOADING)
export const resetLoads = createAction(RESET)
