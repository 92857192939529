import { createAction } from 'redux-actions'

// Actions
export const UPDATE_FORM_TO_SHOW = 'customerPortal/login/UPDATE_FORM_TO_SHOW'
export const RESET = 'customerPortal/login/RESET'

const initialState = {
  formToShow: `login`
}

// Reducer
export default function login (state = initialState, action) {
  switch (action.type) {
    case UPDATE_FORM_TO_SHOW:
      return {
        ...state,
        formToShow: action.payload
      }
    case RESET:
      return initialState
    default:
      return state
  }
}

// Action Creators
export const updateFormToShowLogin = createAction(UPDATE_FORM_TO_SHOW)
export const resetLogin = createAction(RESET)