import React from 'react'
import DocumentDropzone from '../DocumentDropzone'

//import css
import styles from './styles.module.scss'

const AddDocumentModal = ( props ) => {
  return (
    <div className={styles.modalBackgroundLayer}> 
    <div onClick={props.closeModal} className={styles.exitLayer}></div>
        <div className={styles.modal}>
            <div className={styles.header}>
                <h1 className={styles.headerText}>Document Upload</h1>
                <button className={styles.closeModal} onClick={props.closeModal}><span>&#10005;</span></button>
            </div>
            <DocumentDropzone fileType="file" refreshDocs={props.refreshDocs} uploadDocumentsStatus={props.uploadDocumentsStatus} resetUploadDocuments={props.resetUploadDocuments} loadid={props.loadid} uploadIdocsFile={(file, loadid) => {props.uploadIdocsFile(file, loadid)}} closeModal={props.closeModal}/>
        </div>
    </div>
  )
}


export default AddDocumentModal;
