import React from 'react'

//import css
import styles from './styles.module.scss'

const ReleaseModal = ( props ) => {
  return (
    <div className={styles.modalBackgroundLayer}> 
    <div onClick={props.hideModal} className={styles.exitLayer}></div>
        <div className={styles.modal}>
            <div className={styles.header}>
                <div className={styles.headerContainer}>
                    <p className={styles.headerText}>What's new</p>
                </div>
                <button className={styles.closeModal} onClick={props.hideModal}><span>&#10005;</span></button>
            </div>
            <div className={styles.modalContent}>
                {
                    props.changelog.map(( entry ) => 
                    <React.Fragment>
                        <p className={styles.date}>{entry.created_at}</p>
                        <h1 className={styles.title}>{entry.title}</h1>
                        <p className={styles.mobileParagraph}>{entry.description}</p>
                    </React.Fragment>
                    )   
                }
            </div>
        </div>
    </div>
  )
}


export default ReleaseModal;
