import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

//import containers
import DataViewContainer from 'containers/DataViewContainer'

//import components
import ServerSortAndFilter from 'components/ServerSortAndFilter'
import LoadLineButton from 'components/LoadLineButton'
import AgeCell from 'components/AgeCell'
import BilledARLine from 'components/BilledARLine'
import UnbilledPoLine from 'components/UnbilledPoLine'

//import actions
import {
  updateSortFieldPurchaseOrders,
  toggleSortDirectionPurchaseOrders,
  setSortDirectionPurchaseOrders,
  updateParamsPurchaseOrders,
  updateSortParamsPurchaseOrders,
  updateFilterParamsPurchaseOrders,
  setActiveFiltersPurchaseOrders,
  setPendingFiltersPurchaseOrders,
  submitPendingFiltersPurchaseOrders,
  resetPurchaseOrders
} from 'modules/purchaseOrders'

import IELAPICustomerService from '../../services/IELAPICustomerService'

export class PurchaseOrderDataViewContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      lines: [],
      customTableValues: {
        button: {
          component: LoadLineButton
        },
        age: {
          component: AgeCell
        }
      }
    }
  }

  componentDidMount() {
    this.customerService = new IELAPICustomerService();

    if (this.props.multiCustomerSelectedId !== null && this.props.currentType) {
      this.customerService.getPurchaseOrders({
        type: this.props.currentType,
        params: `${this.props.params}`,
        page: 1,
      }, this.props.multiCustomerSelectedId
      )
    }
  }

  componentDidUpdate(prevProps) {
    if ((prevProps.params !== this.props.params || prevProps.multiCustomerSelectedId !== this.props.multiCustomerSelectedId) && this.props.multiCustomerSelectedId !== null) {
      this.customerService.getPurchaseOrders({
        type: this.props.currentType,
        params: `${this.props.params}`,
        page: 1,
      },
        this.props.multiCustomerSelectedId
      )
    }

    if (prevProps.currentType !== this.props.currentType && this.props.multiCustomerSelectedId !== null && this.props.currentType !== null && this.props.currentType !== '') {
      this.customerService.getPurchaseOrders({
        type: this.props.currentType,
        params: `${this.props.params}`
      },
        this.props.multiCustomerSelectedId
      )
    }

    if (this.props.data !== prevProps.data) {
      const data = this.props.data
      let lines = []

      if (this.props.currentType === `billed`) {
        lines = data.map(invoice =>
          <BilledARLine
            key={invoice.id}
            {...invoice}
          ></BilledARLine>
        )
      }
      if (this.props.currentType === `unbilled`) {
        lines = data.map(invoice =>
          <UnbilledPoLine
            key={invoice.id}
            {...invoice}
          ></UnbilledPoLine>
        )
      }

      this.setState({
        lines
      })
    }
  }

  componentWillUnmount() {
    this.props.resetPurchaseOrders()
  }

  getMorePurchaseOrders = () => {
    if (this.props.currentPage < this.props.pages) {
      this.customerService.getPurchaseOrders({
        type: this.props.currentType,
        params: `${this.props.params}`,
        page: this.props.currentPage + 1,
        update: true
      }, this.props.multiCustomerSelectedId)
    }
  }
  //passing id

  render() {
    return (
      <>
        <DataViewContainer
          lines={this.state.lines}
          rawData={this.props.data}
          schema={this.props.schema}
          customTableValues={this.state.customTableValues}
          sortKey={`val`}
          schemaFilter={this.props.schemaFilter}
          sortField={this.props.sortField}
          direction={this.props.direction}
          toggleDirection={this.props.toggleSortDirectionPurchaseOrders}
          setDirection={this.props.setSortDirectionPurchaseOrders}
          updateSortField={this.props.updateSortFieldPurchaseOrders}
          activeFilters={this.props.activeFilters}
          pendingFilters={this.props.pendingFilters}
          submitPendingFilters={this.props.submitPendingFiltersPurchaseOrders}
          setActiveFilters={this.props.setActiveFiltersPurchaseOrders}
          setPendingFilters={this.props.setPendingFiltersPurchaseOrders}
          showFilters
          useLocalSorting={false}
          loading={this.props.loading}
          showExport
          exportEnabled={this.props.exportEnabled}
          exportOnClick={this.props.exportFunction}
          getMoreData={this.getMorePurchaseOrders}
          lazyLoad
        ></DataViewContainer>
        <ServerSortAndFilter
          sortByField={this.props.sortField}
          filters={this.props.activeFilters}
          direction={this.props.direction}
          sortParams={this.props.sortParams}
          filterParams={this.props.filterParams}
          updateParams={this.props.updateParamsPurchaseOrders}
          updateSortParams={this.props.updateSortParamsPurchaseOrders}
          updateFilterParams={this.props.updateFilterParamsPurchaseOrders}
        ></ServerSortAndFilter>
      </>
    )
  }
}

const mapStateToProps = state => ({
  ...state.purchaseOrders,
  viewportWidth: state.viewport.width,
  multiCustomerSelectedId: state.multiCustomer.selectedID
})

const mapDispatchToProps = dispatch => bindActionCreators({
  updateSortFieldPurchaseOrders,
  toggleSortDirectionPurchaseOrders,
  setSortDirectionPurchaseOrders,
  updateParamsPurchaseOrders,
  updateSortParamsPurchaseOrders,
  updateFilterParamsPurchaseOrders,
  setActiveFiltersPurchaseOrders,
  setPendingFiltersPurchaseOrders,
  submitPendingFiltersPurchaseOrders,
  // getPurchaseOrders,
  resetPurchaseOrders,
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PurchaseOrderDataViewContainer)