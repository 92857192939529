import React from 'react'
import PropTypes from 'prop-types'

//import css
// import styles from './styles.module.scss'

const LoadingGif = React.memo(({
  height,
  width,
  color,
  className
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 38 38" stroke={color} className={className}>
      <g fill="none" fillRule="evenodd">
          <g transform="translate(1 1)" strokeWidth="2">
              <circle strokeOpacity=".5" cx="18" cy="18" r="18"/>
              <path d="M36 18c0-9.94-8.06-18-18-18" transform="rotate(213.195 18 18)">
                  <animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="1s" repeatCount="indefinite"/>
              </path>
          </g>
      </g>
  </svg>
  )
})

LoadingGif.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string
}

LoadingGif.defaultProps = {
  height: 38,
  width: 38,
  color: `#fff`
}

export default LoadingGif