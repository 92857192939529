import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Route, Switch } from 'react-router-dom'

//import containers
import ARbilledContainer from 'containers/ARbilledContainer'
import ARunbilledContainer from 'containers/ARunbilledContainer'
import PieChartAR from 'components/PieChartAR'

//import components
import PageWithTitle from 'components/PageWithTitle'
import LayoutAR from 'components/LayoutAR'
import CustomerDataViewAR from 'components/CustomerDataViewAR'
import StatsDataViewAR from 'components/StatsDataViewAR'
import WrapperBoxWithTitle from 'components/WrapperBoxWithTitle'

//import actions
import {
  showGraphBillingPage, 
  hideGraphBillingPage,
  selectedAgeValueBillingPage,
} from 'modules/billingPage'
import {
  setActiveFiltersPurchaseOrders
} from 'modules/purchaseOrders'
import {
  updateSelectedAge
} from 'modules/statsAR'

import IELAPICustomerService from '../../services/IELAPICustomerService'

import store from '../../store'

export class ARcontainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ageClicked: 0
    }
  }

  componentDidMount() {
    this.customerService = new IELAPICustomerService();

    if (this.props.multiCustomerSelectedId !== null) {
      this.customerService.getARSummary(this.props.multiCustomerSelectedId)
    }
    if(this.props.location.pathname === `/ar/billed`){
      this.props.showGraphBillingPage()
    }
  }

  componentDidUpdate(prevProps){
    if (this.props.location.pathname !== prevProps.location.pathname){
      if (this.props.location.pathname === `/ar/unbilled`){
        this.props.hideGraphBillingPage()
      }
      if (this.props.location.pathname === `/ar/billed`){
        this.props.showGraphBillingPage()
      }
    }
    if (prevProps.multiCustomerSelectedId !== this.props.multiCustomerSelectedId && this.props.multiCustomerSelectedId !== null) {
      this.customerService.getARSummary(this.props.multiCustomerSelectedId)
    }

    if (this.props.selectedAgeValue.val !== prevProps.selectedAgeValue.val){
      const ageValue = this.props.selectedAgeValue.val ? this.props.selectedAgeValue.val : '0'
      let ageType = ''
      if (ageValue.slice(0,4) === '0-30' || ageValue.slice(0,5) === '31-60' || ageValue.slice(0,5) === '61-90') {
        ageType = 'between'
      } else if (ageValue.slice(0,3) === '90+'){
        ageType = 'greater'
      }
      this.props.setActiveFiltersPurchaseOrders([{
        id: `age`,
        val: ageValue,
        type: ageType
      }])
    }   
  }

  render() {
    let layout = null;

    if (store.getState().userInfo.permissions ? store.getState().userInfo.permissions.AR_VIEW : false) {
      layout = <LayoutAR
        contact={
          <WrapperBoxWithTitle
            padding
          >
            <CustomerDataViewAR
              {...this.props.customerInfo}
            ></CustomerDataViewAR>
          </WrapperBoxWithTitle>
        }
        terms={
          <WrapperBoxWithTitle
            padding
          >
            <StatsDataViewAR
              payTerms={this.props.payTerm}
              billed={this.props.totalBilledFormatted}
              unbilled={this.props.totalUnbilledFormatted}
              used={this.props.totalUsedFormatted}
              term1={this.props.amount0to30Formatted}
              term2={this.props.amount31to60Formatted}
              term3={this.props.amount61to90Formatted}
              term4={this.props.amountOver90Formatted}
            ></StatsDataViewAR>
          </WrapperBoxWithTitle>
        }
        chart={
          <WrapperBoxWithTitle
            padding
          >
            <PieChartAR
              term1={this.props.amount0to30}
              term2={this.props.amount31to60}
              term3={this.props.amount61to90}
              term4={this.props.amountOver90}
              term1f={this.props.amount0to30Formatted}
              term2f={this.props.amount31to60Formatted}
              term3f={this.props.amount61to90Formatted}
              term4f={this.props.amountOver90Formatted}
              updateFilter={this.props.updateSelectedAge}

            ></PieChartAR>
          </WrapperBoxWithTitle>
        }
        view={
          <WrapperBoxWithTitle>
            <Switch>
              <Route path='/ar/billed' component={ARbilledContainer} />
              <Route path='/ar/unbilled' component={ARunbilledContainer} />
            </Switch>
          </WrapperBoxWithTitle>
        }
        showChart={this.props.showGraph}
      ></LayoutAR>
    } else {
      layout = <WrapperBoxWithTitle padding textAlignCenter>
        <div>You do not have permission to view billed data.</div>
    </WrapperBoxWithTitle>
    }

    return (
      <PageWithTitle
        title={this.props.pageTitle}
      >
        {layout}
      </PageWithTitle>
    )
  }
}

const mapStateToProps = state => ({
  customerInfo: state.customerInfo,
  ...state.statsAR,
  pageTitle: state.app.pageTitle,
  chartLoading: state.billingPage.graphIsLoading,
  showGraph: state.billingPage.showGraph,
  selectedAgeValue: state.statsAR.selectedAgeValue,
  multiCustomerSelectedId: state.multiCustomer.selectedID
})

const mapDispatchToProps = dispatch => bindActionCreators({
  showGraphBillingPage,
  hideGraphBillingPage,
  selectedAgeValueBillingPage,
  setActiveFiltersPurchaseOrders,
  updateSelectedAge
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ARcontainer)