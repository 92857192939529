import { createAction } from 'redux-actions'

// Actions
export const SET_WIDTH = 'customerPortal/viewport/SET_WIDTH'
export const SET_HEIGHT = 'customerPortal/viewport/SET_HEIGHT'
export const RESET = 'customerPortal/viewport/RESET'

const initialState = {
  width: 0,
  height: 0
}
// Reducer
export default function viewport (state = initialState, action) {
  switch (action.type) {
    case SET_WIDTH:
      return {
        ...state,
        width: action.payload
      }
    case SET_HEIGHT:
      return {
        ...state,
        height: action.payload 
      }
    case RESET:
      return initialState
    default:
      return state
  }
}

// Action Creators
export const setWidthViewport = createAction(SET_WIDTH)
export const setHeightViewport = createAction(SET_HEIGHT)
export const resetViewport = createAction(RESET)