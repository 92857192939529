import { createAction } from 'redux-actions'

// Actions
export const UPDATE_INFO = 'customerPortal/statsAR/UPDATE_INFO'
export const UPDATE_AGE = 'customerPortal/statsAR/UPDATE_AGE'
export const RESET = 'customerPortal/statsAR/RESET'

const initialState = {
  payTerm: null,
  totalBilled: null,
  totalUnbilled: null,
  totalUsed: null,
  countUnbilled: 0,
  count0to30: 0,
  amount0to30: 0,
  count31to60: 0,
  amount31to60: 0,
  count61to90: 0,
  amount61to90: 0,
  countOver90: 0,
  amountOver90: 0,
  totalBilledFormatted: null,
  totalUnbilledFormatted: null,
  totalUsedFormatted: null,
  countUnbilledFormatted: 0,
  amount0to30Formatted: 0,
  amount31to60Formatted: 0,
  amount61to90Formatted: 0,
  amountOver90Formatted: 0,
  selectedAgeValue: 0
}

// Reducer
export default function statsAR (state = initialState, action) {
  switch (action.type) {
    case UPDATE_INFO:
      return {
        ...initialState,
        ...action.payload
      }
    case UPDATE_AGE:
      return {
        ...state,
        selectedAgeValue: action.payload
      }
    case RESET:
      return initialState
    default:
      return state
  }
}

// Action Creators
export const updateStatsAR = createAction(UPDATE_INFO)
export const updateSelectedAge = createAction(UPDATE_AGE)
export const resetStatsAR = createAction(RESET)