import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import PageWithTitle from 'components/PageWithTitle'
import LoadRequestHistoryLine from 'components/LoadRequestHistoryLine'
import LoadRequestHistory from 'components/LoadRequestHistory'
import DataViewContainer from 'containers/DataViewContainer'
// import getLoadRequestHistory from 'utils/getLoadRequestHistory'

import WrapperBoxWithTitle from 'components/WrapperBoxWithTitle' //ADDED
import ServerSortAndFilter from 'components/ServerSortAndFilter' //ADDED

import {
  updateSortFieldRequestLoads,
  toggleSortDirectionRequestLoads,
  setSortDirectionRequestLoads,
  updateParamsLoads,
  updateLoadRequestPageData,
  updateFilterParamsLoads,
  setActiveFiltersLoads,
  updateActiveFiltersLoads,
  updateSortParamsLoads,
  setPendingFiltersLoads,
  submitPendingFiltersLoads,
  setEditRequest,
  reset
} from 'modules/loadRequestHistory'

//import services
import IELAPIPendingRequestsService from '../../services/IELAPIPendingRequestsService'
import IELAPIDocumentService from '../../services/IELAPIDocumentService'

export class LoadRequestHistoryContainer extends React.Component {

  componentDidMount() {
    this.docService = new IELAPIDocumentService();
    this.pendingRequestService = new IELAPIPendingRequestsService();

    let activeFilters = this.props.activeFilters //ADDED
    if (this.props.multiCustomerSelectedId  !==  null){
        if (activeFilters.length === 0) {
          this.pendingRequestService.getLoadRequestHistory(this.props.multiCustomerSelectedId, 1, false, ``)
        } else {
          this.pendingRequestService.getLoadRequestHistory(this.props.multiCustomerSelectedId, 1, false, `${this.props.params}`)
        }
     }
    }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.multiCustomerSelectedId !== this.props.multiCustomerSelectedId) {
      this.pendingRequestService.getLoadRequestHistory(this.props.multiCustomerSelectedId, 1, false, `${this.props.params}`)
    }

    if (prevProps.params !== this.props.params && this.props.customerID !== null) {
      this.pendingRequestService.getLoadRequestHistory( this.props.multiCustomerSelectedId, 1, true, `${this.props.params}`)
    }
  }

  getMoreRequestLoads = () => {
    // will need for lazy loading
  }

  exportFile = () => {
    this.docService.exportPendingRequestsCsv(this.props.multiCustomerSelectedId);
  }

  render() {
    const LinesLoadRequestHistory = this.props.data.map(data => (
      <LoadRequestHistoryLine key={data.id} data={data} setEditRequest={this.props.setEditRequest}></LoadRequestHistoryLine>
		))

    const table = (
      <WrapperBoxWithTitle>
        <DataViewContainer
          schema={this.props.schema}
          useTableView={this.props.viewportWidth > 1100}
          rawData={this.props.data}
          lines={LinesLoadRequestHistory}
          loading={this.props.loading}
          getMoreData={this.getMoreRequestLoads}
          sortKey={`val`}
          sortField={this.props.sortField}
          direction={this.props.direction}
          toggleDirection={this.props.toggleSortDirectionRequestLoads}
          setDirection={this.props.setSortDirectionRequestLoads}
          updateSortField={this.props.updateSortFieldRequestLoads}
          activeFilters={this.props.activeFilters}
          pendingFilters={this.props.pendingFilters}
          submitPendingFilters={this.props.submitPendingFiltersLoads}
          setActiveFilters={this.props.setActiveFiltersLoads}
          setPendingFilters={this.props.setPendingFiltersLoads}
          showFilters
          updateData={this.props.updateLoadRequestPageData}
          lazyLoad
        ></DataViewContainer>
        <ServerSortAndFilter
          sortByField={this.props.sortField}
          filters={this.props.activeFilters}
          direction={this.props.direction}
          sortParams={this.props.sortParams}
          filterParams={this.props.filterParams}
          updateParams={this.props.updateParamsLoads}
          updateSortParams={this.props.updateSortParamsLoads}
          updateFilterParams={this.props.updateFilterParamsLoads}
        ></ServerSortAndFilter>
      </WrapperBoxWithTitle>
    )

    return (
      <>
        <PageWithTitle
          title={this.props.pageTitle}
          bgContrast={'dark'}
        >
          <LoadRequestHistory
            table={table}
            exportFile={this.exportFile}
          ></LoadRequestHistory>
        </PageWithTitle>
      </>
    )
  }
}

const mapStateToProps = state => ({
  ...state.loadRequestHistory,
  pageTitle: state.app.pageTitle,
  schema: state.loadRequestHistory.schema,
  viewportWidth: state.viewport.width,
  multiCustomerSelectedId: state.multiCustomer.selectedID,
  data: state.loadRequestHistory.data,
  isLoading: state.loadRequestHistory.displayLoadingMessageCSV
})

const mapDispatchToProps = dispatch => bindActionCreators({
  updateSortFieldRequestLoads,
  updateLoadRequestPageData,
  toggleSortDirectionRequestLoads,
  setSortDirectionRequestLoads,
  updateParamsLoads,
  updateFilterParamsLoads,
  setActiveFiltersLoads,
  updateActiveFiltersLoads,
  updateSortParamsLoads,
  setPendingFiltersLoads,
  submitPendingFiltersLoads,
  setEditRequest,
  reset
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoadRequestHistoryContainer)

