import React, {useState} from 'react'
import PropTypes from 'prop-types'

//import components
import Box from 'components/WrapperBoxWithTitle'
import PageWithTitle from 'components/PageWithTitle'
import AddDocumentModal from 'components/AddDocumentModal'

//import css
import styles from './styles.module.scss'

import store from '../../store'

const LoadView = ({
  detail,
  map,
  billing,
  documents,
  checkCalls,
  accessorials,
  picks,
  drops,
  notes,
  notesData,
  macroPointView,
  macroPointData,
  loadid,
  refreshDocs
}) => {
  const [displayModal, setModal] = useState(false);

  //permissions stuff
  var iDocsUploadButton = <button title="You do not have permission to upload documents." disabled className={styles.addDocument}>&#43;</button>;
  if (store.getState().userInfo.permissions ? store.getState().userInfo.permissions.IDOCS_SUBMIT : false) {
    iDocsUploadButton = <button onClick={() => {setModal(true);}} className={styles.addDocument}>&#43;</button>
  }

    let showNotesBox
    if (notesData && notesData.length > 0) {
      for (let i = 0; i < notesData.length; i++) {
        if (notesData[i].notes) {
          showNotesBox = 
          <Box
          title='Broker Notes'
          showTitleBar={true}
          padding={true}
          className={styles.brokerNotes}
          >
            {notes}
          </Box>
        }
      }
    } 

    let showMacroPointBox
    if (macroPointData && macroPointData.length > 0) {
      showMacroPointBox = 
        <Box
          title="Macropoint Updates"
          showTitleBar='true'
          padding='true'
          className={styles.macropoint}
        >
          {macroPointView}
        </Box> 
    }
    
  return (
    <PageWithTitle
      title='Load Details'
    >
      <div className={styles.grid}>
        <Box
          title='Details'
          showTitleBar={true}
          className={styles.details}
        >
          {detail}
        </Box>
        <Box
          className={styles.map}
        >
          {map}
        </Box>
        <Box
          title='Accessorial Charges'
          showTitleBar={true}
          padding={true}
          className={styles.accessorialCharges}
        >
          <div className={styles.scroll}>
            {accessorials}
          </div>
        </Box>
        <Box
          title='Billing'
          showTitleBar={true}
          padding={true}
        >
          {billing}
        </Box>

        <Box
          title='Documents'
          showTitleBar={true}
          padding={true}
          className={styles.positionInitial}
        >
          <React.Fragment>
            {documents}
            {iDocsUploadButton}
           </React.Fragment>
        </Box>

        {showNotesBox}
        {showMacroPointBox}
        
        <Box
          title='Check Calls'
          padding={true}
          showTitleBar={true}
          className={styles.checkCalls}
        >
          <div className={styles.scroll}>
            {checkCalls}
          </div>
        </Box>
        <Box
          title='Origin'
          showTitleBar={true}
          padding={true}
          className={styles.origin}
        >
          {picks}
        </Box>
        <Box
          title='Destination'
          showTitleBar={true}
          padding={true}
          className={styles.drops}
        >
          {drops}
        </Box>
      </div>
      {displayModal ? <AddDocumentModal loadid={loadid} refreshDocs={refreshDocs} openModal={() => {setModal(true);}} closeModal={() => {setModal(false);}}/> : ""}
    </PageWithTitle>
  )
}

//rules for props being passed in
LoadView.propTypes = {
  detail: PropTypes.object,
  map: PropTypes.object,
  checkCalls: PropTypes.object,
  accessorials: PropTypes.object,
  picks: PropTypes.object,
  drops: PropTypes.object,
  loadid: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  refreshDocs: PropTypes.func,
}

export default LoadView;