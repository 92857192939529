import React from 'react'
import BuildLoadDisclaimer from 'components/BuildLoadDisclaimer'
import styles from './styles.module.scss'

const BuildLoad = ({
  userID,
  customerID
}) => {
  return (
    <div className={styles.container}>
    <div className={styles.disclaimerHouse}>
      <BuildLoadDisclaimer
        userID={userID}
        customerID={customerID}
      ></BuildLoadDisclaimer>
    </div>
    </div>
  )
}

export default BuildLoad