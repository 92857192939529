
export default function passwordCharactersValidation (response) {
  return dispatch => {
    let re =  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[~`!@#$%^&*()\-_=[\]{};:'",<.>/?]).{8,}$/
    let payload = {
      valid: false, 
      message: null,
      value: null
    }
    
    if(!re.test(response) && response !== null){
      payload.message = `Password should have at least 1 Uppercase letter, 1 Lowercase letter, 1 Numeric character, 1 Special character and be at least 8 characters long.`
    }
    else{
      payload.valid = true
      payload.value = response 
    }
    return payload
  }
}