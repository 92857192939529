import React from 'react'
import DatePickerLib from 'react-datepicker'
import PropTypes from 'prop-types'

//import css
import 'react-datepicker/dist/react-datepicker.css'

const DatePicker = ({
  className,
  onChange,
  value
}) => {
  let date
  if (value !== undefined) {
    date = new Date(`${value}T12:00:00`)
  }
  return (
    <DatePickerLib
      dateFormat={`yyyy-MM-dd`}
      selected={date}
      className={className}
      onChange={onChange}
    ></DatePickerLib>
  )   
}

DatePicker.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string
}

export default DatePicker