import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Button } from '@intxlog/iuilib'

//import css
import styles from './styles.module.scss'

const ExpandFilterButton = React.memo(({
  expanded,
  className,
  onClick
}) => {
  let text = `Expand Filters`

  if (expanded) {
    text = `Hide Filters`
  }

  return (
    <Button
      text={text}
      size={`small`}
      className={classNames(
        styles.container,
        {[className]: className}
      )}
      onClick={onClick}
    ></Button>
  )
})

//rules for props being passed in
ExpandFilterButton.propTypes = {
  expanded: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func
}

export default ExpandFilterButton