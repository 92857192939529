import React from 'react'
import PropTypes from 'prop-types'
import { Input } from '@intxlog/iuilib'
import classNames from 'classnames'

//import styles
import styles from './styles.module.scss'

import TextExceptionBoxContainer from 'containers/TextExceptionBoxContainer'

const AdjustPayment = ({
  id,
  initialValue,
  isDisabled,
  isChecked,
  onCheckboxClick,
  onInputChange,
  error,
  matchingAmounts,
  textOnChange,
  textBoxInvalid,
  inputArray,
  outstandingBalance
}) => {
  let inputValue
  let errorValue
  if (typeof initialValue === 'number') {
    inputValue = initialValue.toFixed(2)
  }
  if (typeof outstandingBalance === 'number') {
    outstandingBalance = outstandingBalance.toFixed(2)
  }

  const arrayIndex = inputArray.findIndex(x => x.id === id)
  if (arrayIndex >= 0) {
    inputValue = inputArray[arrayIndex].val
    if (typeof inputValue === 'number') {
      inputValue = inputValue.toFixed(2)
    }
    if (inputValue !== outstandingBalance) {
      matchingAmounts = false
    }
    errorValue = inputArray[arrayIndex].exception
    if (errorValue) {
      textBoxInvalid = false
    }
  }

  return (
    <div className={styles.AdjustPaymentWrapper}>
        <div className={styles.checkboxWrapper}>
          <input
            className={styles.checkbox}
            type='checkbox'
            id={`includeInvoice${id}`}
            checked={isChecked}
            onChange={onCheckboxClick}
            >
          </input>
        </div>
          <div className={styles.inputWrap}>
            <Input
              className={
                classNames(
                  styles.input,
                  {
                    [styles.error]: error === true
                  }
                  )
                }
                type='text'
                idForLabel={`invoiceAmount${id}`}
                defaultValue={inputValue}
                onChange={onInputChange}
                disabled={isDisabled}
                >
            </Input>
              <TextExceptionBoxContainer
                matchingAmounts={matchingAmounts}
                textOnChange={textOnChange}
                textBoxInvalid={textBoxInvalid}
                defaultValue={errorValue}
                isChecked={isChecked}
                ></TextExceptionBoxContainer>
          </div>
    </div>
  )
}

AdjustPayment.propTypes = {
  id: PropTypes.number,
  intialValue: PropTypes.number,
  isDisabled: PropTypes.bool,
  onCheckboxClick: PropTypes.func,
  onInputChange: PropTypes.func,
  error: PropTypes.bool
}

export default AdjustPayment
