import { createAction } from 'redux-actions'

export const SHOW_MODAL = 'customerPortal/receiveUpdates/SHOW_MODAL'
export const CREATE_SUBSCRIPTION_OBJECT = 'customerPortal/receiveUpdates/CREATE_OPTIN_OBJECT'
export const UPDATE_SUBSCRIPTION_OBJECT = 'customerPortal/receiveUpdates/UPDATE_OPTIN_OBJECT'
export const UNSUBSCRIBE_SUBSCRIPTION_OBJECT = 'customerPortal/receiveUpdates/UNSUBSCRIBE_SUBSCRIPTION_OBJECT'
export const SAVE_RECEIVE_BUTTON_ID = 'customerPortal/receiveUpdates/SAVE_RECEIVE_BUTTON_ID'
export const FORCE_CLOSE_MODAL = 'customerPortal/receiveUpdates/FORCE_CLOSE_MODAL'
export const CREATE_SUB_ARRAY_FROM_API = 'customerPortal/receiveUpdates/CREATE_SUB_ARRAY_FROM_API'
export const SAVE_ACTIVE_NOTIFICATION = 'customerPortal/receiveUpdates/SAVE_ACTIVE_NOTIFICATION'



const initialState= {
  showModal: false,
  subscriptions: [],
  id: null,
  activeNotification: false
}

export default function receiveUpdates (state = initialState, action) {
  switch(action.type) {
    case SHOW_MODAL:
      return {
        ...state, 
        showModal: !state.showModal
      }

      case FORCE_CLOSE_MODAL: 
        return {
          ...state, 
          showModal: false
        }

      case SAVE_RECEIVE_BUTTON_ID:
        //do not remove
        return {
          ...state,
          id: action.payload
        }

        case SAVE_ACTIVE_NOTIFICATION:
          //do not remove
          return {
            ...state,
            activeNotification: action.payload
          }
      default:
        return state
  }
}



export const showModalReceiveUpdates = createAction(SHOW_MODAL)
export const saveButtonID = createAction(SAVE_RECEIVE_BUTTON_ID)
export const forceCloseModal = createAction(FORCE_CLOSE_MODAL)
export const saveActiveNotification = createAction(SAVE_ACTIVE_NOTIFICATION)

