import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

//import components
import LoadSummaryLink from 'components/LoadSummaryLink'
import ScaleIt from 'components/ScaleIt'

//import css
import styles from './styles.module.scss'

//import images/svgs
import highwayJpg from 'assets/images/highwayBW.jpg'
import truckJpg from 'assets/images/truckBW.jpg'
import shippingCenterJpg from 'assets/images/shippingCenter.jpg'
import trucksJpg from 'assets/images/trucksInaLineBW.jpg'
import { ReactComponent as DeliveredSVG } from 'assets/svg/icon_delivered.svg'
import { ReactComponent as InTransitdSVG } from 'assets/svg/icon_inTransit.svg'
import { ReactComponent as TodaySVG } from 'assets/svg/icon_today.svg'
import { ReactComponent as FutureSVG } from 'assets/svg/icons_future.svg'

const LoadSummaryLinks = ({
  todayTotal,
  inTransitTotal,
  deliveredTotal,
  futureTotal,
  excludeArray,
  rowLayout
}) => {
  const linksTypesArray = [
    `today`,
    `inTransit`,
    `delivered`,
    `future`
  ]

  //need to exclude items from the excluded array before we render
  const linksTypesArrayModified = linksTypesArray.filter(link => !excludeArray.includes(link))
  const linksArray = linksTypesArrayModified.map(type => {
    let component = null
    switch (type) {
      case `today`:
        component = <ScaleIt key={type} width={450} height={300}>
            <LoadSummaryLink
            title={`Today's`}
            number={todayTotal}
            icon={<TodaySVG></TodaySVG>}
            bgImage={highwayJpg}
            linkTo={`/loads/today`}
          ></LoadSummaryLink>
        </ScaleIt>
        break
      case `inTransit`:
        component = <ScaleIt key={type} width={450} height={300}>
          <LoadSummaryLink
            title={`In Transit`}
            number={inTransitTotal}
            icon={<InTransitdSVG></InTransitdSVG>}
            bgImage={truckJpg}
            linkTo={`/loads/intransit`}
          ></LoadSummaryLink>
        </ScaleIt>
        break
      case `delivered`:
        component = <ScaleIt key={type} width={450} height={300}>
          <LoadSummaryLink
            title={`Delivered`}
            number={deliveredTotal}
            icon={<DeliveredSVG></DeliveredSVG>}
            bgImage={shippingCenterJpg}
            linkTo={`/loads/delivered`}
          ></LoadSummaryLink>
        </ScaleIt>
        break
      case `future`:
        component = <ScaleIt key={type} width={450} height={300}>
          <LoadSummaryLink
            title={`Future`}
            number={futureTotal}
            icon={<FutureSVG></FutureSVG>}
            bgImage={trucksJpg}
            linkTo={`/loads/future`}
          ></LoadSummaryLink>
        </ScaleIt>
        break
      default:
        break
    }

    return component
  })

  return (
    <div className={
      classNames(
        styles.container,
        {
        [styles.row]: rowLayout
      })
    }>
      {linksArray}
    </div>
  )
}

LoadSummaryLinks.propTypes = {
  todayTotal: PropTypes.number,
  inTransitTotal: PropTypes.number,
  deliveredTotal: PropTypes.number,
  futureTotal: PropTypes.number,
  excludeArray: PropTypes.array,
  rowLayout: PropTypes.bool
}

LoadSummaryLinks.defaultProps = {
  excludeArray: []
}

export default LoadSummaryLinks