import React from 'react'
import styles from './styles.module.scss'
import classNames from 'classnames'


const UnsubAreYouSure = ({
  handleAreYouSureYes,
  handleAreYouSureNo
}) => {
  return (
    <div className={styles.unsubOverlay}>
      <div className={styles.unsubConfirmFormContainer}>
        <div className={styles.unsubTitle}>Are you sure?</div>
        <div className={styles.unsubConfirmForm}>
          <input className={classNames( styles.btn, styles.btn_submit ) } type='button' name='confirmYes' value='Yes' onClick={handleAreYouSureYes}></input>
          <input className={classNames(styles.btn, styles.btn_unsubscribe)} type='button' name='confirmNo' value='No' onClick={handleAreYouSureNo}></input> 
        </div>
      </div>
    </div>
  )
}

export default UnsubAreYouSure