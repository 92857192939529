import React from 'react'
import PropTypes from 'prop-types'

//import css
import styles from './styles.module.scss'

const LoadDrops = ({
  data
}) => {
  let drops = `No information provided.`
  if (data.length > 0) {
    drops = data.map(function(d){
      let indexOfDrop = data.findIndex(i => i.id === d.id);
      return <div key={d.id} className={styles.row}>
        <div className={styles.identifier}>{d.identifier}</div>
        <div><strong>Drop {indexOfDrop + 1} of {data.length}:</strong> {d.drop}<br />
          <strong>City: </strong> {d.city}<br />
          <strong>State: </strong> {d.state}<br />
          <strong>Zip: </strong> {d.zip}</div>
        <div><strong>Del. PO: </strong> {d.delvPO}<br />
          <strong>Del. Date: </strong> {d.date}<br />
          <strong>Del. Time: </strong> {d.time}</div>
        <div>
          <strong>Unload Chg: </strong> {d.unloadCharge}<br />
          <strong>Delivered: </strong> {d.delivered}</div>
        </div>
    })
  }

  return (
    <div className={styles.container}>
      {drops}
    </div>
  )
}

//rules for props being passed in
LoadDrops.propTypes = {
  data: PropTypes.array.isRequired
}

export default LoadDrops
