import React from "react"
import styles from "./styles.module.scss"

class NotesSideBarItems extends React.Component{
    render() {
        let className = this.props.isActive ? styles.active : styles;
        return(
            <div className={styles.container}>
                <span className={className} onClick={this.props.onActiveChange.bind(this)} id={this.props.releaseID}>{this.props.value}</span>
            </div>
        );
    }
}

export default NotesSideBarItems;
