import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { Input } from '@intxlog/iuilib'

//import css
import styles from './styles.module.scss'

const TextWithLabelEditable = ({
  text,
  labelText,
  align,
  alignRight,
  className,
  labelClassName,
  textClassName,
  editing,
  idForInput,
  onChange
}) => {

  const containerClasses = classNames(
    styles.container,
    { 
      [styles.aligned]: align,
      [styles.alignedRight]: alignRight,
      [className]: className
    }
  )
  
  const labelClasses = classNames(
    styles.label,
    {[labelClassName]: labelClassName}
  )

  const textClasses = classNames(
    styles.text,
    {[textClassName]: textClassName}
  )

  var labelOrInput;

  if (editing) {
    labelOrInput = <Input id={idForInput} onChange={(e) => {onChange(e, idForInput)}} className={styles.editInput} type="text" defaultValue={text}></Input>
  } else {
    labelOrInput = <div className={textClasses}>{text}</div>
  }

  return (
    <div className={containerClasses}>
      <div className={labelClasses}>{labelText}</div>
      {labelOrInput}
    </div>
  )
}

TextWithLabelEditable.propTypes = {
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  labelText: PropTypes.string,
  align: PropTypes.bool,
  alignRight: PropTypes.bool,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  textClassName: PropTypes.string,
  editing: PropTypes.bool,
  idForInput: PropTypes.string,
  onChange: PropTypes.func
}

export default TextWithLabelEditable