export default function getColorFromStatus (status) {
  let backgroundColor;

  switch (status) {
    case `Delivered`: {
      backgroundColor = 'rgb(68, 157, 80)';
      break;
    }
    case `Loaded`: {
      backgroundColor = 'rgb(102, 74, 151)';
      break;
    }
    case 'Delivery Check-In':
    case 'Pickup Check-In': {
      backgroundColor = 'rgb(0, 119, 178)';
      break;
    }
    case `Dispatched`: {
      backgroundColor = 'rgb(67, 158, 151)';
      break;
    }
    case `Ordered`: {
      backgroundColor = 'rgb(253, 203, 74)';
      break;
    }
    default:
      backgroundColor = 'black';
      break;
  }
  return backgroundColor;
}