import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import store from '../../store'

//components
import ReceiveUpdatesModal from 'components/ReceiveUpdatesModal'

//modules
import {
	showModalReceiveUpdates
} from 'modules/receiveUpdates'

//helpers
import handleValidateEmail from 'helpers/validateEmail'

//import services
import IELAPILoadService from '../../services/IELAPILoadService'

export class ReceiveUpdatesModalContainer extends Component {
	constructor(props) {
		super(props)
		this.state = {
			emailListValues: '',
			emailInputValid: true,
			showAreYouSureForm: false
		}
		this.loadService = new IELAPILoadService();
	}

	handleSubmit = event => {
		event.preventDefault()
		if (handleValidateEmail(this.state.emailListValues)) {
			//if all emails are valid, submit
			// const CCList =
			// 	this.state.emailListValues === ''
			// 		? null
			// 		: this.state.emailListValues.split(',')
			this.props.showModalReceiveUpdates()
		} else {
			//if any emails return valid = false set state for error message to be true, rendering a message on screen
			this.setState({ emailInputValid: false })
		}
		this.loadService.postSubscriptionDetails(
			this.props.id,
			this.props.userId,
			'false',
			'true',
			this.props.selectedID,
			store.getState().subscribeEmails.ccEmails
		)
	}

	handleUpdate = () => {
		if (handleValidateEmail(this.state.emailListValues)) {
			//if all emails are valid, submit
			// const CCList =
			// 	this.state.emailListValues === ''
			// 		? null
			// 		: this.state.emailListValues.split(',')
			this.props.showModalReceiveUpdates()
		} else {
			this.setState({ emailInputValid: false })
		}
	}

	handleUnsubscribe = e => {
		this.setState({ showAreYouSureForm: true })
	}

	handleAreYouSureYes = () => {
		this.loadService.postSubscriptionDetails(
			this.props.id,
			this.props.userId,
			'false',
			'false',
			this.props.selectedID
		)
		this.setState({ showAreYouSureForm: false })
		this.props.showModalReceiveUpdates()
	}

	handleAreYouSureNo = () => {
		this.setState({ showAreYouSureForm: false })
	}

	handleEmailInputChange = e => {
		this.setState({ emailListValues: e.target.value })
	}

	handleCancel = () => {
		this.props.showModalReceiveUpdates()
	}

	render() {
		return (
			<ReceiveUpdatesModal
				loadID={this.props.id}
				subscriptions={this.props.subscriptions}
				handleEmailInputChange={this.handleEmailInputChange}
				handleSubmit={this.handleSubmit}
				handleCancel={this.handleCancel}
				validEmailFormat={this.state.emailInputValid}
				handleUpdate={this.handleUpdate}
				handleUnsubscribe={this.handleUnsubscribe}
				update={this.props.activeLoadNotification}
				showAreYouSureForm={this.state.showAreYouSureForm}
				handleAreYouSureYes={this.handleAreYouSureYes}
				handleAreYouSureNo={this.handleAreYouSureNo}
			></ReceiveUpdatesModal>
		)
	}
}

const mapStateToProps = state => ({
	id: state.receiveUpdates.id,
	subscriptions: state.receiveUpdates.subscriptions,
	userEmail: state.userInfo.email,
	userId: state.userInfo.id,
  selectedID: state.multiCustomer.selectedID,
  activeLoadNotification: state.receiveUpdates.activeNotification
})

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			showModalReceiveUpdates
		},
		dispatch
	)

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ReceiveUpdatesModalContainer)
