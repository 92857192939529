import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@intxlog/iuilib'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

//import styles
import styles from './styles.module.scss'

import store from '../../store'

function Avatar(props) {
  const node = useRef();
  const [open, setOpen] = useState(false);
  const handleClick = e => {
    if (!node.current.contains(e.target)) {
      setOpen(false);
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    }
  }, []);

  let adminConsoleItem = null;
  if (store.getState().userInfo ? store.getState().userInfo.is_admin : false) {
    adminConsoleItem = <li>
    <Button
      linkTo={`/admin`}
      linkComponent={Link}
      elementType={`routedLink`}
      type={`plain`}
      text={`Admin Console`}
      className={styles.logout}
    ></Button>
  </li>
  }

  return (
    <div className={styles.avatarWrap} ref={node}>
      {!props.name ? null : <button className={styles.avatar}
        onClick={e => setOpen(!open)}
      >
        {props.name}
        <div className={styles.arrow}></div>
      </button>}
      <ul className={
        classNames(
          styles.avatarDropDown,
          {
            [styles.open]: open === true
          }
        )
      }>
        <li>
          <Button
            linkTo={`/paynow`}
            linkComponent={Link}
            elementType={`routedLink`}
            type={`plain`}
            text={`Pay Now`}
            className={styles.payment}
          ></Button>
        </li>
        <li>
          <Button
            linkTo={`/history`}
            linkComponent={Link}
            elementType={`routedLink`}
            type={`plain`}
            text={`Pay History`}
            className={styles.payment}
          ></Button>
        </li>
        {adminConsoleItem}
        <li>
        </li>
        <li>
          <Button
            linkTo={`/logout`}
            linkComponent={Link}
            elementType={`routedLink`}
            type={`plain`}
            text={`Log Out`}
            className={styles.logout}
          ></Button>
        </li>
      </ul>
    </div>
  )
}


Avatar.propTypes = {
  name: PropTypes.string,
  open: PropTypes.bool,
}

export default Avatar
