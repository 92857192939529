import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

//import components
import LayoutVerticalResponsive from 'components/LayoutVerticalResponsive'

//import css
import styles from './styles.module.scss'

const LayoutAR = ({
  contact,
  terms,
  chart,
  view,
  showChart
}) => {
  return (
    <div className={styles.container}>
      <div className={
        classNames(
          styles.topContainer,
          {[styles.noChart]: !showChart}
        )
      }>
        <div className={styles.contact}>{contact}</div>
        <div className={styles.terms}>{terms}</div>
        {showChart &&
          <div className={styles.chart}>{chart}</div>
        }
      </div>
      <LayoutVerticalResponsive
        minHeight={500}
        height={`auto`}
        addPadding
      >
        {view}
      </LayoutVerticalResponsive>
    </div>
  )

}

LayoutAR.propTypes = {
  contact: PropTypes.object,
  terms: PropTypes.object,
  chart: PropTypes.object,
  view: PropTypes.object,
  showChart: PropTypes.bool
}

LayoutAR.defaultProps = {
  showChart: true
}

export default LayoutAR