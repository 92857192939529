import React from 'react'
import styles from './styles.module.scss'
import classNames from 'classnames'
import UnsubAreYouSure from 'components/UnsubAreYouSure'
import EmailChips from 'components/EmailChips'
import picture from '../../assets/images/updates-popup.png'

const ReceiveUpdatesModal = ({
  handleEmailInputChange,
  handleSubmit,
  handleCancel,
  validEmailFormat,
  handleUnsubscribe,
  handleUpdate,
  update,
  showAreYouSureForm,
  handleAreYouSureYes,
  handleAreYouSureNo
}) => {
  const errorMessage = validEmailFormat ? null : <div className={styles.error}>Invalid email format</div>
  let currentDisplayedButtons = null
  let showForm = null
  let emailChips = null

  if (update) {
    currentDisplayedButtons =
      <div className={styles.buttonContainer}>
        {/* <input className={classNames(styles.btn, styles.btn_submit)} type='button' name='Update' value='Update' onClick={handleUpdate}></input> */}
        <input className={classNames(styles.btn, styles.btn_unsubscribe)} type='button' name='Unsibscribe' value='Unsubscribe' id='unsubscribe' onClick={handleUnsubscribe}></input>
      </div>
  } else {
    currentDisplayedButtons = <input className={classNames(styles.btn, styles.btn_submit)} type="submit" value="Track Now" onClick={handleSubmit} ></input>
    emailChips = <div><EmailChips/></div>
  }

  if (showAreYouSureForm) {
    showForm =
      <UnsubAreYouSure
        handleAreYouSureYes={handleAreYouSureYes}
        handleAreYouSureNo={handleAreYouSureNo}
      ></UnsubAreYouSure>
  }

  return (
    <div className={styles.modalWrapper}>
      <div className={styles.modal}>
        {showForm}
        <div className={styles.UpdateModalContainer}>
          <input className={styles.btn} type='button' name='cancel' id='cancel' value='X' onClick={handleCancel}></input>
        </div>
        <div className={styles.modalImageContainer}>
          <img src={picture} alt="status pictures"/>
        </div>
        {emailChips}
        <div className={styles.emailCCSubmit}>
          <div className={styles.buttonContainer}>
            {currentDisplayedButtons}
          </div>
          {errorMessage}
        </div>
      </div>
    </div>
  )
}

export default ReceiveUpdatesModal
