import React, { Component } from 'react'
import BuildLoadForm from 'components/BuildLoadForm'
import { connect } from 'react-redux'
import PageWithTitle from 'components/PageWithTitle'



class BuildLoadFormContainer extends Component {
  render() {
    return (
      <div>
        <PageWithTitle 
          title={this.props.pageTitle}
          bgContrast={'light'}
        >
        <BuildLoadForm></BuildLoadForm>
        </PageWithTitle>
      </div>
    )
  }
}


const mapStateToProps = state => ({
  pageTitle: state.app.pageTitle,

})

export default connect(
  mapStateToProps
)(BuildLoadFormContainer)