import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

//import components
import AdjustPayment from 'components/AdjustPayment'

//import helpers
import currency from 'helpers/currency'
import inputBoxError from 'helpers/inputBoxError'

//import actions
import {
	addAmountToTotalOutstandingInvoice,
	removeAmountFromTotalOutstandingInvoice,
	updateAmountInTotalOutstandingInvoice,
	updateExceptionInputArray,
	setErrorTrueIfTextBoxEmpty,
	setErrorFalseIfTextBoxNotEmpty
} from 'modules/outstandingInvoices'

export class AdjustPaymentContainer extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			matchingAmounts: true,
			textBoxInvalid: false,
			previousId: 0
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.selectAll !== prevProps.selectAll) {
			const isAlreadyInArray = this.props.inputArray.some(
				x => x.id === this.props.id
			)
			if ((this.props.selectAll && !isAlreadyInArray) || (!this.props.selectAll && isAlreadyInArray)) {
				this.toggleInputOnClick();
			}
		}
	}

	toggleInputOnClick = () => {
		this.setState({
			textBoxInvalid: false,
			previousId: this.props.id
		})
		let insertValue =
			this.props.userValue !== undefined
				? this.props.userValue
				: this.props.outstandingBalance
		const isAlreadyInArray = this.props.inputArray.some(
			x => x.id === this.props.id
		)
		if (isAlreadyInArray) {
			this.props.removeAmountFromTotalOutstandingInvoice(this.props.id)
		} else {
			let error = inputBoxError(insertValue)
			this.props.addAmountToTotalOutstandingInvoice({
				id: this.props.id,
				IELPONumber: this.props.IELPONumber,
				val: insertValue,
				error: error,
				textBoxError: insertValue === this.props.outstandingBalance ? false:true
			})
		}
	}

	amountOnChange = val => {
		let defaultValue = this.props.value
			? this.props.value
			: this.props.outstandingBalance
		let editedValue = val
		let outstandingBalance = defaultValue.toFixed(2)
		const arrayIndex = this.props.inputArray.findIndex(
			x => x.id === this.props.id
		)

		if (editedValue === outstandingBalance) {
			this.setState({ matchingAmounts: true })
			this.props.setErrorFalseIfTextBoxNotEmpty({
				id: this.props.id,
				textBoxError: false
			})
		} else if (editedValue !== outstandingBalance) {
			this.setState({ matchingAmounts: false })
			if (this.props.inputArray[arrayIndex].exception === undefined) {
				this.props.setErrorTrueIfTextBoxEmpty({
					id: this.props.id,
					textBoxError: true
				})
			}
		}

		let error = inputBoxError(val)
		this.props.updateAmountInTotalOutstandingInvoice({
			id: this.props.id,
			val: currency(val),
			error
		})
	}

	textOnChange = textVal => {
		let textBoxValue = textVal.target.value
		this.setState({ textBoxInvalid: textBoxValue.length > 0 })
		if (textBoxValue.length > 0) {
			this.props.setErrorFalseIfTextBoxNotEmpty({
				id: this.props.id,
				textBoxError: false
			})
		} else {
			this.props.setErrorTrueIfTextBoxEmpty({
				id: this.props.id,
				textBoxError: true
			})
		}

		this.props.updateExceptionInputArray({
			id: this.props.id,
			exception: textBoxValue
		})
	}

	render() {
		let isDisabled = true
		let value = this.props.value
			? this.props.value
			: this.props.outstandingBalance
		let error = false
		const dataObj = this.props.inputArray.find(x => x.id === this.props.id)
		if (dataObj !== undefined) {
			isDisabled = false
			value = dataObj.val
			error = dataObj.error
		}

		if (Number(this.props.outstandingBalance) < 0) {
			isDisabled = true
		}

		return (
			<AdjustPayment
				matchingAmounts={this.state.matchingAmounts}
				textBoxInvalid={!this.state.textBoxInvalid}
				id={this.props.id}
				initialValue={value}
				isDisabled={isDisabled}
				value={value}
				isChecked={!isDisabled}
				onCheckboxClick={this.toggleInputOnClick}
				onInputChange={this.amountOnChange}
				outstandingBalance={this.props.outstandingBalance}
				inputArray={this.props.inputArray}
				textOnChange={this.textOnChange}
				error={error}
			></AdjustPayment>
		)
	}
}

const mapStateToProps = state => ({
	inputArray: state.outstandingInvoices.inputArray,
	numOfErrors: state.outstandingInvoices.textBoxErrorNum,
	outstandingInvoices: state.outstandingInvoices
})

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			addAmountToTotalOutstandingInvoice,
			removeAmountFromTotalOutstandingInvoice,
			updateAmountInTotalOutstandingInvoice,
			updateExceptionInputArray,
			setErrorTrueIfTextBoxEmpty,
			setErrorFalseIfTextBoxNotEmpty
		},
		dispatch
	)

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AdjustPaymentContainer)
