import React from 'react'
import { connect } from 'react-redux'

//import components
import Avatar from 'components/Avatar'

export class AvatarContainer extends React.Component {
  render(open) {
    return (
      <Avatar
        name={this.props.name}
      ></Avatar>
    )
  }
}

const mapStateToProps = state => ({
  name: state.userInfo.name
})

export default connect(
  mapStateToProps
)(AvatarContainer)