import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { Button } from '@intxlog/iuilib'

//import containers
import CompanyNameContainer from 'containers/CompanyNameContainer'

//import components
import LayoutVerticalResponsive from 'components/LayoutVerticalResponsive'

//import css
import styles from './styles.module.scss'

const PageWithTitle = React.memo(({
  title,
  children,
  addPadding,
  bgContrast,
  customButtons
}) => {
  return (
    <div className={styles.container}>
      <div className={classNames(
        styles.headingContainer,
        bgContrast === 'light' ? styles.light : null
      )}>
        <h1 className={styles.heading}>{title}</h1>
        <CompanyNameContainer></CompanyNameContainer>
        <Button
          linkTo={`/paynow`}
          linkComponent={Link}
          elementType={`routedLink`}
          type={`plain`}
          text={`Make a payment`}
          className={styles.link}
        ></Button>

        {/* The following DIV houses custom buttons in the page title, right aligned */}
        <div style={{marginLeft: "auto"}}>{customButtons}</div> 
      </div> 
      <LayoutVerticalResponsive
        height={`auto`}
        addPadding={addPadding}
      >
        {children}
      </LayoutVerticalResponsive>
    </div>
  )
})

PageWithTitle.propTypes = {
  title: PropTypes.string,
  addPadding: PropTypes.bool
}

PageWithTitle.defaultProps = {
  bgContrast: 'light'
}

export default PageWithTitle