import { createAction } from 'redux-actions'

// Actions
export const UPDATE_BANNER_TEXT = 'customerPortal/siteStatusBanner/UPDATE_BANNER_TEXT'
export const CLOSE_BANNER = 'customerPortal/siteStatusBanner/CLOSE_BANNER'
export const RESET = 'customerPortal/siteStatusBanner/RESET'

const initialState = {
  //If this is empty or undefined, the banner will not display at all.
  bannerText: '',
  bannerMoreInfo: ''
}

// Reducer
export default function siteStatusBanner (state = initialState, action) {
  switch (action.type) {
    case UPDATE_BANNER_TEXT:
      return {
        ...state,
        bannerText: action.payload.data.message, //Update banner text from API which will display the banner
        bannerMoreInfo: action.payload.data.more_info
      }
    case CLOSE_BANNER:
      sessionStorage.setItem(`hideStatusBanner`, true)

      return {
        ...state,
        bannerText: '', //We clear the text so it doesnt show
        bannerMoreInfo: ''
      }
    case RESET:
      return initialState
    default:
      return state
  }
}

// Action Creators
export const updateSiteStatusBanner = createAction(UPDATE_BANNER_TEXT)
export const closeSiteStatusBanner = createAction(CLOSE_BANNER)
export const resetSiteStatusBanner = createAction(RESET)