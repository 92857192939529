import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import CustomerAdminConsole from 'components/CustomerAdminConsole'

import IELAPICustomerAdminService from '../../services/IELAPICustomerAdminService'

import store from 'store'
import { setSelectedUser, selectAllPermissions, clearAllPermissions, updateSelectedUserPermissions } from '../../modules/customerAdmin'
import WrapperBoxWithTitle from '../../components/WrapperBoxWithTitle'

export class CustomerAdminConsoleContainer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            showDisabledUsers: false,
            showCreateUserModal: false
        }
    }

    componentDidMount() {
        this.customerAdminService = new IELAPICustomerAdminService();

        this.customerAdminService.getUsers(this.props.adminUserID, this.state.showDisabledUsers);
    }

    componentDidUpdate(prevProps) {

    }

    componentWillUnmount() {
        // Set the selected user to none if the page unmounts/user leaves
        store.dispatch(setSelectedUser(null))
    }

    updateSwitchArray = (perm) => {
        store.dispatch(updateSelectedUserPermissions(perm))
    }

    // Set all switches to on
    selectAll = () => {
        store.dispatch(selectAllPermissions())
    }

    // Set all switches to off
    clearAll = () => {
        store.dispatch(clearAllPermissions())
    }

    // Reset switches by getting the user from the API
    reset = async () => {
        await this.getSelectedUser(this.props.selectedUser);
    }

    showDisabled = (show) => {
        this.setState({ ...this.state, showDisabledUsers: show })

        this.customerAdminService.getUsers(this.props.adminUserID, this.state.showDisabledUsers);
    }

    getSelectedUser = async (user) => {
        this.setState({
            isLoading: true
        });
        await this.customerAdminService.getUserInfo(user.id);

        this.setState({
            isLoading: false
        });
    }

    saveUserProfile = async (user) => {
        this.setState({
            isLoading: true
        });

        await this.customerAdminService.saveUserProfile(user);
        await this.customerAdminService.getUsers(this.props.adminUserID);
        await this.getSelectedUser(user);

        this.setState({
            isLoading: false
        });
    }

    saveUserPermissions = async (userWithPermissions) => {
        this.setState({
            isLoading: true
        });

        await this.customerAdminService.saveUserPermissions(userWithPermissions);
        await this.getSelectedUser(userWithPermissions);

        this.setState({
            isLoading: false
        });
    }

    disableUser = async (user) => {
        this.setState({
            isLoading: true
        });
        await this.customerAdminService.disableUser(user);
        await this.getSelectedUser(user);
        this.setState({
            isLoading: false
        });
    }

    enableUser = async (user) => {
        this.setState({
            isLoading: true
        });
        await this.customerAdminService.enableUser(user);
        await this.getSelectedUser(user);
        this.setState({
            isLoading: false
        });
    }

    render() {
        // If the user is not an admin and they try to access the admin page, show them a box saying they cant access
        let customerAdminConsole = <div style={{ margin: '20px', textAlign: 'center' }}><WrapperBoxWithTitle padding>You do not have permission to access this page.</WrapperBoxWithTitle></div>

        if (store.getState().userInfo ? store.getState().userInfo.is_admin : false) {
            customerAdminConsole = <CustomerAdminConsole
                title="Admin Console"
                addPadding
                showDisabled={(show) => this.showDisabled(show)}
                users={this.props.users}
                selectedUser={this.props.selectedUser}
                getSelectedUser={(user) => this.getSelectedUser(user)}
                saveUserProfile={(user) => this.saveUserProfile(user)}
                saveUserPermissions={(user) => this.saveUserPermissions(user)}
                disableUser={(user) => this.disableUser(user)}
                enableUser={(user) => this.enableUser(user)}
                deleteUser={(user) => this.deleteUser(user)}
                isLoading={this.state.isLoading}
                showCreateUserModal={this.state.showCreateUserModal}
                permSwitches={this.props.selectedUserPermissions}
                updateSwitchArray={(perms) => this.updateSwitchArray(perms)}
                selectAll={() => { this.selectAll() }}
                clearAll={() => { this.clearAll() }}
                reset={() => { this.reset() }}
            >
            </CustomerAdminConsole>
        }

        return (
            <>
                {customerAdminConsole}
            </>
        )
    }
}

const mapStateToProps = state => ({
    ...state.customerAdmin,
    pageTitle: state.app.pageTitle,
    viewportWidth: state.viewport.width,
    adminUserID: state.userInfo.id,
    users: state.customerAdmin.users,
    selectedUser: state.customerAdmin.selectedUser,
    selectedUserPermissions: state.customerAdmin.selectedUserPermissions
})

// TODO: update the below
const mapDispatchToProps = dispatch => bindActionCreators({
    setSelectedUser,
    selectAllPermissions,
    clearAllPermissions,
    updateSelectedUserPermissions
}, dispatch)

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomerAdminConsoleContainer))