import { createAction } from 'redux-actions'

// Actions
export const SET_USERS = 'customerPortal/customerAdmin/SET_USERS'
export const SET_SELECTED_USER = 'customerPortal/customerAdmin/SET_SELECTED_USER'
export const UPDATE_SELECTED_USER_PERMISSIONS = 'customerPortal/customerAdmin/UPDATE_SELECTED_USER_PERMISSIONS'
export const SELECT_ALL_PERMISSIONS = 'customerPortal/customerAdmin/SELECT_ALL_PERMISSIONS'
export const CLEAR_ALL_PERMISSIONS = 'customerPortal/customerAdmin/CLEAR_ALL_PERMISSIONS'
export const UPDATE_USERS = 'customerPortal/customerAdmin/UPDATE_USERS'
export const UPDATE_TOTAL = 'customerPortal/customerAdmin/UPDATE_TOTAL'
export const SET_LOADING = 'customerPortal/customerAdmin/SET_LOADING'
export const UNSET_LOADING = 'customerPortal/customerAdmin/UNSET_LOADING'
export const RESET = 'customerPortal/customerAdmin/RESET'

const initialState = {
    users: [],
    total: 0,
    loading: false,
    selectedUser: null,
    selectedUserPermissions: []
}

// Reducer
export default function customerAdmin (state = initialState, action) {
    var permSwitches = {};

    switch (action.type) {
        case SET_USERS:
            // We need to add a label to each user so that the dropdown can properly show their names
            // We also need to set a value to differentiate each one in the dropdown, set to users ID
            action.payload.forEach(user => {
                user.label = user.name;
                user.value = user.id;
            });

            return {
                ...state,
                users: action.payload
            }
        case SET_SELECTED_USER: //Contains user info and permissions
            //Build permissions array
            var userPerms = action.payload ? action.payload.permissions : null;

            permSwitches = {
                IDOCS_SUBMIT: userPerms ? userPerms.IDOCS_SUBMIT ? true : false : false,
                IDOCS_DOWNLOAD: userPerms ? userPerms.IDOCS_DOWNLOAD ? true : false : false,
                LOAD_REQUEST_GENERATE: userPerms ? userPerms.LOAD_REQUEST_GENERATE ? true : false : false,
                PAY_NOW: userPerms ? userPerms.PAY_NOW ? true : false : false,
                PAY_HISTORY: userPerms ? userPerms.PAY_HISTORY ? true : false : false,
                SUBSCRIBE_TO_LOAD: userPerms ? userPerms.SUBSCRIBE_TO_LOAD ? true : false : false,
                AR_VIEW: userPerms ? userPerms.AR_VIEW ? true : false : false,
                AR_EXPORT: userPerms ? userPerms.AR_EXPORT ? true : false : false,
                LOAD_HISTORY_VIEW: userPerms ? userPerms.LOAD_HISTORY_VIEW ? true : false : false,
                LOAD_HISTORY_EXPORT: userPerms ? userPerms.LOAD_HISTORY_EXPORT ? true : false : false,
                TODAYS_LOADS_VIEW: userPerms ? userPerms.TODAYS_LOADS_VIEW ? true : false : false,
                IN_TRANSIT_VIEW: userPerms ? userPerms.IN_TRANSIT_VIEW ? true : false : false,
                DELIVERED_VIEW: userPerms ? userPerms.DELIVERED_VIEW ? true : false : false,
                FUTURE_VIEW: userPerms ? userPerms.FUTURE_VIEW ? true : false : false
            }

            var userInfo = {
                ...action.payload,
                id: action.payload ? action.payload.api_user_id : null //Set the ID field for easier use elsewhere
            }

            return {
                ...state,
                selectedUser: userInfo,
                selectedUserPermissions: permSwitches
            }
        case SELECT_ALL_PERMISSIONS:
            permSwitches = {
                IDOCS_SUBMIT: true,
                IDOCS_DOWNLOAD: true,
                LOAD_REQUEST_GENERATE: true,
                PAY_NOW: true,
                PAY_HISTORY: true,
                SUBSCRIBE_TO_LOAD: true,
                AR_VIEW: true,
                AR_EXPORT: true,
                LOAD_HISTORY_VIEW: true,
                LOAD_HISTORY_EXPORT: true,
                TODAYS_LOADS_VIEW: true,
                IN_TRANSIT_VIEW: true,
                DELIVERED_VIEW: true,
                FUTURE_VIEW: true
            }

            return {
                ...state,
                selectedUserPermissions: {
                    ...permSwitches
                }
            }
        case CLEAR_ALL_PERMISSIONS:
            permSwitches = {
                IDOCS_SUBMIT: false,
                IDOCS_DOWNLOAD: false,
                LOAD_REQUEST_GENERATE: false,
                PAY_NOW: false,
                PAY_HISTORY: false,
                SUBSCRIBE_TO_LOAD: false,
                AR_VIEW: false,
                AR_EXPORT: false,
                LOAD_HISTORY_VIEW: false,
                LOAD_HISTORY_EXPORT: false,
                TODAYS_LOADS_VIEW: false,
                IN_TRANSIT_VIEW: false,
                DELIVERED_VIEW: false,
                FUTURE_VIEW: false
            }
            return {
                ...state,
                selectedUserPermissions: {
                    ...permSwitches
                }
            }

        case UPDATE_SELECTED_USER_PERMISSIONS:
            return {
                ...state,
                selectedUserPermissions: {
                    ...state.selectedUserPermissions,
                    ...action.payload
                }
            }
        case UPDATE_USERS:
            return {
                ...state,
                users: [
                    ...state.users,
                    ...action.payload
                ]
            }
        case UPDATE_TOTAL:
            return {
                ...state,
                total: action.payload
            }
        case SET_LOADING:
            return {
                ...state,
                loading: true
            }
        case UNSET_LOADING:
            return {
                ...state,
                loading: false
            }
        case RESET:
            return initialState
        default:
            return state
    }
}

// Action Creators
export const setUsers = createAction(SET_USERS)
export const setSelectedUser = createAction(SET_SELECTED_USER)
export const selectAllPermissions = createAction(SELECT_ALL_PERMISSIONS)
export const clearAllPermissions = createAction(CLEAR_ALL_PERMISSIONS)
export const updateSelectedUserPermissions = createAction(UPDATE_SELECTED_USER_PERMISSIONS)
export const updateUsers = createAction(UPDATE_USERS)
export const updateTotalUsers = createAction(UPDATE_TOTAL)
export const setLoadingUsers = createAction(SET_LOADING)
export const unsetLoadingUsers = createAction(UNSET_LOADING)
export const resetUsers = createAction(RESET)