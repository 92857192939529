import { createAction } from 'redux-actions'


// Actions
export const UPDATE_LOADS = 'customerPortal/loadsFuture/UPDATE_LOADS'
export const UPDATE_TOTAL = 'customerPortal/loadsFuture/UPDATE_TOTAL'
export const UPDATE_MODIFIED_LOADS = 'customerPortal/loadsFuture/UPDATE_MODIFIED_LOADS'
export const UPDATE_SORT_FIELD = 'customerPortal/loadsFuture/UPDATE_SORT_FIELD'
export const TOGGLE_SORT_DIRECTION = 'customerPortal/loadsFuture/TOGGLE_SORT_DIRECTION'
export const SET_SORT_DIRECTION = 'customerPortal/loadsFuture/SET_SORT_DIRECTION'
export const RESET = 'customerPortal/loadsFuture/RESET'

const initialState = {
  title: `Future`,
  loads: [],
  total: 0,
  modifiedLoads: [],
  sortField: `pickupDate`,
  direction: `desc`
}

// Reducer
export default function loadsFuture (state = initialState, action) {
  switch (action.type) {
    case UPDATE_LOADS:
      return {
        ...state,
        loads: action.payload
      }
    case UPDATE_TOTAL:
      return {
        ...state,
        total: action.payload
      }
    case UPDATE_MODIFIED_LOADS:
      return {
        ...state,
        modifiedLoads: action.payload
      }
    case UPDATE_SORT_FIELD:
      return {
        ...state,
        sortField: action.payload,
        direction: `desc`
      }
    case TOGGLE_SORT_DIRECTION:
      return {
        ...state,
        direction: state.direction === `asc` ? `desc` : `asc`
      }
    case SET_SORT_DIRECTION:
      return {
        ...state,
        direction: action.payload
      }
    case RESET:
      return initialState
    default:
      return state
  }
}

// Action Creators
export const updateLoadsFuture = createAction(UPDATE_LOADS)
export const updateTotalLoadsFuture = createAction(UPDATE_TOTAL)
export const updateModifiedLoadsFuture = createAction(UPDATE_MODIFIED_LOADS)
export const updateSortFieldLoadsFuture = createAction(UPDATE_SORT_FIELD)
export const toggleSortDirectionLoadsFuture = createAction(TOGGLE_SORT_DIRECTION)
export const setSortDirectionLoadsFuture = createAction(SET_SORT_DIRECTION)
export const resetLoadsFuture = createAction(RESET)