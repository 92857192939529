import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

//import containers
import PurchaseOrderDataViewContainer from 'containers/PurchaseOrderDataViewContainer'

//import services
import IELAPIDocumentService from '../../services/IELAPIDocumentService'

//import actions
import {
  setCurrentTypePurchaseOrders
} from 'modules/purchaseOrders'

import store from 'store'

export class ARunbilledContainer extends React.Component {
  componentDidMount() {
    this.docService = new IELAPIDocumentService();
    this.props.setCurrentTypePurchaseOrders(`unbilled`)
  }

  exportFile = () => {
    this.docService.exportUnbilledArCsv(this.props.customerID);
  }
  
  render(){
    let exportPermission = store.getState().userInfo.permissions ? store.getState().userInfo.permissions.AR_EXPORT : false;

    return (
      <PurchaseOrderDataViewContainer
        schema={this.props.schema}
        exportFunction={this.exportFile}
        exportEnabled={exportPermission}
      ></PurchaseOrderDataViewContainer>
    )
  }
}

const mapStateToProps = state => ({
  data: state.purchaseOrders.data,
  schema: state.purchaseOrders.schemaUnbilled,
  params: state.purchaseOrders.params,
  customerID: state.multiCustomer.selectedID
})

const mapDispatchToProps = dispatch => bindActionCreators({
  // getPurchaseOrders,
  setCurrentTypePurchaseOrders
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ARunbilledContainer)