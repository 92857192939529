import React from 'react'
import { connect } from 'react-redux'

//import components
import BillingLinkDropdown from 'components/BillingLinkDropdown'

export class BillingLinkDropdownContainer extends React.Component {
  render(open){
    return (
      <BillingLinkDropdown
        name={'Billing Tools'}
      ></BillingLinkDropdown>
    )
  }
}

const mapStateToProps = state => ({
  name: state.userInfo.name
})

export default connect(
  mapStateToProps
)(BillingLinkDropdownContainer)