import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

//import css
import styles from './styles.module.scss'

//NOTE: This components parent must have `display: flex` and `flex-direction: column` to work 
const LayoutVerticalResponsive = ({
  children,
  minHeight,
  height,
  className,
  addPadding
}) => {
  return (
    <div className={classNames(
      styles.content,
      {[className]: className}
    )} style={{minHeight: `${minHeight}px`}}>
      <div className={classNames(
        styles.innerContent,
        {[styles.padded]: addPadding}
      )} style={{height: `${height}`}}>
        {children}
      </div>
    </div>
  )
}

LayoutVerticalResponsive.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  height: PropTypes.string,
  minHeight: PropTypes.number,
  className: PropTypes.string,
  addPadding: PropTypes.bool
}

export default LayoutVerticalResponsive