import React from 'react'
import { connect } from 'react-redux'

//import components
import LoadRequestLinkDropdown from 'components/LoadRequestLinkDropdown'

export class LoadRequestLinkDropdownContainer extends React.Component {
  render(open){
    return (
      <LoadRequestLinkDropdown
        name={'Load Request'}
      ></LoadRequestLinkDropdown>
    )
  }
}

const mapStateToProps = state => ({
  name: state.userInfo.name
})

export default connect(
  mapStateToProps
)(LoadRequestLinkDropdownContainer)
