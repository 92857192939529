import React from 'react'
import { useEffect, useRef, useState } from 'react'
import styles from './styles.module.scss'

const FormPayeezy = ({
	payeezyArray,
	removeInvoiceModal,
	updateNewInvoice,
	updatePO,
	customer
}) => {
	const [showErrorText, setShowErrorText] = useState(false);
	const didMountRef = useRef(false)
	const payeezyInitial = useRef(payeezyArray.payment_num)
	useEffect(() => {
		if (
			didMountRef.current &&
			payeezyInitial.current !== payeezyArray.payment_num
		) {
			document.getElementById('PrePayeezyForm').submit()
		} else {
			didMountRef.current = true
		}
	})

	async function updateNewInvoices(event) {
		event.preventDefault()
		var reg = /^-?\d+\.?\d*$/
		let amount = event.target.x_amount.value
		let customer = event.target.customer.value
		if (reg.test(amount)) { //validate if number
			setShowErrorText(false)
			//save amount to redux and generate form values
			updateNewInvoice(amount, customer, 'PrePay')
		} else {
			setShowErrorText(true)
		}
	}

	function updatePOs(event) {
		//save PO to redux
		updatePO(event.target.value)
	}

	function hideModal(event) {
		event.preventDefault()
		removeInvoiceModal()
	}

	return (
		<form
			id="PrePayeezyForm"
			className={styles.addInvoiceForm}
			method="post"
			action={process.env.REACT_APP_PAYEEZY_URL}
			onSubmit={updateNewInvoices}
		>
			<input name="x_login" value={payeezyArray.login} type="hidden" />
			<input
				name="x_invoice_num"
				value={payeezyArray.payment_num}
				type="hidden"
			/>
			<input name="x_fp_sequence" value={payeezyArray.sequence} type="hidden" />
			<input
				name="x_fp_timestamp"
				value={payeezyArray.timestamp}
				type="hidden"
			/>
			<input name="x_fp_hash" value={payeezyArray.hash} type="hidden" />
			<input name="x_show_form" value="PAYMENT_FORM" type="hidden" />
			<input name="x_relay_response" value="TRUE" type="hidden" />
			<label>Payment Description</label>
			<input
				type="text"
				required
				name="loadNum"
				id="loadNum"
				onBlur={updatePOs}
			/>
			<label>Payment Amount</label>
			{showErrorText && <div className={styles.errorText}>Please enter a numeric value for the amount.</div>}			
			<input type="text" required name="x_amount" id="x_amount" />
			<input
				required
				name="customer"
				id="customer"
				value={customer}
				type="hidden"
			/>
			{payeezyArray.items}
			<button className={styles.buttonAdd} type="submit">
				Pay Invoice
			</button>
			<button className={styles.buttonCancel} onClick={hideModal}>
				Cancel
			</button>
		</form>
	)
}

export default FormPayeezy
