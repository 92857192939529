import React from 'react'
import PropTypes from 'prop-types'

const LoadDetailLine = ({
  fields
}) => {
  const content = fields.map((text, index) => 
    <div key={index}>{text}</div>
  )
  return (
    <>
      {content}
    </>
  )
}

//rules for props being passed in
LoadDetailLine.propTypes = {
  fields: PropTypes.array.isRequired
}

export default LoadDetailLine