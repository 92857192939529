import React from 'react'
import styles from './styles.module.scss'

import { Button } from '@intxlog/iuilib'

export default function SiteStatusBannerMoreInfo({
  moreInfo,
  onClick
}) {

  return (
    <div className={styles.container}>
      <div className={styles.headerWrapper}>
        <h2>More Info</h2>
      </div>
      <div className={styles.mainWrapper}>
        <div className={styles.moreInfo}>
          {moreInfo}
        </div>
        <Button
          text={'Close'}
          onClick={onClick}
          className={styles.button}
        ></Button>
      </div>
    </div>
  )
}
