import React from 'react'
import PropTypes from 'prop-types'

//import css
import styles from './styles.module.scss'

const LoadCheckCalls = ({
  data
}) => {
  let calls = `No information provided.`
  if (data.length > 0) {
    calls = data.map((call) =>
      <div key={call.id} className={styles.row}>
        <div><strong>Date: </strong> {call.time.toLocaleDateString()}<br />
          <strong>Time: </strong> {call.time.toLocaleTimeString()}
        </div>
        <div><strong>City: </strong>{call.city}<br />
          <strong>State: </strong>{call.state}<br />
          <strong>Zip: </strong>{call.zip}
        </div>
        <div>
          <strong>Temp: </strong> {call.temp}<br />
          <strong>Type: </strong>{call.type}
        </div>
      </div>
    )
  }

  return (
    <div className={styles.container}>
      {calls}
    </div>
  )
}

//rules for props being passed in
LoadCheckCalls.propTypes = {
  data: PropTypes.array.isRequired
}

export default LoadCheckCalls
