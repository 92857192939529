import { combineReducers } from 'redux'

import app from './app'
import auth from './auth'
import billingPage from './billingPage'
import createPasswordForm from './createPasswordForm'
import forgotPasswordForm from './forgotPasswordForm'
import load from './load'
import loads from './loads'
import loadsDelivered from './loadsDelivered'
import loadsToday from './loadsToday'
import loadsFuture from './loadsFuture'
import loadsInTransit from './loadsInTransit'
import loadLocation from './loadLocation'
import login from './login'
import loginForm from './loginForm'
import modal from './modal'
import payeezy from './payeezy'
import purchaseOrders from './purchaseOrders'
import statsAR from './statsAR'
import customerInfo from './customerInfo'
import viewport from './viewport'
import userInfo from './userInfo'
import latestChangelog from './latestChangelog'
import outstandingInvoices from './outstandingInvoices'
import receiveUpdates from './receiveUpdates'
import multiCustomer from './multiCustomers'
import payHistoryPage from './payHistoryPage'
import createLoad from './createLoad'
import loadRequestHistory from './loadRequestHistory'
import siteStatusBanner from './siteStatusBanner'
import subscribeEmails from './subscribeEmails'
import customerAdmin from './customerAdmin'

export default combineReducers({
  app,
  auth,
  billingPage,
  createLoad,
  createPasswordForm,
  customerInfo,
  forgotPasswordForm,
  load,
  loads,
  loadsDelivered,
  loadRequestHistory,
  loadsToday,
  loadsFuture,
  loadsInTransit,
  loadLocation,
  login,
  loginForm,
  modal,
  multiCustomer,
  outstandingInvoices,
  payeezy,
  payHistoryPage,
  purchaseOrders,
  receiveUpdates,
  statsAR,
  viewport,
  userInfo,
  siteStatusBanner,
  subscribeEmails,
  latestChangelog,
  customerAdmin
})
