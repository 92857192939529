import React from 'react'
import { Button } from '@intxlog/iuilib'
import PropTypes from 'prop-types'

//import css
import styles from './styles.module.scss'
import logo from 'assets/images/blue_iel.png'

const LoginFormWrapper = ({
  title,
  formElements,
  buttonText,
  errorMessage,
  passwordCriteria,
  onSubmit,
  disabled
}) => {
  const assistanceText =
    <p>
      To request an account, please contact your IEL sales rep
      or call 513-780-5906
    </p>
  return (
    <div className={styles.container}>
      <div className={styles.title}>{title}</div>
      <form onSubmit={onSubmit}>
        <div className={styles.inputsContainer}>
          {formElements}
        </div>
        {errorMessage &&
          <div className={styles.errorMessage}>{errorMessage}</div>
        }
        <div className={styles.passwordCharacterFormatting}>
          {passwordCriteria}
        </div>
        <Button
          disabled={disabled}
          className={styles.submitButton}
          fullWidth
          text={buttonText}
          submitsForm
        ></Button>
      </form>
      <div className={styles.helpText}>
        {assistanceText}
      </div>
      <div className={styles.bottomLogo}>
        <img src={logo} alt="Integrity Express Logistics"/>
      </div>
    </div>
  )
}

LoginFormWrapper.propTypes = {
  title: PropTypes.string,
  formElements: PropTypes.object,
  passwordCriteria:PropTypes.object,
  buttonText: PropTypes.string,
  errorMessage: PropTypes.string,
  onSubmit: PropTypes.func
}

export default LoginFormWrapper
