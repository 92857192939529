import React from 'react'
import PropTypes from 'prop-types'
import LoadLineButton from 'components/LoadLineButton'

//import components
import TextWithLabel from 'components/TextWithLabel'

//import css
import styles from './styles.module.scss'

const UnbilledPoLine = ({
  id,
  poNumber,
  customerPO,
  deliveryDate,
  amount,
  contact
}) => {
  return (
    <div className={styles.container}>
      <TextWithLabel
        text={customerPO}
        labelText={`PO/Order:`}
      ></TextWithLabel>
      <TextWithLabel
        text={poNumber}
        labelText={`IEL PO:`}
      ></TextWithLabel>
      <TextWithLabel
        text={amount}
        labelText={`Amount:`}
      ></TextWithLabel>
      <TextWithLabel
        text={deliveryDate}
        labelText={`Delivery Date:`}
      ></TextWithLabel>
      <TextWithLabel
        text={contact}
        labelText={`Broker:`}
      ></TextWithLabel>
      <div className={styles.buttonWrapper}>
        <LoadLineButton id={id}></LoadLineButton>
      </div>
    </div>
  )
}

UnbilledPoLine.propTypes = {
  id: PropTypes.number,
  poNumber: PropTypes.string,
  customerPO: PropTypes.string,
  deliveryDate: PropTypes.string,
  amount: PropTypes.string,
  contact: PropTypes.string
}

export default UnbilledPoLine