import React from "react"
import styles from "./styles.module.scss"
import getColorFromStatus from "../../helpers/getColorFromStatus"
import formatMiles from "../../helpers/formatMiles"
import checkmark from "../../assets/images/checkmark.png"

class LoadDetailsStatusBar extends React.Component{
render() {
    const backgroundColor = getColorFromStatus(this.props.status);
    const remainingMiles = formatMiles(this.props.remainingMiles);
    const style = {
        backgroundColor,
        color : this.props.status === 'Ordered' ? '#000' : '#FFF',
    }
    return(
        <div className={styles.container} style={style}>
            <span style={style}>{this.props.status}</span>
            <span style={style}>{
                remainingMiles === null ? "Unable to calculate remaining miles" :
                ( remainingMiles === 0 ?
                <i style={style}><img src={checkmark} alt="checkmark"/>load delivered</i> :
                <i style={style}><strong style={style}>{remainingMiles}</strong> estimated miles
                    remaining</i>)
            }</span>
        </div>
    );
}
}

export default LoadDetailsStatusBar;
