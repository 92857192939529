import React from 'react'
import Select, { components } from 'react-select'
import { withRouter } from 'react-router-dom'

//import styles
import styles from './styles.module.scss'

const CustomerAdminUsersDropdown = ({
	selectedUser,
	users,
	showDisabled
}) => {

	const customStyles = {
		option: (styles, state) => ({
			...styles,
			cursor: 'pointer',
			minWidth: '200px',
			marginRight: '1rem'
		}),
		control: (styles) => ({
			...styles,
			cursor: 'pointer',
			minWidth: '200px',
			marginRight: '1rem'
		})
	}

	const renderView = () => {
		let multiOrSingleView = null

		// Custom option component that displays the users name above their email in the dropdown
		const Option = props => {
			if (props.data.deleted_at) {

			}

			return (
				<components.Option {...props}>
					<div>{props.data.label}</div>
					<div style={{ fontSize: 12 }}>{props.data.email}</div>
				</components.Option>
			);
		};

		if (users.length >= 1) {
			multiOrSingleView = (
				<div>
					<div className={styles.dropdownButton}>
						<Select
							styles={customStyles}
							defaultValue={selectedUser}
							options={users}
							onChange={(e) => { selectedUser(e) }}
							components={{ Option }}
						>
						</Select>
					</div>
				</div>
			)
		} else {
			multiOrSingleView = (
				<span className={styles.companyName}> No Users </span>
			)
		}
		return multiOrSingleView
	}

	return <div className={styles.selectContainer}>{renderView()}</div>
}

export default withRouter(CustomerAdminUsersDropdown)
