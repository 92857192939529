import React from 'react'
import PropTypes from 'prop-types'

//import css
import styles from './styles.module.scss'

//svg
import logo from "assets/svg/header_logo.svg"

const LoginPage = ({
  children
}) => {

  const welcome = <p> Welcome! </p>
  const welcomeTwo = 
    <p>
      Nexus is a web based portal designed to give our customers <br/> 
      visibility to all supply chain information in one centralized location
    </p>
  const xMarks = <p>X marks the spot</p>
  const xMarksTwo = <p>for tracking every shipment</p>

  return (
    <div className={styles.container} >
      <div className={styles.logBoxContainer}>
          <div className={styles.svg}>
            <img src={logo} alt="Integrity Express Logistics"/>
          </div>

      <div>
        <div className={styles.welcome}>
          {welcome}
        </div>
        <div className={styles.welcomeTwo}>
          {welcomeTwo}
        </div>
        </div>
          {children}
      </div>

      <div>
        <div className={styles.xMark}>
          {xMarks}
        </div>
        <div className={styles.xMarksTwo}>
          {xMarksTwo}
        </div>
      </div>
    </div>
  )
}

LoginPage.propTypes = {
  children: PropTypes.object
}

export default LoginPage
