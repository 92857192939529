import loadDetails from "../assets/images/releaseNotes/ReleaseNotes_Content_LoadDetails.jpg"
import loadStatus from "../assets/images/releaseNotes/ReleaseNotes_Content_Status.jpg"
import miles from "../assets/images/releaseNotes/ReleaseNotes_Content_Estimatedmiles.jpg"
import brokerNotes1 from "../assets/images/releaseNotes/ReleaseNotes_Content_BrokerNotes1.png"
import brokerNotes2 from "../assets/images/releaseNotes/ReleaseNotes_Content_BrokerNotes2.png"
import brokerNotes3 from "../assets/images/releaseNotes/ReleaseNotes_Content_BrokerNotes3.png"
import brokerNotes4 from "../assets/images/releaseNotes/ReleaseNotes_Content_BrokerNotes4.png"
import macropoint1 from "../assets/images/releaseNotes/ReleaseNotes_Macropoint_Updates1.png"
import macropoint2 from "../assets/images/releaseNotes/ReleaseNotes_Macropoint_Updates2.png"
import privacyPolicy from "../assets/images/releaseNotes/ReleaseNotes_Privacy_Policy.png"
import phoneNumber from "../assets/images/releaseNotes/ReleaseNotes_Phone_Number.png"
import navbar from "../assets/images/releaseNotes/ReleaseNotes_Navbar.png"
import billingDropdown from "../assets/images/releaseNotes/ReleaseNotes_Billing_Dropdown.png"
import userDropdown from "../assets/images/releaseNotes/ReleaseNotes_User_Dropdown.png"
import paynow from "../assets/images/releaseNotes/ReleaseNotes_Paynow.png"
import applyButton from "../assets/images/releaseNotes/ReleaseNotes_ApplyButton.png"
import disclaimer from "../assets/images/releaseNotes/ReleaseNotes_LoadRequest_Disclaimer.png"
import loadOrder from "../assets/images/releaseNotes/ReleaseNotes_LoadRequest_OrderPage.png"
import loadOrderMessage from "../assets/images/releaseNotes/ReleaseNotes_LoadRequest_Message.png"
//import loadOrderHistory from "../assets/images/releaseNotes/ReleaseNotes_LoadRequest_History.png"

export const notes = [
/*    {
        id: "Version 1.4.0",
        title: "Request History",
        date: "",
        summary: "",
        intro1: "The Request History page will allow you to look at every load request you have created through Nexus.",
        img1: loadOrderHistory,
        list1: [],
        intro2: "",
        img2: "",
        list2: [],
        intro3: "",
        img3: "",
        list3: [],
        intro4: "",
        img4: "",
        list4: [],
    },*/

    {
        id: "Version 1.4.0",
        title: "Load Request Page",
        date: "",
        summary: "",
        intro1: "The Load Request feature will allow you to fill out an order quote that will be sent to your broker. You will be presented with a disclaimer when you first click on the Load Request link at the top.  If you have multiple businesses that you can create a load for, click on the “Build Load For” drop-down menu, choose the business for which you are building a load request, and then click on the green checkmark icon to save your selection. Click on Continue to proceed to the form itself.",
        img1: disclaimer,
        list1: [],
        intro2: "Fill out the Pick Date, Drop Date, Equipment, and both Business Name and Zip fields. Fill out any other additional fields if needed and click on the orange Submit button to complete the form. If you have more than one pick or drop, click on the orange + button to add them to the request.Fill out the Business Name and Zip fields to submit the form. If you need to remove this column, click on the – button at the bottom.",
        img2: loadOrder,
        list2: [],
        intro3: "When you are done filling out the form, click on the orange Submit button and you should see this message pictured below. Click on blue Ok button to return to the Load Summary page.",
        img3: loadOrderMessage,
        list3: [],
        intro4: "",
        img4: "",
        list4: [],
    },

    {
        id: "11",
        title: "Apply Button Update",
        date: "",
        summary: "",
        intro1:"Beginning with version 1.4.0, the Apply button, which was previously located at the bottom of the column, has been moved to the top of the Filters column in Load Search.",
        img1: applyButton,
        list1: [],
        intro2: "",
        img2: "",
        list2: [],
        intro3: "",
        img3: "",
        list3: [],
        intro4: "",
        img4: "",
        list4: [],
    },

    {
        id: "10",
        title: "Payment Submission Update",
        date: "",
        summary: "",
        intro1:"When you make a payment, the Pay Now button will be locked until you are automatically redirected to the payment page.",
        img1: paynow,
        list1: [],
        intro2: "",
        img2: "",
        list2: [],
        intro3: "",
        img3: "",
        list3: [],
        intro4: "",
        img4: "",
        list4: [],
    },

    {
        id: "9",
        title: "Navigation Bar Update",
        date: "",
        summary: "",
        intro1:"The navigation bar at the top every page has been updated with new pages along with a couple of existing ones being moved to a drop-down menu. A new page that was added for v1.4.0 is the Load Request page, which sits in-between the Load Summary and Load Search link.",
        img1: navbar,
        list1: [],
        intro2: "Both billing pages (Billed A/R & Unbilled Loads) have been moved to the Billing drop-down menu. Load Summary and Load Search are still in their original spots. The Log Out option is still located underneath the user drop-down menu.",
        img2: billingDropdown,
        list2: [],
        intro3: "Two more existing pages have been added to the user drop-down menu (which already includes the Log Out feature), Pay now (Make A Payment page) and Pay History.",
        img3: userDropdown,
        list3: [],
        intro4: "",
        img4: "",
        list4: [],
    },

    {
        id: "8",
        title: "Load Status Color Updates",
        date: "",
        summary: "The colors for load statuses have been updated in the new release on the Load Details and Load Search pages. Each status will be color coded as such: " +
            "<span style='color: rgb(253, 203, 74); font-weight: bold'>Ordered</span>, " +
            "<span style='color: rgb(102, 74, 151); font-weight: bold'>Loaded</span>, " +
            "<span style='color: rgb(67, 158, 151); font-weight: bold'>Dispatched</span>, " +
            "<span style='color: rgb(0, 119, 178); font-weight: bold'>Pickup Check-In</span>, " +
            "<span style='color: rgb(0, 119, 178); font-weight: bold'>Delivery Check-In</span>, and " +
            "<span style='color: rgb(68, 157, 80); font-weight: bold'>Delivered</span>.",
        intro1: "",
        img1: "",
        list1: [],
        intro2: "",
        img2: "",
        list2: [],
        intro3: "",
        img3: "",
        list3: [],
        intro4: "",
        img4: "",
        list4: [],
    },

    {
        id: "7",
        title: "Updated Support Line",
        date: "",
        summary: "We have changed our support line number (which you can find on the login screen). Our number is now (513) 780-5906.",
        intro1: "",
        img1: phoneNumber,
        list1: [],
        intro2: "",
        img2: "",
        list2: [],
        intro3: "",
        img3: "",
        list3: [],
        intro4: "",
        img4: "",
        list4: [],
    },

    {
        id: "6",
        title: "Privacy Policy Updates",
        date: "",
        summary: "Starting with IEL Nexus v1.4.0, we will now be collecting cookies from you when you visit our site. To view more information about this, please visit our Privacy Policy page (located at the bottom of each page).",
        intro1: "",
        img1: privacyPolicy,
        list1: [],
        intro2: "",
        img2: "",
        list2: [],
        intro3: "",
        img3: "",
        list3: [],
        intro4: "",
        img4: "",
        list4: [],
    },

    {
        id: "5",
        title: "Macropoint Updates",
        date: "",
        summary: "A new section has been added to IEL Nexus named Macropoint Updates. If you are opted into Macropoint, this will display all stop updates. ",
        intro1: "In the Load Details page, you will find these notes under Accessorial Charges and Billing sections (or it will be found under the newly released Broker Notes section that will ONLY appear if a broker has sent a note over to you. For more information about that section, please see the release notes for “Broker Notes”). If there is more than one update, you will need to click on “Show More...” to view these. ",
        img1: macropoint1,
        list1: [],
        intro2: "Click on “Show Less…” to close every update besides the most recent one.",
        img2: macropoint2,
        list2: [],
        intro3: "",
        img3: "",
        list3: [],
        intro4: "",
        img4: "",
        list4: [],
    },

    {
        id: "4",
        title: "Broker Notes",
        date: "",
        summary: "In this latest release, you can now view notes that have been sent from brokers regarding important updates and other information about your loads.",
        intro1: "In the Load Details Page, will find these notes under the Accessorial Changes and Billing sections (highlighted in orange in comparison to the other sections on the page).",
        img1: brokerNotes1,
        list1: [],
        intro2: "If a broker has sent you more than one update, the most recent note will be shown. To view the rest, click on the “Show more…” link below the note.",
        img2: brokerNotes2,
        list2: [],
        intro3: "Click on the “Show less…” link to close all notes except for the most recent one.",
        img3: brokerNotes3,
        list3: [],
        intro4: "On the Load Summary page, you will find a new Note column where the most recent note from a broker will be displayed. If a broker has sent you a note, you will see an exclamation icon that you can hover your mouse over that will show it. If a broker hasn’t sent you any notes at all, then you will not see an exclamation icon at all.",
        img4: brokerNotes4,
        list4: [],
    },

    {
        id: "3",
        title: "Remaining Miles",
        date: "",
        summary: "",
        intro1: "Based on feedback, we have moved the Estimated Miles Remaining field to the header at the top of the Details section under Load Details.",
        img1: miles,
        list1: [],
        intro2: "This will show how many miles are remaining along with the status of the load ",
        img2: "",
        list2: [
            {listKey: "Dispatched", value: ""},
            {listKey: "Ordered", value: ""},
            {listKey: "Loaded", value: ""},
            {listKey: "Pickup Check-In", value: ""},
            {listKey: "Delivery Check-In", value: ""},
            {listKey: "Delivered", value: ""},
        ],
        intro3: "",
        img3: "",
        list3: [],
        intro4: "Check calls will also be provided for you to see as well. This will help you keep track of current loads that have already been dispatched so that you will not have to reach out to the broker and ask about the current status of the order.",
        img4: "",
        list4: [],
    },

    {
        id: "2",
        title: "Load Status",
        date: "",
        summary: "",
        intro1: "These have been replaced with a new load status called Ordered to help make communication between brokers and customers better and easier.\n" +
            " Under Load Details, users will see a color bar under " +
            "the Details section. The color will be changed depending on what status it falls under.",
        img1: loadStatus,
        list1: [
            {listKey: "Yellow:  ", value: "Ordered"},
            {listKey: "Purple:  ", value: "Loaded"},
            {listKey: "Dark Green:  ", value: "Dispatched"},
            {listKey: "Green:  ", value: "Delivered"},
        ],
        intro2: "Based on feedback, we have removed a couple unnecessary load statuses:",
        img2: "",
        list2: [
            {listKey: "Not Dispatched", value: ""},
            {listKey: "Booked", value: ""},
        ],
        intro3: "",
        img3: "",
        list3: [],
        intro4: "",
        img4: "",
        list4: [],
    },

    {
        id: "1",
        title: "Load Details Header",
        date: "",
        summary: "",
        intro1: "Load Details now has a title to help users know what page they are currently on and to help better understand the application. This can be found right next to the customer’s name in the header section of the page.",
        img1: loadDetails,
        list1: [],
        intro2: "",
        img2: "",
        list2: [],
        intro3: "",
        img3: "",
        list3: [],
        intro4: "",
        img4: "",
        list4: [],
    },
];
