import { createAction } from 'redux-actions'

// Actions
export const UPDATE_NEW_CUSTOMER = 'customerPortal/payeezy/UPDATE_NEW_CUSTOMER'
export const UPDATE_NEW_INVOICE_AMOUNT =
	'customerPortal/payeezy/UPDATE_NEW_INVOICE_AMOUNT'
export const UPDATE_INVOICES_TOTAL =
	'customerPortal/payeezy/UPDATE_INVOICES_TOTAL'
export const UPDATE_ITEMS = 'customerPortal/payeezy/UPDATE_ITEMS'
export const UPDATE_PO = 'customerPortal/payeezy/UPDATE_PO'
export const UPDATE_TIMESTAMP = 'customerPortal/payeezy/UPDATE_TIMESTAMP'
export const UPDATE_HASH = 'customerPortal/payeezy/UPDATE_HASH'
export const UPDATE_PAYMENT_NUM = 'customerPortal/payeezy/UPDATE_PAYMENT_NUM'
export const RESET = 'customerPortal/payeezy/RESET'

const initialState = {
	sequence: process.env.REACT_APP_PAYEEZY_SEQUENCE,
	login: process.env.REACT_APP_PAYEEZY_LOGIN_ID,
	total: ``,
	amount: ``,
	hash: ``,
	timestamp: ``,
	payment_num: ``,
	items: ``,
	PO: ``,
	customerid: ``
}

// Reducer
export default function payeezy (state = initialState, action) {
	switch (action.type) {
		case UPDATE_NEW_CUSTOMER:
			return {
				...state,
				customerid: action.payload
			}
		case UPDATE_NEW_INVOICE_AMOUNT:
			return {
				...state,
				amount: action.payload
			}
		case UPDATE_HASH:
			return {
				...state,
				hash: action.payload
			}
		case UPDATE_ITEMS:
			return {
				...state,
				items: action.payload
			}
		case UPDATE_PO:
			return {
				...state,
				PO: action.payload
			}
		case UPDATE_PAYMENT_NUM:
			return {
				...state,
				payment_num: action.payload
			}
		case UPDATE_TIMESTAMP:
			return {
				...state,
				timestamp: action.payload
			}
		case UPDATE_INVOICES_TOTAL:
			return {
				...state,
				total: action.payload
			}
		case RESET:
			return initialState
		default:
			return state
	}
}

// Action Creators
export const updatePayeezyCustomer = createAction(UPDATE_NEW_CUSTOMER)
export const updatePayeezyAmount = createAction(UPDATE_NEW_INVOICE_AMOUNT)
export const updatePayeezyTotal = createAction(UPDATE_INVOICES_TOTAL)
export const updatePayeezyTimestamp = createAction(UPDATE_TIMESTAMP)
export const updatePayeezyHash = createAction(UPDATE_HASH)
export const updatePayeezyPaymentNum = createAction(UPDATE_PAYMENT_NUM)
export const updatePayeezyPO = createAction(UPDATE_PO)
export const updatePayeezyItems = createAction(UPDATE_ITEMS)
export const resetLogin = createAction(RESET)
