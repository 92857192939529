import React from 'react'
import styles from './styles.module.scss'

export default function SiteStatusBanner({
  bannerText,
  bannerMoreInfo,
  onClick
}) {
  let moreInfo = [];

  // Check if banner should be displayed or not. If redux is empty, do not display the banner.
  if (bannerText) {
    if (bannerMoreInfo) {
      moreInfo = [
        <div className={styles.bannerMoreInfo}>
          {bannerMoreInfo}
        </div>
      ]
    }

    return (
      <div className={styles.bannerContainer}>
        <div className={styles.bannerText} >
          {bannerText}
        </div>

        {/* This displays a link in the banner for the user to click for more info */}
        {/* It will only appear if bannerMoreInfo is filled */}
        {moreInfo}

        <div className={styles.bannerDismiss} onClick={onClick}>
          &times;
        </div>
      </div>
    )
  } else return null
}
