import { createAction } from 'redux-actions'

// Actions
export const UPDATE_EMAIL = 'customerPortal/forgotPasswordForm/UPDATE_EMAIL'
export const UPDATE_EMAIL_IS_VALID = 'customerPortal/forgotPasswordForm/UPDATE_EMAIL_IS_VALID'
export const UPDATE_ERROR_MESSAGE = 'customerPortal/forgotPasswordForm/UPDATE_ERROR_MESSAGE'
export const INCREMENT_FORM_IS_SUBMITTED = 'customerPortal/forgotPasswordForm/INCREMENT_FORM_IS_SUBMITTED'
export const TOGGLE_SUBMITTED = 'customerPortal/forgotPasswordForm/TOGGLE_SUBMITTED'
export const RESET = 'customerPortal/forgotPasswordForm/RESET'

const initialState = {
  email: null,
  emailIsValid: false,
  errorMessage: null,
  formSubmitted: 0,
  submitted: false
}

// Reducer
export default function forgotPasswordForm (state = initialState, action) {
  switch (action.type) {
    case UPDATE_EMAIL:
      return {
        ...state,
        email: action.payload
      }
    case UPDATE_EMAIL_IS_VALID:
      return {
        ...state,
        emailIsValid: action.payload
      }
    case UPDATE_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: action.payload
      }
    case INCREMENT_FORM_IS_SUBMITTED: 
      return {
        ...state,
        formSubmitted: parseInt(state.formSubmitted) + 1
      }
    case TOGGLE_SUBMITTED: 
      return {
        ...state,
        submitted: !state.submitted
      }
    case RESET:
      return initialState
    default:
      return state
  }
}

// Action Creators
export const updateEmailForgotPasswordForm = createAction(UPDATE_EMAIL)
export const updateEmailIsValidForgotPasswordForm = createAction(UPDATE_EMAIL_IS_VALID)
export const updateErrorMessageForgotPasswordForm = createAction(UPDATE_ERROR_MESSAGE)
export const incrementFormIsSubmittedForgotPasswordForm = createAction(INCREMENT_FORM_IS_SUBMITTED)
export const toggleSubmittedForgotPasswordForm = createAction(TOGGLE_SUBMITTED)
export const resetForgotPasswordForm = createAction(RESET)