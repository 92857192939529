import React from 'react'

import {withRouter} from 'react-router-dom'
import BackButton from 'components/backButton'

export class BackButtonContainer extends React.Component {

  prevScreen = () => {
    this.props.history.goBack()
  }

  render(){
    return (
      <BackButton
        goBack={this.prevScreen}
      ></BackButton>
    )
  }
}

export default withRouter(BackButtonContainer)
