export default function inputBoxError (val) {
  let value = val
  if (typeof val === 'number') {
    value = val.toFixed(2)
  }
  let error = false
  var regex = /^(?:\d{1,3}(?:,?\d{3})*)?(?:\.\d{2})?$/;
  if (!regex.test(value) || value === "0.00" || value === "") {
    error = true
  }
  return error
}