import { createAction } from 'redux-actions'

// Actions
export const UPDATE = 'customerPortal/customerInfo/UPDATE'
export const RESET = 'customerPortal/customerInfo/RESET'

const initialState = {
  id: null,
  name: null,
  address1: null,
  address2: null,
  city: null,
  state: null,
  zip: null,
  phone: null,
  fax: null,
  contact: null,
  email: null,
  externalcustomername: null
}

// Reducer
export default function customerInfo (state = initialState, action) {
  switch (action.type) {
    case UPDATE:
      return {
        ...initialState,
        ...action.payload
      }
    case RESET:
      return initialState
    default:
      return state
  }
}

// Action Creators
export const updateCustomerInfo = createAction(UPDATE)
export const resetCustomerInfo = createAction(RESET)