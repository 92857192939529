import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

//import components
import PayNow from "components/PayNow";
import PageWithTitle from "components/PageWithTitle";

import PayNowLine from "components/PayNowLine";
import AddInvoiceModal from "components/AddInvoiceModal";
import ACHModal from "components/ACHModal";
import ServerSortAndFilter from "components/ServerSortAndFilter";

//import containers
import AdjustPaymentContainer from "containers/AdjustPaymentContainer";
import DataViewContainer from "containers/DataViewContainer";

//import actions
import {
  updateModifiedOutstandingInvoicesData,
  updateSortFieldOutstandingInvoicesData,
  toggleSortDirectionOutstandingInvoicesData,
  setSortDirectionOutstandingInvoicesData,
  setDirectionOutstandingInvoices,
  updateSortParamsOutstandingInvoices,
  updateParamsOutstandingInvoices,
  updateFilterParamsOutstandingInvoices,
  setActiveFiltersOutstandingInvoices,
  updateActiveFiltersOutstandingInvoices,
  setPendingFiltersOutstandingInvoices,
  submitPendingFiltersOutstandingInvoices,
} from "modules/outstandingInvoices";

import {
  updatePayeezyAmount,
  updatePayeezyTimestamp,
  updatePayeezyItems,
  updatePayeezyHash,
  updatePayeezyPO,
  updatePayeezyPaymentNum,
  updatePayeezyCustomer,
} from "modules/payeezy";

import { setTypeModal, toggleOpenModal, setTextModal } from "modules/modal";

// import services
import IELAPICustomerService from "../../services/IELAPICustomerService";
import IELAPIPaymentService from "../../services/IELAPIPaymentService";

export class PayNowContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      number: 0,
      showModal: false,
      showACHModal: false,
    };
  }

  componentDidMount() {
    this.customerService = new IELAPICustomerService();
    this.payService = new IELAPIPaymentService();

    if (this.props.multiCustomerSelectedId !== null) {
      this.customerService.getOutstandingInvoice(
        this.props.multiCustomerSelectedId
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (
      (prevProps.params !== this.props.params &&
        this.props.multiCustomerSelectedId !== null) ||
      prevProps.multiCustomerSelectedId !== this.props.multiCustomerSelectedId
    ) {
      this.customerService.getOutstandingInvoice(
        this.props.multiCustomerSelectedId,
        `${this.props.params}`
      );
    }
  }

  addInvoice = () => {
    this.setState({
      showModal: true,
    });
  };

  achPay = (number) => {
    this.setState({
      showACHModal: true,
      number: number,
    });
  };

  removeInvoiceModal = () => {
    this.setState({
      showModal: false,
    });
  };

  removeAchModal = () => {
    this.setState({
      showACHModal: false,
    });
  };

  updatePO = (PO, event) => {
    this.props.updatePayeezyPO(PO);
  };

  updateNewInvoice = async (amount, customerid, form) => {
    const { updatePayeezyCustomer, updatePayeezyAmount } = this.props;
    await updatePayeezyAmount(amount);
    await updatePayeezyCustomer(customerid);
    let loadid = this.props.payeezyArray.PO;
    let note = loadid;
    if (form !== "PrePay") {
      note = `New Invoice for PO #: ${loadid}`;
    }

    let paymentInfo = {
      other: [
        {
          customerid,
          note,
          amount: Number(amount),
        },
      ],
    };
    let timestamp = Math.floor(Date.now() / 1000);
    this.props.updatePayeezyTimestamp(timestamp);
    let hash = await this.payService.createPayeezyHash(
      this.props.payeezyArray,
      timestamp,
      amount
    );
    this.props.updatePayeezyHash(hash);
    this.payService.getPaymentResponse(paymentInfo, (id) => {
      this.props.updatePayeezyPaymentNum(id);
    });
    let value = `${this.props.payeezyArray.PO}<|><|>PO ${this.props.payeezyArray.PO}<|>1<|>${amount}<|>N`;
    let content = (
      <input name="x_line_item" value={value} type="hidden"></input>
    );
    this.props.updatePayeezyItems(content);
  };

  render() {
    const invoiceObj = this.props.outstandingInvoices;
    let dispatchInvoices = invoiceObj.modifiedData;
    if (invoiceObj.modifiedData.length === 0) {
      dispatchInvoices = invoiceObj.data;
    }
    const linesPayNow = dispatchInvoices.map((data) => (
      <PayNowLine key={data.id} {...data}></PayNowLine>
    ));

    const data =
      this.props.modifiedData.length > 0
        ? this.props.modifiedData
        : this.props.data;
    const table = (
      <>
        <DataViewContainer
          lines={linesPayNow}
          rawData={data}
          useTableView={this.props.viewportWidth > 1100}
          schema={this.props.schema}
          updateData={this.props.updateModifiedOutstandingInvoicesData}
          toggleDirection={
            this.props.toggleSortDirectionOutstandingInvoicesData
          }
          setDirection={this.props.setSortDirectionOutstandingInvoicesData}
          updateSortField={this.props.updateSortFieldOutstandingInvoicesData}
          useLocalSorting
          sortField={this.props.sortField}
          sortKey={`val`}
          direction={this.props.direction}
          customTableValues={{
            invoiceBoxes: {
              component: AdjustPaymentContainer,
            },
          }}
          loading={this.props.loading}
          lazLoad
        ></DataViewContainer>
        <ServerSortAndFilter
          sortByField={this.props.sortField}
          filters={this.props.activeFilters}
          direction={this.props.direction}
          sortParams={this.props.sortParams}
          filterParams={this.props.filterParams}
          updateParams={this.props.updateParamsOutstandingInvoices}
          updateSortParams={this.props.updateSortParamsOutstandingInvoices}
          updateFilterParams={this.props.updateFilterParamsOutstandingInvoices}
        ></ServerSortAndFilter>
      </>
    );

    let showModal = this.state.showModal ? (
      <AddInvoiceModal
        customer={this.props.customerId}
        updateNewInvoice={this.updateNewInvoice}
        updatePO={this.updatePO}
        payeezyArray={this.props.payeezyArray}
        removeInvoiceModal={this.removeInvoiceModal}
      ></AddInvoiceModal>
    ) : null;

    let showACHModal = this.state.showACHModal ? (
      <ACHModal
        customer={this.props.customerId}
        updatePO={this.updatePO}
        removeAchModal={this.removeAchModal}
        defaultAmount={this.state.number}
        toggleOpenModal={this.props.toggleOpenModal}
        setTypeModal={this.props.setTypeModal}
        setTextModal={this.props.setTextModal}
      ></ACHModal>
    ) : null;
    return (
      <>
        <PageWithTitle title={this.props.pageTitle}>
          <PayNow
            addInvoice={this.addInvoice}
            achPay={this.achPay}
            inputArray={this.props.inputArray}
            table={table}
            removeInvoiceModal={this.removeInvoiceModal}
          ></PayNow>
        </PageWithTitle>
        {showModal}
        {showACHModal}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.outstandingInvoices,
  pageTitle: state.app.pageTitle,
  customerId: state.multiCustomer.selectedID,
  data: state.outstandingInvoices.data,
  schema: state.outstandingInvoices.schema,
  viewportWidth: state.viewport.width,
  direction: state.outstandingInvoices.direction,
  sortField: state.outstandingInvoices.sortField,
  modifiedData: state.outstandingInvoices.modifiedData,
  inputArray: state.outstandingInvoices.inputArray,
  outstandingInvoices: state.outstandingInvoices,
  numberOfTextBoxErrors: state.outstandingInvoices.numberOfBlankTextBoxes,
  payeezyArray: state.payeezy,
  multiCustomerSelectedId: state.multiCustomer.selectedID,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateModifiedOutstandingInvoicesData,
      updateSortFieldOutstandingInvoicesData,
      toggleSortDirectionOutstandingInvoicesData,
      setSortDirectionOutstandingInvoicesData,
      setTypeModal,
      toggleOpenModal,
      setTextModal,
      updatePayeezyCustomer,
      updatePayeezyAmount,
      updatePayeezyTimestamp,
      updatePayeezyHash,
      updatePayeezyItems,
      updatePayeezyPaymentNum,
      updatePayeezyPO,

      setDirectionOutstandingInvoices,
      updateSortParamsOutstandingInvoices,
      updateParamsOutstandingInvoices,
      updateFilterParamsOutstandingInvoices,
      setActiveFiltersOutstandingInvoices,
      updateActiveFiltersOutstandingInvoices,
      setPendingFiltersOutstandingInvoices,
      submitPendingFiltersOutstandingInvoices,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PayNowContainer);
