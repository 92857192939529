import { createAction } from 'redux-actions'

// Actions
export const SET_TYPE = 'customerPortal/modal/SET_TYPE'
export const TOGGLE_OPEN = 'customerPortal/modal/TOGGLE_OPEN'
export const SET_TEXT = 'customerPortal/modal/SET_TEXT'
export const SET_BUTTON_TEXT = 'customerPortal/modal/SET_BUTTON_TEXT'
export const RESET = 'customerPortal/modal/RESET'

const initialState = {
  type: `success`,
  isOpen: false,
  text: null,
  buttonText: null
}
// Reducer
export default function modal (state = initialState, action) {
  switch (action.type) {
    case SET_TYPE:
      return {
        ...state,
        type: action.payload
      }
    case TOGGLE_OPEN:
      return {
        ...state,
        isOpen: !state.isOpen
      }
    case SET_TEXT:
      return {
        ...state,
        text: action.payload
      }
    case SET_BUTTON_TEXT:
      return {
        ...state,
        buttonText: action.payload
      }
    case RESET:
      return initialState
    default:
      return state
  }
}

// Action Creators
export const setTypeModal = createAction(SET_TYPE)
export const toggleOpenModal = createAction(TOGGLE_OPEN)
export const setTextModal = createAction(SET_TEXT)
export const setTextButtonModal = createAction(SET_BUTTON_TEXT)
export const resetModal = createAction(RESET)