import React from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as LogoImage } from 'assets/svg/header_logo.svg'

//import styles
import styles from './styles.module.scss'

class Logo extends React.Component {
  render(){
    //inline styles
    let inlineStyles = {
      width: this.props.width
    }

    return (
      <div className={styles.container} style={inlineStyles}>
        <LogoImage
          style={{ width: 200, height: 'auto' }}
        ></LogoImage>
      </div>
    );
  }
}

//rules for props being passed in
Logo.propTypes = {
  width: PropTypes.number
}

// Specifies the default values for props:
Logo.defaultProps = {
  width: 100
}

export default Logo