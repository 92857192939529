import React from 'react'

import { Button } from '@intxlog/iuilib'
import styles from './styles.module.scss'

const backButton = ({
goBack
}) => {
  return (
      <Button
        size={`small`}
        className={styles.button}
        text={`Previous Page`}
        onClick={goBack}
      ></Button>
  )
}

export default backButton