import React from 'react'
import { Chart } from 'react-google-charts';

import styles from './styles.module.scss'

const PieChartAR = ({
  term1,
  term2,
  term3,
  term4,
  term1f,
  term2f,
  term3f,
  term4f,
  updateFilter
}) => {
  const pieData = [
    ['Days', '$ Amount past due'],
    [`0-30 (${term1f})`, term1],
    [`31-60 (${term2f})`, term2],
    [`61-90 (${term3f})`, term3],
    [`90+ (${term4f})`, term4],
  ]
  const pieOptions = {
    title: '',
    chartArea: {
      width: '100%',
      height: '90%'
    },
    sliceVisibilityThreshold: 0,
    backgroundColor: 'rgb(247, 247, 247)',
    legend: {
      textStyle: {
        fontSize: 12
      },
      labeledValueText: 'both',
      position: 'right',
      alignment: 'center'
    },
    is3D: true,
    tooltip: {
      showColorCode: true
    },
    slices: {
      0: { offset: 0.1, color: "rgb(16, 150, 24)" },
      1: { offset: 0.1, color: "rgb(51, 102, 204)" },
      2: { offset: 0.1, color: "rgb(255, 153, 0)" },
      3: { offset: 0.1, color: "rgb(220, 57, 18)" }
    }
  }
  const title = 'Number of days past due'
  const pieChartDaysRange = [
    "0-30",
    "31-60",
    "61-90",
    "90+"
  ]


  return (
    <div className={styles.PieChartARContainer}>
      <div className={styles.chartTitle}>
        {title}
        <Chart
          width={'100%'}
          height={'100%'}
          chartType="PieChart"
          loader={<div>Loading Chart</div>}
          data={pieData}
          options={pieOptions}
          rootProps={{ 'data-testid': '2' }}
          chartEvents={[
            {
              eventName: 'select',
              callback: ({ chartWrapper }) => {
                const chart = chartWrapper.getChart()
                const selection = chart.getSelection()
                if (selection.length === 1) {
                  const [selectedItem] = selection;
                  const { row } = selectedItem; 
                  const selectedSlice = pieChartDaysRange[row]; 
                  updateFilter({
                      val: selectedSlice
                  });
                }
              }
            }
          ]}
        />
      </div>
    </div>
  )
}

export default PieChartAR