import React from 'react'
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik'
import * as Yup from 'yup'
import styles from './styles.module.scss'
import { withRouter } from 'react-router-dom'
import { Button } from '@intxlog/iuilib'
import { connect } from 'react-redux'
import { Datepicker } from 'react-formik-ui'
import { bindActionCreators } from 'redux'
import LoadingGif from 'components/LoadingGif'
import { Modal } from '@intxlog/iuilib'
import AddressSearch from '../AddressSearch/index'


import {forceCloseModal} from 'modules/createLoad'
import {resetEditRequest} from 'modules/loadRequestHistory'
import IELAPIUtilityService from '../../services/IELAPIUtilityService'
import IELAPIPendingRequestsService from '../../services/IELAPIPendingRequestsService'


class BuildLoadForm extends React.Component {
  constructor(props) {
    super(props);
    let editingRequest = this.props.editingRequest;
    let pickups = [];
    if (editingRequest && editingRequest.picks) {
      for (let i = 0; i < editingRequest.picks.length; ++i) {
        pickups.push({
          pickup_business_name: editingRequest.picks[i].businessName,
          pickup_street_line1: editingRequest.picks[i].streetLine1,
          pickup_street_line2: editingRequest.picks[i].streetLine2,
          pickup_city: editingRequest.picks[i].city,
          pickup_state: editingRequest.picks[i].state,
          pickup_zip: editingRequest.picks[i].zip,
          pickup_number:editingRequest.picks[i].refNumber,
        })
      }
    }
    let drops = [];
    if (editingRequest && editingRequest.drops) {
      for (let i = 0; i < editingRequest.drops.length; ++i) {
        drops.push({
          drop_business_name: editingRequest.drops[i].businessName,
          drop_street_line1: editingRequest.drops[i].streetLine1,
          drop_street_line2: editingRequest.drops[i].streetLine2,
          drop_city: editingRequest.drops[i].city,
          drop_state: editingRequest.drops[i].state,
          drop_zip: editingRequest.drops[i].zip,
          drop_number: editingRequest.drops[i].refNumber,
        })
      }
    }
    this.state = {
      stateChange: false,
      initialValues: {
        customerid: this.props.customerid,
        userid: this.props.userid,
        pickup_date: editingRequest ? editingRequest.pickup_date : '',
        drop_date: editingRequest ? editingRequest.delivery_date : '',
        equipment: editingRequest ? editingRequest.equipment : '',
        po_number: editingRequest ? editingRequest.po_number : '',
        pickups: editingRequest && editingRequest.picks ? pickups : [
          {
            pickup_business_name: '',
            pickup_street_line1: '',
            pickup_street_line2: '',
            pickup_city: '',
            pickup_state: '',
            pickup_zip: '',
            pickup_number:'',
          }
        ],
        drops: editingRequest && editingRequest.drops ? drops : [
          {
            drop_business_name: '',
            drop_street_line1: '',
            drop_street_line2: '',
            drop_city: '',
            drop_state: '',
            drop_zip: '',
            drop_number: ''
          }
        ],
        notes: editingRequest ? editingRequest.notes : '',
        status: editingRequest ? editingRequest.status : null
      },
      openVoidModal: false
    }
    this.utilService = new IELAPIUtilityService();
    this.pendingRequestService = new IELAPIPendingRequestsService();
  }

  componentDidMount = () => {
    this.utilService.getTrailerTypes();
  }

  componentWillUnmount() {
    this.props.resetEditRequest();
  }

  onSubmit = (values, touched) => {
    values['customerid'] =  this.props.customerid;
    values['userid'] =  this.props.userid;
    if (this.props.editingRequest) {
      values['requestid'] =  this.props.editingRequest.id;
    }
    this.pendingRequestService.submitLoadRequestForm(values)
  }

  textError = (props) => {
    return (
      <div className={styles.error}>
        {props.children}
      </div>
    )
  }

  handleCloseModal = () => {
    this.props.forceCloseModal()
    if (!this.state.initialValues.status) {
      this.props.history.push('/')
    } else {
      this.props.history.push('/loadhistory')
    }
  }

  handleCancelButton = () => {
    if (!this.state.initialValues.status) {
      this.props.history.push('/')
    } else {
      this.props.history.push('/loadhistory')
    }
  }

  handleVoidRequest = () => {
    if (this.props.editingRequest && this.props.editingRequest.id) {
      this.pendingRequestService.voidLoadRequest(this.props.editingRequest.id)
    }
    this.setState({openVoidModal:false})
    this.props.history.push('/loadhistory')
  }

  customValidation = (errors, touched, value, arrayString) => {
    if (errors.hasOwnProperty(arrayString) && touched && touched.hasOwnProperty(arrayString) && (value === '' || value === undefined)) {
      return <div style={{color:'red'}}>Required</div>
    }
  }

  validationSchema = Yup.object({
    pickup_date: Yup.date().min(new Date(Date.now() -86400000), 'Pickup Date must not be in the past').max('12/25/2100', 'Too far into the future').required('Required'),
    drop_date: Yup.date().min(Yup.ref('pickup_date'), 'Drop date must be after pickup date').max('12/25/2100', 'Too far into the future').required('Required'),
    equipment: Yup.string().required('Required'),
    pickups: Yup.array().of(Yup.object().shape({
      pickup_business_name: Yup.string().strict().trim('Cannot have empty spaces').required('Required'),
      pickup_street_line1: Yup.string().required('Required'),
      pickup_city: Yup.string().required('Required'),
      pickup_zip: Yup.string().required('Required'),
      pickup_state: Yup.string().required('Required'),
      })
    ),
    drops: Yup.array().of(Yup.object().shape({
      drop_business_name: Yup.string().strict().trim('Cannot have empty spaces').required('Required'),
      drop_street_line1: Yup.string().required('Required'),
      drop_city: Yup.string().required('Required'),
      drop_zip: Yup.string().required('Required'),
      drop_state: Yup.string().required('Required'),
      })
    ),
  })

  setAddresstoFormik = async (address, formikValues, formikIndex, pickOrDropText, values) => {
    const addressArray = address[0].address_components
    const streetAddressArray = []
    let fullStreet = ''
    for (let i=0; i<addressArray.length; i++) {
      if (addressArray[i].types.includes('street_number') || addressArray[i].types.includes('route')) {
        streetAddressArray.push(addressArray[i].long_name)
        fullStreet = streetAddressArray.join(' ')
        if (pickOrDropText === 'pickup') {
            values.pickups[formikIndex].pickup_street_line1 = fullStreet
        }
        if (pickOrDropText === 'drops') {
            values.drops[formikIndex].drop_street_line1 = fullStreet
        }
      }
      if (!addressArray[i].types.includes('street_number') || !addressArray[i].types.includes('route')) {
        if (pickOrDropText === 'pickup') {
          values.pickups[formikIndex].pickup_street_line1 = fullStreet
        }
        if (pickOrDropText === 'drops') {
          values.drops[formikIndex].drop_street_line1 = fullStreet
        }
      }
      if (addressArray[i].types.includes('locality')) {
        if (pickOrDropText === 'pickup') {
          values.pickups[formikIndex].pickup_city = addressArray[i].long_name
        }
        if (pickOrDropText === 'drops') {
          values.drops[formikIndex].drop_city = addressArray[i].long_name + ' '
        }
      }
      if (addressArray[i].types.includes('administrative_area_level_1')) {
        if (pickOrDropText === 'pickup') {
          values.pickups[formikIndex].pickup_state = addressArray[i].long_name
        }
        if (pickOrDropText === 'drops') {
          values.drops[formikIndex].drop_state = addressArray[i].long_name + ' '
        }
      }
      if (addressArray[i].types.includes('postal_code')) {
        if (pickOrDropText === 'pickup') {
          values.pickups[formikIndex].pickup_zip = addressArray[i].long_name
        }
        if (pickOrDropText === 'drops') {
          values.drops[formikIndex].drop_zip = addressArray[i].long_name + ' '
        }
      }
    }
    // This triggers an update in Formik state
    this.setState({
      stateChange: !this.state.stateChange
    })
  }

  render() {
    let loader = null
    let pushPick = {
      pickup_business_name: '',
      pickup_street_line1: '',
      pickup_street_line2: '',
      pickup_city: '',
      pickup_state: '',
      pickup_zip: '',
      pickup_number:'',
    }
    let pushDrop = {
      drop_business_name: '',
      drop_street_line1: '',
      drop_street_line2: '',
      drop_city: '',
      drop_state: '',
      drop_zip: '',
      drop_number: ''
    }
    if (this.props.loading) {
      loader = <div className={styles.loadingWrapper}>
        <LoadingGif
          height={80}
          width={80}
        ></LoadingGif>
      </div>
    }
    const { initialValues } = this.state
    const { onSubmit, validationSchema, textError, handleCloseModal } = this
    const asterisk = '*'
    const notPending = this.state.initialValues.status === 'accepted' || this.state.initialValues.status === 'declined'
        || this.state.initialValues.status === 'voided';
    return (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          isSubmitting
        }) => (
            <div className={styles.formContainer}>
              <Form>
                <fieldset disabled={notPending ? "disabled" : null}>
                <div className={styles.loadControl}>
                  <div className={styles.formControl}>
                    <label htmlFor='pickup_date'>Pick Date</label>
                    <span className={styles.fieldInline}>
                      <Datepicker
                        name='pickup_date'
                        dateFormat='MM/dd/yyyy'
                        placeholder='mm/dd/yyyy'
                        />
                      {asterisk}
                    </span>
                  </div>
                  <div className={styles.formControl}>
                    <label htmlFor='drop_date'>Drop Date</label>
                    <span className={styles.fieldInline}>
                      <Datepicker
                        name='drop_date'
                        dateFormat='MM/dd/yyyy'
                        placeholder='mm/dd/yyyy'
                        />
                        {asterisk}
                    </span>
                  </div>
                  <div className={styles.formControl}>
                    <label htmlFor='equipment'>Equipment</label>
                    <span className={styles.fieldInline}>
                      <Field as='select' id='equipment' name='equipment' size='large'>
                        <option value=''></option>
                        {this.props.trailerTypes.map(obj => {
                          return <option key={obj.id} value={obj.trailertype}>{obj.trailertype}</option>
                        })}
                        <option value='Other'>Other</option>
                      </Field>
                      {asterisk}
                    </span>
                    <ErrorMessage name='equipment' component={textError} />
                  </div>
                  <div>
                    <label htmlFor='po_number'>PO Number</label>
                    <Field type='text' id='po_number' name='po_number' />
                  </div>
                </div>
                <div className={styles.pickDropContainer}>
                  <div className={styles.formControl}>
                    <label name='Picks'>Picks</label>
                    <FieldArray name='pickups'>
                      {
                        (fieldArrayProps) => {
                          const { push, remove, form } = fieldArrayProps
                          const { values } = form
                          const { pickups } = values
                          return (
                            <div className={styles.pickFieldArrayContainer}>
                              {
                                pickups.map((pick, index) => (
                                  <div className={styles.pickFieldArray} key={index}>
                                    <h5 name='picknumber'>Pick #{index + 1}</h5>
                                    <div className={styles.formControl}>
                                      <label htmlFor={`pickups[${index}].pickup_business_name`}></label>
                                      <span className={styles.fieldInline}>
                                        <Field className={styles.streetAddress} type='text' placeholder='Business Name' id={`pickups[${index}].pickup_business_name`} name={`pickups[${index}].pickup_business_name`} />
                                        {asterisk}
                                      </span>
                                      {this.customValidation(errors, touched, pick.pickup_business_name, 'pickups')}
                                    </div>
                                    <div className={styles.formControl}>
                                    <AddressSearch setAddresstoFormik={this.setAddresstoFormik} formikValues={pickups[index]} formikIndex={index} pickOrDropText={'pickup'} values={values}/>
                                    </div>
                                    <div className={styles.formControl}>
                                      <label htmlFor={`pickups[${index}].pickup_street_line1`}></label>
                                      <span className={styles.fieldInline}>
                                        <Field className={styles.streetAddress} type='text' placeholder='Street Address' id={`pickups[${index}].pickup_street_line1`} name={`pickups[${index}].pickup_street_line1`} disabled/>
                                        {asterisk}
                                      </span>
                                      {this.customValidation(errors, touched, pick.pickup_street_line1, 'pickups')}
                                    </div>
                                    <div className={styles.formControl}>
                                      <label htmlFor={`pickups[${index}].pickup_street_line2`}></label>
                                      <Field className={styles.streetAddress} type='text' placeholder='Street Address Line 2' id={`pickups[${index}].pickup_street_line2`} name={`pickups[${index}].pickup_street_line2`} disabled/>
                                    </div>
                                    <div className={styles.cityState}>
                                      <div className={styles.formControl}>
                                        <label htmlFor={`pickups[${index}].pickup_city`}></label>
                                        <span className={styles.fieldInline}>
                                        <Field type='text' placeholder='City' id={`pickups[${index}].pickup_city`} name={`pickups[${index}].pickup_city`} disabled/>
                                          {asterisk}
                                        </span>
                                      {this.customValidation(errors, touched, pick.pickup_city, 'pickups')}
                                      </div>
                                      <div className={styles.formControl}>
                                        <label htmlFor={`pickups[${index}].pickup_state`}></label>
                                        <span className={styles.fieldInline}>
                                          <Field type='text' placeholder='State' id={`pickups[${index}].pickup_state`} name={`pickups[${index}].pickup_state`} disabled/>
                                          {asterisk}
                                        </span>
                                        {this.customValidation(errors, touched, pick.pickup_state, 'pickups')}
                                      </div>
                                    </div>
                                    <div className={styles.formControl}>
                                      <label htmlFor={`pickups[${index}].pickup_zip`}></label>
                                      <span className={styles.fieldInline}>
                                        <Field type='text' placeholder='Zip' id={`pickups[${index}].pickup_zip`} name={`pickups[${index}].pickup_zip`} disabled/>
                                        {asterisk}
                                      </span>
                                      {this.customValidation(errors, touched, pick.pickup_zip, 'pickups')}
                                    </div>
                                    <div className={styles.formControl}>
                                      <label htmlFor={`pickups[${index}].pickup_number`}></label>
                                      <Field type='text' placeholder='Pick No.' id={`pickups[${index}].pickup_number`} name={`pickups[${index}].pickup_number`}/>
                                    </div>
                                    {index === pickups.length - 1 ? <div className={styles.buttonContainer}>
                                      {index > 0 ? <button className={styles.removeBtn} type='button' onClick={() => remove(index)}>-</button> : null}
                                      <button className={styles.addBtn} type='button' onClick={() => push(pushPick)}>+</button>
                                    </div> : null}
                                  </div>
                                ))
                              }
                            </div>
                          )
                        }
                      }
                    </FieldArray>
                  </div>
                  <div className={styles.formControl}>
                    <label name='Drops'>Drops</label>
                    <FieldArray name='drops'>
                      {
                        (fieldArrayProps) => {
                          const { push, remove, form } = fieldArrayProps
                          const { values } = form
                          const { drops } = values
                          return (
                            <div className={styles.dropFieldArrayContainer}>
                              {
                                drops.map((drop, index) => (
                                  <div className={styles.dropFieldArray} key={index}>
                                    <h5 name='dropnumber'>Drop #{index + 1}</h5>
                                    <div className={styles.formControl}>
                                      <label htmlFor={`drops[${index}].drop_business_name`}></label>
                                      <span className={styles.fieldInline}>
                                      <Field className={styles.streetAddress} type='text' placeholder='Business Name' id={`drops[${index}].drop_business_name`} name={`drops[${index}].drop_business_name`} />
                                      {asterisk}
                                      </span>
                                      {this.customValidation(errors, touched, drop.drop_business_name, 'drops')}
                                    </div>
                                    <div className={styles.formControl}>
                                      <AddressSearch setAddresstoFormik={this.setAddresstoFormik} formikValues={drops[index]} formikIndex={index} pickOrDropText={'drops'} values={values}/>
                                    </div>
                                    <div className={styles.formControl}>
                                      <label htmlFor={`drops[${index}].drop_street_line1`}></label>
                                      <span className={styles.fieldInline}>
                                        <Field className={styles.streetAddress} type='text' placeholder='Street Address' id={`drops[${index}].drop_street_line1`} name={`drops[${index}].drop_street_line1`} disabled/>
                                        {asterisk}
                                      </span>
                                      {this.customValidation(errors, touched, drop.drop_street_line1, 'drops')}
                                    </div>
                                    <div className={styles.formControl}>
                                      <label htmlFor={`drops[${index}].drop_street_line2`}></label>
                                      <Field className={styles.streetAddress} type='text' placeholder='Street Address Line 2' id={`drops[${index}].drop_street_line2`} name={`drops[${index}].drop_street_line2`} disabled/>
                                    </div>
                                    <div className={styles.cityState}>
                                      <div className={styles.formControl}>
                                        <label htmlFor={`drops[${index}].drop_city`}></label>
                                        <span className={styles.fieldInline}>
                                        <Field type='text' placeholder='City' id={`drops[${index}].drop_city`} name={`drops[${index}].drop_city`} disabled/>
                                          {asterisk}
                                        </span>
                                        {this.customValidation(errors, touched, drop.drop_city, 'drops')}
                                      </div>
                                      <div className={styles.formControl}>
                                        <label htmlFor={`drops[${index}].drop_state`}></label>
                                        <span className={styles.fieldInline}>
                                          <Field type='text' placeholder='State' id={`drops[${index}].drop_state`} name={`drops[${index}].drop_state`} disabled/>
                                        {asterisk}
                                      </span>
                                        {this.customValidation(errors, touched, drop.drop_state, 'drops')}
                                      </div>
                                    </div>
                                    <div className={styles.formControl}>
                                      <label htmlFor={`drops[${index}].drop_zip`}></label>
                                      <span className={styles.fieldInline}>
                                        <Field type='text' placeholder='Zip' id={`drops[${index}].drop_zip`} name={`drops[${index}].drop_zip`} disabled/>
                                        {asterisk}
                                      </span>
                                      {this.customValidation(errors, touched, drop.drop_zip, 'drops')}
                                    </div>
                                    <div className={styles.formControl}>
                                      <label htmlFor={`drops[${index}].drop_number`}></label>
                                      <Field type='text' placeholder='Drop No.' id={`drops[${index}].drop_number`} name={`drops[${index}].drop_number`}/>
                                    </div>
                                    {index === drops.length - 1 ? <div className={styles.buttonContainer}>
                                      {index > 0 ? <button className={styles.removeBtn} type='button' onClick={() => remove(index)}>-</button> : null}
                                      <button className={styles.addBtn} type='button' onClick={() => push(pushDrop)}>+</button>
                                    </div> : null}
                                  </div>
                                ))
                              }
                            </div>
                          )
                        }
                      }
                    </FieldArray>
                  </div>
                </div>
                <div className={styles.formControl}>
                  <label htmlFor='notes'></label>
                  <Field as='textarea' id='notes' name='notes' placeholder='Notes' />
                  {values.equipment === 'Other' ? <div className={styles.error}>Provide more information regarding equipment</div> : null}
                </div>
                </fieldset>

                <div className={styles.formSubmitButtonContainer}>
                  <Button
                    className={styles.cancelBtn}
                    text='Cancel'
                    onClick={this.handleCancelButton}
                  />
                  {notPending ? null :
                      <button className={values.equipment === 'Other' && values.notes === '' ? styles.disabledBtn : styles.submitBtn} type='submit' disabled={(values.equipment === 'Other' && values.notes === '') || isSubmitting ? true : false}>{values.status ? 'Update' : 'Submit'}</button>
                  }
                  {values.status === 'pending' ? <Button text='VOID' className={styles.voidBtn} onClick={()=>{this.setState({openVoidModal: true})}}/> : null}
                </div>
              </Form>
              {loader}
              <Modal
                type={'success'}
                isOpen={this.props.toggleModal}
                text={'Your load request was successfully submitted, please wait for your broker to contact you. You will now be returned to the home page, thank you.'}
                closeFunc={handleCloseModal}
              ></Modal>
              <Modal
                  type={'warning'}
                  isOpen={this.state.openVoidModal}
                  customContent={
                    <div className={styles.voidModal}><p>Are you sure you want to void the current pending load request?</p>
                      <Button text='YES' onClick={this.handleVoidRequest}/>
                    </div>}
                  closeFunc={()=>{this.setState({openVoidModal: false})}}
              ></Modal>
            </div>
          )}
      </Formik>
    );
  }
}

const mapStateToProps = state => ({
  createdLoadid: state.createLoad.id,
  userid: state.userInfo.id,
  customerid: state.multiCustomer.selectedID,
  loading: state.app.isLoading,
  toggleModal: state.createLoad.modal,
  trailerTypes: state.loads.trailerTypes,
  editingRequest: state.loadRequestHistory.editingRequest
  })

  const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
          forceCloseModal,
          resetEditRequest
		},
		dispatch
	)

  const BuildLoadFormWithRouter = withRouter(BuildLoadForm)

  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(BuildLoadFormWithRouter)
