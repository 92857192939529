import { createAction } from 'redux-actions'

// Actions
export const UPDATE_PIN = 'customerPortal/createPasswordForm/UPDATE_PIN'
export const UPDATE_EMAIL = 'customerPortal/createPasswordForm/UPDATE_EMAIL'
export const UPDATE_PASSWORD = 'customerPortal/createPasswordForm/UPDATE_PASSWORD'
export const UPDATE_PASSWORD_CONFIRM = 'customerPortal/createPasswordForm/UPDATE_PASSWORD_CONFIRM'
export const UPDATE_ERROR_MESSAGE = 'customerPortal/createPasswordForm/UPDATE_ERROR_MESASAGE'
export const INCREMENT_FORM_IS_SUBMITTED = 'customerPortal/createPasswordForm/INCREMENT_FORM_IS_SUBMITTED'
export const UPDATE_PASSWORD_IS_VALID = 'customerPortal/createPasswordForm/UPDATE_PASSWORD_IS_VALID'
export const UPDATE_PASSWORD_CONFIRM_IS_VALID = 'customerPortal/createPasswordForm/UPDATE_PASSWORD_CONFIRM_IS_VALID'
export const SET_FORM_IS_VALID = 'customerPortal/createPasswordForm/SET_FORM_IS_VALID'
export const UNSET_FORM_IS_VALID = 'customerPortal/createPasswordForm/UNSET_FORM_IS_VALID'
export const UPDATE_ACTION = 'customerPortal/createPasswordForm/UPDATE_ACTION'
export const RESET = 'customerPortal/CreatePasswordForm/RESET'

const initialState = {
  pin: null,
  email: null,
  password: null,
  passwordConfirm: null,
  passwordIsValid: false,
  passwordConfirmIsValid: false,
  formIsValid: false,
  formSubmitted: 0,
  errorMessage: null,
  action: `create`
}

// Reducer
export default function createPasswordForm (state = initialState, action) {
  switch (action.type) {
    case UPDATE_PIN:
      return {
        ...state,
        pin: action.payload
      }
    case UPDATE_EMAIL:
      return {
        ...state,
        email: action.payload
      }
    case UPDATE_PASSWORD:
      return {
        ...state,
        password: action.payload
      }
    case UPDATE_PASSWORD_CONFIRM:
      return {
        ...state,
        passwordConfirm: action.payload
      }
    case UPDATE_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: action.payload
      }
    case UPDATE_PASSWORD_IS_VALID:
      return {
        ...state,
        passwordIsValid: action.payload
      }
    case UPDATE_PASSWORD_CONFIRM_IS_VALID:
      return {
        ...state,
        passwordConfirmIsValid: action.payload
      }
    case SET_FORM_IS_VALID: 
      return {
        ...state,
        formIsValid: true
      }
    case UNSET_FORM_IS_VALID: 
      return {
        ...state,
        formIsValid: false
      }
    case INCREMENT_FORM_IS_SUBMITTED: 
      return {
        ...state,
        formSubmitted: parseInt(state.formSubmitted) + 1
      }
    case UPDATE_ACTION:
      return {
        ...state,
        action: action.payload
      }
    case RESET:
      return initialState
    default:
      return state
  }
}

// Action Creators
export const updatePinCreatePasswordForm = createAction(UPDATE_PIN)
export const updateEmailCreatePasswordForm = createAction(UPDATE_EMAIL)
export const updatePasswordCreatePasswordForm = createAction(UPDATE_PASSWORD)
export const updatePasswordConfirmCreatePasswordForm = createAction(UPDATE_PASSWORD_CONFIRM)
export const updatePasswordIsValidCreatePasswordForm = createAction(UPDATE_PASSWORD_IS_VALID)
export const updatePasswordConfirmIsValidCreatePasswordForm = createAction(UPDATE_PASSWORD_CONFIRM_IS_VALID)
export const updateErrorMessageCreatePasswordForm = createAction(UPDATE_ERROR_MESSAGE)
export const setIsValidCreatePasswordForm = createAction(SET_FORM_IS_VALID)
export const unsetIsValidCreatePasswordForm = createAction(UNSET_FORM_IS_VALID)
export const incrementFormIsSubmittedCreatePasswordForm = createAction(INCREMENT_FORM_IS_SUBMITTED)
export const updateActionCreatePasswordForm = createAction(UPDATE_ACTION)
export const resetCreatePasswordForm = createAction(RESET)