import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@intxlog/iuilib'

//import css
import styles from './styles.module.scss'

const DocumentsLine = ({
  id,
  name,
  onClick
}) => {
  return (
    <Button
      type={`link`}
      text={name}
      id={id}
      size={`small`}
      className={styles.link}
      onClick={onClick}
    ></Button>
  )
}

//rules for props being passed in
DocumentsLine.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  name: PropTypes.string,
  onClick: PropTypes.func
}

export default DocumentsLine
