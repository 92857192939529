import { createAction } from 'redux-actions'

// Actions
export const SET_LOADING = 'customerPortal/loadSummaryPage/SET_LOADING'
export const UNSET_LOADING = 'customerPortal/loadSummaryPage/UNSET_LOADING'
export const RESET = 'customerPortal/loadSummaryPage/RESET'

const initialState = {
  isLoading: false
}

// Reducer
export default function loadSummaryPage (state = initialState, action) {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        isLoading: true
      }   
    case UNSET_LOADING:
      return {
        ...state,
        isLoading: false
      }
    case RESET:
      return initialState
    default:
      return state
  }
}

// Action Creators
export const setLoadingLoadSummaryPage = createAction(SET_LOADING)
export const unsetLoadingLoadSummaryPage = createAction(UNSET_LOADING)
export const resetLoadSummaryPage = createAction(RESET)