import React, { useMemo, useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import handleError from 'utils/handleError'
import { useDispatch } from 'react-redux'

import IELAPIDocumentService from '../../services/IELAPIDocumentService';

//import css
import styles from './styles.module.scss'

const outerContainer = {
    margin: 0,
    color: '#bdbdbd',
    borderRadius: "10px",
    height: '100%'
};

const DocumentDropzone = (props) => {
    const docService = new IELAPIDocumentService();

    const dispatch = useDispatch();

    const [files, setFiles] = useState([]);
    const [buttonClass, setClass] = useState(styles.submissionButton);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState(false);
    const [isRejected, setRejected] = useState(false);

    const onDropAccepted = useCallback((acceptedFiles) => {
        setError("");
        setRejected(false);
        setFiles([...files, ...acceptedFiles]);
    }, [files])

    const onDropRejected = useCallback(() => {
        setRejected(true);
        setError("");
    }, [])

    let accept = '.pdf, .xls, .xlsx, .doc, .docx, .csv'

    //specify file type with fileType={file/image/both}
    switch (props.fileType) {
        case "file":
            accept = '.pdf, .xls, .xlsx, .doc, .docx, .csv'
            break;
        case "image":
            accept = 'image/*'
            break;
        case "both":
            accept = '.pdf, .xls, .xlsx, .doc, .docx, .csv, image/*'
            break;
        default:
            accept = '.pdf, .xls, .xlsx, .doc, .docx, .csv, image/*'
    }

    const {
        getRootProps,
        getInputProps,
        open
    } = useDropzone({ onDropAccepted, onDropRejected, maxFiles: 5, accept: accept, noClick: true });

    const style = useMemo(() => ({
        ...outerContainer,
    }), [])

    const deleteFile = (file) => {
        const tempArray = [...files];
        tempArray.splice(files.indexOf(file), 1);
        setFiles(tempArray);
    }

    const submit = async () => {
        let uploadArray = [];
        if (files.length > 0) {
            for (var i = 0; i < files.length; i++) {
                let upload = await docService.uploadIdocsFile(files[i], props.loadid);
                uploadArray.push(upload);
            }

            let noError = true;

            for (var e = 0; e < uploadArray.length; e++) {
                if (uploadArray[e].message !== "Success" && uploadArray[e].message !== null && uploadArray[e].message !== undefined) {
                    let message = uploadArray[e] + "   If problem persists, please contact NEXUS@ielfreight.com."
                    if (uploadArray[e].errors !== undefined) {
                        message = uploadArray[e].errors.file;
                        noError = false;
                    }
                    setError(message);
                    dispatch(handleError(uploadArray[e], false));
                } else if (uploadArray[e].message === null && uploadArray[e].message === undefined) {
                    setError("An unknown error has occured. Please reload and try again. If problem persists, please contact NEXUS@ielfreight.com.");
                    dispatch(handleError(uploadArray[e], false));
                    noError = false;
                }

            }

            if (noError === true) {
                setClass(styles.submissionButtonDisabled);
                setError(false);
                setSuccess(true);
                setTimeout(function () {
                    props.closeModal();
                    props.refreshDocs();
                }, 2000);
            }
        } else {
            setError("An unknown error has occured. Please reload and try again. If problem persists, please contact NEXUS@ielfreight.com.");
        }
    }

    return (
        <React.Fragment>
            <div className={styles.hoverBorder}>
                <div {...getRootProps({
                    style,
                })}>
                    <input {...getInputProps()} />
                    <p>Drag and drop file(s) here or click Select a File. <br />Supported files types are .pdf, .xls, .xlsx, .doc, .docx, .csv.</p>
                    {isRejected ? <p className={styles.errorText}>That's not a supported file type. Please try again.</p> : ""}
                    {error !== "" ? <p className={styles.errorText}>{error}</p> : ""}
                    {success ? <p className={styles.successText}>Success!</p> : ""}
                </div>
                <div>

                </div>
                <button type="button" className={styles.selectFile} onClick={open}>
                    Select a File
                </button>
            </div>
            <div className={styles.fileContainer}>
                {files.map((file, index) => (
                    <div className={styles.documentContainer} key={index}>
                        <p className={styles.documentInfo}><strong>{file.path}</strong>{!success ? <button className={styles.unsubmit} onClick={() => { deleteFile(file) }}><span>&#10005;</span></button> : ""}</p>
                        <p className={styles.documentInfo}>Size: {file.size / 1000} KBs</p>
                        <p className={styles.documentInfo}>File type: {file.name.substring(file.name.lastIndexOf('.') + 1, file.name.length) || file.name}</p>
                    </div>
                ))}
                <button className={buttonClass} onClick={submit}>Submit</button>
            </div>
        </React.Fragment>
    );
}

export default DocumentDropzone;