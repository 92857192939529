import React from 'react'
import PropTypes from 'prop-types'

//import components
import LineItem from 'components/LineItem'
import BackButtonContainer from 'containers/BackButtonContainer'
//import css
import styles from './styles.module.scss'
import LoadDetailsStatusBar from "../LoadDetailsStatusBar";

const LoadDetail = ({
    PONumber,
    invoiceNumber,
    weight,
    loadDate,
    commodity,
    lane,
    delDate,
    status,
    remainingMiles,
    broker,
    officePhone,
    mobilePhone,
    email

}) => {
    return (
        <div>
            <LoadDetailsStatusBar status={status} remainingMiles={remainingMiles}/>
            <div className={styles.mainContainer}>
                <BackButtonContainer></BackButtonContainer>
                <div className={styles.container}>
                    <LineItem
                        label={`PO/Order`}
                        field={PONumber}
                    ></LineItem>
                    <LineItem
                        label={`IEL PO`}
                        field={invoiceNumber}
                    ></LineItem>
                    <LineItem
                        label={`Weight`}
                        field={weight}
                    ></LineItem>
                    <LineItem
                        label={`Load Date`}
                        field={loadDate}
                    ></LineItem>
                    <LineItem
                        label={`Commodity`}
                        field={commodity}
                    ></LineItem>
                    <LineItem
                        label={`Lane`}
                        field={lane}
                    ></LineItem>
                    <LineItem
                        label={`Deliver Date`}
                        field={delDate}
                    ></LineItem>
                    <LineItem
                        label={`Broker`}
                        field={broker}
                    ></LineItem>
                    <LineItem
                        label={`Office Phone`}
                        field={officePhone}
                    ></LineItem>
                    <LineItem
                        label={`Mobile Phone`}
                        field={mobilePhone}
                    ></LineItem>
                    <LineItem
                        label={`Email`}
                        field={email}
                    ></LineItem>
                </div>
            </div>
        </div>
    )
}

//rules for props being passed in
LoadDetail.propTypes = {
    PONumber: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    invoiceNumber: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    weight: PropTypes.number,
    loadDate: PropTypes.string,
    commodity: PropTypes.string,
    lane: PropTypes.string,
    delDate: PropTypes.string,
    status: PropTypes.string,
    remainingMiles: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    broker: PropTypes.string,
    officePhone: PropTypes.string,
    mobilePhone: PropTypes.string,
    email: PropTypes.string
}

export default LoadDetail
