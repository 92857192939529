import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types'

import Switch from "react-switch";

//import css
import styles from './styles.module.scss'

const ToggleSwitch = ({
    id,
    text,
    isChecked,
    disabled,
    onChange,
    title
}) => {
    const [checked, setChecked] = useState(isChecked);
    const handleChange = (nextChecked, event, id) => {
        setChecked(nextChecked);
        onChange(id, nextChecked);
    };

    useEffect(() => {
        setChecked(isChecked);
    }, [isChecked]);

    return (
        <div className={styles.toggleSwitchContainer} title={title}>
            <label htmlFor="toggle-switch">
                <Switch
                    id={id}
                    onChange={handleChange}
                    checked={checked}
                    className={styles.reactSwitch}
                    height={20}
                    width={50}
                    disabled={disabled}
                    onColor={"#FD9036"} //Hardcoded for npm package
                />
                <div className={styles.switchText}>{text}</div>
            </label>
        </div>
    );
};

ToggleSwitch.defaultProps = {
    isChecked: false,
};

ToggleSwitch.propTypes = {
    isChecked: PropTypes.bool,
    text: PropTypes.string,
    disabled: PropTypes.bool,
    title: PropTypes.string
};

export default ToggleSwitch;