import React from 'react'
import { Button } from '@intxlog/iuilib'
import styles from './styles.module.scss'
import invoiceTotal from 'helpers/invoiceTotal'

const TotalPaymentsChecked = ({
  inputArray = [],
  addInvoice,
  achPay,
  removeInvoiceModal
}) => {

  let total = invoiceTotal(inputArray)

  return (
    <div className={styles.container}>
      <div className={styles.addInvoice}>
          <Button id="orbipay-checkout-button" onClick={()=>{achPay(total)}} text={`Pay Now`}></Button>
      </div>
      <div className={styles.invoiceTotalText}>Invoice Total: <span className={styles.invoiceTotal}>${total}</span></div>
    </div>
  )
}

export default TotalPaymentsChecked
