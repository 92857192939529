import { createAction } from "redux-actions";

// Actions
export const UPDATE = "customerPortal/load/UPDATE";
export const RESET = "customerPortal/load/RESET";

const initialState = {
  id: null,
  invoiceNumber: null,
  POnumber: null,
  weight: null,
  loadDate: null,
  commodity: null,
  lane: null,
  delDate: null,
  status: `N/A`,
  remainingMiles: null,
  broker: null,
  officePhone: null,
  mobilePhone: null,
  email: null,
  billingARexportDate: null,
  billingTotal: null,
  billingTotalAccessorials: null,
  billingTotalAdjustments: null,
  billingInvoiceTotal: null,
  documents: [],
  accessorials: [],
  checkCalls: [],
  picks: [],
  drops: [],
  stops: [],
  latestCheckCallLong: null,
  latestCheckCallLat: null,
};

// Reducer
export default function load(state = initialState, action) {
  switch (action.type) {
    case UPDATE:
      return {
        ...initialState,
        ...action.payload,
      };
    case RESET:
      return initialState;
    default:
      return state;
  }
}

//Action Creators
export const updateLoad = createAction(UPDATE);
export const resetLoad = createAction(RESET);
