import IELAPIServiceFactory from './IELAPIServiceFactory'
import store from 'store'

import { setUsers, setSelectedUser } from '../modules/customerAdmin'

export default class IELAPICustomerAdminService {

    constructor(props) {
        this.service = IELAPIServiceFactory.new()
    }

    getUsers(userID, showDisabledUsers) {
        return this.service._withToken().get(`nexus/admin/getUsers/` + showDisabledUsers, {}).then((result) => {
            store.dispatch(setUsers(result.data));
        }).catch(error => {
            error.user = userID
            this.service.handleError(error, false)
        })
    }

    getUserInfo(userID) {
        return this.service._withToken().get(`nexus/admin/getUserInfo/${userID}`, {}).then((result) => {
            store.dispatch(setSelectedUser(result.data))
        }).catch(error => {
            error.user = userID
            this.service.handleError(error, false)
        })
    }

    saveUserProfile(user) {
        return this.service._withToken().post(`nexus/admin/saveUserProfile`, { id: user.id, name: user.name, email: user.email }).then((result) => {
        }).catch(error => {
            error.user = user
            this.service.handleError(error, false)
        })
    }

    saveUserPermissions(userWithPermissions) {
        return this.service._withToken().post(`nexus/admin/saveUserPermissions`, { id: userWithPermissions.id, permissions: userWithPermissions.permissions }).then((result) => {
        }).catch(error => {
            error.user = userWithPermissions.id
            this.service.handleError(error, false)
        })
    }

    disableUser(user) {
        return this.service._withToken().post(`nexus/admin/disableUser`, { id: user.id }).then((result) => {
        }).catch(error => {
            error.user = user
            this.service.handleError(error, false)
        })
    }

    enableUser(user) {
        return this.service._withToken().post(`nexus/admin/enableUser`, { id: user.id }).then((result) => {
        }).catch(error => {
            error.user = user
            this.service.handleError(error, false)
        })
    }

}