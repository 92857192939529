import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Button } from '@intxlog/iuilib'

//import components
import DataViewSorters from 'components/DataViewSorters'
import DataViewFilters from 'components/DataViewFilters'
import DataViewTable from 'components/DataViewTable'
import LoadingGif from 'components/LoadingGif'
import ExpandFilterButton from 'components/ExpandFilterButton'
import LayoutVerticalResponsive from 'components/LayoutVerticalResponsive'

//import css
import styles from './styles.module.scss'

export class DataView extends React.PureComponent {
  handleSortClick = (sortValue, direction) => {
    if (sortValue === this.props.sortField) {
      this.props.toggleDirection()
      if (this.props.direction === `desc`) {
        this.props.updateSortField('')
      }
    } else {
      this.props.updateSortField(sortValue)
    }
  }

  handleTableSortClick = (sortValue, desiredDirection) => {
    if (sortValue === this.props.sortField && this.props.direction === desiredDirection) {
      this.props.updateSortField(``)
    } else {
      this.props.updateSortField(sortValue)
    }
    this.props.setDirection(desiredDirection)
  }

  handleScroll = (e) => {
    if (this.props.lazyLoad && !this.props.loading) {
      const el = e.target
      //el.clientHeight = height of the content housed in the container
      //el.scrollTop = the position is calculated by the position of the scrollable content in relation to the top of the container
      //el.scrollHeight = the height of the container that is housing the scrollable content
      const bottom = (el.scrollHeight - el.scrollTop) - el.clientHeight
      if (bottom < 1) {
        this.props.getMoreData()
      }
    }
  }

  render() {
    let loader = null
    if (this.props.loading) {
      loader = <div className={styles.loadingWrapper}>
        <LoadingGif
          height={80}
          width={80}
        ></LoadingGif>
      </div>
    }

      let sorters = null

    if (!this.props.useTableView) {
      sorters = <DataViewSorters
        schema={this.props.schema}
        sortKey={this.props.sortKey}
        direction={this.props.direction}
        activeValue={this.props.sortField}
        onButtonClick={this.handleSortClick}
      ></DataViewSorters>
    }

    let expandFiltersButton = null
    if (this.props.showFilters && this.props.showExpandFiltersButton) {
      expandFiltersButton = <ExpandFilterButton
        expanded={this.props.isFilterExpanded}
        className={styles.headerButton}
        onClick={this.props.toggleShowFilters}
      ></ExpandFilterButton>
    }

    let exportButton = null
    if (this.props.showExport) {
      if (this.props.exportEnabled) {
        exportButton = <Button
          text={`Export to CSV`}
          size={`small`}
          className={styles.headerButton}
          onClick={this.props.exportOnClick}
        ></Button>
      } else {
        exportButton =
          <div title="You do not have permission to export to CSV."><Button
            text={`Export to CSV`}
            size={`small`}
            className={styles.headerButton}
            disabled
          ></Button>
          </div>
      }
    }

    return (
      <div className={styles.container}>
        <div className={styles.headerWrapper}>
          {expandFiltersButton}
          {exportButton}
        </div>
        <LayoutVerticalResponsive>
          <div className={styles.contentWrapper}>
            {this.props.showFilters &&
              <div className={classNames(
                styles.filtersWrapper,
                { [styles.open]: this.props.isFilterExpanded }
              )}>
                <DataViewFilters
                  schema={this.props.schema}
                  activeFilters={this.props.activeFilters}
                  pendingFilters={this.props.pendingFilters}
                  setActiveFilters={this.props.setActiveFilters}
                  setPendingFilters={this.props.setPendingFilters}
                  submitPendingFilters={this.props.submitPendingFilters}
                ></DataViewFilters>
              </div>
            }
            <div className={styles.sortersContainer}>
              {sorters}
              <div className={styles.listWrapper} onScroll={this.handleScroll}>
                {
                  this.props.useTableView ?
                    <DataViewTable
                      data={this.props.rawData}
                      schema={this.props.schema}
                      sortField={this.props.sortField}
                      sortKey={this.props.sortKey}
                      customFields={this.props.customTableValues}
                      onSortClick={this.handleTableSortClick}
                      direction={this.props.direction}
                      showModal={this.props.showModal}
                      id={this.props.id}
                      setEditRequest={this.props.setEditRequest}
                    ></DataViewTable>
                    :
                    this.props.lines
                }
              </div>
            </div>
          </div>
        </LayoutVerticalResponsive>
        {loader}
      </div>
    )
  }
}

DataView.propTypes = {
  schema: PropTypes.array,
  filterSchema: PropTypes.array,
  rawData: PropTypes.array,
  customTableValues: PropTypes.object,
  sortKey: PropTypes.string,
  sortField: PropTypes.string,
  direction: PropTypes.string,
  updateData: PropTypes.func,
  onClickSort: PropTypes.func,
  useLocalSorting: PropTypes.bool,
  activeFilters: PropTypes.array,
  pendingFilters: PropTypes.array,
  setActiveFilters: PropTypes.func,
  setPendingFilters: PropTypes.func,
  setEditRequest: PropTypes.func,
  submitPendingFilters: PropTypes.func,
  loading: PropTypes.bool,
  showExport: PropTypes.bool,
  exportDisabled: PropTypes.bool,
  showFilters: PropTypes.bool,
  useTableView: PropTypes.bool,
  exportOnClick: PropTypes.func,
  lazyLoad: PropTypes.bool,
  getMoreData: PropTypes.func,
  isFilterExpanded: PropTypes.bool,
  showExpandFiltersButton: PropTypes.bool,
  toggleShowFilters: PropTypes.func
}

DataView.defaultProps = {
  schema: [],
  showFilters: false,
  showExport: false,
  exportDisabled: false,
  useTableView: true,
  useLocalSorting: true
}

export default DataView
