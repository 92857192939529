import React from 'react'

//import components
import WrapperBoxWithTitle from 'components/WrapperBoxWithTitle'
import TotalPaymentsChecked from 'components/TotalPaymentsChecked'
import LayoutVerticalResponsive from 'components/LayoutVerticalResponsive'

import BackButtonContainer from 'containers/BackButtonContainer'
import PayHistoryButton from 'components/PayHistoryButton'

//import css
import styles from './styles.module.scss'

const PayNow = ({
  table,
  inputArray,
  number,
  setTypeModal,
  toggleOpenModal,
  setTextModal,
  addInvoice, 
  achPay,
  removeInvoiceModal
}) => {
  return (
    <div className={styles.container}>
      <WrapperBoxWithTitle>
        <div className={styles.backButton}>
        <BackButtonContainer></BackButtonContainer>
        <PayHistoryButton></PayHistoryButton>
        </div>
          <div className={styles.flexWrapper}>
            <TotalPaymentsChecked
              addInvoice={addInvoice}
              achPay={achPay}
              inputArray={inputArray}
              removeInvoiceModal={removeInvoiceModal}
            ></TotalPaymentsChecked>
            <LayoutVerticalResponsive
              height={`auto`}
              className={styles.layoutWrapper}
            >
            {table}
          </LayoutVerticalResponsive>
        </div>
      </WrapperBoxWithTitle>
    </div> 
  )
}

export default PayNow
