import React from 'react'
import PropTypes from 'prop-types'
import { Button, Input } from '@intxlog/iuilib'

//import components
import DatePicker from 'components/DatePicker'

//import helpers
import convertDateObjectToString from 'helpers/convertDateObjectToString'
import SelectStatesProvinces from 'helpers/selectOptions/StatesProvinces'
import SelectStatus from 'helpers/selectOptions/StatusOptions'
import SelectEquipmentType from 'helpers/selectOptions/EquipmentTypes'


//import css
import styles from './styles.module.scss'

export class FilterInput extends React.Component {
  handleOnChange = (val) => {
    this.props.onChange(this.props.filterID, val)
  }

  handleOnDateChange = (dateObj) => {
    const dateString = convertDateObjectToString(dateObj)
    this.props.onChange(this.props.filterID, dateString)
  }

  render(){
    let content = null
    if (this.props.active) {
      content = <div className={styles.valueBox}>
        {this.props.value}
        <Button 
          className={styles.closeButton}
          type={`plain`}
          text={``}
          onClick={this.props.onCancelClick}
        ></Button>
      </div>
    } else {
      switch (this.props.type) {
        case `date`:
          content = <DatePicker
            className={styles.input}
            onChange={this.handleOnDateChange}
            value={this.props.value}
          ></DatePicker>
          break
        case `selectState`:
          content = <Input onChange={this.handleOnChange} type="select" defaultValue="default">
            <option key='default'value="default" disabled>State/Province</option>
              <SelectStatesProvinces ></SelectStatesProvinces> 
          </Input>
          break
        case `selectStatus` :
            content = <Input onChange={this.handleOnChange} type="select" defaultValue="default">
              <option key='default'value="default" disabled>Status</option>
                <SelectStatus ></SelectStatus> 
            </Input>
            break
        case 'selectEquipmentType':
          content = <Input onChange={this.handleOnChange} type="select" defaultValue="default">
            <option key='default' value="default" disabled>Equipment</option>
            <SelectEquipmentType ></SelectEquipmentType>
          </Input>
          break
        case 'checkbox':
          content = <div className={styles.checkboxWrapper}>
            <input
              className={styles.checkbox}
              type='checkbox'
              id={this.props.filterID}
              onChange={this.props.onChange}
              checked={this.props.isChecked}
              disabled={this.props.isDisabled}
            ></input>
            <label htmlFor={this.props.filterID}>{this.props.text}</label>
          </div>
          break
        default:
          content = <Input
            className={styles.input}
            onChange={this.handleOnChange}
          ></Input>
          break
      }
    }
  
    return (
      <div className={styles.container}>
        <label className={styles.label}>{this.props.text}</label>
        {content}
      </div>
    )
  }
}

FilterInput.propTypes = {
  text: PropTypes.string,
  type: PropTypes.string,
  active: PropTypes.bool,
  filterID: PropTypes.string,
  onChange: PropTypes.func,
  onCancelClick: PropTypes.func,
  isChecked: PropTypes.bool,
  isDisabled: PropTypes.bool
}

export default FilterInput