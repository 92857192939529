import React from 'react'
// import PropTypes from 'prop-types'

//import components
import { Button } from '@intxlog/iuilib'
import {Link} from 'react-router-dom'

//import css
import styles from './styles.module.scss'

class PrivacyPolicy extends React.Component {
  componentDidMount = () => {
    window.scrollTo(0,0)
  }

render() {
  return (
      <div className={styles.container}>
        <div className={styles.message}>
          <Button
            linkComponent={Link}
            elementType={`routedLink`}
            type={`link`}
            linkTo={`/`}
            text={`Home Page`}
            className={styles.link}
          ></Button>
          <h1>Privacy Policy</h1>
          <div id='policyContent'>
            <p><strong>Introduction</strong></p>
            <p>INTEGRITY EXPRESS LOGISTICS, LLC (<strong>&ldquo;Company&rdquo;</strong>&nbsp;or<strong>&nbsp;&ldquo;We&rdquo;</strong>) respects your privacy and is committed to protecting it through compliance with this policy.</p>
            <p>This policy describes the types of information We may collect from you or that you may provide when you visit the website www.ielfreight.com (our &ldquo;<strong>Website</strong>&ldquo; or &ldquo;<strong>Site</strong>&rdquo;) and our practices for collecting, using, maintaining, protecting, and disclosing that information.&nbsp; As used herein, all references to &ldquo;Website&rdquo; or &ldquo;Site" may include, individually, all and/or in any combination, IELFREIGHT.COM, INTXLOG.COM, BLOG.IELFREIGHT.COM/CARRIERS, BLOG.IELFREIGHT.COM/CUSTOMERS, IELJOBS.COM, IELNEXUS.COM, IELFRESH.COM, AND IELLANES.COM, as well as other websites, domains, and subdomains operated by or on behalf of Integrity Express Logistics, LLC, and also includes mobile applications including but not limited to IEL NEXUS customer mobile application and/or IEL LANES carrier mobile application.&nbsp; The Website(s) are maintained by Integrity Express Logistics, LLC.</p>
            <p>This policy applies to information we collect:</p>
            <ul>
              <li>On this Website.</li>
              <li>In email, text, and other electronic messages between you and the Website.</li>
              <li>When you interact with our advertising and applications on third-party websites and services, if those applications or advertising include links to this policy. &nbsp;<strong>Be advised that there are links between our Website and the websites of third-parties or into software portals of various software or third-party vendors that we utilize. &nbsp;Sometimes, websites of third-parties may have customized functions that may make it appear that you are on our Website; but, you are actually on the third-party vendors website or working within the software of a third-party vendor. &nbsp;When you are on the website or within the software of a third-party, the privacy policy(ies) of that third-party control and the Company disclaims all liability relating to use of the websites of third-parties, even if linked there through our Website.</strong></li>
            </ul>
            <p>This policy does not apply to information collected by:</p>
            <ul>
              <li>Us offline or through any other means, including on any other website operated by any third party; or</li>
              <li>Any third party that may link to or be accessible from or on the Website</li>
            </ul>
            <p>Please read this policy carefully to understand our policies and practices regarding your information and how we will treat it. If you do not agree with our policies and practices, your choice is not to use our Website. &nbsp;By accessing or using our Website, you agree to this privacy policy. This policy may change from time to time (<em>see</em>&nbsp;<a href="https://nexus.ielfreight.com/privacy">https://nexus.ielfreight.com/privacy</a>). &nbsp;&nbsp;Your continued use of our Website after we make changes is deemed to be acceptance of those changes, so please check the policy periodically for updates.</p>
            <p><strong>Children Under the Age of 16</strong></p>
            <p>Our Website is not intended for children under 16 years of age. &nbsp;No one under age 16 may provide any information to or on the Website. &nbsp;We do not knowingly collect personal information from children under 16. &nbsp;If you are under 16, do not: &nbsp;a) use or provide any information on this Website or on or through any of its features/registers on the Website; b) use any of the interactive or public comment features of this Website; or, c) provide any information about yourself to us, including your name, address, telephone number, email address, or any screen name or user name you may use. &nbsp;If we learn we have collected or received personal information from a child under 16 without verification of parental consent, we will delete that information. &nbsp;If you believe we might have any information from or about a child under 16, please contact us at <a href="mailto:helpdesk@intxlog.com">helpdesk@intxlog.com</a>.</p>
            <p><strong>Information We Collect About You and How We Collect It</strong></p>
            <p>We collect several types of information from and about users of our Website, including information:</p>
            <ul>
              <li>by which you may be personally identified, such as name, postal address, e-mail address, telephone number, facsimile, website address, URL addresses, employer, years of experience, or any other information the Website collects that is defined as personal or personally identifiable information under any applicable law, including, but not limited to, credit information, shipping needs and information, billing information, and/or banking and/or credit card information (&ldquo;<strong>personal information</strong>&ldquo;);</li>
              <li>that is about you, but does not individually identify you, such as email addresses, website address, URL addresses, IP address; and/or</li>
              <li>about your internet connection, the equipment you use to access our Website and usage details.</li>
            </ul>
            <p>We collect this information:</p>
            <ul>
              <li>Directly from you when you provide it to us.</li>
              <li>Automatically as you navigate through the site. Information collected automatically may include usage details, IP addresses, and information collected through cookies, web beacons, and other tracking technologies.</li>
              <li>[From third parties, for example, &nbsp;businesses with which we have strategic alliance and/or business relationships..]</li>
            </ul>
            <p><i><strong>Information You Provide to Us</strong></i></p>
            <p>The information we collect on or through our Website may include:</p>
            <ul>
              <li>Information that you provide by filling in forms on our Website. This includes information provided at the time of registering to use our Website, subscribing to our service, posting material, or requesting quotes or further services. We may also ask you for information when you enter a contest or promotion sponsored by us, and when you report a problem with our Website.</li>
              <li>Records and copies of your correspondence (including email addresses), if you contact us.</li>
              <li>Your responses to surveys that we might ask you to complete for research purposes.</li>
              <li>Your search queries on the Website.</li>
              <li>Information about you, such as your company or individual name, address, phone, facsimile, email addresses, website address, URL addresses, credit information, shipping needs and information, such as weight, description, freight classification, shipment origin(s) and destination(s), billing information, which may include banking and/or credit card information, etc.</li>
            </ul>
            <p>You also may provide information to be published or displayed (hereinafter, &ldquo;<strong>posted</strong>&ldquo;) on public areas of the Website, or transmitted to other users of the Website or third parties (collectively, &ldquo;<strong>User Contributions</strong>&ldquo;). &nbsp;Your User Contributions are posted on and transmitted to others at your own risk.&nbsp; Please be aware that no security measures are perfect or impenetrable. &nbsp;Additionally, we cannot control the actions of other users of the Website with whom you may choose to share your User Contributions. Therefore, we cannot and do not guarantee that your User Contributions will not be viewed by unauthorized persons.</p>
            <p><i><strong>Information We Collect Through Automatic Data Collection Technologies</strong></i></p>
            <p>As you navigate through and interact with our Website, we may use automatic data collection technologies to collect certain information about your equipment, browsing actions, and patterns, including:</p>
            <ul>
              <li>Details of your visits to our Website, including traffic data, location data, logs, and other communication data and the resources that you access and use on the Website.</li>
              <li>Information about your computer and internet connection, including your IP address, operating system, and browser type.</li>
            </ul>
            <p>We also may use these technologies to collect information about your online activities over time and across third-party websites or other online services (behavioral tracking). &nbsp;The information we collect automatically may include personal information, or we may maintain it or associate it with personal information we collect in other ways or receive from third parties. &nbsp;It helps us to improve our Website and to deliver a better and more personalized service, including by enabling us to:</p>
            <ul>
              <li>Estimate our audience size and usage patterns.</li>
              <li>Store information about your preferences, allowing us to customize our Website according to your individual interests.</li>
              <li>Speed up your searches.</li>
              <li>Recognize you when you return to our Website.</li>
              <li>The technologies we use for this automatic data collection may include:</li>
              <li><strong>Cookies (or browser cookies).</strong>&nbsp; A cookie is a small file placed on the hard drive of your computer. &nbsp;You may refuse to accept browser cookies by activating the appropriate setting on your browser. &nbsp;However, if you select this setting you may be unable to access certain parts of our Website. Unless you have adjusted your browser setting so that it will refuse cookies, our system will issue cookies when you direct your browser to our Website.</li>
              <li><strong>Flash Cookies.</strong>&nbsp; Certain features of our Website may use local stored objects (or Flash cookies) to collect and store information about your preferences and navigation to, from, and on our Website. Flash cookies are not managed by the same browser settings as are used for browser cookies.</li>
              <li><strong>Web Beacons.</strong>&nbsp; Pages of the Website and our e-mails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related Website statistics (for example, recording the popularity of certain Website content and verifying system and server integrity).</li>
              <li><strong>And other tracking technology.</strong></li>
            </ul>
            <p>We do not collect personal information automatically, but we may tie this information to personal information about you that we collect from other sources or you provide to us.</p>
            <p><strong>Third-Party Use of Cookies and Other Tracking Technologies</strong></p>
            <p>Some content or applications, including advertisements, on the Website are served by third-parties, including advertisers, ad networks and servers, content providers, and application providers. &nbsp;These third parties may use cookies alone or in conjunction with web beacons or other tracking technologies to collect information about you when you use our website. &nbsp;The information they collect may be associated with your personal information or they may collect information, including personal information, about your online activities over time and across different websites and other online services. &nbsp;They may use this information to provide you with interest-based (behavioral) advertising or other targeted content.</p>
            <p>We do not control these third parties&rsquo; tracking technologies or how they may be used. If you have any questions about an advertisement or other targeted content, you should contact the responsible provider directly.</p>
            <p><strong>How We Use Your Information</strong></p>
            <p>We use information that we collect about you or that you provide to us, including any personal information:</p>
            <ul>
              <li>To present our Website and its contents to you.</li>
              <li>To provide you with information, products, or services that you request from us.</li>
              <li>To provide you with support and to respond to your inquiries, including to investigate and address your concerns and monitor and improve our responses</li>
              <li>To carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection.</li>
              <li>To notify you about changes to our Website or any products or services we offer or provide though it.</li>
              <li>To allow you to participate in interactive features on our Website.</li>
              <li>In any other way we may describe when you provide the information.</li>
              <li>For any other purpose with your consent.</li>
            </ul>
            <p>We may also use your information to contact you about our own goods and services that may be of interest to you. If you do not want us to use your information in this way, please exit the Website and do not use it.</p>
            <p><strong>Disclosure of Your Information</strong></p>
            <p>We may disclose aggregated information about our users and information that does not identify any individual, without restriction.</p>
            <p>We may disclose personal information that we collect or you provide as described in this privacy policy:</p>
            <ul>
              <li>To our subsidiaries and affiliates.</li>
              <li>To contractors, service providers, and other third parties we use to support our business and who are bound by contractual obligations to keep personal information confidential and use it only for the purposes for which we disclose it to them.</li>
              <li>To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Integrity Express Logistics, LLC&rsquo;s assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by Integrity Express Logistics, LLC about our Website users is among the assets transferred.</li>
              <li>To fulfill the purpose for which you provide it.</li>
              <li>For any other purpose disclosed by us when you provide the information.</li>
              <li>With your consent.</li>
            </ul>
            <p>We may also disclose your personal information:</p>
            <ul>
              <li>To comply with any court order, law, or legal process, including to respond to any government or regulatory request.</li>
              <li>To enforce or apply our terms of use&nbsp;and other agreements.</li>
              <li>If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of Integrity Express Logistics, LLC, our customers, or others. This includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction.</li>
            </ul>
            <p><strong>Choices About How We Use and Disclose Your Information</strong></p>
            <p>We strive to provide you with choices regarding the personal information you provide to us. We have created mechanisms to provide you with the following control over your information:</p>
            <ul>
              <li><strong>Tracking Technologies and Advertising.</strong>&nbsp; You can set your browser to refuse all or some browser cookies, or to alert you when cookies are being sent. To learn how you can manage your Flash cookie settings, visit the Flash player settings page on Adobe&rsquo;s website. If you disable or refuse cookies, please note that some parts of this site may then be inaccessible or not function properly.</li>
              <li><strong>Disclosure of Your Information for Third-Party Advertising.</strong>&nbsp; Company does not share information for third-party advertising.</li>
              <li><strong>Promotional Offers from the Company.</strong>&nbsp;&nbsp; Company does not use information provided through its Website for Company promotional offers.</li>
              <li><strong>Targeted Advertising.</strong>&nbsp; Company will &nbsp;use information that we collect or that you provide to us to deliver advertisements according to our advertisers&rsquo; target-audience preferences.</li>
            </ul>
            <p>We do not control third parties&rsquo; collection or use of your information to serve interest-based advertising. &nbsp;However, these third parties may provide you with ways to choose not to have your information collected or used in this way. You can opt out of receiving targeted ads from members of the Network Advertising Initiative (&ldquo;<strong>NAI</strong>&ldquo;) on the NAI&rsquo;s website at&nbsp;<a href="https://www.networkadvertising.org/">www.networkadvertising.org</a>.</p>
            <p>California residents may have additional personal information rights and choices. &nbsp;Please reference privacy rights under California law&nbsp;for more information.</p>
            <p><strong>Accessing and Correcting Your Information</strong></p>
            <p>If you delete your User Contributions from the Website, copies of your User Contributions may remain viewable in cached and archived pages. They might also have been copied or stored by other Website users. Proper access and use of information provided on the Website, including User Contributions, is governed by our terms of use.</p>
            <p>California residents may have additional personal information rights and choices. Please reference privacy rights under California law&nbsp;for more information.</p>
            <p><strong>Your California Privacy Rights</strong></p>
            <p>If you are a California resident, California law may provide you with additional rights regarding our use of your personal information. To learn more about your California privacy rights, visit&nbsp;<a href="https://leginfo.legislature.ca.gov/faces/billTextClient.xhtml?bill_id=201720180AB375">https://leginfo.legislature.ca.gov/faces/billTextClient.xhtml?bill_id=201720180AB375</a>.</p>
            <p>California&rsquo;s &ldquo;Shine the Light&rdquo; law (Civil Code Section &sect; 1798.83) permits users of our Website that are California residents to request certain information regarding our disclosure of personal information to third parties for their direct marketing purposes. To make such a request, please send an email to <a href="mailto:helpdesk@intxlog.com">helpdesk@intxlog.com</a> or write us at: 4420 Cooper Road, Suite 400, Cincinnati, Ohio 45242.</p>
            <p><strong>Data Security</strong></p>
            <p>We have implemented measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure. &nbsp;All information you provide to us is stored on our secure servers behind firewalls.</p>
            <p>The safety and security of your information also depends on you. &nbsp;Where we have given you (or where you have chosen) a password for access to certain parts of our Website, you are responsible for keeping this password confidential. &nbsp;We ask you not to share your password with anyone. &nbsp;We urge you to be careful about giving out information in public areas of the Website like message boards. The information you share in public areas may be viewed by any user of the Website.</p>
            <p>Unfortunately, the transmission of information via the internet is not completely secure. Although we do our best to protect your personal information, we cannot guarantee the security of your personal information transmitted to our Website. &nbsp;Any transmission of personal information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures contained on the Website.</p>
            <p><strong>Changes to Our Privacy Policy</strong></p>
            <p>It is our policy to post any changes we make to our privacy policy on this page with a notice that the privacy policy has been updated on the Website home page. &nbsp;If we make material changes to how we treat our users&rsquo; personal information, we will notify you by email to the email address specified in your account and/or through a notice on the Website home page. &nbsp;The date the privacy policy was last revised is identified at the top of the page. &nbsp;You are responsible for ensuring we have an up-to-date active and deliverable email address for you, and for periodically visiting our Website and this privacy policy to check for any changes.</p>
            <p><strong>Contact Information</strong></p>
            <p>To ask questions or comment about this privacy policy and our privacy practices, contact us at:</p>
            <p><strong>4420 Cooper Road<br/>
            Suite 400<br/>
            Cincinnati, Ohio 45242<br/>
              Email:&nbsp; <a href="mailto:helpdesk@intxlog.com">helpdesk@intxlog.com</a></strong></p>
          </div>
        </div>
      </div>
    )
  }
}

export default PrivacyPolicy
