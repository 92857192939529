import { createAction } from 'redux-actions'

// Actions
export const SET_DATA = 'customerPortal/LoadRequestHistory/UPDATE'
export const UPDATE_DATA = 'customerPortal/LoadRequestHistory/UPDATE_DATA'
export const UPDATE_SORT_FIELD = 'customerPortal/LoadRequestHistory/UPDATE_SORT_FIELD'
export const TOGGLE_SORT_DIRECTION = 'customerPortal/LoadRequestHistory/TOGGLE_SORT_DIRECTION'
export const SET_SORT_DIRECTION = 'customerPortal/LoadRequestHistory/SET_SORT_DIRECTION'
export const UPDATE_PARAMS = 'customerPortal/LoadRequestHistory/UPDATE_PARAMS'
export const SET_LOAD_REQUEST_LOADING = 'customerPortal/LoadRequestHistory/SET_LOAD_REQUEST_LOADING'
export const UPDATE_FILTER_PARAMS = 'customerPortal/LoadRequestHistory/UPDATE_FILTER_PARAMS'
export const SET_ACTIVE_FILTERS = 'customerPortal/LoadRequestHistory/SET_ACTIVE_FILTERS'
export const UPDATE_ACTIVE_FILTERS = 'customerPortal/LoadRequestHistory/UPDATE_ACTIVE_FILTERS'
export const UPDATE_SORT_PARAMS = 'customerPortal/LoadRequestHistory/UPDATE_SORT_PARAMS'
export const SET_PENDING_FILTERS = 'customerPortal/LoadRequestHistory/SET_PENDING_FILTERS'
export const SET_EDITING_REQUEST = 'customerPortal/LoadRequestHistory/SET_EDITING_REQUEST'
export const RESET_EDITING_REQUEST = 'customerPortal/LoadRequestHistory/RESET_EDITING_REQUEST'
export const SUBMIT_PENDING_FILTERS = 'customerPortal/LoadRequestHistory/SUBMIT_PENDING_FILTERS'
export const RESET = 'customerPortal/LoadRequestHistory/RESET'

const initialState = {
  schema: [
    {
      // The val field below is in camel case because it has to match the Business Object (BO) from the API, not the response data
      text: `Pickup Date`, val: `pickupDate`, appValue: `pickup_date`, sort: true, filter: {
        show: true,
        type: `date`
      }
    },
    {
      // The val field below is in camel case because it has to match the Business Object (BO) from the API, not the response data
      text: `Pickup Zip`, val: `pickZip`, appValue: `pick_zip`, sort: true, filter: {
        show: true
      }
    },
    {
      // The val field below is in camel case because it has to match the Business Object (BO) from the API, not the response data
      text: `Drop Date`, val: `deliveryDate`, appValue: `delivery_date`, sort: true, filter: {
        show: true,
        type: `date`
      }
    },
    {
      // The val field below is in camel case because it has to match the Business Object (BO) from the API, not the response data
      text: `Delivery Zip`, val: `dropZip`, appValue: `drop_zip`, sort: true, filter: {
        show: true
      }
    },
    {
      // The val field below is in camel case because it has to match the Business Object (BO) from the API, not the response data
      text: `Equipment`, val: `equipment`, appValue: `equipment`, sort: true, filter: {
        show: true,
        type: 'selectEquipmentType'
      }
    },
    {
      text: `Status`, val: `status`, appValue: `status`, sort: true, filter: {
        show: true,
      }
    },
  ],
  data: [],
  pages: 0,
  currentPage: 0,
  loading: false,
  sortField: ``,
  direction: ``,
  activeFilters: [],
  pendingFilters: [],
  sortParams: ``,
  filterParams: ``,
  editingRequest: null,
}

// Reducer
export default function LoadRequestHistory (state = initialState, action) {
  switch (action.type) {
    case SET_DATA:
      return {
        ...state,
        data: action.payload
      }
    case UPDATE_DATA:
      return {
        ...state,
        data: action.payload.length === 0 ? [...state.data] : [...action.payload]
      }
    case SET_LOAD_REQUEST_LOADING:
      return {
        ...state,
        loading: action.payload
      }
    case UPDATE_SORT_FIELD:
      return {
        ...state,
        sortField: action.payload,
        direction: `desc`
      }
    case TOGGLE_SORT_DIRECTION:
      return {
        ...state,
        direction: state.direction === `asc` ? `desc` : `asc`
      }
    case SET_SORT_DIRECTION:
      return {
        ...state,
        direction: action.payload
      }
    case UPDATE_PARAMS:
      return {
        ...state,
        params: action.payload
      }
    case UPDATE_FILTER_PARAMS:
      return {
        ...state,
        filterParams: action.payload
      }
    case UPDATE_SORT_PARAMS:
      return {
        ...state,
        sortParams: action.payload
      }
    case SET_ACTIVE_FILTERS:
      return {
        ...state,
        activeFilters: action.payload
      }
    case UPDATE_ACTIVE_FILTERS:
      return {
        ...state,
        activeFilters: [
          ...state.activeFilters
        ]
      }
    case SET_PENDING_FILTERS:
      return {
        ...state,
        pendingFilters: action.payload
      }
    case SUBMIT_PENDING_FILTERS:
      return {
        ...state,
        activeFilters: [
          ...state.activeFilters,
          ...state.pendingFilters
        ],
        pendingFilters: []
      }
    case SET_EDITING_REQUEST:
      let result = null;
      for(var i = 0; i < state.data.length; ++i) {
        if (state.data[i].id === action.payload) {
          result = state.data[i];
        }
      }
      return {
        ...state,
        editingRequest : result
      }
    case RESET_EDITING_REQUEST:
      return {
        ...state,
        editingRequest: null
      }
    case RESET:
      return initialState
    default:
      return state
  }
}

// Action Creators
export const setLoadRequestPageData = createAction(SET_DATA)
export const updateLoadRequestPageData = createAction(UPDATE_DATA)
export const setLoadRequestHistoryLoading = createAction(SET_LOAD_REQUEST_LOADING)
export const updateSortFieldRequestLoads = createAction(UPDATE_SORT_FIELD)
export const toggleSortDirectionRequestLoads = createAction(TOGGLE_SORT_DIRECTION)
export const setSortDirectionRequestLoads = createAction(SET_SORT_DIRECTION)
export const updateParamsLoads = createAction(UPDATE_PARAMS)
export const updateFilterParamsLoads = createAction(UPDATE_FILTER_PARAMS)
export const setActiveFiltersLoads = createAction(SET_ACTIVE_FILTERS)
export const updateActiveFiltersLoads = createAction(UPDATE_ACTIVE_FILTERS)
export const updateSortParamsLoads = createAction(UPDATE_SORT_PARAMS)
export const setPendingFiltersLoads = createAction(SET_PENDING_FILTERS)
export const submitPendingFiltersLoads = createAction(SUBMIT_PENDING_FILTERS)
export const setEditRequest = createAction(SET_EDITING_REQUEST)
export const resetEditRequest = createAction(RESET_EDITING_REQUEST)
export const reset = createAction(RESET)
