import IELAPIService from 'iel-api.js'
import { setAccessToken, setRefreshToken, loginUser, setLoading, setLoginFailed, setLoginSuccess } from 'modules/app'
import { updateUserInfo } from 'modules/userInfo'

import { saveMultiCustomerArray } from 'modules/multiCustomers'

import { userLoggedInAuth } from 'modules/auth'

import { resetLogin } from 'modules/login'
import { updateErrorMessageLoginForm, resetLoginForm } from 'modules/loginForm'

import store from 'store'

import handleError from '../utils/handleError'

export default class CUPOIELAPIService extends IELAPIService {

    userInfo() {
        store.dispatch(setLoading(true))
        return this._withToken().get('nexus/user')
            .then((result) => {
                store.dispatch(updateUserInfo(result.data.data))
                store.dispatch(setLoading(false))
                return result.data.data;
            }).catch(error => {
                this.handleError(error, false)
                store.dispatch(setLoading(false))
            });
    }

    getMultiCustomers() {
        store.dispatch(setLoading(true))
        return this._withToken().get('nexus/user/customers/enabled')
            .then((result) => {
                let customers = result.data;
                let multiCustomerArray = [];
                for (let i = 0; i < customers.length; i++) {
                    let customerName = customers[i].externalcustomername !== null ? customers[i].externalcustomername : customers[i].customername
                    multiCustomerArray.push({ value: customers[i].id, label: customerName })
                }
                store.dispatch(saveMultiCustomerArray(multiCustomerArray))
                store.dispatch(setLoading(false))
                return multiCustomerArray;
            }).catch(error => {
                this.handleError(error, false)
                store.dispatch(setLoading(false))
            });
    }

    afterLoginError(error) {
        var code = null;
        if (error.response.status) {
            code = error.response.status
        }

        let message = null;

        //check code
        switch (code) {
            case 401:
                message = `Invalid Email or Password. Please contact nexus@ielfreight.com for assistance.`
                break
            case 429:
                message = `You have been locked out. Please wait at least 1 minute before trying your password again.`
                break
            case 403:
                message = `Invalid permissions, please confirm account credentials. If issue persists, contact nexus@ielfreight.com`
                break
            default:
                // Check to see if error is invalid credentials and if so, give that message - still log either way
                if (error.response.data.message.includes('credentials')) {
                    message = `Invalid Email or Password. Please contact nexus@ielfreight.com for assistance.`
                } else {
                    message = `A server error has occured. Please contact nexus@ielfreight.com for assistance.`
                }
                this.handleError(error, false)
                break
        }

        store.dispatch(updateErrorMessageLoginForm(message))
    }

    afterLoginSuccess(result) {
        store.dispatch(setAccessToken(this.accessToken))
        store.dispatch(setRefreshToken(this.refreshToken))

        //set tokens and logged in items on the session storage
        sessionStorage.setItem(`accessToken`, this.accessToken)
        sessionStorage.setItem(`refreshToken`, this.refreshToken)

        result = this.userInfo().then((result) => {
            if (result) {
                this.getMultiCustomers().then((customerResult) => {
                    if (customerResult) {
                        store.dispatch(resetLoginForm())
                        store.dispatch(resetLogin())
                        store.dispatch(userLoggedInAuth())
                        store.dispatch(loginUser(true))
                        store.dispatch(setLoginSuccess(true))
                        sessionStorage.setItem(`loggedIn`, true)
                        sessionStorage.setItem(`hideStatusBanner`, false)
                        store.dispatch(setLoading(false))
                    } else {
                        store.dispatch(setLoginFailed({ success: true, message: 'No enabled customers.' }));
                        store.dispatch(loginUser(false))
                        store.dispatch(setLoginSuccess(false))
                    }
                });
            } else {
                store.dispatch(setLoginFailed({ success: true, message: 'Invalid Permissions' }));
                store.dispatch(loginUser(false))
                store.dispatch(setLoginSuccess(false))
            }
        });
    }

    // Handle error method that can be called anywhere the service is used, just uses existing handleError stuffs
    handleError = (error, showModal, customMessage) => {
        store.dispatch(handleError(error, showModal, customMessage));
    }

    _withToken() {
        // Need to set the token in parent from Session
        super.accessToken = sessionStorage.getItem('accessToken') ? sessionStorage.getItem('accessToken') : this.accessToken;
        super.refreshToken = sessionStorage.getItem('refreshToken') ? sessionStorage.getItem('refreshToken') : this.refreshToken;
        return super._withToken();
    }

    _getFileWithToken() {
        // Need to set the token in parent from Session
        super.accessToken = sessionStorage.getItem('accessToken') ? sessionStorage.getItem('accessToken') : this.accessToken;
        super.refreshToken = sessionStorage.getItem('refreshToken') ? sessionStorage.getItem('refreshToken') : this.refreshToken;
        return super._getFileWithToken();
    }
}