import CUPOIELAPIService from './CUPOIELAPIService'
import store from 'store'

export default class IELAPIServiceFactory {
    static new() {
        return new CUPOIELAPIService({
            baseURL: process.env.REACT_APP_ITMS_API_V2_URL,
            accessToken: store.accessToken,
            refreshToken: store.refreshToken
        })
    }
}