import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
//import { Button } from '@intxlog/iuilib'
//import actions
import {
  resetLoad
} from 'modules/load'

//import components
import LoadView from 'components/LoadView'
import LoadDetail from 'components/LoadDetail'
import LoadCheckCalls from 'components/LoadCheckCalls'
import LoadAccessorials from 'components/LoadAccessorials'
import LoadBilling from 'components/LoadBilling'
import LoadDocuments from 'components/LoadDocuments'
import LoadPicks from 'components/LoadPicks'
import LoadDrops from 'components/LoadDrops'
import LoadMap from 'components/LoadMap'
import BrokerNotesLoadView from 'components/BrokerNotesLoadView'
import LoadDetailsMacroPoint from 'components/LoadDetailsMacroPoint'

//import services
import IELAPILoadService from '../../services/IELAPILoadService'
import IELAPIDocumentService from '../../services/IELAPIDocumentService'

export class LoadContainer extends React.Component {
  componentDidMount() {
    this.loadService = new IELAPILoadService();
    this.docService = new IELAPIDocumentService();

    if (this.props.multiCustomerSelectedId !== null ) {
      this.loadService.getLoad(this.props.match.params.id, this.props.multiCustomerSelectedId)
    }
  }
  componentDidUpdate (prevProps) {
    if (prevProps.multiCustomerSelectedId !== this.props.multiCustomerSelectedId) {
      this.loadService.getLoad(this.props.match.params.id, this.props.multiCustomerSelectedId)
    }
  }

  componentWillUnmount() {
    this.props.resetLoad()
  }

  viewDocsOnClick = (id, name) => {
    this.docService.downloadLoadFile(id, name);
  }

  render(){
    const data = this.props.loadData

    const detail = <LoadDetail
      PONumber={data.POnumber}
      invoiceNumber={data.invoiceNumber}
      weight={data.weight}
      loadDate={data.loadDate}
      commodity={data.commodity}
      lane={data.lane}
      delDate={data.delDate}
      status={data.status}
      remainingMiles={data.remainingMiles}
      broker={data.broker}
      officePhone={data.officePhone}
      mobilePhone={data.mobilePhone}
      email={data.email}
    ></LoadDetail>

    const map = <LoadMap
      stops={data.stops}
    ></LoadMap>

    const checkCalls = <LoadCheckCalls
      data={data.checkCalls}
    ></LoadCheckCalls>

    let documentList = []
    let documentData = data.documents
    documentData.map(d => {
      documentList.push(d)
      return documentList
    })

    const billing = <LoadBilling
      accessorials={data.billingTotalAccessorials}
      adjustments={data.billingTotalAdjustments}
      invoiceTotal={data.billingInvoiceTotal}
      total={data.billingTotal}
    ></LoadBilling>

    const documents = <LoadDocuments
      documents={documentList}
      viewDocsOnClick={this.viewDocsOnClick}
    ></LoadDocuments>

    const accessorials = <LoadAccessorials
      data={data.accessorials}
    ></LoadAccessorials>

    const picks = <LoadPicks
      data={data.picks}
    ></LoadPicks>

    const drops = <LoadDrops
      data={data.drops}
    ></LoadDrops>

    const notes = <BrokerNotesLoadView
    data={data.checkCalls}
    ></BrokerNotesLoadView>

    const macroPoint = <LoadDetailsMacroPoint
      data={data.macroPoint}
    ></LoadDetailsMacroPoint>

    return (
      <LoadView
        detail={detail}
        map={map}
        billing={billing}
        documents={documents}
        accessorials={accessorials}
        checkCalls={checkCalls}
        picks={picks}
        drops={drops}
        notes={notes}
        notesData={data.checkCalls}
        macroPointView={macroPoint}
        macroPointData={data.macroPoint}
        loadid={this.props.match.params.id}
        refreshDocs={() => {this.loadService.getLoad(this.props.match.params.id, this.props.multiCustomerSelectedId)}}
      ></LoadView>
    )
  }
}

const mapStateToProps = state => ({
  loadData: state.load,
  multiCustomerSelectedId: state.multiCustomer.selectedID
})

const mapDispatchToProps = dispatch => bindActionCreators({
  resetLoad,
}, dispatch)

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(LoadContainer))