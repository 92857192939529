import IELAPIServiceFactory from './IELAPIServiceFactory'
// import store from 'store' //May need this in the future but not right now

export default class IELAPIPaymentService {

    constructor(props) {
        this.service = IELAPIServiceFactory.new()
    }

    getPaymentResponse(invoiceInfo, callback) {
        return this.service._withToken().post(`nexus/payment/payeezy`, invoiceInfo).then((result) => {
            if (callback) {
                callback(result.paymentID)
            }
        }).catch(error => {
            this.service.handleError(error, false)
        })
    }

    createPayeezyHash(payeezyArray) {
        let hash = ''

        return this.service._withToken().post(`nexus/payment/payeezy/getHash`, payeezyArray).then((result) => {
            hash = result.data.data.payeezyHash
            return hash
        }).catch(error => {
            this.service.handleError(error, false)
        })
    }

    makeACHPayment(userID, customerID, orbipayToken, digiSign, amount,customerAccountReference, customFields) {
        let data = {
            "userid" : userID,
            "customerid" : customerID,
            "customerAccountReference" : customerAccountReference,
            "amount" : amount,
            "token" : orbipayToken,
            "digiSign" : digiSign,
            "customFields" : customFields
        }
        return this.service._withToken().post(`nexus/payment/achPayment`, data, {headers: {'X-IEL-APP' : process.env.REACT_APP_ITMS_API_ENV_NAME}}).then((result) => {
            return result
        }).catch(error => {
            this.service.handleError(error, false)
        })
    }
}
