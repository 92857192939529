import React from 'react'

export default function StatusOptions () {
  return [
    <option key="Ordered" value="Ordered">Ordered</option>,
    <option key="Dispatched" value="Dispatched">Dispatched</option>,
    <option key="Pickup Check-In" value="Pickup Check-In">Pickup Check-In</option>,
    <option key="Loaded" value="Loaded">Loaded</option>,
    <option key="Delivery Check-In" value="Delivery Check-In">Delivery Check-In</option>,
    <option key="Delivered" value="Delivered">Delivered</option>,
  ]
}
