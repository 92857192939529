
import React from "react";
import styles from "./styles.module.scss"
import NotesSideBar from "../NotesSideBar";
import NotesDetails from "../NotesDetails";
import {notes} from "../../data/notes"
import ReactDOM from "react-dom";

class Notes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeItem : notes[0].id,
        };
    }
    handleOnScroll=(current)=> {
        const element = ReactDOM.findDOMNode(current);
        window.addEventListener('scroll', ()=>{
            if (current != null && !this.flag) {
                let height = window.innerHeight;
                let top = current.getBoundingClientRect().top;
                let bottom = current.getBoundingClientRect().bottom;
                let elementHeight = top + (bottom - top) / 4;
                if (elementHeight <= height) {
                    this.setState({activeItem : element.id});
                }
            }
        });

        window.addEventListener('wheel', () => {this.flag = false})
    };

    handleItemChange=(e)=> {
        let ScrollModule = require('react-scroll');
        let scroller = ScrollModule.scroller;
        scroller.scrollTo(e.target.id);
        this.setState({activeItem : e.target.id});
        this.flag = true;
    };

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className={styles.container}>
                <NotesSideBar activeItem={this.state.activeItem} onActiveChange={this.handleItemChange}/>
                <NotesDetails activeItem={this.state.activeItem} onScroll={this.handleOnScroll}/>
            </div>
        )
    }
}

export default Notes;
