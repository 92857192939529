import React from "react";
import PropTypes from "prop-types";

//import containers
import LoadSummaryLinksContainer from "containers/LoadSummaryLinksContainer";
import DataViewContainer from "containers/DataViewContainer";
import LayoutVerticalResponsive from "components/LayoutVerticalResponsive";

//import css
import styles from "./styles.module.scss";

const LoadSummary = ({
  title,
  subTitle,
  total,
  icon,
  bgImage,
  dataViewProps,
  type,
  lines,
  hasPermission,
}) => {
  let subTitleString = subTitle ? (
    <span className={styles.subTitle}>{subTitle}</span>
  ) : null;

  let table = (
    <div className={styles.noPermissionText}>
      You do not have permission to view {title} loads.
    </div>
  );
  if (hasPermission) {
    let sortedLines = lines;
    if (dataViewProps.sortField !== "") {
      sortedLines.sort((a, b) => {
        let specA = a.props[dataViewProps.sortField]?.toLowerCase();
        let specB = b.props[dataViewProps.sortField]?.toLowerCase();

        specA = specA ?? ""
        specB = specB ?? ""

        if (specA < specB) {
          return 1;
        }
        if (specA > specB) {
          return -1;
        }
        return 0;
      });
    }

    let sortedDataViewProps = dataViewProps;
    if (dataViewProps.sortField !== "") {
      sortedDataViewProps.rawData.sort((a, b) => {
        let specA = a[dataViewProps.sortField]?.toLowerCase();
        let specB = b[dataViewProps.sortField]?.toLowerCase();

        specA = specA ?? ""
        specB = specB ?? ""

        if (dataViewProps.direction === "desc") {
          if (specA < specB) {
            return 1;
          }
          if (specA > specB) {
            return -1;
          }
        } else {
          if (specA < specB) {
            return -1;
          }
          if (specA > specB) {
            return 1;
          }
        }
        return 0;
      });
    }

    table = (
      <DataViewContainer
        lines={sortedLines}
        {...sortedDataViewProps}
      ></DataViewContainer>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.banner}>
        <div className={styles.titleWrapper}>
          <div className={styles.title}>
            {title}
            {subTitleString}
          </div>
          <div className={styles.iconWrapper}>{icon}</div>
        </div>
        <div
          className={styles.heroWrapper}
          style={{ backgroundImage: `url(${bgImage})` }}
        ></div>
        <div className={styles.totalWrapper}>
          <div className={styles.total}>
            {total}
            <span>Loads</span>
          </div>
        </div>
      </div>
      <LayoutVerticalResponsive
        height={`auto`}
        className={styles.dataViewWrapper}
      >
        {table}
      </LayoutVerticalResponsive>
      <div className={styles.navWrapper}>
        <LoadSummaryLinksContainer
          excludeArray={[type]}
          rowLayout
        ></LoadSummaryLinksContainer>
      </div>
    </div>
  );
};

LoadSummary.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  total: PropTypes.number,
  icon: PropTypes.object,
  bgImage: PropTypes.string,
  dataViewProps: PropTypes.object,
  type: PropTypes.string,
  hasPermission: PropTypes.bool,
};

export default LoadSummary;
