import IELAPIServiceFactory from './IELAPIServiceFactory'
import store from 'store'

import fileSaver from 'file-saver'

// import modules
import {
    setPayHistoryPageLoadingCsv,
    unSetPayHistoryPageLoadingCsv
} from "modules/payHistoryPage"

export default class IELAPIDocumentService {

    constructor(props) {
        this.service = IELAPIServiceFactory.new()
    }

    exportUnbilledArCsv(customerID) {
        return this.service._getFileWithToken().get(`nexus/customer/${customerID}/unbilledarcsv`, {}).then((result) => {
            fileSaver(result.data, `Unbilled loads export.csv`)
        }).catch(error => {
            error.customer = customerID
            this.service.handleError(error, false)
        })
    }

    exportBilledArCsv(customerID) {
        return this.service._getFileWithToken().get(`nexus/customer/${customerID}/billedarcsv`, {}).then((result) => {
            fileSaver(result.data, `Billed A/R export.csv`)
        }).catch(error => {
            error.customer = customerID
            this.service.handleError(error, false)
        })
    }

    exportPendingRequestsCsv(customerID) {
        return this.service._getFileWithToken().get(`nexus/pendingrequests/customer/` + customerID + `/csv`, {}).then((result) => {
            fileSaver(result.data, `Load_Request_History.csv`)
        }).catch(error => {
            error.customer = customerID
            this.service.handleError(error, false)
        })
    }

    exportPayHistoryCsv(customerID) {
        store.dispatch(setPayHistoryPageLoadingCsv())
        return this.service._getFileWithToken().get(`nexus/customer/` + customerID + `/payhistorycsv`, {}).then((result) => {
            fileSaver(result.data, `Pay_History.csv`)
            store.dispatch(unSetPayHistoryPageLoadingCsv())
        }).catch(error => {
            store.dispatch(unSetPayHistoryPageLoadingCsv())
            error.customer = customerID
            this.service.handleError(error, false)
        })
    }

    downloadLoadFile(id, name) {
        return this.service._getFileWithToken().get(`nexus/documents/file/${id}`, {}).then((result) => {
            fileSaver(result.data, name)
        }).catch(error => {
            this.service.handleError(error, false)
        })
    }

    uploadIdocsFile(file, loadid) {
        //We do form data like this so that it can properly handle the file being a file
        const formData = new FormData();
        formData.append('file', file);
        formData.append('loadid', loadid);

        return this.service._withToken().post(`nexus/documents/upload`, formData).then((result) => {
            return result.data
        }).catch(error => {
            this.service.handleError(error, false)
        })
    }
}
