import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Input } from '@intxlog/iuilib'
import Cookies from 'js-cookie'

//import components
import LoginFormWrapper from 'components/LoginFormWrapper'
import ForgotPasswordLink from 'components/ForgotPasswordLink'

//import actions
import {
  updateEmailLoginForm,
  updatePasswordLoginForm,
  updateEmailIsValidLoginForm,
  updatePasswordIsValidLoginForm,
  setIsValidLoginForm,
  unsetIsValidLoginForm,
  incrementFormIsSubmittedLoginForm
} from 'modules/loginForm'
import {
  updateFormToShowLogin
} from 'modules/login'
import IELAPIUserService from '../../services/IELAPIUserService'

export class LoginFormContainer extends React.Component {
  componentDidMount() {
    this.userService = new IELAPIUserService();
  }

  componentDidUpdate = (prevProps, prevState) => {
    //check to see if the email and the password both become valid
    if (this.props.emailIsValid && this.props.passwordIsValid && !prevProps.formIsValid) {
      this.props.setIsValidLoginForm()
    }
    if ((!this.props.emailIsValid || !this.props.passwordIsValid) && prevProps.formIsValid) {
      this.props.unsetIsValidLoginForm()
    }
  }

  handleEmailChange = (val) => {
    if (val !== Cookies.get('user')) {
      Cookies.remove('selectedOption')
    }
    this.props.updateEmailLoginForm(val)
  }

  handlePwdChange = (val) => {
    this.props.updatePasswordLoginForm(val)
  }

  handleForgotPwdOnClick = (e) => {
    e.preventDefault()
    this.props.updateFormToShowLogin(`requestChangePassword`)
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.props.incrementFormIsSubmittedLoginForm()

    //only submit the form if the form is valid
    if (this.props.formIsValid) {
      this.userService.submitLoginForm(this.props.emailAddress, this.props.password)
    }
  }

  render() {
    const formElements = <>
      <label htmlFor="email">Email Address</label>
      <Input
        type={`email`}
        idForLabel={`email`}
        required
        onChange={this.handleEmailChange}
        formSubmitted={this.props.formSubmitted}
        isValid={this.props.updateEmailIsValidLoginForm}
      // className={styles.inputBorder}
      ></Input>
      <label htmlFor="password">Password</label>
      <Input
        type={`password`}
        idForLabel={`password`}
        required
        onChange={this.handlePwdChange}
        formSubmitted={this.props.formSubmitted}
        isValid={this.props.updatePasswordIsValidLoginForm}
      ></Input>
      <ForgotPasswordLink
        onClick={this.handleForgotPwdOnClick}
      ></ForgotPasswordLink>
    </>

    return (
      <LoginFormWrapper
        title={`Login`}
        formElements={formElements}
        buttonText={`Sign In`}
        errorMessage={this.props.errorMessage}
        onSubmit={this.handleSubmit}
        forgot={this.handleForgotPwdOnClick}
      ></LoginFormWrapper>
    )
  }
}

const mapStateToProps = state => ({
  ...state.loginForm
})

const mapDispatchToProps = dispatch => bindActionCreators({
  updateEmailLoginForm,
  updatePasswordLoginForm,
  updateEmailIsValidLoginForm,
  updatePasswordIsValidLoginForm,
  setIsValidLoginForm,
  unsetIsValidLoginForm,
  incrementFormIsSubmittedLoginForm,
  updateFormToShowLogin
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginFormContainer)