//import actions
import {
  userLoggedOutAuth
} from 'modules/auth'

export default function logoutUser () {
  return dispatch => {
    //clear the session storage
    sessionStorage.setItem(`accessToken`, '')
    sessionStorage.setItem(`refreshToken`, '')
    sessionStorage.setItem(`loggedIn`, false)
    sessionStorage.setItem(`hideStatusBanner`, false)
    dispatch(userLoggedOutAuth())
  }
}