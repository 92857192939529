import { createAction } from 'redux-actions'

// Actions
export const UPDATE = 'userPortal/userInfo/UPDATE'
export const RESET = 'userPortal/userInfo/RESET'

const initialState = {
  id: '',
  email: '',
  name: ''
}

// Reducer
export default function userInfo (state = initialState, action) {
  switch (action.type) {
    case UPDATE:
      return {
        ...initialState,
        ...action.payload
      }
    case RESET:
      return initialState
    default:
      return state
  }
}

// Action Creators
export const updateUserInfo = createAction(UPDATE)
export const resetUserInfo = createAction(RESET)