import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import IELAPIUtilityService from '../../../services/IELAPIUtilityService'

class EquipmentTypes extends React.Component {
  componentDidMount = () => {
    this.utilService = new IELAPIUtilityService();
    this.utilService.getTrailerTypes();
  }

  render() {
    const trailertypesoptions = this.props.trailerTypes.map(obj => {
      return <option key={obj.id} value={obj.trailertype}>{obj.trailertype}</option>
    })

    return (trailertypesoptions)
  }
}

const mapStateToProps = state => ({
  trailerTypes: state.loads.trailerTypes
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {},
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EquipmentTypes)
