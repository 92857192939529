import { createAction } from 'redux-actions'
import Cookies from 'js-cookie'

// Actions
export const SAVE_MULTI_CUSTOMERS_ARRAY_FROM_API = 'customerPortal/multiCustomers/SAVE_MULTI_CUSTOMERS_ARRAY_FROM_API'
export const SAVE_SELECTED_CUSTOMER_ID = 'customerPortal/multiCustomers/SAVE_SELECTED_CUSTOMER_ID'
export const SAVE_SELECTED_OPTION = 'customerPortal/multiCustomers/SAVE_SELECTED_OPTION'
export const RESET = 'customerPortal/multiCustomers/RESET'

var cookieSelectedObj = !Cookies.get('selectedOption') ? null : JSON.parse(Cookies.get('selectedOption'))
var cookieSelectedObjValue = cookieSelectedObj !== null ? cookieSelectedObj.value : null

const initialState = {
  multiCustomerArray: [],
  selectedID: cookieSelectedObjValue,
  selectedOption: cookieSelectedObj
}

// Reducer
export default function multiCustomers (state = initialState, action) {
  switch (action.type) {
    case SAVE_MULTI_CUSTOMERS_ARRAY_FROM_API:
      return {
        ...state,
        multiCustomerArray: action.payload,
        selectedID: cookieSelectedObj === null ? action.payload[0].value : cookieSelectedObjValue
      }
    case SAVE_SELECTED_CUSTOMER_ID:
      return {
        ...state,
        selectedID: action.payload
      }
    case SAVE_SELECTED_OPTION:
      return {
        ...state,
        selectedOption: action.payload
      }

    case RESET:
      // The below things MUST be manually reset otherwise they are being cached which causes the next user
      // that logs in to see the previous user's selected customer. Info: CUPO-524
      Cookies.remove('selectedOption');
      cookieSelectedObj = null;
      cookieSelectedObjValue = null;

      return {
        multiCustomerArray: [],
        selectedID: '',
        selectedOption: ''
      };
    default:
      return state
  }
}

// Action Creators
export const saveMultiCustomerArray = createAction(SAVE_MULTI_CUSTOMERS_ARRAY_FROM_API)
export const saveSelectedCustomerId = createAction(SAVE_SELECTED_CUSTOMER_ID)
export const saveSelectedOption = createAction(SAVE_SELECTED_OPTION)
export const resetMultiCustomerState = createAction(RESET)
