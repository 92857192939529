import React from 'react'
import PropTypes from 'prop-types'
import TextWithLabel from 'components/TextWithLabel'
import LoadLineButton from 'components/LoadLineButton'

//import css
import styles from './styles.module.scss'

const BilledARLine = ({
  id,
  poNumber,
  customerPO,
  deliveryDate,
  amount,
  contact,
  debits,
  credits,
  amountPaid,
  balance,
  dateBilled,
  age
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.poWrapper}>
        <TextWithLabel
          text={contact}
          labelText={`Broker:`}
          ></TextWithLabel>
        <TextWithLabel
          text={poNumber}
          labelText={`IEL PO:`}
        ></TextWithLabel>
        <TextWithLabel
          text={customerPO}
          labelText={`Cust PO:`}
        ></TextWithLabel>
        <TextWithLabel
          text={dateBilled}
          labelText={`Billed Date:`}
        ></TextWithLabel>
        <TextWithLabel
          text={deliveryDate}
          labelText={`Delivery Date:`}
        ></TextWithLabel>
        <TextWithLabel
          text={age}
          labelText={`Age:`}
        ></TextWithLabel>
      </div>
      <div className={styles.detailsWrapper}>
        <div className={styles.routeWrapper}>
          <div className={styles.line}></div>
        </div>
      <div className={styles.startDetails}>
        <TextWithLabel
          text={amount}
          labelText={`Amount:`}
        ></TextWithLabel>
        <TextWithLabel
          text={balance}
          labelText={`Balance:`}
        ></TextWithLabel>
      </div>
      <div className={styles.endDetails}>
        <TextWithLabel
          text={debits}
          labelText={`Debits:`}
        ></TextWithLabel>
        <TextWithLabel
          text={credits}
          labelText={`Credits:`}
        ></TextWithLabel>
        <TextWithLabel
          text={amountPaid}
          labelText={`Amount Paid:`}
        ></TextWithLabel>
      </div>
      </div>
      <div className={styles.buttonWrapper}>
        <LoadLineButton id={id}></LoadLineButton>
      </div>
    </div>
  )
}

BilledARLine.propTypes = {
  id: PropTypes.number,
  poNumber: PropTypes.string,
  customerPO: PropTypes.string,
  deliveryDate: PropTypes.string,
  amount: PropTypes.string,
  contact: PropTypes.string,
  debits: PropTypes.string,
  credits: PropTypes.string,
  amountPaid: PropTypes.string,
  balance: PropTypes.string,
  dateBilled: PropTypes.string,
  age: PropTypes.number
}

export default BilledARLine

