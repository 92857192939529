import React from 'react'

import { Button } from '@intxlog/iuilib'
import { Link } from 'react-router-dom'
import styles from './styles.module.scss'

const PayHistoryButton = () => {
  return (
    <Button
    linkTo={`/history`}
    linkComponent={Link}
    elementType={`routedLink`}
    text={`Pay History`}
    className={styles.button}
  ></Button>
  )
}

export default PayHistoryButton