import React from 'react'
import PropTypes from 'prop-types'

//import components
import TextWithLabel from 'components/TextWithLabel'

//import css
import styles from './styles.module.scss'

const StatsDataViewAR = React.memo(({
  payTerms,
  billed,
  unbilled,
  used,
  term1,
  term2,
  term3,
  term4
}) => {
  return (
    <div className={styles.container}>
      <TextWithLabel
        text={payTerms}
        labelText={`Pay Terms:`}
        alignRight
      ></TextWithLabel>
      <TextWithLabel
        text={term1}
        labelText={`0-30 Days:`}
        alignRight
      ></TextWithLabel>
      <TextWithLabel
        text={billed}
        labelText={`Billed A/R:`}
        alignRight
      ></TextWithLabel>
      <TextWithLabel
        text={term2}
        labelText={`31-60 Days:`}
        alignRight
      ></TextWithLabel>
      <TextWithLabel
        text={unbilled}
        labelText={`Unbilled Loads:`}
        alignRight
      ></TextWithLabel>
      <TextWithLabel
        text={term3}
        labelText={`61-90 Days:`}
        alignRight
      ></TextWithLabel>
      <TextWithLabel
        text={used}
        labelText={`Total:`}
        alignRight
        textClassName={styles.totalLine}
      ></TextWithLabel>
      <TextWithLabel
        text={term4}
        labelText={`91 Days & Over:`}
        alignRight
      ></TextWithLabel>
    </div>
  )
})

StatsDataViewAR.propTypes = {
  payTerms: PropTypes.string,
  billed: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  unbilled: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  used: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  term1: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  term2: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  term3: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  term4: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
}

export default StatsDataViewAR