import React from 'react'
import PropTypes from 'prop-types'

//import css
import styles from './styles.module.scss'

const LoadDetailHeaderCell = ({
  children
}) => {
  return (
    <div className={styles.container}>{children}</div>
  )
}

//rules for props being passed in
LoadDetailHeaderCell.propTypes = {
  children: PropTypes.string
}

export default LoadDetailHeaderCell