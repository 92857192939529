import React from 'react'

//import components
import LayoutVerticalResponsive from 'components/LayoutVerticalResponsive'
import WrapperBoxWithTitle from 'components/WrapperBoxWithTitle'
//import containers
import BackButtonContainer from 'containers/BackButtonContainer'
//import styles
import styles from './styles.module.scss'

import { Button } from '@intxlog/iuilib'

import store from '../../store'

const LoadRequestHistory = ({
  table,
  exportFile
}) => {
  var exportToCsvButton = <div title="You do not have permission to export load history."><Button disabled size={`small`} className={styles.button} text={`Export CSV`}></Button></div>
  if (store.getState().userInfo.permissions ? store.getState().userInfo.permissions.LOAD_HISTORY_EXPORT : false) {
    exportToCsvButton = <Button size={`small`} className={styles.button} text={`Export CSV`} onClick={exportFile}></Button>
  }

  var loadRequetHistoryTable = <div>You do not have permission to view load history.</div>
  if (store.getState().userInfo.permissions ? store.getState().userInfo.permissions.LOAD_HISTORY_VIEW : false) {
    loadRequetHistoryTable = table
  }

  return (
    <div className={styles.container}>
      <WrapperBoxWithTitle>
        <div className={styles.backButton}>
            <BackButtonContainer></BackButtonContainer>   
            {exportToCsvButton}
        </div>
        
        <LayoutVerticalResponsive
          height={`auto`}
          className={styles.layoutWrapper}
        >
          {loadRequetHistoryTable}
        </LayoutVerticalResponsive>
      </WrapperBoxWithTitle>
    </div>
  )
}

export default LoadRequestHistory