import React from "react";
import styles from "./styles.module.scss"
import NotesSideBarItems from "../NotesSideBarItems";
import {notes} from "../../data/notes"

class NotesSideBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentActive: this.props.activeItem,
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            currentActive: nextProps.activeItem
        };
    }

    render() {
        const currentActive = this.state.currentActive;
        return (
            <div className={styles.container}>
                {notes.map((item,i) =>
                    <NotesSideBarItems key = {i} releaseID={item.id} onActiveChange={this.props.onActiveChange} isActive={item.id === currentActive} value={item.title}/>)}
            </div>
        )
    }
}

export default NotesSideBar;
