import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

//import components
import BuildLoad from 'components/BuildLoad'

export class BuildLoadContainer extends React.Component {
  render(open){
    return (

      <div>
        <BuildLoad
          userID={this.props.userID}
          customerID={this.props.customerID}
        ></BuildLoad>
      </div>
    )
  }
}

const mapStateToProps = state => ({
userID: state.userInfo.id,
customerID: state.multiCustomer.selectedID,
})

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{},
		dispatch
	)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BuildLoadContainer)