import { createAction } from 'redux-actions'

// Actions
export const UPDATE = 'customerPortal/loadLocation/UPDATE'
export const RESET = 'customerPortal/loadLocation/RESET'

const initialState = {
  latestCheckCallLong: null,
  latestCheckCallLat: null
}

// Reducer
export default function loadLocation (state = initialState, action) {
  switch (action.type) {
    case UPDATE:
      return {
        ...initialState,
        ...action.payload
      }
    case RESET:
      return initialState
    default:
      return state
  }
}

// Action Creators
export const updateLoadLocation = createAction(UPDATE)
export const resetLoadLocation = createAction(RESET)