
import {
  resetApp
} from 'modules/app'
import {
  resetAuth
} from 'modules/auth'
import {
  resetBillingPage
} from 'modules/billingPage'
import {
  resetCreatePasswordForm
} from 'modules/createPasswordForm'
import {
  resetCustomerInfo
} from 'modules/customerInfo'
import {
  resetForgotPasswordForm
} from 'modules/forgotPasswordForm'
import {
  resetLoad
} from 'modules/load'
import {
  resetLoads
} from 'modules/loads'
import {
  resetLoadsDelivered
} from 'modules/loadsDelivered'
import {
  resetLoadsToday
} from 'modules/loadsToday'
import {
  resetLoadsFuture
} from 'modules/loadsFuture'
import {
  resetLoadsInTransit
} from 'modules/loadsInTransit'
import {
  resetLoadSummaryPage
} from 'modules/loadSummaryPage'
import {
  resetLogin
} from 'modules/login'
import {
  resetLoginForm
} from 'modules/loginForm'
import {
  resetModal
} from 'modules/modal'
import {
  resetPurchaseOrders
} from 'modules/purchaseOrders'
import {
  resetStatsAR
} from 'modules/statsAR'
import {
  resetUserInfo
} from 'modules/userInfo'
import {
  resetMultiCustomerState
} from 'modules/multiCustomers'

export default function resetState () {
  return dispatch => {
    dispatch(resetApp())
    dispatch(resetAuth())
    dispatch(resetBillingPage())
    dispatch(resetCreatePasswordForm())
    dispatch(resetCustomerInfo())
    dispatch(resetForgotPasswordForm())
    dispatch(resetLoad())
    dispatch(resetLoads())
    dispatch(resetLoadsDelivered())
    dispatch(resetLoadsToday())
    dispatch(resetLoadsFuture())
    dispatch(resetLoadsInTransit())
    dispatch(resetLoadSummaryPage())
    dispatch(resetLogin())
    dispatch(resetLoginForm())
    dispatch(resetModal())
    dispatch(resetPurchaseOrders())
    dispatch(resetStatsAR())
    dispatch(resetUserInfo())
    dispatch(resetMultiCustomerState())
  }
}
