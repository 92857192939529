import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@intxlog/iuilib'

//import components
import FilterInput from 'components/FilterInput'

//import css
import styles from './styles.module.scss'

export class DocumentFilters extends React.PureComponent {
  // Store document filters
  documentFilters = [];

  handleSubmit = (e) => {
    e.preventDefault()

    //Go through and remove the document category array from active filters
    //This is because we need to push a new rebuilt array and dont want duplicates
    //in the query parameters
    for (let i = 0; i < this.props.activeFilters.length; i++) {
      if (this.props.activeFilters[i]['id'] === 'documentcategory') {
        this.props.activeFilters.splice(i, 1)
      }
    }

    this.props.submitPendingFilters()
  }

  handleFilterChange = (id, val) => {
    let filters = [...this.props.pendingFilters]

    for (let i = 0; i < filters.length; i++) {
      if (filters[i]['id'] === 'documentcategory') {
        filters.splice(i, 1)
      }
    }

    if (id === "bol") {
      if (val === true) {
        this.documentFilters.push(10);
      } else {
        this.documentFilters.splice(this.documentFilters.indexOf(10), 1)
      }

      this.props.filters.bol.value = !this.props.filters.bol.value;
    }

    if (id === "invoice") {
      if (val === true) {
        this.documentFilters.push(12);
      } else {
        this.documentFilters.splice(this.documentFilters.indexOf(12), 1)
      }

      this.props.filters.invoice.value = !this.props.filters.invoice.value;
    }

    if (id === "none") {
      if (val === true) {
        this.documentFilters.push(-1);
        this.props.filters.bol.value = false;
        this.props.filters.invoice.value = false;
        this.props.filters.bol.disabled = true;
        this.props.filters.invoice.disabled = true;
      } else {
        this.documentFilters.splice(this.documentFilters.indexOf(-1), 1)
        this.props.filters.bol.disabled = false;
        this.props.filters.invoice.disabled = false;
      }
      
      this.props.filters.none.value = !this.props.filters.none.value;
    }

    // Build the documentcategory filter array
    // If statement is to make sure we dont push an empty filter array
    if (this.props.filters.bol.value !== false || this.props.filters.invoice.value !== false || this.props.filters.none.value !== false) {
      filters.push({id: 'documentcategory',val:`[${this.documentFilters}]`,type:'like'})
    }

    this.props.setPendingFilters(filters)
  }

  handleClearFilters = () => {
    this.props.setActiveFilters([])
    this.props.filters.bol.value = false;
    this.props.filters.invoice.value = false;
    this.props.filters.none.value = false;
    this.props.filters.bol.disabled = false;
    this.props.filters.invoice.disabled = false;
    this.props.filters.none.disabled = false;
  }

  render() {
    const inputs = (
        <div>
          <FilterInput
            key={0} 
            text={"BOL"}
            type={"checkbox"}
            active={false}
            value={"bol"}
            filterID={"bol"}
            onChange={() => this.handleFilterChange("bol", !this.props.filters.bol.value)}
            isChecked={this.props.filters.bol.value}
            isDisabled={this.props.filters.bol.disabled}
          ></FilterInput>
          <FilterInput
            key={1} 
            text={"Invoice"}
            type={"checkbox"}
            active={false}
            value={"invoice"}
            filterID={"invoice"}
            onChange={() => this.handleFilterChange("invoice", !this.props.filters.invoice.value)}
            isChecked={this.props.filters.invoice.value}
            isDisabled={this.props.filters.invoice.disabled}
          ></FilterInput>
          <FilterInput
            key={2} 
            text={"None"}
            type={"checkbox"}
            active={false}
            value={"none"}
            filterID={"none"}
            onChange={() => this.handleFilterChange("none", !this.props.filters.none.value)}
            isChecked={this.props.filters.none.value}
            isDisabled={this.props.filters.none.disabled}
          ></FilterInput>
        </div>
    )

    return (
      <div className={styles.container}>
        <div className={styles.headerWrapper}>
          <h2>Document Filters</h2>
          <Button
            text={`Clear All`}
            type={`link`}
            onClick={this.handleClearFilters}
          ></Button>
        </div>
        <form onSubmit={this.handleSubmit}>
          <Button
            className={styles.button}
            fullWidth
            submitsForm
            size={`small`}
            text={`Apply`}
          ></Button>
          {inputs}
        </form>
      </div>
    )
  }
}

DocumentFilters.propTypes = {
  filters: PropTypes.object,
  activeFilters: PropTypes.array,
  pendingFilters: PropTypes.array,
  setAcitveFilters: PropTypes.func,
  setPendingFilters: PropTypes.func,
  submitPendingFilters: PropTypes.func,
}

export default DocumentFilters