import React from 'react'

// import PropTypes from 'prop-types'

//import components
import LineItem from 'components/LineItem'

//import css
import styles from './styles.module.scss'

const LoadBilling = ({
  accessorials,
  adjustments,
  invoiceTotal,
  total
}) => {
  
  return (
    <div className={styles.container}>
        <LineItem
          label={`Billed Amount`}
          field={`${total}`}
          fieldClassName={styles.rightAlign}
        ></LineItem>
        <LineItem
          label={`Accessorials`}
          field={`${accessorials}`}
          fieldClassName={styles.rightAlign}
        ></LineItem>
        <LineItem
          label={`Adjustments`}
          field={`${adjustments}`}
          fieldClassName={styles.rightAlign}
        ></LineItem>
        <LineItem
          label={`Total`}
          field={`${invoiceTotal}`}
          fieldClassName={styles.rightAlign}
        ></LineItem>
      </div>
  )
}

//rules for props being passed in
// LoadBilling.propTypes = {

// }

export default LoadBilling
