import React from 'react'
import PropTypes from 'prop-types'

//import components
import LoadDetailHeaderCell from 'components/LoadDetailHeaderCell'
import LoadDetailLine from 'components/LoadDetailLine'

//import css
import styles from './styles.module.scss'

const LoadAccessorials = ({
  data
}) => { 
  const expenses = data.map((x, index) => 
    <LoadDetailLine
      key={index}
      fields={[
        x.expense,
        x.amount
      ]}
    ></LoadDetailLine>  
  )

  return (
    <div className={styles.container}>
      <LoadDetailHeaderCell>Expense</LoadDetailHeaderCell>        
      <LoadDetailHeaderCell>Amount</LoadDetailHeaderCell>
      {expenses}
    </div>
  )
}

//rules for props being passed in
LoadAccessorials.propTypes = {
  data: PropTypes.array.isRequired
}

export default LoadAccessorials