import React, {useEffect, useState, useRef}from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import classNames from 'classnames'

//import styles
import styles from './styles.module.scss'

function BillingLinkDropdown (props) {
  const node = useRef();
  const [open, setOpen] = useState(false);
  const handleClick = e => {
    if (!node.current.contains(e.target)) {
      setOpen(false);
    }
  }

useEffect(() => {
  document.addEventListener("mousedown", handleClick);
  return () => {
    document.removeEventListener("mousedown", handleClick);
  }
}, []);

  return (
    <div className={styles.billingWrap} ref={node}>
      {!props.name ? null : <button className={styles.billing}
        onClick={e => setOpen(!open)}
      >
        {props.name}
      <div className={styles.arrow}></div>
      </button>}
      <ul className={
        classNames(
          styles.billingDropdown,
          {
            [styles.open]: open === true
          }
        )
      }>
        <li onClick={e => setOpen(!open)}>
        <NavLink
          className={styles.link}
          to="/ar/billed"
        >Billed A/R</NavLink>
        </li>
        <li onClick={e => setOpen(!open)}>
        <NavLink
          className={styles.link}
          to="/ar/unbilled"
        >Unbilled Loads</NavLink>
        </li>
      </ul>
    </div>
  )
}


BillingLinkDropdown.propTypes = {
  name: PropTypes.string,
  open: PropTypes.bool,
}

export default BillingLinkDropdown
