import React from 'react'
import PropTypes from 'prop-types'

//components
import LoadLineButton from 'components/LoadLineButton'
import ReceiveUpdatesButton from 'components/ReceiveUpdatesButton'

//helpers
import getColorFromStatus from 'helpers/getColorFromStatus'

//import css
import styles from './styles.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'

const LoadSummaryLine = ({
	id,
	customerPO,
	pickupDate,
	pickupCity,
	pickupState,
	delDate,
	delCity,
	delState,
	status,
	showModal,
	loadId,
	activeNotification,
	checkcallnote
}) => {

	const showNotesIcon = checkcallnote? <FontAwesomeIcon icon={faExclamationCircle} size="1x" /> : null
	const textColor = getColorFromStatus(status)
	const statusString = status ? `${status} ` : null
	return (
		<div className={styles.container}>
			<div className={styles.summaryWrap}>
				<div className={styles.poWrapper}>
					<span className={styles.detailsLabel}>PO/Order:</span> {customerPO}
					<br></br>
					<span>Status: </span>
					<span style={{ color: textColor, fontWeight: 600 }}>
						{statusString}
					</span>
						<span className={styles.notificationIcon}>
							{showNotesIcon}
						</span>
				</div>
				<div className={styles.detailsWrap}>
					<div className={styles.startDetails}>
						<span className={styles.detailsLabel}>Pickup</span>
						<div>
							{pickupCity}, {pickupState}
						</div>
						<div>{pickupDate}</div>
					</div>
					<div className={styles.endDetails}>
						<span className={styles.detailsLabel}>Delivery</span>
						<div>
							{delCity}, {delState}
						</div>
						<div>{delDate}</div>
					</div>
				</div>
			</div>
			<div>
				<div className={styles.buttonWrapper}>
					<LoadLineButton id={id}></LoadLineButton>
				</div>
				<ReceiveUpdatesButton
					id={id}
					activeNotification={activeNotification}
				></ReceiveUpdatesButton>
			</div>
		</div>
	)
}

LoadSummaryLine.propTypes = {
	id: PropTypes.number,
	customerPO: PropTypes.string,
	pickupDate: PropTypes.string,
	pickupCity: PropTypes.string,
	pickupState: PropTypes.string,
	delDate: PropTypes.string,
	delCity: PropTypes.string,
	delState: PropTypes.string
}

export default LoadSummaryLine
