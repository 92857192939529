import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import styles from './styles.module.scss'
import PlacesAutocomplete, {
 geocodeByPlaceId
} from 'react-places-autocomplete'

const AddressSearch = (props) => {

  const [searchAddress, setSearchAddress] = React.useState('')

  const handleSelect = async (value, placeId, suggestion) => {
  const results = await geocodeByPlaceId(placeId)
  props.setAddresstoFormik(results, props.formikValues, props.formikIndex, props.pickOrDropText, props.values)
  setSearchAddress('')
  }

return (
  <div>
    <PlacesAutocomplete className={styles.autocompleteSearch} value={searchAddress} onChange={setSearchAddress} onSelect={handleSelect}>
    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
    <div>
      <div className={styles.searchInput}>
        <input className={styles.searchField} {...getInputProps({ placeholder: 'Search Address' })}/>
        <span className={styles.awesomeIcon}><FontAwesomeIcon icon={faSearch} size="xs" color="green" /></span>
      </div>
      <div>
        {loading ? <div>...loading</div> : null}
        {suggestions.map((suggestion) => {
        const style = {
          backgroundColor: suggestion.active ? 'orange' : 'white'
        }
        return (
          <div key={suggestion.placeId} {...getSuggestionItemProps(suggestion, {style})}>
            {suggestion.description}
          </div>)
        })}
      </div>
  </div>
    )}
    </PlacesAutocomplete>
</div>)
}

export default AddressSearch;