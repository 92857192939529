import React from 'react'
import styles from './styles.module.scss'
import classNames from 'classnames'

const TextExceptionBox = ({
  textOnChange,
  defaultValue,
  textBoxInvalid
}) => {

  return (
    <div>
      <textarea 
        onChange={textOnChange}
        className={ 
          classNames(
          styles.textBox,
          {
            [styles.textBoxInvalid]: textBoxInvalid === true
          }
        )}
        placeholder='Reason for partial payment'
        value={defaultValue}
      ></textarea>
    </div>
  )
}

export default TextExceptionBox
