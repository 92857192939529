import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Modal } from '@intxlog/iuilib'

import styles from './styles.module.scss'

//import components
import SiteStatusBanner from 'components/SiteStatusBanner'
import SiteStatusBannerMoreInfo from 'components/SiteStatusBannerMoreInfo'

//import actions
import {
  updateSiteStatusBanner,
  closeSiteStatusBanner
} from 'modules/siteStatusBanner'

class SiteStatusBannerContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showStatusMoreInfoModal: false
    }
  }

  openStatusMoreInfoModal = () => {
    this.setState({ showStatusMoreInfoModal: true });
  }

  closeStatusMoreInfoModal = () => {
    this.setState({ showStatusMoreInfoModal: false });
  }

  moreInfoButton = () => {
    return (
      [
        <div className={styles.bannerMoreInfo}
          onClick={() => this.openStatusMoreInfoModal()}
        >More Info<i className={styles.rightarrow}></i></div>
      ]
    );
  }

  moreInfomodal = () => {
    return (
        <SiteStatusBannerMoreInfo
          moreInfo={this.props.bannerMoreInfo}
          onClick={this.closeStatusMoreInfoModal}
        ></SiteStatusBannerMoreInfo>
    )
  }
  
  render() {
    return (
      <div>
        <SiteStatusBanner
          bannerText={this.props.bannerText}
          bannerMoreInfo={this.moreInfoButton()}
          onClick={this.props.closeSiteStatusBanner}
        ></SiteStatusBanner>
        
        <Modal
          isOpen={this.state.showStatusMoreInfoModal}
          closeFunc={this.closeStatusMoreInfoModal}
          customContent={this.moreInfomodal()}
        ></Modal>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  ...state.siteStatusBanner
})

const mapDispatchToProps = dispatch => bindActionCreators({
  updateSiteStatusBanner,
  closeSiteStatusBanner
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SiteStatusBannerContainer)
