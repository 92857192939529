import React from 'react'
import PropTypes from 'prop-types'
import { SizeMe } from 'react-sizeme'

const ScaleIt = ({
  width,
  height,
  children
}) => {
  const aspectPercentage = `${height / width * 100}%`
  return (
    <SizeMe>
      {({ size }) => 
        <div style={{
          width: `100%`,
          paddingBottom: aspectPercentage,
          position: `relative`,
          overflow: `hidden`
        }}>
          <div style={{
            position: `absolute`,
            top: 0,
            left: 0,
            width: `100%`,
            height: `100%`,
            transform: `scale(${size.width / width})`,
            transformOrigin: `top left`
          }}>{children}</div>
        </div>
      }
    </SizeMe>
  )
}

//rules for props being passed in
ScaleIt.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  children: PropTypes.object
}

ScaleIt.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  children: PropTypes.object
}

export default ScaleIt