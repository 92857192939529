import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

//import css
import styles from './styles.module.scss'

const WrapperBoxWithTitle = React.memo(({
  title,
  showTitleBar,
  padding,
  textAlignCenter,
  className,
  children
}) => {
  const containerClasses = classNames({
    [styles.container]: true,
    [className]: className
  })

  const contentWrapperClasses = classNames({
    [styles.contentWrapper]: true,
    [styles.padded]: padding,
    [styles.permMessage]: textAlignCenter
  })

  //if title is broker notes, display an orange title bar
  const titleBarStyle = title !== 'Broker Notes'? styles.titleBar : styles.titleBarNotes 

  return (
    <div className={containerClasses}>
      {showTitleBar &&
        <div className={titleBarStyle}>
          <p className={styles.title}>{title}</p>
        </div>
      }
      <div className={contentWrapperClasses}>{children}</div>
    </div>
  )
})

//rules for props being passed in
WrapperBoxWithTitle.propTypes = {
  title: PropTypes.string,
  showTitleBar: PropTypes.bool,
  padding: PropTypes.bool,
  textAlignCenter: PropTypes.bool,
  className: PropTypes.string
}

// Specifies the default values for props:
WrapperBoxWithTitle.defaultProps = {
  showTitleBar: false,
  padding: false,
  textAlignCenter: false
}

export default WrapperBoxWithTitle