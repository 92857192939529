import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { NavLink } from 'react-router-dom'

import AvatarContainer from 'containers/AvatarContainer'
import BillingLinkDropdownContainer from 'containers/BillingLinkDropdownContainer'
import LoadRequestLinkDropdownContainer from 'containers/LoadRequestLinkDropdownContainer'

//import styles
import styles from './styles.module.scss'

const SiteMenu = ({
  isMenuOpen,
  toggleMenu,
  screenWidth
}) => {
  let loadRequestMobileDisplay
  let billingMobileDisplay
  if (screenWidth > 1000) {
    //if desktop view enabled, display Billing drop down
    loadRequestMobileDisplay = <LoadRequestLinkDropdownContainer/>
    billingMobileDisplay = <BillingLinkDropdownContainer></BillingLinkDropdownContainer>
  } else {
    //if mobile display enabled, display single button links for the hamburger menu
    billingMobileDisplay =
        (<div>
        <NavLink
          className={styles.link}
          to="/ar/billed"
        >Billed A/R</NavLink>
        <NavLink
          className={styles.link}
          to="/ar/unbilled"
        >Unbilled Loads</NavLink>
      </div>)
    loadRequestMobileDisplay = (
        <div>
            <NavLink
                className={styles.link}
                to="/buildload"
            >Submit Request</NavLink>
            <NavLink
                className={styles.link}
                to="/loadhistory"
            >Request History</NavLink>
        </div>
    )
  }


  return (
    <div className={styles.container}>
      <div onClick={toggleMenu} className={styles.menuIcon}>
        <div className={styles.top}></div>
        <div className={styles.middle}></div>
        <div className={styles.bottom}></div>
      </div>
      <div className={classNames(
        styles.linksWrapper,
        { [styles.isOpen]: isMenuOpen }
      )}>
        <NavLink
          className={styles.link}
          to="/"
        >Load Summary</NavLink>

        {loadRequestMobileDisplay}

        <NavLink
          className={styles.link}
          to="/search"
        >Load Search</NavLink>

        {billingMobileDisplay}

      </div>
      <AvatarContainer></AvatarContainer>
    </div>
  )
}

//rules for props being passed in
SiteMenu.propTypes = {
  isMenuOpen: PropTypes.bool,
  toggleMenu: PropTypes.func
}

export default SiteMenu
