import React from 'react'
import PropTypes from 'prop-types'

//components
import LoadLineButton from 'components/LoadLineButton'
import LineItem from 'components/LineItem'
import ReceiveUpdatesButton from 'components/ReceiveUpdatesButton'

//helper
import getColorFromStatus from 'helpers/getColorFromStatus'

//import css
import styles from './styles.module.scss'

const LoadLine = ({
  id,
  customerPO,
  poNumber,
  pickupDate,
  pickupCity,
  pickupState,
  delDate,
  delCity,
  delState,
  status,
  showModal,
  loadID,
  activeNotification
}) => {
  const textColor = getColorFromStatus(status)
  return (
    <div className={styles.container}>
      <div className={styles.linesWrapper}>
        <LineItem
          fieldClassName={styles.ellipsis}
          label={`PO/Order`}
          field={customerPO}
        ></LineItem>
        <LineItem
          label={`IEL/PO`}
          field={poNumber}
        ></LineItem>
        <LineItem
          label={`Pickup Date`}
          field={pickupDate}
        ></LineItem>
        <LineItem
          label={`Pickup City`}
          field={pickupCity}
        ></LineItem>
        <LineItem
          label={`Pickup State`}
          field={pickupState}
        ></LineItem>
        <LineItem
          label={`Delivery Date`}
          field={delDate}
        ></LineItem>
        <LineItem
          label={`Delivery City`}
          field={delCity}
        ></LineItem>
        <LineItem
          label={`Delivery State`}
          field={delState}
        ></LineItem>
        <LineItem
          label={`Status`}
          field={
            <div style={{ color: textColor, fontWeight: 600 }}>
              {status}
            </div>}
        ></LineItem>
      </div>
      <LoadLineButton
        id={id}
      ></LoadLineButton>
      <div className={styles.updateButton}>
        <ReceiveUpdatesButton
          id={id}
          activeNotification={activeNotification}
        ></ReceiveUpdatesButton>
      </div>
    </div>
  )
}

LoadLine.propTypes = {
  id: PropTypes.number,
  poNumber: PropTypes.string,
  customerPO: PropTypes.string,
  pickupDate: PropTypes.string,
  pickupCity: PropTypes.string,
  pickupState: PropTypes.string,
  delDate: PropTypes.string,
  delCity: PropTypes.string,
  delState: PropTypes.string,
  status: PropTypes.string
}

export default LoadLine