import React from 'react'
import LogoImage from 'assets/svg/header_logo.svg'
import IconFB from 'assets/images/icon-fb.png'
import IconTwitter from 'assets/images/icon-twitter.png'


//import css
import styles from './styles.module.scss'

const Footer = () => {
  return(
    <div className={styles.footerMain}>
      <div className={styles.container}>
        <div className={styles.footerBrand}>
          <img className={styles.nexusLogo} src={LogoImage} alt="Nexus" />
          <p className={styles.tagline}>
            X Marks The Spot for Tracking Every Shipment.
          </p>
        </div>
        <div className={styles.contact}>
          <h3>Contact</h3>
          <p><a href="mailto:nexus@ielfreight.com">nexus@ielfreight.com</a><br />for customer support</p>
          <ul className={styles.social}>
            <li><a href="https://www.facebook.com/pg/ielfreight/" className={styles.icon} target="_blank" rel="noopener noreferrer"><img src={IconFB} alt="Like us on Facebook" /></a></li>
            <li><a href="https://twitter.com/iel_llc/" className={styles.icon} target="_blank" rel="noopener noreferrer"><img src={IconTwitter} alt="Follow us on Twitter" /></a></li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Footer
