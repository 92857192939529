import IELAPIServiceFactory from './IELAPIServiceFactory'
import store from 'store'

// import modules
import {
    setLoadRequestPageData,
    setLoadRequestHistoryLoading
} from "modules/loadRequestHistory"
import { updateLoadRequestPageData } from "modules/loadRequestHistory";
import {
    unsetIsLoadingApp,
    setIsLoadingApp
} from 'modules/app'
import { toggleLoadRequestModal } from 'modules/createLoad'

export default class IELAPIPendingRequestsService {

    constructor(props) {
        this.service = IELAPIServiceFactory.new()
    }

    getLoadRequestHistory(customerID, page = 1, update = false, params = '') {
        let parameters = params !== `` ? `&${params}` : '';

        store.dispatch(setLoadRequestHistoryLoading(true))

        return this.service._withToken().post(`pendingrequests/customer/list?${parameters}`, { customerid: customerID }).then((result) => {
            const requestHistoryArray = Object.keys(result.data.data).map((key) => {
                const obj = result.data.data[key]

                return {
                    ...obj
                }
            })
            store.dispatch(update ? updateLoadRequestPageData(requestHistoryArray) : setLoadRequestPageData(requestHistoryArray))
            store.dispatch(setLoadRequestHistoryLoading(false))
        }).catch(error => {
            error.customer = customerID
            this.service.handleError(error, false)
        })
    }

    submitLoadRequestForm(values) {
        store.dispatch(setIsLoadingApp())
        return this.service._withToken().post(`nexus/pendingrequests/save`, values).then(() => {
            store.dispatch(unsetIsLoadingApp())
            store.dispatch(toggleLoadRequestModal())
        }).catch(error => {
            this.service.handleError(error, false)
        })
    }

    voidLoadRequest(requestID) {
        store.dispatch(setIsLoadingApp())
        return this.service._withToken().post(`nexus/pendingrequests/void`, {requestid : requestID}).then(() => {
            store.dispatch(unsetIsLoadingApp())
        }).catch(error => {
            this.service.handleError(error, false)
        })
    }
}
