import React from 'react'
import PropTypes from 'prop-types'

//import components
import TextWithLabel from 'components/TextWithLabel'

//import css
import styles from './styles.module.scss'

const CustomerDataViewAR = React.memo(({
  name,
  address1,
  address2,
  city,
  state,
  zip,
  phone,
  fax,
  contact,
  email
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.infoWrapper}>
        <div className={styles.nameWrapper}>{name}</div>
        <div>{address1}</div>
        <div>{address2}</div>
        <div>{city} {state}, {zip}</div>
      </div>
      <div className={styles.contactWrapper}>
        <TextWithLabel
          text={phone}
          labelText={`Phone:`}
        ></TextWithLabel>
        <TextWithLabel
          text={fax}
          labelText={`Fax:`}
        ></TextWithLabel>
        <TextWithLabel
          text={contact}
          labelText={`Contact:`}
        ></TextWithLabel>
        <TextWithLabel
          text={email}
          labelText={`Email:`}
        ></TextWithLabel>
      </div>
    </div>
  )
})

CustomerDataViewAR.propTypes = {
  name: PropTypes.string,
  address1: PropTypes.string,
  address2: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  zip: PropTypes.string,
  phone: PropTypes.string,
  fax: PropTypes.string,
  contact: PropTypes.string,
  email: PropTypes.string
}

export default CustomerDataViewAR