import React, {useState} from 'react'
import {Redirect} from 'react-router-dom'

//import css
import styles from './styles.module.scss'

const LoadRequestHistoryLine = ({
data,
setEditRequest
}) => {
	let backgroundColor = 'white';
	if (data.status === 'pending') {
		backgroundColor = '#f2c39df5'
	}
	const[redirect, setRedirect] = useState(false);

	const editPendingRequest = (id) => {
		console.log(id)
		setEditRequest(id);
		setRedirect(true);
	}

	if (redirect) {
		return <Redirect to="/buildload"/>
	}

	return (
		<div className={styles.container} style={{
			backgroundColor: backgroundColor,
		}} onClick={() => {editPendingRequest(data.id)}}>
			<div className={styles.summaryWrap}>
				<div className={styles.detailsWrap}>
					<span className={styles.detailsLabel}>Pickup Date: {data.pickup_date}</span>
				</div>
					<div className={styles.detailsWrap}>
						<span className={styles.detailsLabel}>Pickup Zip: {data.pick_zip}</span>
					</div>
					<div className={styles.detailsWrap}>
						<span className={styles.detailsLabel}>Dropoff Date: {data.delivery_date}</span>
					</div>
					<div className={styles.detailsWrap}>
						<span className={styles.detailsLabel}>Drop Zip: {data.drop_zip}</span>
					</div>
					<div className={styles.detailsWrap}>
						<span className={styles.detailsLabel}>Equipment: {data.equipment}</span>
					</div>
					<div className={styles.detailsWrap}>
						<span className={styles.detailsLabel}>Status: {data.status}</span>
					</div>
				</div>
			</div>
	)
}


export default LoadRequestHistoryLine
