import IELAPIServiceFactory from './IELAPIServiceFactory'
import store from 'store'

// import modules
import {
    setLoads,
    updateLoads,
    updateTotalLoads,
    updatePagesLoads,
    updateCurrentPageLoads,
    unsetLoadingLoads
} from 'modules/loads'
import {
    updateLoadsFuture,
    updateTotalLoadsFuture
} from 'modules/loadsFuture'
import {
    updateLoadsToday,
    updateTotalLoadsToday
} from 'modules/loadsToday'
import {
    updateLoadsInTransit,
    updateTotalLoadsInTransit
} from 'modules/loadsInTransit'
import {
    updateLoadsDelivered,
    updateTotalLoadsDelivered
} from 'modules/loadsDelivered'
import {
    setIsLoadingApp,
    unsetIsLoadingApp
} from 'modules/app'
import {
    updateLoad
} from 'modules/load'

// import helpers
import getLetterFromIndex from 'helpers/getLetterFromIndex'

export default class IELAPILoadService {

    constructor(props) {
        this.service = IELAPIServiceFactory.new()
    }

    getLoad(id, customerID, includeMacropoint = true) {
        store.dispatch(setIsLoadingApp())
        return this.service._withToken().get(`nexus/loads/${id}/allloaddata?customers=${customerID}&includeMacropoint=${includeMacropoint}`, {}).then((result) => {
            const details = result.data.loaddetails || {}
            const brokerDetails = result.data.brokerdetails || {}
            const billingInfo = result.data.billinginfo || {}
            const rawAccessorials = result.data.accessorials || { expenses: [] }
            const expenses = rawAccessorials.expenses || []
            const rawCheckCalls = result.data.checkcalls || []
            const rawStops = result.data.stops || { pickups: [], drops: [] }
            const rawPicks = rawStops.pickups
            const rawDrops = rawStops.drops
            const documents = result.data.documents || []
            let stops = []
            const macroPointDetails = result.data.macropoint || {}

            const checkCalls = rawCheckCalls.map(x => {
                if (!x.nexusnotes) {
                    return {
                        id: x.id,
                        time: new Date(x.ccdatetime),
                        city: x.city,
                        state: x.state,
                        zip: x.zip,
                        temp: x.temperature,
                        type: x.type_description,
                    }
                } else {
                    return {
                        id: x.id,
                        time: new Date(x.ccdatetime),
                        city: x.city,
                        state: x.state,
                        zip: x.zip,
                        temp: x.temperature,
                        type: x.type_description,
                        notes: x.nexusnotes
                    }
                }
            })

            let documentsObj = []
            Object.keys(documents).map((key) => {
                if (key === 'BOL' || key === 'Invoice' || key === 'Nexus Documents') {

                    //if documents exists get the file name and id
                    if (documents[key].length > 0) {
                        let objectInv = documents[key]
                        Object.keys(objectInv).map((key, index) => {
                            let customerdocument = objectInv[index].filename
                            let documentpath = objectInv[index].filepath
                            let documentid = objectInv[index].id
                            documentsObj.push({ name: customerdocument, path: documentpath, id: documentid })
                            return documentsObj
                        })
                    }
                }
                return documentsObj
            })

            const accessorials = expenses.map(x => {
                return {
                    expense: x.expensetype,
                    amount: x.expenseamountFormatted
                }
            })

            const picks = rawPicks.map((p) => {
                stops.push({
                    id: p.id,
                    lat: p.latitude || 0,
                    lng: p.longitude || 0
                })

                return {
                    id: p.id,
                    pick: p.shedname,
                    city: p.city,
                    state: p.state,
                    zip: p.zip,
                    number: p.pickupnumber,
                    date: p.pickupdate,
                    time: p.pickuptime,
                    commodity: p.commodity,
                    loaded: p.loaded ? `Yes` : `No`,
                    identifier: getLetterFromIndex(stops.length - 1)
                }
            })

            const drops = rawDrops.map((d) => {
                stops.push({
                    id: d.id,
                    lat: d.latitude || 0,
                    lng: d.longitude || 0
                })

                return {
                    id: d.id,
                    drop: d.shedname,
                    city: d.city,
                    state: d.state,
                    zip: d.zip,
                    delvPO: d.deliverypo,
                    date: d.deliverydate,
                    time: d.deliverytime,
                    unloadCharge: d.unloadingcharge,
                    delivered: d.delivered ? `Yes` : `No`,
                    identifier: getLetterFromIndex(stops.length - 1)
                }
            })

            const loadObj = {
                id: result.data.loadid,
                invoiceNumber: details.ielpo,
                POnumber: details.customerpo,
                weight: details.weight,
                loadDate: details.loaddate,
                commodity: details.commodity,
                lane: details.lane,
                delDate: details.delivereddate,
                status: details.status === `Needs Carrier` || details.status === `Booked` || details.status === `Not Dispatched` ? `Ordered` : details.status,
                remainingMiles: details.remainingMiles,
                broker: brokerDetails.broker,
                officePhone: brokerDetails.officephone,
                mobilePhone: brokerDetails.mobilephone,
                email: brokerDetails.email,
                billingTotal: billingInfo.totalbillFormatted,
                billingTotalAccessorials: billingInfo.totalaccessorialsFormatted,
                billingTotalAdjustments: billingInfo.totalsalesadjustmentsFormatted,
                billingInvoiceTotal: billingInfo.invoicetotalFormatted,
                documents: documentsObj,
                accessorials,
                checkCalls,
                picks,
                drops,
                stops,
                macroPoint: macroPointDetails
            }

            store.dispatch(updateLoad(loadObj))
            store.dispatch(unsetIsLoadingApp())

        }).catch(error => {
            this.service.handleError(error, false)
        })
    }

    getLoads(callType, params, page, update = false, customerID) {
        let urlTail
        let updatePages = 0
        let updateCurrentPage = 0
        let updateTotal
        let setLoadsAction
        let updateLoadsAction
        // let onStart // Commenting out for now because we may need it in the future but its is throwing errors right now since unused
        let onEnd = unsetLoadingLoads
        let recordPageInfo = false
        let parameters = params !== '' ? `&${params}` : ''

        switch (callType) {
            case `future`:
                urlTail = `nexus/loads/upcoming?customers=${customerID}&limit=1000`
                updateTotal = updateTotalLoadsFuture
                setLoadsAction = updateLoadsFuture
                break
            case `today`:
                urlTail = `nexus/loads/today?customers=${customerID}&limit=1000`
                updateTotal = updateTotalLoadsToday
                setLoadsAction = updateLoadsToday
                break
            case `inTransit`:
                urlTail = `nexus/loads/intransit?customers=${customerID}&limit=1000`
                updateTotal = updateTotalLoadsInTransit
                setLoadsAction = updateLoadsInTransit
                break
            case `delivered`:
                urlTail = `nexus/loads/completed?customers=${customerID}&limit=1000`
                updateTotal = updateTotalLoadsDelivered
                setLoadsAction = updateLoadsDelivered
                break
            default:
                urlTail = `nexus/loads?customers=${customerID}${parameters}&limit=200&page=${page}`
                updateTotal = updateTotalLoads
                updatePages = updatePagesLoads
                updateCurrentPage = updateCurrentPageLoads
                setLoadsAction = setLoads
                updateLoadsAction = updateLoads
                // onStart = setLoadingLoads // Commenting out for now because we may need it in the future
                recordPageInfo = true
                break
        }

        return this.service._withToken().get(urlTail, {}).then((result) => {
            const formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
              });
            // modify the data so we have a consistant schema
            const loadsArray = Object.keys(result.data.data).map(key => {
                const obj = result.data.data[key]
                return {
                    id: obj.id,
                    activeNotification: obj.activeloadnotification,
                    poNumber: obj.ponumber,
                    customerPO: obj.customerpopreview,
                    pickupDate: obj.loaddate,
                    pickupCity: obj.origincity,
                    pickupState: obj.originstate,
                    delDate: obj.deliverydate,
                    delCity: obj.destinationcity,
                    delState: obj.destinationstate,
                    billedTotal: formatter.format(obj.bill_amount),
                    checkcalldescription: obj.checkcalldescription,
                    checkcalltype: obj.checkcalltype,
                    status: obj.custom_status,
                    checkcallnote: obj.checkcallnote
                }
            })
            const totalLoads = result.data.total
            const totalPages = result.data.last_page
            const currentPage = result.data.current_page

            store.dispatch(
                update ? updateLoadsAction(loadsArray) : setLoadsAction(loadsArray)
            )
            store.dispatch(updateTotal(totalLoads))
            if (recordPageInfo) {
                store.dispatch(updatePages(totalPages))
                store.dispatch(updateCurrentPage(currentPage))
            }
            store.dispatch(onEnd())

        }).catch(error => {
            this.service.handleError(error, false)
        })
    }

    postSubscriptionDetails(loadIDActive, userId, deleted, active, customerid, ccEmails) {
        return this.service._withToken().post(`nexus/loads/${loadIDActive}/subscribe`, {
            load: {
                userid: userId,
                subscription: {
                    deleted: deleted,
                    active: active,
                    ccEmails: ccEmails
                }
            }
        }).then((result) => {
            // The result.data entry will be an integer of 200, but just to be safe lets check the status as well
            if (result.data === 200 && result.status === 200) {
                this.getLoads(`future`, '', 1, false, customerid)
                this.getLoads(`inTransit`, '', 1, false, customerid)
                this.getLoads(`today`, '', 1, false, customerid)
                this.getLoads(`delivered`, '', 1, false, customerid)
                this.getLoads('', '', 1, false, customerid)
            }
        }).catch(error => {
            this.service.handleError(error, false)
        })
    }
}
