import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

//import components
import DataView from 'components/DataView'

//import actions
import {
  setShowFiltersApp,
  unsetShowFiltersApp,
  toggleShowFiltersApp
} from 'modules/app'
import {setEditRequest} from 'modules/loadRequestHistory'


export class DataViewContainer extends React.Component {
  render(){
    return (
      <DataView
        isFilterExpanded={this.props.isFilterExpanded}
        showExpandFiltersButton={this.props.viewportWidth < 1100}
        toggleShowFilters={this.props.toggleShowFiltersApp}
        useTableView={this.props.viewportWidth > 1100}
        lines={this.props.lines}
        schema={this.props.schema}
        rawData={this.props.rawData}
        customTableValues={this.props.customTableValues}
        sortKey={this.props.sortKey}
        sortField={this.props.sortField}
        direction={this.props.direction}
        toggleDirection={this.props.toggleDirection}
        setDirection={this.props.setDirection}
        updateSortField={this.props.updateSortField}
        activeFilters={this.props.activeFilters}
        pendingFilters={this.props.pendingFilters}
        submitPendingFilters={this.props.submitPendingFilters}
        setActiveFilters={this.props.setActiveFilters}
        setPendingFilters={this.props.setPendingFilters}
        setEditRequest={this.props.setEditRequest}
        showFilters={this.props.showFilters}
        useLocalSorting={this.props.useLocalSorting}
        loading={this.props.loading}
        getMoreData={this.props.getMoreData}
        lazyLoad={this.props.lazyLoad}
        updateData={this.props.updateData}
        showExport={this.props.showExport}
        exportEnabled={this.props.exportEnabled}
        exportOnClick={this.props.exportOnClick}
        showModal={this.props.showModal}
        id={this.props.id}
      ></DataView>
    )
  }
}

const mapStateToProps = state => ({
  isFilterExpanded: state.app.showFilters,
  viewportWidth: state.viewport.width
})

const mapDispatchToProps = dispatch => bindActionCreators({
  setShowFiltersApp,
  unsetShowFiltersApp,
  toggleShowFiltersApp,
  setEditRequest
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DataViewContainer)
