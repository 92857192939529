import React from 'react'
import styles from './styles.module.scss'
import FormAchOrbipay from 'components/FormAchOrbipay'

const achModal = ({
                      removeAchModal,
                      updateACHPayment,
                      updatePO,
                      customer,
                      defaultAmount,
                      setTypeModal, 
                      toggleOpenModal, 
                      setTextModal
                  }) => {
    return (
        <div className={styles.modalWrapper}>
            <div className={styles.modal}>
                <FormAchOrbipay
                    customer={customer}
                    removeAchModal={removeAchModal}
                    updateACHPayment={updateACHPayment}
                    updatePO={updatePO}
                    defaultAmount={defaultAmount}
                    toggleOpenModal={toggleOpenModal}
                    setTypeModal={setTypeModal}
                    setTextModal={setTextModal}
                >
                    <div id="orbipay-checkout-iframe-div"></div>
                </FormAchOrbipay>
            </div>
        </div>
    )
}

export default achModal

