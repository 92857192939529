import React from 'react'
import { Button } from '@intxlog/iuilib'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import classNames from 'classnames'
import styles from './styles.module.scss'
import { showModalReceiveUpdates, saveButtonID, saveActiveNotification } from 'modules/receiveUpdates'

import store from '../../store'

class ReceiveUpdatesButton extends React.Component {
	handleShowModal = () => {
		this.props.saveButtonID(this.props.id)
		this.props.saveActiveNotification(this.props.activeNotification)
		this.props.showModalReceiveUpdates()
	}

	render() {
		let name = this.props.activeNotification ? `Update` : `Subscribe`

		var subscribeUpdateButton = <div title="You do not have permission to subscribe to loads."><Button disabled className={classNames(styles.button, { [styles.button_update]: name === 'Update' })} text={name} size={`small`}></Button></div>
		if (store.getState().userInfo.permissions ? store.getState().userInfo.permissions.SUBSCRIBE_TO_LOAD : false) {
			subscribeUpdateButton = <Button className={classNames(styles.button, { [styles.button_update]: name === 'Update' })} text={name} size={`small`} onClick={this.handleShowModal}></Button>
		}

		return (
			<>
				{subscribeUpdateButton}
			</>
		)
	}
}

const mapStateToProps = state => ({
	sub: state.receiveUpdates.subscriptions
})

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			showModalReceiveUpdates,
			saveButtonID,
			saveActiveNotification
		},
		dispatch
	)

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ReceiveUpdatesButton)
