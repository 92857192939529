export default function formatMiles (miles) {
    // Check valid input
    if (miles === 'Unable to calculate due to bad zip') {
        return null;
    }

    if (miles === 0) {
        return 0;
    }
    let res;
    /*  @Param 1,234.50 mi
        @Return 1234
    *   @Pram null
        @Return null
    * */
    if (miles) {
        res  = miles.match('[0-9]+,*[0-9]+');
        if (res) {
            res = res.toString().replace(",", "");
        }
    } else {
        res = miles;
    }
    return res;
}