import React from 'react'
import PropTypes from 'prop-types'

//import components
import ButtonArrow from 'components/ButtonArrow'

//import css
import styles from './styles.module.scss'

const DataViewHeaderCell = ({
  text,
  value,
  active,
  showSorters,
  onClick,
  checkbox,
  setSelectAll
}) => {
  let customCheckbox = null;
  const toggleCheckbox = (event) => {
    setSelectAll(event.target.checked);
  }
  if (checkbox) {
    customCheckbox = <input type="checkbox" title="Select All" onChange={event => toggleCheckbox(event)}/>
  }
  return (
    <div className={styles.container}>
      {customCheckbox}
      <div className={styles.textWrapper}>{text}</div>
      {showSorters &&
        <div className={styles.arrowsWrapper}>
          <ButtonArrow
            active={active === `asc`}
            onClick={() => onClick(value, `asc`)}
          ></ButtonArrow>
          <ButtonArrow
            orientation={`down`}
            active={active === `desc`}
            onClick={() => onClick(value, `desc`)}
          ></ButtonArrow>
        </div>
      }
    </div>
  )
}

DataViewHeaderCell.propTypes = {
  text: PropTypes.string,
  value: PropTypes.string,
  active: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]),
  showSorters: PropTypes.bool,
  onClick: PropTypes.func,
  setSelectAll: PropTypes.func
}

export default DataViewHeaderCell
