import React from 'react'
import { Button } from '@intxlog/iuilib'

//import components
import LayoutVerticalResponsive from 'components/LayoutVerticalResponsive'
import WrapperBoxWithTitle from 'components/WrapperBoxWithTitle'
//import containers
import BackButtonContainer from 'containers/BackButtonContainer'
//import styles
import styles from './styles.module.scss'



const PayHistory = ({
  table,
  exportFile,
  displayLoadingMessage
}) => {
  const displayGeneratingMessage = displayLoadingMessage ? <p className={styles.loading}>Generating file: This can take a few minutes</p> : null
  return (
    <div className={styles.container}>
      <WrapperBoxWithTitle>
        <div className={styles.backButton}>
        <BackButtonContainer></BackButtonContainer>
        <Button
          size={`small`}
          className={styles.button}
          text={`Export CSV`}
          onClick={exportFile}
        ></Button>                    
        </div>
        {displayGeneratingMessage}
        <LayoutVerticalResponsive
          height={`auto`}
          className={styles.layoutWrapper}
        >
          {table}
        </LayoutVerticalResponsive>
      </WrapperBoxWithTitle>
    </div>
  )
}

export default PayHistory