import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

//import containers
import PurchaseOrderDataViewContainer from 'containers/PurchaseOrderDataViewContainer'

//import actions
import {
  setCurrentTypePurchaseOrders
} from 'modules/purchaseOrders'

//import services
import IELAPIDocumentService from '../../services/IELAPIDocumentService'

import store from '../../store'

export class ARbilledContainer extends React.Component {
  componentDidMount() {
    this.docService = new IELAPIDocumentService();
    this.props.setCurrentTypePurchaseOrders(`billed`)
  }

  exportFile = () => {
    this.docService.exportBilledArCsv(this.props.customerID);
  }
  
  render(){
    let exportPermission = store.getState().userInfo.permissions ? store.getState().userInfo.permissions.AR_EXPORT : false;

    return (
        <PurchaseOrderDataViewContainer
          schema={this.props.schema}
          exportFunction={this.exportFile}
          exportEnabled={exportPermission}
        ></PurchaseOrderDataViewContainer>
    )
  }
}

const mapStateToProps = state => ({
  schema: state.purchaseOrders.schemaBilled,
  params: state.purchaseOrders.params,
  customerID: state.multiCustomer.selectedID
})

const mapDispatchToProps = dispatch => bindActionCreators({
  setCurrentTypePurchaseOrders
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ARbilledContainer)  