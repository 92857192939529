import React from 'react'
import { connect } from 'react-redux'

import TextExceptionBox from 'components/TextExceptionBox'

export class TextExceptionBoxContainer extends React.Component {
  render() {
    const dialogueBox = 
      <TextExceptionBox 
        matchingAmounts={this.props.matchingAmounts}
        textBoxInvalid={this.props.textBoxInvalid} 
        textOnChange={this.props.textOnChange}
        defaultValue={this.props.defaultValue}
      ></TextExceptionBox>
    let displayBox = !this.props.matchingAmounts && this.props.isChecked === true ? dialogueBox : null 
    return (
      <div>
        {displayBox}
      </div>
    )
  }
}

export default connect()(TextExceptionBoxContainer)