import React, {useState} from 'react';
import styles from './styles.module.scss'
import {useDispatch} from 'react-redux'

//modules
import {
    updateSubscribeEmails
} from 'modules/subscribeEmails'

export default function EmailChips() {
    const [items, setItems] = useState([]);
    const [value, setValue] = useState("");
    const [error, setError] = useState(null);

    const dispatch = useDispatch();

    const handleKeyDown = (event) => {
        if (["Enter", "Tab", ","].includes(event.key)) {
            event.preventDefault();

            var v = value.trim();

            if (v && isValid(v)) {
                dispatch(updateSubscribeEmails([...items, value]));
                setItems([...items, value]);
                setValue("");
            }
        }
    };

    const handleChange = (event) => {
        setValue(event.target.value);
        setError(null);
    };

    const handleDelete = (item) => {
        const arr = items.filter(i => i !== item);
        setItems(arr);
        dispatch(updateSubscribeEmails([...arr]));
    };

    const handlePaste = (event) => {
        event.preventDefault();

        var paste = event.clipboardData.getData("text");
        var emails = paste.match(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);

        if (emails) {
            var toBeAdded = emails.filter(email => !isInList(email));
            setItems([...items, ...toBeAdded]);
        }
    };

    const isValid = (email) => {
        let err = null;

        if (isInList(email)) {
            err = `${email} has already been added.`;
        }
        if (!isEmail(email)) {
            err = `${email} is not a valid email address.`;
        }

        if (err) {
            setError(err);
            return false;
        }
        return true;
    }

    const isInList = (email) => {
        return items.includes(email);
    }

    const isEmail = (email) => {
        return /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email);
    }

    return (
        <div className={styles.emailChipsWrapper}>
            {items.map(item => (
                <div className={styles.tagItem} key={item}>
                    {item}
                    <button
                        type="button"
                        className={styles.deleteButton}
                        onClick={() => handleDelete(item)}
                    >
                        &times;
                    </button>
                </div>
            ))}
            <input
                type="email"
                className={error && styles.hasError}
                value={value}
                placeholder="cc: Type email addresses and press 'Enter'"
                onKeyDown={handleKeyDown}
                onChange={handleChange}
                onPaste={handlePaste}
            />

            {error && <p className={styles.error}>{error}</p>}
        </div>
    );
}
