import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Input } from '@intxlog/iuilib'

//import components
import LoginFormWrapper from 'components/LoginFormWrapper'
import ForgotPasswordSuccessMessage from 'components/ForgotPasswordSuccessMessage'

//import actions
import {
  updateEmailForgotPasswordForm,
  incrementFormIsSubmittedForgotPasswordForm,
  updateEmailIsValidForgotPasswordForm
} from 'modules/forgotPasswordForm'
import IELAPIUserService from '../../services/IELAPIUserService'

export class ForgotPasswordForm extends React.Component {
  componentDidMount() {
    this.userService = new IELAPIUserService();
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.props.incrementFormIsSubmittedForgotPasswordForm()
    
    //only submit the form if the form is valid
    if (this.props.emailIsValid) {
      this.userService.submitForgotPasswordForm(this.props.email)
    }
  }

  render(){
    const form = <>
      <label htmlFor="email">Email Address</label>
      <Input 
        type={`email`}
        idForLabel={`email`}
        required
        formSubmitted={this.props.formSubmitted}
        onChange={this.props.updateEmailForgotPasswordForm}
        isValid={this.props.updateEmailIsValidForgotPasswordForm}
      ></Input>
    </>

    let content = <LoginFormWrapper
      title={`Reset Password`}
      formElements={form}
      buttonText={`Request Reset Password Link`}
      errorMessage={this.props.errorMessage}
      onSubmit={this.handleSubmit}
    ></LoginFormWrapper>

    if (this.props.submitted) {
      content = <ForgotPasswordSuccessMessage></ForgotPasswordSuccessMessage>
    }

    return content
  }
}

const mapStateToProps = state => ({
  ...state.forgotPasswordForm
})

const mapDispatchToProps = dispatch => bindActionCreators({
  updateEmailForgotPasswordForm,
  incrementFormIsSubmittedForgotPasswordForm,
  updateEmailIsValidForgotPasswordForm
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPasswordForm)