import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

//import components
import LayoutVerticalResponsive from 'components/LayoutVerticalResponsive'

//import css
import styles from './styles.module.scss'
import CustomerAdminUsersDropdown from '../CustomerAdminUsersDropdown'

import EditUserPage from 'components/EditUserPage'

import { LoadingGif } from '@intxlog/iuilib'

const CustomerAdminConsole = React.memo(({
  title,
  children,
  addPadding,
  bgContrast,
  customButtons,
  users,
  selectedUser,
  getSelectedUser,
  saveUserProfile,
  saveUserPermissions,
  disableUser,
  enableUser,
  isLoading,
  showDisabled,
  permSwitches,
  updateSwitchArray,
  selectAll,
  clearAll,
  reset
}) => {
  var isDisabled = selectedUser ? selectedUser.deleted_at ? true : false : false

  return (
    <div className={styles.container}>
      <div className={classNames(
        styles.headingContainer,
        bgContrast === 'light' ? styles.light : null
      )}>
        <h1 className={styles.heading}>{title}</h1>
        <CustomerAdminUsersDropdown
          selectedUser={(user) => { getSelectedUser(user) }}
          users={users}
          showDisabled={showDisabled}
        ></CustomerAdminUsersDropdown>

        {/* The following DIV houses custom buttons in the page title, right aligned */}
        <div style={{ marginLeft: "auto" }}>{customButtons}</div>
      </div>
      <LayoutVerticalResponsive
        height={`auto`}
        addPadding={addPadding}
      >
        <EditUserPage
          switchArray={permSwitches}
          selectedUser={selectedUser}
          isDisabled={isDisabled}
          saveUserProfile={(user) => { saveUserProfile(user) }}
          saveUserPermissions={(user) => { saveUserPermissions(user) }}
          disableUser={(user) => { disableUser(user) }}
          enableUser={(user) => { enableUser(user) }}
          updateSwitchArray={(perm) => { updateSwitchArray(perm) }}
          selectAll={selectAll}
          clearAll={clearAll}
          reset={reset}
        ></EditUserPage>
      </LayoutVerticalResponsive>
      {isLoading &&
        <LoadingGif></LoadingGif>
      }
    </div>
  )
})

CustomerAdminConsole.propTypes = {
  title: PropTypes.string,
  addPadding: PropTypes.bool
}

CustomerAdminConsole.defaultProps = {
  bgContrast: 'light'
}

export default CustomerAdminConsole