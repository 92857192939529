import React from "react";
import PropTypes from "prop-types";
import { Button } from "@intxlog/iuilib";

//import components
import FilterInput from "components/FilterInput";

//import css
import styles from "./styles.module.scss";

export class DataViewFilters extends React.PureComponent {
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.submitPendingFilters();
  };

  handleFilterChange = (id, val) => {
    let filters = [...this.props.pendingFilters];
    const entryObj = { id, val, type: `ilike` };

    // checks if the user is searching for a po number with a split load
    if (entryObj.id === "ponumber") {
      const splitLetterIndex = filters.map((f) => f.id).indexOf("loadsplitnumber");
      if (
          (entryObj.val.charCodeAt(entryObj.val.length - 1) >= 65 &&
          entryObj.val.charCodeAt(entryObj.val.length - 1) <= 90) ||
          (entryObj.val.charCodeAt(entryObj.val.length - 1) >= 97 &&
          entryObj.val.charCodeAt(entryObj.val.length - 1) <= 122)
      ) {
        const splitLetter = entryObj.val.slice(-1);
        entryObj.val = entryObj.val.slice(0, -1);

        const offset = splitLetter.charCodeAt(0) >= 97 && splitLetter.charCodeAt(0) <= 122 ? 96 : 64;
        const splitNum = splitLetter.charCodeAt(0) - offset;
        const splitObj = {
          id: "loadsplitnumber",
          val: `${splitNum}`,
          type: `ilike`
        };

        if (splitLetterIndex >= 0) {
          //update at the index
          filters[splitLetterIndex] = splitObj;
        } else {
          //add a new entry
          filters.push(splitObj);
        }
      } else if (splitLetterIndex >= 0) {
        filters.splice(splitLetterIndex, 1);
      }
    }

    const index = filters.map((f) => f.id).indexOf(id);
    if (index >= 0) {
      //update at the index
      filters[index] = entryObj;
    } else {
      //add a new entry
      filters.push(entryObj);
    }

    this.props.setPendingFilters(filters);
  };

  handleRemoveFilter = (id) => {
    const filters = [...this.props.activeFilters];
    const newFilters = filters.filter((f) => {
      if (id === "ponumber") {
        return f.id !== id && f.id !== "loadsplitnumber";
      }
      return f.id !== id;
    });

    this.props.setActiveFilters(newFilters);
  };

  handleClearFilters = () => {
    this.props.setActiveFilters([]);
  };

  render() {
    const inputs = this.props.schema.map((x, index) => {
      let requiresFiltering = false;
      let filterType;
      //show filter logic
      if (x.hasOwnProperty(`filter`)) {
        const filterObj = x.filter;
        requiresFiltering = filterObj.show ? true : false;
        filterType = filterObj.type;
      }
      let isActive = false;
      let value;
      const pendingFilter = this.props.pendingFilters.find(
        (p) => p.id === x.val
      );
      if (pendingFilter !== undefined) {
        value = pendingFilter.val;
      }
      const filter = this.props.activeFilters.find((f) => f.id === x.val);
      if (filter !== undefined) {
        isActive = true;
        value = filter.val;
        const splitNumFilter = this.props.activeFilters.find((f) => f.id === "loadsplitnumber");
        if (x.val === "ponumber" && splitNumFilter) {
          value = filter.val + String.fromCharCode(parseInt(splitNumFilter.val) + 64);
        }
      }

      if (requiresFiltering) {
        return (
          <FilterInput
            key={index}
            text={x.text}
            type={filterType}
            active={isActive}
            value={value}
            filterID={x.val}
            onChange={this.handleFilterChange}
            onCancelClick={() => this.handleRemoveFilter(x.val)}
          ></FilterInput>
        );
      } else {
        return null;
      }
    });

    return (
      <div className={styles.container}>
        <div className={styles.headerWrapper}>
          <h2>Filters</h2>
          <Button
            text={`Clear All`}
            type={`link`}
            onClick={this.handleClearFilters}
          ></Button>
        </div>
        <form onSubmit={this.handleSubmit}>
          <Button
            className={styles.button}
            fullWidth
            submitsForm
            size={`small`}
            text={`Apply`}
          ></Button>
          {inputs}
        </form>
      </div>
    );
  }
}

DataViewFilters.propTypes = {
  schema: PropTypes.array,
  activeFilters: PropTypes.array,
  pendingFilters: PropTypes.array,
  setAcitveFilters: PropTypes.func,
  setPendingFilters: PropTypes.func,
  submitPendingFilters: PropTypes.func,
};

export default DataViewFilters;
