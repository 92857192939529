import { createAction } from 'redux-actions'

// Actions
export const LOG_IN = 'customerPortal/auth/LOG_IN'
export const LOG_OUT = 'customerPortal/auth/LOG_OUT'
export const SET_ACCESS_TOKEN = 'customerPortal/auth/SET_ACCESS_TOKEN'
export const SET_REFRESH_TOKEN = 'customerPortal/auth/SET_REFRESH_TOKEN'
export const SET_V2_ACCESS_TOKEN = 'customerPortal/auth/SET_V2_ACCESS_TOKEN'
export const SET_V2_REFRESH_TOKEN = 'customerPortal/auth/SET_V2_REFRESH_TOKEN'
export const RESET = 'customerPortal/auth/RESET'

const initialState = {
  loggedIn: false,
  accessToken: null,
  refreshToken: null
}

// Reducer
export default function auth (state = initialState, action) {
  switch (action.type) {
    case LOG_IN:
      return {
        ...state,
        loggedIn: true
      }
    case LOG_OUT:
      return {
        ...state,
        loggedIn: false
      }
    case SET_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: action.payload
      }
    case SET_REFRESH_TOKEN:
      return {
        ...state,
        refreshToken: action.payload
      }
      case RESET:
      return initialState
    default:
      return state
  }
}

// Action Creators
export const userLoggedInAuth = createAction(LOG_IN)
export const userLoggedOutAuth = createAction(LOG_OUT)
export const setAccessTokenAuth = createAction(SET_ACCESS_TOKEN)
export const setRefreshTokenAuth = createAction(SET_REFRESH_TOKEN)
export const resetAuth = createAction(RESET)