import { createAction } from 'redux-actions'


// Actions
export const UPDATE_LOADS = 'customerPortal/loadsDelivered/UPDATE_LOADS'
export const UPDATE_TOTAL = 'customerPortal/loadsDelivered/UPDATE_TOTAL'
export const UPDATE_MODIFIED_LOADS = 'customerPortal/loadsDelivered/UPDATE_MODIFIED_LOADS'
export const UPDATE_SORT_FIELD = 'customerPortal/loadsDelivered/UPDATE_SORT_FIELD'
export const TOGGLE_SORT_DIRECTION = 'customerPortal/loadsDelivered/TOGGLE_SORT_DIRECTION'
export const SET_SORT_DIRECTION = 'customerPortal/loadsDelivered/SET_SORT_DIRECTION'
export const RESET = 'customerPortal/loadsDelivered/RESET'

const initialState = {
  title: `Delivered`,
  subTitle: `Last 72 Hours`,
  loads: [],
  total: 0,
  modifiedLoads: [],
  sortField: `pickupDate`,
  direction: `desc`
}

// Reducer
export default function loadsDelivered (state = initialState, action) {
  switch (action.type) {
    case UPDATE_LOADS:
      return {
        ...state,
        loads: action.payload
      }
    case UPDATE_TOTAL:
      return {
        ...state,
        total: action.payload
      }
    case UPDATE_MODIFIED_LOADS:
      return {
        ...state,
        modifiedLoads: action.payload
      }
    case UPDATE_SORT_FIELD:
      return {
        ...state,
        sortField: action.payload,
        direction: `desc`
      }
    case TOGGLE_SORT_DIRECTION:
      return {
        ...state,
        direction: state.direction === `asc` ? `desc` : `asc`
      }
    case SET_SORT_DIRECTION:
      return {
        ...state,
        direction: action.payload
      }
    case RESET:
      return initialState
    default:
      return state
  }
}

// Action Creators
export const updateLoadsDelivered = createAction(UPDATE_LOADS)
export const updateTotalLoadsDelivered = createAction(UPDATE_TOTAL)
export const updateModifiedLoadsDelivered = createAction(UPDATE_MODIFIED_LOADS)
export const updateSortFieldLoadsDelivered = createAction(UPDATE_SORT_FIELD)
export const toggleSortDirectionLoadsDelivered = createAction(TOGGLE_SORT_DIRECTION)
export const setSortDirectionLoadsDelivered = createAction(SET_SORT_DIRECTION)
export const resetLoadsDelivered = createAction(RESET)