import React from 'react'
import PropTypes from 'prop-types'

export class MapRoute extends React.Component {
  constructor(props) {
    super(props)
    this.state={
      pathLineArray: []
    }
  }

  componentDidMount = () => {
    this.plotPoints()
  }
  componentDidUpdate(prevProps) {
    if (prevProps.directions !== this.props.directions) {
      this.plotPoints()
    }
  }
  
  plotPoints = () => {
    const directionsService = new this.props.google.maps.DirectionsService()
    const directionsDisplay = new this.props.google.maps.DirectionsRenderer({suppressMarkers: true})

    directionsDisplay.setMap(this.props.map)

    const directions = this.props.directions
    let origin = ``
    let waypoints = []
    let destination = ``

    for (let i = 0; i < directions.length; i++) {
      const point = directions[i]
      const pointObj = new this.props.google.maps.LatLng(parseFloat(point.lat), parseFloat(point.lng))

      if (i < 1){
        origin = pointObj
      } else if(i === directions.length - 1) {//make the last stop the destination
        destination = pointObj
      } else {//this is a waypoint
        waypoints.push({
          location: pointObj,
          stopover:true,
        })
      }
    }

    if(this.props.directions !== [] && origin !== `` && destination !== ``){
      directionsService.route({
        origin,
        waypoints,
        destination,
        travelMode: `DRIVING`,
      }, (result, status) => {
        if (status === `OK`) {
          directionsDisplay.setDirections(result)
        }
      })
    }
  }

  render(){
    return null
  }
}

// rules for props being passed in
MapRoute.propTypes = {
  directions: PropTypes.array.isRequired
}

export default MapRoute