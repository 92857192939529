import IELAPIServiceFactory from "./IELAPIServiceFactory";
import store from "store";

// import modules
import { updateLoadLocation } from "modules/loadLocation";
import { getTrailerTypes } from "modules/loads";
import { updateSiteStatusBanner } from "modules/siteStatusBanner";

export default class IELAPIUtilityService {
  constructor(props) {
    this.service = IELAPIServiceFactory.new();
  }

  getMapLocation(id) {
    return this.service
      ._withToken()
      .get(`nexus/loads/${id}/location`, {})
      .then((result) => {
        if (
          result.data !== null &&
          result.data !== `` &&
          result.data.length !== 0
        ) {
          const resObj = result.data;
          const locationObj = {
            latestCheckCallLong: resObj?.longitude ?? null,
            latestCheckCallLat: resObj?.latitude ?? null,
          };
          store.dispatch(updateLoadLocation(locationObj));
        }
      })
      .catch((error) => {
        this.service.handleError(error, false);
      });
  }

  getTrailerTypes() {
    return this.service
      ._withToken()
      .get(`nexus/trailertypes`, {})
      .then((result) => {
        const trailerTypes = result.data;
        store.dispatch(getTrailerTypes(trailerTypes));
      })
      .catch((error) => {
        this.service.handleError(error, false);
      });
  }

  getSiteStatusBanner() {
    return this.service
      ._withToken()
      .get(`nexus/status`, {})
      .then((result) => {
        store.dispatch(updateSiteStatusBanner(result));
      })
      .catch((error) => {
        this.service.handleError(error, false);
      });
  }

  submitFeedback(feedback) {
    feedback.project_code = "cupo";

    return this.service
      ._withToken()
      .post(`feedback/submit`, feedback)
      .then((result) => {})
      .catch((error) => {
        this.service.handleError(error, false);
      });
  }
}
