import React from 'react'
import PropTypes from 'prop-types'

//import css
import styles from './styles.module.scss'

const LoadPicks = ({
  data
}) => {
  let picks = `No information provided.`
  if (data.length > 0) {
    picks = data.map(function(p){
      let indexOfPick = data.findIndex(i => i.id === p.id);
      return <div key={p.id} className={styles.row}> 
        <div className={styles.identifier}>{p.identifier}</div>
        <div><strong>Pick {indexOfPick + 1} of {data.length}:</strong> {p.pick}<br />
          <strong>City:</strong> {p.city}<br />
          <strong>State:</strong> {p.state}<br />
          <strong>Zip:</strong> {p.zip}</div>
        <div><strong>Pickup Number:</strong> {p.number}<br />
          <strong>Pickup Date:</strong> {p.date}<br />
          <strong>Pickup Time:</strong> {p.time}</div>
          <div><strong>Commodity:</strong> {p.commodity}<br />
        <strong>Loaded:</strong> {p.loaded}</div>
      </div>
    })
  }

  return (
    <div className={styles.container}>
      {picks}
    </div>
  )
}

//rules for props being passed in
LoadPicks.propTypes = {
  data: PropTypes.array.isRequired
}

export default LoadPicks