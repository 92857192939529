import { createAction } from 'redux-actions'

// Actions
export const SET_POS = 'customerPortal/purchaseOrders/SET_POS'
export const UPDATE_POS = 'customerPortal/purchaseOrders/UPDATE_POS'
export const SET_PAGES = 'customerPortal/purchaseOrders/SET_PAGES'
export const SET_CURRENT_PAGE = 'customerPortal/purchaseOrders/SET_CURRENT_PAGE'
export const SET_CURRENT_TYPE = 'customerPortal/purchaseOrders/SET_CURRENT_TYPE'
export const UPDATE_SORT_FIELD = 'customerPortal/purchaseOrders/UPDATE_SORT_FIELD'
export const TOGGLE_SORT_DIRECTION = 'customerPortal/purchaseOrders/TOGGLE_SORT_DIRECTION'
export const SET_SORT_DIRECTION = 'customerPortal/purchaseOrders/SET_SORT_DIRECTION'
export const UPDATE_PARAMS = 'customerPortal/purchaseOrders/UPDATE_PARAMS'
export const UPDATE_SORT_PARAMS = 'customerPortal/purchaseOrders/UPDATE_SORT_PARAMS'
export const UPDATE_FILTER_PARAMS = 'customerPortal/purchaseOrders/UPDATE_FILTER_PARAMS'
export const SET_ACTIVE_FILTERS = 'customerPortal/purchaseOrders/SET_ACTIVE_FILTERS'
export const UPDATE_ACTIVE_FILTERS = 'customerPortal/purchaseOrders/UPDATE_ACTIVE_FILTERS'
export const SET_PENDING_FILTERS = 'customerPortal/purchaseOrders/SET_PENDING_FILTERS'
export const SUBMIT_PENDING_FILTERS = 'customerPortal/purchaseOrders/SUBMIT_PENDING_FILTERS'
export const SET_LOADING = 'customerPortal/purchaseOrders/SET_LOADING'
export const UNSET_LOADING = 'customerPortal/purchaseOrders/UNSET_LOADING'
export const RESET = 'customerPortal/purchaseOrders/RESET'

const initialState = {
  data: [],
  pages: 0,
  currentPage: 0,
  currentType: ``,
  schemaBilled: [
    {text: `PO/Order`, val: `customerpo`, appValue: `customerPO`, sort: true, filter: {
      show: true
    }},
    {text: `IEL PO`, val: `ponumber`, appValue: `poNumber`, sort: true, filter: {
      show: true
    }},
    {text: `Amount`, val: `amount`, appValue: `amount`, sort: true},
    {text: `Delivery Date`, val: `deliverydate`, appValue: `deliveryDate`, sort: true},
    {text: `Debits`, val: `debits`, appValue: `debits`, sort: true},
    {text: `Credits Applied`, val: `appliedcredits`, appValue: `credits`, sort: true},
    {text: `Amount Paid`, val: `paidamount`, appValue: `amountPaid`, sort: true},
    {text: `Balance`, val: `balance`, appValue: `balance`, sort: true},    
    {text: `Date Billed`, val: `invoicedate`, appValue: `dateBilled`, sort: true},
    {text: `Age`, val: `age`, appValue: `age`, sort: true, custom: true},
    {text: `View Details`, appValue: `button`, custom: true}
  ],
  schemaUnbilled: [
    {text: `PO/Order`, val: `customerpo`, appValue: `customerPO`, sort: true,  filter: {
      show: true
    }},
    {text: `IEL PO`, val: `ponumber`, appValue: `poNumber`, sort: true,  filter: {
      show: true
    }},
    {text: `Amount`, val: `amount`, appValue: `amount`, sort: true},
    {text: `Delivery Date`, val: `deliverydate`, appValue: `deliveryDate`, sort: true},
    {text: `Contact`, val: `broker`, appValue: `contact`, sort: true},
    {text: `View Details`, appValue: `button`, custom: true} 
  ],
  sortField: ``,
  activeFilters: [],
  pendingFilters: [],
  direction: ``,
  params: ``,
  sortParams: ``,
  filterParams: ``,
  loading: true
}

// Reducer
export default function purchaseOrders (state = initialState, action) {
  switch (action.type) {
    case SET_POS:
      return {
        ...state,
        data: action.payload
      }
    case UPDATE_POS:
      return {
        ...state,
        data: [
          ...state.data,
          ...action.payload
        ]
      }
    case SET_PAGES:
      return {
        ...state,
        pages: action.payload
      }
    case SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload
      }
    case SET_CURRENT_TYPE:
      return {
        ...state,
        currentType: action.payload
      }
    case UPDATE_SORT_FIELD:
      return {
        ...state,
        sortField: action.payload,
        direction: `asc`
      }
    case TOGGLE_SORT_DIRECTION:
      return {
        ...state,
        direction: state.direction === `asc` ? `desc` : `asc`
      }
    case SET_SORT_DIRECTION:
      return {
        ...state,
        direction: action.payload
      }
    case UPDATE_PARAMS:
      return {
        ...state,
        params: action.payload 
      }
    case UPDATE_SORT_PARAMS:
      return {
        ...state,
        sortParams: action.payload 
      }
    case UPDATE_FILTER_PARAMS:
      return {
        ...state,
        filterParams: action.payload 
      }
    case SET_ACTIVE_FILTERS:
      return {
        ...state,
        activeFilters: action.payload
      }
    case UPDATE_ACTIVE_FILTERS:
      return {
        ...state,
        activeFilters: [
          ...state.activeFilters,
          ...action.payload
        ]
      }
    case SET_PENDING_FILTERS:
      return {
        ...state,
        pendingFilters: action.payload
      }
    case SUBMIT_PENDING_FILTERS:
      return {
        ...state,
        activeFilters: [
          ...state.activeFilters,
          ...state.pendingFilters
        ],
        pendingFilters: []
      }
    case SET_LOADING:
      return {
        ...state,
        loading: true
      }
    case UNSET_LOADING:
      return {
        ...state,
        loading: false
      }
    case RESET:
      return initialState
    default:
      return state
  }
}

// Action Creators
export const setPurchaseOrders = createAction(SET_POS)
export const updatePurchaseOrders = createAction(UPDATE_POS)
export const setPagesPurchaseOrders = createAction(SET_PAGES)
export const setCurrentPagePurchaseOrders = createAction(SET_CURRENT_PAGE)
export const setCurrentTypePurchaseOrders = createAction(SET_CURRENT_TYPE)
export const updateSortFieldPurchaseOrders = createAction(UPDATE_SORT_FIELD)
export const toggleSortDirectionPurchaseOrders = createAction(TOGGLE_SORT_DIRECTION)
export const setSortDirectionPurchaseOrders = createAction(SET_SORT_DIRECTION)
export const updateParamsPurchaseOrders = createAction(UPDATE_PARAMS)
export const updateSortParamsPurchaseOrders = createAction(UPDATE_SORT_PARAMS)
export const updateFilterParamsPurchaseOrders = createAction(UPDATE_FILTER_PARAMS)
export const setActiveFiltersPurchaseOrders = createAction(SET_ACTIVE_FILTERS)
export const updateActiveFiltersPurchaseOrders = createAction(UPDATE_ACTIVE_FILTERS)
export const setPendingFiltersPurchaseOrders = createAction(SET_PENDING_FILTERS)
export const submitPendingFiltersPurchaseOrders = createAction(SUBMIT_PENDING_FILTERS)
export const setLoadingPurchaseOrders = createAction(SET_LOADING)
export const unsetLoadingPurchaseOrders = createAction(UNSET_LOADING)
export const resetPurchaseOrders = createAction(RESET)