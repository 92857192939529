import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

// import components
import Logo from 'components/Logo'

//import container
import SiteMenuContainer from 'containers/SiteMenuContainer'
import SiteStatusBannerContainer from 'containers/SiteStatusBannerContainer'

//import styles
import styles from './styles.module.scss'

const Header = ({
  height
}) => {
  //inline styles
  let inlineStyles = {
    height
  }

  return (
    <div>
      <div className={styles.container}>
        <div className={styles.innerWrapper} style={inlineStyles}>
          <Link to={`/`}>
            <Logo></Logo>
          </Link>
          <SiteMenuContainer></SiteMenuContainer>
        </div>
      </div>
      {/* The status banner container is outside of all other divs due to z-index issues */}
      <SiteStatusBannerContainer></SiteStatusBannerContainer>
    </div>
  )
}

//rules for props being passed in
Header.propTypes = {
  height: PropTypes.string
}

// Specifies the default values for props:
Header.defaultProps = {
  height: 'auto'
}

export default Header