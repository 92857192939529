import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Button } from '@intxlog/iuilib'

//import css
import styles from './styles.module.scss'

const SortButton = React.memo(({
  text,
  active,
  onClick
}) => {
  return (
    <Button
      text={text}
      size={`small`}
      type={`plain`}
      className={
        classNames({
          [styles.button]: true,
          [styles.activeUp]: active === `asc`,
          [styles.activeDown]: active === `desc`
        })
      }
      onClick={onClick}
    ></Button>
  )
})

SortButton.propTypes = {
  text: PropTypes.string,
  active: PropTypes.oneOf([false, `asc`, `desc`]),
  onClick: PropTypes.func
}

export default SortButton