import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Redirect } from 'react-router-dom'

//import utils
import logoutUser from 'utils/logoutUser'

export class LogoutContainer extends React.Component {
  componentDidMount = () => {
    this.props.logoutUser()
  }
  
  render(){
    return (
      <Redirect to={`/`}></Redirect>
    )
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  logoutUser
}, dispatch)

export default connect(
  null,
  mapDispatchToProps
)(LogoutContainer)