import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Route, Switch } from 'react-router-dom'
import { withRouter } from 'react-router'
import { Modal } from '@intxlog/iuilib'

//import containers
import LoginContainer from 'containers/LoginContainer'
import LoadSummaryContainer from 'containers/LoadSummaryContainer'
import LoadSearchContainer from 'containers/LoadSearchContainer'
import LoadContainer from 'containers/LoadContainer'
import CustomerAdminConsoleContainer from 'containers/CustomerAdminConsoleContainer'
import LogoutContainer from 'containers/LogoutContainer'
import ARcontainer from 'containers/ARcontainer'
import PayNowContainer from 'containers/PayNowContainer'
import PayHistoryContainer from '../PayHistoryContainer'
import BuildLoadContainer from 'containers/BuildLoadContainer'
import BuildLoadFormContainer from 'containers/BuildLoadFormContainer'
import LoadRequestHistoryContainer from 'containers/LoadRequestHistoryContainer'

//import components
import BasicPageWithHeader from 'components/BasicPageWithHeader'
import ViewportTracker from 'components/ViewportTracker'
import Footer from 'components/Footer'
import ReleaseModal from 'components/ReleaseModal'
import FooterLinks from 'components/FooterLinks'

//import assets
import bgJPG from 'assets/images/nexusBackground.jpg'

import IELAPICustomerService from '../../services/IELAPICustomerService'

//import actions
import {
	userLoggedInAuth,
	setAccessTokenAuth,
	setRefreshTokenAuth
} from 'modules/auth'
import { toggleOpenModal, resetModal } from 'modules/modal'
import { unsetMenuIsOpenApp, setPageTitle } from 'modules/app'
import {forceCloseModal} from 'modules/receiveUpdates'

//import helpers
import getPageNameFromPath from 'helpers/getPageNameFromPath'

//import utils
import resetState from 'utils/resetState'
import PrivacyPolicy from '../../components/PrivacyPolicy'
import Notes from "../../components/Notes";
import IELAPIUtilityService from '../../services/IELAPIUtilityService'
import IELAPIUserService from '../../services/IELAPIUserService'

//Feedback stuff
import FeedbackModal from '../../components/FeedbackModal'
import FeedbackBanner from '../../components/FeedbackBanner'

let showChangelogModal = false;
export class App extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			changelog: null,
			feedbackModal: false
		}
		this.customerService = new IELAPICustomerService();
		this.utilService = new IELAPIUtilityService();
		this.userService = new IELAPIUserService();
	  }

	componentDidMount = () => {
		//check to see if the user is logged in this session
		if (sessionStorage.getItem(`loggedIn`) === `true`) {
			this.props.setAccessTokenAuth(sessionStorage.getItem(`accessToken`))
			this.props.setRefreshTokenAuth(sessionStorage.getItem(`refreshToken`))
			this.props.userLoggedInAuth()
		}
		this.props.setPageTitle(getPageNameFromPath(this.props.location.pathname))

		if (sessionStorage.getItem(`hideStatusBanner`) !== `true`) {
			this.utilService.getSiteStatusBanner();
		}
	}

	componentDidUpdate(prevProps) {
		const prevPath = prevProps.location.pathname
		const currentPath = this.props.location.pathname

		//the page changed
		if (prevPath !== currentPath) {
			this.props.setPageTitle(getPageNameFromPath(currentPath))
			this.props.unsetMenuIsOpenApp()
			this.props.forceCloseModal()
		}

		if (
			this.props.userIsLoggedIn !== prevProps.userIsLoggedIn &&
			this.props.userIsLoggedIn
		) {

			this.userService.userInfo();
			this.userService.getMultiCustomers();

			if (this.props.multiCustomerSelectedId) {
				this.customerService.getCustomerInfo(null, this.props.multiCustomerSelectedId);
			}
		}

		if (this.props.latestChangelog !== null && showChangelogModal === false) {
			if(this.props.latestChangelog.length > 0) {
				this.setState({changelog: this.props.latestChangelog})
				showChangelogModal = true;
			}
		}

		//the user logs out
		if (
			this.props.userIsLoggedIn !== prevProps.userIsLoggedIn &&
			!this.props.userIsLoggedIn
		) {
			this.props.resetState()
		}
	}

	componentDidCatch(error, errorInfo) {
		
	}

	handleCloseModal = () => {
		this.props.toggleOpenModal()
		this.props.resetModal()
	}

	hideModal = () => {
		this.setState({changelog: null})
		console.log(0);
	}

	submitFeedback = async (feedback) => {
		await this.utilService.submitFeedback(feedback);
	}

  render() {
    let content = null
    let bg = bgJPG
    let curPath = this.props.location.pathname

    if (this.props.userIsLoggedIn) {
      if (curPath === "/"
          || curPath === "/loads/today"
          || curPath === "/loads/delivered"
          || curPath === "/loads/intransit"
					|| curPath === "/loads/future"
					|| curPath === "/history"
					|| curPath === "/loadhistory"
      ) {
        bg = null
      }

      content =
          <>
            <BasicPageWithHeader
                isLoading={this.props.isLoading}
                bgImage={bg}
            >
              <Switch>
                <Route path='/load/:id' component={LoadContainer}/>
                <Route path='/search' component={LoadSearchContainer}/>
                <Route path='/ar' component={ARcontainer}/>
				<Route path='/admin' component={CustomerAdminConsoleContainer}/>
                <Route path='/logout' component={LogoutContainer}/>
                <Route path='/paynow' component={PayNowContainer}/>
                <Route path='/privacy' component={PrivacyPolicy}/>
                <Route path='/release' component={Notes}/>
				<Route path='/buildLoad' component={BuildLoadContainer}/>
				<Route path='/buildLoadform' component={BuildLoadFormContainer}/>
				<Route path='/history' component={PayHistoryContainer}/>
				<Route path='/loadhistory' forceRefresh={true} component={LoadRequestHistoryContainer}/>
                <Route path='/' component={LoadSummaryContainer}/>
              </Switch>
            </BasicPageWithHeader>
            <footer>
              <Footer></Footer>
              <FooterLinks userIsLoggedIn={this.props.userIsLoggedIn}/>
            </footer>
			{this.state.changelog !== null && this.state.changelog !== [] ? <ReleaseModal changelog={this.props.latestChangelog} hideModal={this.hideModal}/> : null}
          </>
    } else {
      content =
          <>
            <Switch>
              <Route path='/privacy' component={PrivacyPolicy}/>
              <Route path='/' component={LoginContainer}/>
            </Switch>
            <footer>
				<FooterLinks userIsLoggedIn={this.props.userIsLoggedIn}/>
            </footer>
          </>
    }

    return (
        <>
			{/* Feedback stuff goes above all else */}
			{this.props.userIsLoggedIn && <FeedbackBanner
				openFeedbackModal={() => {this.setState({feedbackModal: true})}}
			></FeedbackBanner>}
			{this.state.feedbackModal && <FeedbackModal
				closeModal={() => {this.setState({feedbackModal: false})}}
				submitFeedback={(feedback) => {this.submitFeedback(feedback)}}
			></FeedbackModal>}

          {content}
          <Modal
              {...this.props.modal}
              closeFunc={this.handleCloseModal}
          ></Modal>
          <ViewportTracker></ViewportTracker>
        </>
    )
  }
}

const mapStateToProps = state => ({
	userIsLoggedIn: state.auth.loggedIn,
	modal: state.modal,
	isLoading: state.app.isLoading,
	userInfo: state.userInfo,
	latestChangelog: state.latestChangelog,
	customerInfo: state.customerInfo,
	multiCustomerSelectedId: state.multiCustomer.selectedID
})

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			userLoggedInAuth,
			setAccessTokenAuth,
			setRefreshTokenAuth,
			toggleOpenModal,
			resetModal,
			unsetMenuIsOpenApp,
			setPageTitle,
			resetState,
			forceCloseModal,
		},
		dispatch
	)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
