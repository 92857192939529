import React from "react";
import styles from "./styles.module.scss"
import NotesDetailsItems from "../NotesDetailsItems";
import {notes} from "../../data/notes"

class NotesDetails extends React.Component {
    render() {
        return (
            <div className={styles.container}>
                {notes.map((item, i) =>
                    <NotesDetailsItems key={i} name={item.id} id={item.id} title={item.title} date={item.date}
                                       summary={item.summary} intro1={item.intro1}
                                       img1={item.img1} list1={item.list1}
                                       intro2={item.intro2} img2={item.img2}
                                       list2={item.list2} intro3={item.intro3}
                                       img3={item.img3} list3={item.list3}
                                       intro4={item.intro4} img4={item.img4}
                                       list4={item.list4}
                                       onScroll={this.props.onScroll}/>)
                }
            </div>
        )
    }
}

export default NotesDetails;
