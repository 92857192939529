import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
//import css
import styles from './styles.module.scss'

const PrivacyPolicyLink = ({
  onClick
}) => {
  return (
    <NavLink
      className={styles.link}
      to="/privacy"
    >Privacy Policy</NavLink>
  )
}

PrivacyPolicyLink.propTypes = {
  onClick: PropTypes.func
}

export default PrivacyPolicyLink