import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

//import components
import SiteMenu from 'components/SiteMenu'

//import actions
import {
  toggleMenuIsOpenApp
} from 'modules/app'

export class SiteMenuContainer extends React.Component {
  render(){
    return (
      <SiteMenu
        toggleMenu={this.props.toggleMenuIsOpenApp}
        isMenuOpen={this.props.menuIsOpen}
        screenWidth={this.props.screenWidth}
      ></SiteMenu>
    )
  }
}

const mapStateToProps = state => ({
  menuIsOpen: state.app.menuIsOpen,
  screenWidth: state.viewport.width
})

const mapDispatchToProps = dispatch => bindActionCreators({
  toggleMenuIsOpenApp,
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SiteMenuContainer)