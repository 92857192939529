import React from "react";
import styles from "./styles.module.scss"

class NotesDetailsItemsLists extends React.Component {
    render() {
        return(
            <div className={styles.container}>
                <li><span>{`${this.props.listKey}`}</span>{this.props.value}</li>
            </div>

        );
    }
}

export default NotesDetailsItemsLists;
