export default function getColorFromAge (value) {
  let color = `transparent`

  if (value <= 30) {
    color = `rgb(16, 150, 24)`
  } else if (value > 30 && value <= 60) {
    color = `rgb(51, 102, 204)`
  } else if (value > 60 && value <= 90) {
    color = `rgb(255, 153, 0)`
  } else if (value > 90) {
    color = `rgb(220, 57, 18)`
  }

  return color
}