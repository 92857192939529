import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
// import whyDidYouUpdate from 'why-did-you-update'
import store from 'store'
import ReactGA from 'react-ga';
import 'assets/styles/base.module.scss'
import '@intxlog/iuilib/dist/main.css'

//import container
import App from 'containers/App'
import ScrollToTop from 'components/ScrollToTop'

//google analytics
if (process.env.REACT_APP_ENV === `prod`) {
  ReactGA.initialize(process.env.REACT_APP_GA_CODE)
  ReactGA.pageview(window.location.pathname + window.location.search)
}
//whydidyouupdate
// whyDidYouUpdate(React)

const renders = () => {
  return render( 
    <Provider store={store}>
      <Router>
        <ScrollToTop>
          <App /> 
        </ScrollToTop>
      </Router>
    </Provider>,
    document.getElementById('root')
  )
}

renders(App)

if (module.hot) {
  module.hot.accept('containers/App', () => {
    const NextApp = require('containers/App').default
    renders(NextApp)
  })
}