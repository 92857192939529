import React from "react";
import PropTypes from "prop-types";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import truckIcon from "assets/images/mapIcons/in-transit.png";
import destinationFlag from "assets/images/mapIcons/flag_icon_BLACK.png";
import macroPointIcon from "assets/images/mapIcons/mp-icon-01.png"
import MapRoute from "components/MapRoute";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import startIcon from "assets/images/mapIcons/start_icon_GREEN.png";
import travelPin from "assets/images/mapIcons/red-map-pin.png";

import { updateLoadLocation } from "modules/loadLocation";
import IELAPILoadService from "../../services/IELAPIUtilityService";

export class LoadMap extends React.Component {
  componentDidMount() {
    this.loadService = new IELAPILoadService();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loadId !== this.props.loadId && this.props.loadId !== 0) {
      this.loadService.getMapLocation(Number(this.props.loadId));
    }
  }

  render() {
    let idOfLoadsArray = [];

    this.props.loadData.picks.map((x) => {
      if (x.id) {
        idOfLoadsArray.push(x.id);
      }
      return null;
    });
    const markers = this.props.stops.map((stop) => {
      if (idOfLoadsArray.includes(stop.id)) {
        stop.pick = true;
      } else {
        stop.pick = false;
      }

      const originMarker = (
        <Marker
          key={stop.id}
          name={"Start Point"}
          position={{
            lat: this.props.stops[0].lat,
            lng: this.props.stops[0].lng,
          }}
          animation={this.props.google.maps.Animation.DROP}
          zIndex={9}
          icon={{
            url: startIcon,
            scaledSize: new this.props.google.maps.Size(25, 37),
          }}></Marker>
      );

      const waypointMarkerPick = (
        <Marker
          key={stop.id}
          name={"Pick Waypoint"}
          position={{ lat: stop.lat, lng: stop.lng }}
          animation={this.props.google.maps.Animation.DROP}
          zIndex={8}
          icon={{
            url: travelPin,
            scaledSize: new this.props.google.maps.Size(35, 35),
          }}></Marker>
      );

      const finalDestinationMarker = (
        <Marker
          key={stop.id}
          name={"Final Destination"}
          position={{
            lat: this.props.stops[this.props.stops.length - 1].lat,
            lng: this.props.stops[this.props.stops.length - 1].lng,
          }}
          zIndex={10}
          animation={this.props.google.maps.Animation.DROP}
          icon={{
            url: destinationFlag,
            anchor: new this.props.google.maps.Point(0, 42),
            scaledSize: new this.props.google.maps.Size(35, 45),
          }}></Marker>
      );

      if (this.props.stops.indexOf(stop) === 0) {
        return originMarker;
      }
      if (
        this.props.stops.indexOf(stop) < this.props.stops.length - 1 &&
        this.props.stops.indexOf(stop) > 0
      ) {
        return waypointMarkerPick;
      } else {
        return finalDestinationMarker;
      }
    });

    const stops = this.props.stops;
    let lat = Number(this.props.lat);
    let long = Number(this.props.long);
    let markerIcon = null;
    let macroPointMarker = null;


    if (lat !== null && long !== null) {
      markerIcon = (
        <Marker
          name={`Current Location`}
          icon={{
            url: truckIcon,
            anchor: new this.props.google.maps.Point(32, 32),
            scaledSize: new this.props.google.maps.Size(35, 45),
            zIndex: 101,
          }}
          position={{ lat: lat, lng: long }}></Marker>
      );
    }
    if (this.props.loadData.macroPoint) {
      macroPointMarker = (
        <Marker
          name={"Macro Point Marker"}
          position={{
            lat: this.props.loadData.macroPoint[0]?.latitude,
            lng: this.props.loadData.macroPoint[0]?.longitude,
          }}
          icon={{
            url: macroPointIcon,
            scaledSize: new this.props.google.maps.Size(40, 47),
          }}
          ></Marker>
      );
    }
 
    return (
      <Map
        google={this.props.google}
        initialCenter={{
          lat: 39.23768,
          lng: -84.387043,
        }}
        zoom={4}>
        <MapRoute directions={stops}></MapRoute>
        {markerIcon}
        {macroPointMarker}
        {markers}
      </Map>
    );
  }
}

//rules for props being passed in
LoadMap.propTypes = {
  stops: PropTypes.array,
};

const mapStateToProps = (state) => ({
  loadData: state.load,
  loadId: state.load.id,
  long: state.loadLocation.latestCheckCallLong,
  lat: state.loadLocation.latestCheckCallLat,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateLoadLocation,
    },
    dispatch
  );

export default LoadMap = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  GoogleApiWrapper({
    apiKey: `AIzaSyA1Hctx13x76MfCpb0f5UQpZy1h5HEGUKg&v=3`,
  })(LoadMap)
);