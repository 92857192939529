import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

//import css
import styles from './styles.module.scss'

const LoadSummaryLink = ({
  title,
  number,
  icon,
  bgImage,
  linkTo
}) => {
  const textClassNames = classNames(
    styles.text,
    {
      [styles.smaller]: number > 99
    }
  )

  return (
    <Link to={linkTo}>
      <div className={styles.container}>
        <div className={styles.background} style={{backgroundImage: `url(${bgImage})`}}></div>
        <div className={styles.numberIndicatorWrapper}>
          <div className={textClassNames}>{number}</div>
        </div>
        <div className={styles.bannerWrapper}>
          <div className={styles.bannerWrapperTextWrapper}>
            <div className={styles.title}>{title}</div>
            <div className={styles.subTitle}>Loads</div>
          </div>
          <div className={styles.bannerEnd}></div>
        </div>
        <div className={styles.iconWrapper}>{icon}</div>
      </div>
    </Link>
  )
}

LoadSummaryLink.propTypes = {
  title: PropTypes.string,
  number: PropTypes.number,
  icon: PropTypes.object,
  bgImage: PropTypes.string,
  linkTo: PropTypes.string.isRequired
}

export default LoadSummaryLink