import { createAction } from 'redux-actions'

// Actions
export const UPDATE_EMAILS = 'customerPortal/subscribeEmails/UPDATE_EMAILS'
export const RESET = 'customerPortal/subscribeEmails/RESET'

const initialState = {
    ccEmails: []
}

// Reducer
export default function subscribeEmails (state = initialState, action) {
    switch (action.type) {
        case UPDATE_EMAILS:
            return {
                ...initialState,
                ccEmails: action.payload
            }
        case RESET:
            return initialState
        default:
            return state
    }
}

// Action Creators
export const updateSubscribeEmails = createAction(UPDATE_EMAILS)
export const resetSubscribeEmails = createAction(RESET)
