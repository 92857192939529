import React from 'react'

import DocumentLine from 'components/DocumentLine'

import styles from './styles.module.scss'

import store from '../../store'

const LoadDocuments = ({
  viewDocsOnClick,
  documents
}) => {
  let documentList = documents.map(d =>
    <div title="You do not have permission to download documents.">
    <DocumentLine
      key={d.id}
      id={d.id}
      name={d.name}
    ></DocumentLine>
    </div>
  )

  if (store.getState().userInfo.permissions ? store.getState().userInfo.permissions.IDOCS_DOWNLOAD : false) {
    documentList = documents.map(d =>
      <DocumentLine
        key={d.id}
        id={d.id}
        name={d.name}
        onClick={() => viewDocsOnClick(d.id, d.name)}
      ></DocumentLine>
    )
  }


  let noDocs
  if (documentList.length === 0) {
    noDocs = `Empty.`
  }

  return (

    <div>
      <div className={styles.documentsWrapper}>
        {documentList}
        {noDocs}
      </div>
    </div>
  )
}

export default LoadDocuments;