import React from 'react'
import { Button } from '@intxlog/iuilib'
import styles from './styles.module.scss'
import BackButtonContainer from 'containers/BackButtonContainer'
import { Link } from 'react-router-dom'
import CompanyNameContainer from 'containers/CompanyNameContainer'

import store from '../../store'


export default function BuildLoadDisclaimer({
  userID,
  customerID
}) {
  var loadRequestGenerate = <div title="You do not have permission to generate load requests."><Button className={styles.continueDisabled} text='Continue' size='large'></Button></div>
  if (store.getState().userInfo.permissions ? store.getState().userInfo.permissions.LOAD_REQUEST_GENERATE : false) {
    loadRequestGenerate = <Button className={styles.continue} text='Continue' size='large' linkTo={`/buildLoadform`} linkComponent={Link} elementType={`routedLink`}></Button>
  }
  
  return (
    <div className={styles.disclaimerContainer}>
      <h3 className={styles.header}>Integrity Express Logistics, LLC NEXUS Request for Quote</h3>
      <h3 className={styles.header}>Disclaimer</h3>
      <p>
      This Request for Quote (“RFQ”) by the customer to Integrity Express Logistics, LLC (“IEL”) is intended as an offer of information to IEL only, and is in no way intended to be an offer of services by IEL to the customer.  IEL’s terms and conditions located at www.ielfreight.com supersede any and all customer RFQs and purchase orders whether written or verbally expressed.  Please read IEL’s terms and conditions before issuing an RFQ or purchase order with IEL.
The information provided as part of the customer’s RFQ is for informational purposes only.  This information will allow IEL to evaluate the customer’s business opportunity and subsequently provide a quote, which shall constitute an offer for IEL’s services.  IEL will only endeavor to perform such services upon acceptance of IEL’s quote from the customer.  
All information contained in this RFQ shall be kept confidential in accordance with IEL’s terms and conditions.  Failure to provide information in any field requested by IEL could result in an incomplete quote issued by IEL and IEL reserves the right to revise, amend, or reissue its quote at any time prior to undertaking its services.  

      </p>
      <div className={styles.buttonContainer}>
        {/* <Button text='Back'></Button> */}
        <BackButtonContainer></BackButtonContainer>
        <div className={styles.customerDropdown}><h3>Build Load For:</h3>
        <CompanyNameContainer /></div>
        <span>
          {loadRequestGenerate}
          </span>
      </div>

    </div>
  )
}
