import IELAPIServiceFactory from './IELAPIServiceFactory'
import store from 'store'

// import modules
import {
    resetLoginForm
} from 'modules/loginForm'
import {
    incrementFormIsSubmittedCreatePasswordForm,
    updateErrorMessageCreatePasswordForm,
    resetCreatePasswordForm
} from 'modules/createPasswordForm'
import {
    incrementFormIsSubmittedForgotPasswordForm,
    updateErrorMessageForgotPasswordForm,
    toggleSubmittedForgotPasswordForm
} from 'modules/forgotPasswordForm'

import { setLoading } from 'modules/app'

import { updateUserInfo } from 'modules/userInfo'

import { saveMultiCustomerArray } from 'modules/multiCustomers'

export default class IELAPIUserService {

    constructor(props) {
        this.service = IELAPIServiceFactory.new()
    }

    userInfo() {
        store.dispatch(setLoading(true))
        return this.service._withToken().get('nexus/user')
            .then((result) => {
                store.dispatch(updateUserInfo(result.data.data))
                store.dispatch(setLoading(false))
                return result.data.data;
            }).catch(function (error) {
                this.service.handleError(error, false)
                store.dispatch(setLoading(false))
            });
    }

    getMultiCustomers() {
        store.dispatch(setLoading(true))
        return this.service._withToken().get('nexus/user/customers/enabled')
            .then((result) => {
                let customers = result.data;
                let multiCustomerArray = [];
                for (let i = 0; i < customers.length; i++) {
                    let customerName = customers[i].externalcustomername !== null ? customers[i].externalcustomername : customers[i].customername
                    multiCustomerArray.push({ value: customers[i].id, label: customerName })
                }
                store.dispatch(saveMultiCustomerArray(multiCustomerArray))
                store.dispatch(setLoading(false))
                return multiCustomerArray;
            }).catch(function (error) {
                this.service.handleError(error, false)
                store.dispatch(setLoading(false))
            });
    }

    submitLoginForm(email, pwd) {
        // NOTE: The errors of the call below will be handled in the CUPOIELAPIService.js file under afterLoginError method
        this.service.login(email, pwd, process.env.REACT_APP_ITMS_API_CLIENT_ID, process.env.REACT_APP_ITMS_API_CLIENT_SECRET).then(() => {
            store.dispatch(resetLoginForm())
        })
    }

    submitCreatePasswordForm(pin, email, pwd, pwdConfirm, action) {
        store.dispatch(incrementFormIsSubmittedCreatePasswordForm())

        if (pwd !== pwdConfirm) {
            store.dispatch(updateErrorMessageCreatePasswordForm(`Passwords do not match!`))
            return
        }

        let urlTail

        switch (action) {
            case `create`:
                urlTail = `nexus-public/processCreatePassword`
                break;
            case `reset`:
                urlTail = `nexus-public/processResetPassword`
                break
            default:
                break
        }

        return this.service._withToken().post(urlTail, {
            'email': email,
            'code': pin,
            'password': pwd
        }).then((result) => {
            // If the reset is successful, log the user in with the email and new password that they set
            this.submitLoginForm(email, pwd)

            // Reset the create password form for later use
            store.dispatch(resetCreatePasswordForm())
        }).catch(error => {
            error.email = email
            this.service.handleError(error, false)

            const code = error.response.status
            const serverMessage = error.response.data.message
            let message

            switch (code) {
                case 403:
                    message = `This link has expired. Please contact NEXUS@ielfreight.com for assistance.`
                    break
                case 400:  // Bad Request
                    message = serverMessage
                    break
                case 401:
                    message = `Password reset link has expired, please submit for a new link to reset your password.`
                    break
                case 404:
                    message = `404 ` + serverMessage
                    break
                default: //catch any other error since it is unhandled
                    message = `A server error has occured. Please contact NEXUS@ielfreight.com for assistance.`
                    break
            }
            store.dispatch(updateErrorMessageCreatePasswordForm(message))
        })
    }

    submitForgotPasswordForm(email) {
        store.dispatch(incrementFormIsSubmittedForgotPasswordForm())

        return this.service._withToken().post(`nexus-public/resetPassword`, { email }).then(() => {
            store.dispatch(toggleSubmittedForgotPasswordForm())
        }).catch(error => {
            error.email = email
            
            this.service.handleError(error, false)

            const code = error.response.status
            let message

            switch (code) {
                case 404:
                    message = `A valid user was not found. Please contact NEXUS@ielfreight.com for assistance.`
                    break
                default: //catch any other error since it is unhandled
                    message = `A server error has occured. Please contact NEXUS@ielfreight.com for assistance.`
                    break
            }

            store.dispatch(updateErrorMessageForgotPasswordForm(message))
        })
    }
}
