import React from "react";
import NotesDetailsItemsLists from "../NotesDetailsItemsLists"
import styles from "./styles.module.scss"
import ReactHtmlParser from 'react-html-parser';

class NotesDetailsItems extends React.Component {
    constructor(props) {
        super(props);
        this.details = React.createRef();
    }
    componentDidMount() {
        this.props.onScroll(this.details.current);
    }

    render() {
        let Scroll = require('react-scroll');
        let Element = Scroll.Element;
        let blank = this.props.summary ? <br/> : ``;
        return(
            <Element name={this.props.id}>
                <section id={this.props.id} ref={this.details} className={styles.container}>
                    <h2>{this.props.title}</h2>
                    <i>{this.props.date}</i>
                    <div>{ReactHtmlParser(this.props.summary)}</div>
                    {blank}
                    <div>{this.props.intro1}</div>
                    <div>
                        {
                            this.props.list1.map((item,i) =>
                                <NotesDetailsItemsLists key = {i} listKey={item.listKey} value={item.value} />)
                        }
                    </div>
                    {
                        this.props.img1.length === 0 ? "" : <img src={this.props.img1} alt="" />
                    }
                    <div>{this.props.intro2}</div>
                    <div>
                        {
                            this.props.list2.map((item,i) =>
                                <NotesDetailsItemsLists key = {i} listKey={item.listKey} value={item.value} />)
                        }
                    </div>
                    {
                        this.props.img2.length === 0 ? "" : <img src={this.props.img2} alt=""/>
                    }
                    <div>{this.props.intro3}</div>
                    <div>
                        {
                            this.props.list3.map((item,i) =>
                                <NotesDetailsItemsLists key = {i} listKey={item.listKey} value={item.value} />)
                        }
                    </div>
                    {
                        this.props.img3.length === 0 ? "" : <img src={this.props.img3} alt=""/>
                    }
                    <div>{this.props.intro4}</div>
                    <div>
                        {
                            this.props.list4.map((item,i) =>
                                <NotesDetailsItemsLists key = {i} listKey={item.listKey} value={item.value} />)
                        }
                    </div>
                    {
                        this.props.img4.length === 0 ? "" : <img src={this.props.img4} alt=""/>
                    }

                </section>
            </Element>
        );
    }
}

export default NotesDetailsItems;
