import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@intxlog/iuilib'

//import css
import styles from './styles.module.scss'

const ForgotPasswordLink = ({
  onClick
}) => {
  return (
    <Button 
      type={`link`}
      text={`Forgot Password?`}
      className={styles.link} 
      onClick={onClick}
    ></Button> 
  )
}

ForgotPasswordLink.propTypes = {
  onClick: PropTypes.func
}

export default ForgotPasswordLink


