import React, { useState } from "react";
import styles from './styles.module.scss'


function LoadDetailsMacroPoint(
  data
  ) {
    const [isToggled, setToggled] = useState(false)
    const toggleTrueFalse = () => setToggled(!isToggled)

  let macroPointDisplay
  if (data.data) {
  const macroPointData = data.data.map((x, index) => {
    const dateTime = x.approxlocationdatetime.split(' ')
    const timeFormatted = dateTime[1].split('-')[0]
      return(
        <div className={styles.row}>
          <div>
            <strong>Date:</strong> {dateTime[0]} <br/>
            <strong>Time:</strong> {timeFormatted} <br/>
            <strong>Type: </strong> {x.remarks} 
          </div>
          <div>
            <strong>City:</strong> {x.city} <br/>
            <strong>State:</strong> {x.state} <br/>
            <strong>Zip:</strong> {x.postal} 
          </div>
          <div>
            <strong>Country: </strong> {x.country} <br/>
            <strong> Street: </strong> {x.street1 || 'None Provided'}
          </div>
        </div>
      )
    })
    macroPointDisplay = macroPointData
  }

    let showMoreMacroPoint, display, showSeeMoreOption
    const dataIsLoaded = (data.data && data.data.length > 0)
    if (dataIsLoaded) {
      if (isToggled) {
        //display all macropoint data 
        showMoreMacroPoint = macroPointDisplay
        display = 'Show less...'
      } else {
        //display 1 single latest macropoint update
        showMoreMacroPoint = macroPointDisplay[0]
        display = 'Show more...'
      }
      if (macroPointDisplay.length > 1) {
        showSeeMoreOption = <button className={styles.seeMoreButton} onClick={toggleTrueFalse}> {display} </button>
      }
    }

  return (
    <div>
      <div className={styles.container}>
        {showMoreMacroPoint}
      </div>
      {showSeeMoreOption}
    </div>
  )
}

export default LoadDetailsMacroPoint
