import logoutUser from 'utils/logoutUser'

//import actions
import { unsetIsLoadingApp } from 'modules/app'
import { setTypeModal, toggleOpenModal, setTextModal } from 'modules/modal'

export default function handleError (error, showModal = true, customMessage) {
	return dispatch => {
		let userErrorMessage = ''; // Error message that is only displayed to the user

		// If there is a custom message set, use it
		if (customMessage) {
			userErrorMessage = customMessage;
		} else {
			// No custom message so lets get from the response
			if (error && error.response && error.response.data && error.response.data.message) {
				userErrorMessage = error.response.data.message;
			} else if (error && error.message) {
				userErrorMessage = error.message;
			} else {
				// Fail safe just to make sure we have some sort of error to show the user.
				userErrorMessage = 'An unknown error has occured. Please reload and try again. If problem persists, please contact NEXUS@ielfreight.com.';
			}
		}

		// Check if error code is 403, if so kick the user out
		let logout = false
		const code = error.code

		if (code === 403) {
			userErrorMessage = 'You are not permitted to be here. If this is by error, please contact us at nexus@ielfreight.com.'
			logout = true
		}

		if (showModal) {
			if (logout === true) {
				window.location.replace('/')
				dispatch(logoutUser())
			}
			dispatch(setTypeModal(`error`))
			dispatch(setTextModal(`${userErrorMessage}`))
			dispatch(toggleOpenModal())
			dispatch(unsetIsLoadingApp())
		}
	}
}
