import React, { useState, useEffect } from 'react'
import { useSelector } from "react-redux";
import styles from './styles.module.scss'
import moment from 'moment'
import IELAPIPaymentService from '../../services/IELAPIPaymentService'

const FormAchOrbipay = ({
    removeAchModal,
    updatePO,
    customer,
    defaultAmount,
    setTypeModal,
    toggleOpenModal,
    setTextModal
}) => {
    const [showErrorText, setShowErrorText] = useState(false);
    const [showDescErrorText, setShowDescErrorText] = useState(false);
    const [amount, setAmount] = useState(parseFloat(defaultAmount.replace(",", "")));
    const [description, setDescription] = useState("");
    const userInfo = useSelector((state) => state.userInfo);
    const customerInfo = useSelector((state) => state.customerInfo);
    const outstandingInvoices = useSelector((state) => state.outstandingInvoices);

    const [submitting, setSubmitting] = useState(false);
    const asterisk = '*'

    function hideModal(event) {
        event.preventDefault()
        removeAchModal()
    }

    function removeOrbipayScript() {
        const script = document.getElementById('orbipay-checkout-script');
        if (script) {
            document.head.removeChild(script);
        }
    }

    useEffect(() => {

        const paymentService = new IELAPIPaymentService();

        function paymentModalConfirmation(type, message) {
            setTypeModal(type)
            setTextModal(message)
            toggleOpenModal()
        }

        if (submitting) {
            const poNumbers = outstandingInvoices.inputArray;

            window.addEventListener('checkout-event', function handlePayment(event) {
                window.removeEventListener('checkout-event', handlePayment);
                let orbipayToken = event.detail.token;
                let digiSign = event.detail.digiSign;
                let customerAccountReference = event.detail.customer_account_reference;

                if (orbipayToken && digiSign && customerAccountReference && amount > 0) {
                    const userID = userInfo.id;
                    const customerID = customerInfo.id;
                    const customFields = {
                        notes: description,
                        poNumbers: poNumbers
                    }

                    async function processACHPayment() {
                        let response = await paymentService.makeACHPayment(userID, customerID, orbipayToken, digiSign, amount, customerAccountReference, customFields);
                        if (response === undefined) {
                            paymentModalConfirmation(`error`, `Sorry! Payment could not be processed, please try again. If issue persists, please contact nexus@ielfreight.com for assistance.`)
                        } else if (response.status === 200) {
                            paymentModalConfirmation(`success`, `Your payment was successfully submitted.`)
                        } else if (response.status >= 400) {
                            paymentModalConfirmation(`error`, `Sorry! Payment could not be processed, please try again. If issue persists, please contact nexus@ielfreight.com for assistance.`)
                        }
                    }
                    processACHPayment();
                }
            });
            removeAchModal();
        }
    }, [amount, description, customerInfo, outstandingInvoices, removeAchModal, userInfo, submitting, setTextModal, setTypeModal, toggleOpenModal]);

    useEffect(() => {
        // defaultAmount = parseFloat(defaultAmount);
        const script = document.createElement('script');
        script.src = `${process.env.REACT_APP_ORBIPAY_URL}/app/opco/v3/scripts/checkoutofsc.js`;
        script.setAttribute('id', 'orbipay-checkout-script');
        script.setAttribute('data-client_key', `${process.env.REACT_APP_ORBIPAY_CLIENT_KEY}`);
        script.setAttribute('data-prevent_posting', true);
        document.head.appendChild(script);
        return () => {
            removeOrbipayScript();
        }
    }, []);

    useEffect(() => {
        function updateOribipayScript(amount) {
            if (amount && validateNumberField(amount)) {
                const script = document.getElementById('orbipay-checkout-script');
                script.setAttribute('data-amount', amount);

                console.log("Setting customer account reference...")
                var username = userInfo.email.substring(0, userInfo.email.indexOf("@"));
                var customerAccountReference = userInfo.id + "_" + username
                customerAccountReference = customerAccountReference.substring(0, 50);
                script.setAttribute('data-customer_account_reference', customerAccountReference);
                console.log(customerAccountReference)

                script.setAttribute('data-customer_name', userInfo.name);
                script.setAttribute('data-customer_email', userInfo.email);
                script.setAttribute('data-customer_reference', userInfo.id + "_" + moment().format("MMMDDYYYY"));
                script.async = true;
            }
        }

        updateOribipayScript(amount);
    }, [userInfo, amount])

    function validateNumberField(number) {
        const numberRegEx = /^\d+(\.\d{1,2})?$/;
        return numberRegEx.test(String(number).toLowerCase());
    };

    function validateZero(number) {
        const numberRegEx = /^0(\.\d{1,2})?$/;
        return numberRegEx.test(String(number).toLowerCase());
    }

    function handleDescriptionChange(e) {
        const { value } = e.target;
        setDescription(value);

        if (value.length > 0) {
            setShowDescErrorText(false);
        } else {
            setShowDescErrorText(true);
        }
    }

    function handleAmountChange(e) {
        const { value } = e.target;
        const isValid = !value || validateNumberField(value);
        if (isValid) {
            setShowErrorText(false);
            setAmount(value);
        } else {
            setShowErrorText(true);
        }
    }

    function makeACHPayment(event) {
        event.preventDefault();
        if (amount > 0 && description.length > 0) {
            setSubmitting(true);
        } else {
            setSubmitting(false);
        }
    }

    return (
        <>
            <form
                id="orbipay-checkout-form"
                className={styles.addACHForm}
                onSubmit={makeACHPayment}
            >
                <div>
                    If paying loads that are already invoiced, please select load(s) prior to selecting "Pay Now".
                    <br /><br />
                    If prepaying loads or to make a payment without selecting the invoice, you can enter the load or invoice number into the Payment Description box.
                    <br /><br />
                </div>
                <div className={styles.errorText}>
                    Please note, entering Loads or Invoice numbers in the description for payments takes up to 1 business day to apply.
                </div>
                <label>Payment Description {asterisk}</label>
                {showDescErrorText && <div className={styles.errorText}>Enter a payment description.</div>}
                <input
                    type="text"
                    name="notes"
                    id="notes"
                    defaultValue={description} 
                    onChange={event => { handleDescriptionChange(event) }} 
                />
                <label>Payment Amount {asterisk}</label>
                {showErrorText && <div className={styles.errorText}>Please enter a numeric value for the amount.</div>}
                <input type="text" required name="amount" id="amount" disabled={!validateZero(defaultAmount) ? "disabled" : ""} defaultValue={!validateZero(defaultAmount) ? defaultAmount : ""} onChange={event => { handleAmountChange(event) }} />
                <input
                    required
                    name="customer"
                    id="customer"
                    value={customer}
                    type="hidden"
                />
                <button className={styles.buttonAdd} id="orbipay-checkout-button" disabled={(amount > 0 && description.length > 0) ? "": "disabled"}>
                    Make a payment
                </button>
                <button className={styles.buttonCancel} onClick={hideModal}>
                    Cancel
                </button>
            </form>
        </>
    )
}

export default FormAchOrbipay