import React, { useState } from "react";
import styles from './styles.module.scss'


function BrokerNotesLoadView(
  data
  ) {
    const [isToggled, setToggled] = useState(false)
    const toggleTrueFalse = () => setToggled(!isToggled)

    const notesFiltered = data.data.filter((x, index) => {
      return x.notes
    })

  const brokerNotes = notesFiltered.map((x, index) => {
      return(
        <div key={x.id} className={styles.noteContainer}>
          <div><strong>Date: </strong> {x.time.toLocaleDateString()}
            <strong> | Time: </strong> {x.time.toLocaleTimeString()}
          </div>
          <div>
            <strong>Note: </strong> {x.notes}
          </div>
        </div>
        ) 
    })
    
    const showMoreBrokerNotes = isToggled ? brokerNotes : brokerNotes[0]
    const display = isToggled ? 'Show less...' : 'Show more...'
    const showSeeMoreOption = brokerNotes.length > 1 ? <button className={styles.seeMoreButton} onClick={toggleTrueFalse}> {display} </button> : null
  return (
    <div>
      <div className={styles.container}>
        {showMoreBrokerNotes}
      </div>
      {showSeeMoreOption}
    </div>
  )
}

export default BrokerNotesLoadView
