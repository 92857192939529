import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Route, Switch } from 'react-router-dom'

//import components
import PageWithTitle from 'components/PageWithTitle'
import LoadSummary from 'components/LoadSummary'
import LoadLineButton from 'components/LoadLineButton'
import LoadSummaryLine from 'components/LoadSummaryLine'
import ReceiveUpdatesButton from 'components/ReceiveUpdatesButton'
import ReceiveUpdatesModalContainer from 'containers/ReceiveUpdatesModalContainer'

//import containers
import LoadSummaryLinksContainer from 'containers/LoadSummaryLinksContainer'

import IELAPILoadService from '../../services/IELAPILoadService'

//import actions
import {
  updateModifiedLoadsToday,
  updateSortFieldLoadsToday,
  toggleSortDirectionLoadsToday,
  setSortDirectionLoadsToday
} from 'modules/loadsToday'
import {
  updateModifiedLoadsInTransit,
  updateSortFieldLoadsInTransit,
  toggleSortDirectionLoadsInTransit,
  setSortDirectionLoadsInTransit
} from 'modules/loadsInTransit'
import {
  updateModifiedLoadsDelivered,
  updateSortFieldLoadsDelivered,
  toggleSortDirectionLoadsDelivered,
  setSortDirectionLoadsDelivered
} from 'modules/loadsDelivered'
import {
  updateModifiedLoadsFuture,
  updateSortFieldLoadsFuture,
  toggleSortDirectionLoadsFuture,
  setSortDirectionLoadsFuture
} from 'modules/loadsFuture'

//import images/svgs
import highwayJpg from 'assets/images/highway-Today.jpg'
import truckJpg from 'assets/images/truck-transit.jpg'
import shippingCenterJpg from 'assets/images/shipping-delivered.jpg'
import trucksJpg from 'assets/images/trucksLine-future.jpg'
import { ReactComponent as DeliveredSVG } from 'assets/svg/icon_delivered.svg'
import { ReactComponent as InTransitdSVG } from 'assets/svg/icon_inTransit.svg'
import { ReactComponent as TodaySVG } from 'assets/svg/icon_today.svg'
import { ReactComponent as FutureSVG } from 'assets/svg/icons_future.svg'

import store from '../../store'

export class LoadSummaryContainer extends React.Component {
  componentDidMount() {
    this.loadService = new IELAPILoadService();

    if (this.props.multiCustomerSelectedID !== null) {
      this.loadService.getLoads(`future`, '', 1, false, this.props.multiCustomerSelectedID)
      this.loadService.getLoads(`today`, '', 1, false, this.props.multiCustomerSelectedID)
      this.loadService.getLoads(`inTransit`, '', 1, false, this.props.multiCustomerSelectedID)
      this.loadService.getLoads(`delivered`, '', 1, false, this.props.multiCustomerSelectedID)
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.multiCustomerSelectedID !== null && prevProps.multiCustomerSelectedID !== this.props.multiCustomerSelectedID) {
      this.loadService.getLoads(`future`, '', 1, false, this.props.multiCustomerSelectedID)
      this.loadService.getLoads(`today`, '', 1, false, this.props.multiCustomerSelectedID)
      this.loadService.getLoads(`inTransit`, '', 1, false, this.props.multiCustomerSelectedID)
      this.loadService.getLoads(`delivered`, '', 1, false, this.props.multiCustomerSelectedID)
    }
  }

  render() {
    // Permissions setter
    let todayPerms = store.getState().userInfo.permissions ? store.getState().userInfo.permissions.TODAYS_LOADS_VIEW : false;
    let inTransitPerms = store.getState().userInfo.permissions ? store.getState().userInfo.permissions.IN_TRANSIT_VIEW : false;
    let deliveredPerms = store.getState().userInfo.permissions ? store.getState().userInfo.permissions.DELIVERED_VIEW : false;
    let futurePerms = store.getState().userInfo.permissions ? store.getState().userInfo.permissions.FUTURE_VIEW : false;

    const sortFields = [
      { text: `PO/Order`, val: `customerpopreview`, appValue: `customerPO`, sort: true },
      { text: `Pickup Date`, val: `loaddate`, appValue: `pickupDate`, sort: true },
      { text: `Pickup City`, val: `origincity`, appValue: `pickupCity`, sort: true },
      { text: `Pickup State`, val: `originstate`, appValue: `pickupState`, sort: true },
      { text: `Delivery Date`, val: `deliverydate`, appValue: `delDate`, sort: true },
      { text: `Delivery City`, val: `destinationcity`, appValue: `delCity`, sort: true },
      { text: `Delivery State`, val: `destinationstate`, appValue: `delState`, sort: true },
      { text: `Status`, val: `status`, appValue: `status`, sort: true, filter: {
          show: true,
          type: `selectStatus`
        }
      },
      { text: `Note`, appValue: `checkcallnote`, custom: false },
      { text: `View Details`, appValue: `button`, custom: true },
      { text: `Receive Updates`, appValue: `updates`, custom: true }
    ]

    const sortFieldsToday = [
      { text: `PO/Order`, val: `customerpopreview`, appValue: `customerPO`, sort: true },
      { text: `Pickup Date`, val: `loaddate`, appValue: `pickupDate`, sort: true },
      { text: `Pickup City`, val: `origincity`, appValue: `pickupCity`, sort: true },
      { text: `Pickup State`, val: `originstate`, appValue: `pickupState`, sort: true },
      { text: `Delivery Date`, val: `deliverydate`, appValue: `delDate`, sort: true },
      { text: `Delivery City`, val: `destinationcity`, appValue: `delCity`, sort: true },
      { text: `Delivery State`, val: `destinationstate`, appValue: `delState`, sort: true },
      { text: `Status`, val: `status`, appValue: `status`, sort: true },
      { text: `Note`, appValue: `checkcallnote`, custom: false },
      { text: `View Details`, appValue: `button`, custom: true },
      { text: `Receive Updates`, appValue: `updates`, custom: true }
    ]

    let dataViewProps = {
      schema: sortFields,
      sortKey: `appValue`,
      customTableValues: {
        button: {
          component: LoadLineButton
        },
        updates: {
          component: ReceiveUpdatesButton
        }
      }
    }

    let dataViewPropsToday = {
      schema: sortFieldsToday,
      sortKey: `appValue`,
      customTableValues: {
        button: {
          component: LoadLineButton
        },
        updates: {
          component: ReceiveUpdatesButton
        }
      }
    }

    const dispatchObj = this.props.todaysLoads
    const dispatchLoads = dispatchObj.modifiedLoads.length > 0 ? dispatchObj.modifiedLoads : dispatchObj.loads
    const linesDispatch = dispatchLoads.map(load =>
      <LoadSummaryLine
        showModal={this.props.showModalUpdates}
        loadId={this.props.loadId}
        key={load.id}
        {...load}
      ></LoadSummaryLine>
    )
    const todaysProps = {
      lines: linesDispatch,
      title: dispatchObj.title,
      total: dispatchObj.total,
      status: dispatchObj.status,
      icon: <TodaySVG></TodaySVG>,
      bgImage: highwayJpg,
      dataViewProps: {
        ...dataViewPropsToday,
        rawData: dispatchLoads,
        sortField: dispatchObj.sortField,
        direction: dispatchObj.direction,
        updateData: this.props.updateModifiedLoadsToday,
        toggleDirection: this.props.toggleSortDirectionLoadsToday,
        setDirection: this.props.setSortDirectionLoadsToday,
        updateSortField: this.props.updateSortFieldLoadsToday
      },
      type: `today`,
      hasPermission: todayPerms
    }

    const transitObj = this.props.inTransitLoads

    const transitLoads = transitObj.modifiedLoads.length > 0 ? transitObj.modifiedLoads : transitObj.loads
    const linesTransit = transitLoads.map(load =>
      <LoadSummaryLine
        showModal={this.props.showModalUpdates}
        loadId={this.props.loadId}
        key={load.id}
        {...load}
      ></LoadSummaryLine>
    )
    const transitProps = {
      lines: linesTransit,
      title: transitObj.title,
      total: transitObj.total,
      icon: <InTransitdSVG></InTransitdSVG>,
      bgImage: truckJpg,
      dataViewProps: {
        ...dataViewProps,
        rawData: transitLoads,
        sortField: transitObj.sortField,
        direction: transitObj.direction,
        updateData: this.props.updateModifiedLoadsInTransit,
        toggleDirection: this.props.toggleSortDirectionLoadsInTransit,
        setDirection: this.props.setSortDirectionLoadsInTransit,
        updateSortField: this.props.updateSortFieldLoadsInTransit
      },
      type: `inTransit`,
      hasPermission: inTransitPerms
    }

    const deliveredObj = this.props.deliveredLoads
    const deliveredLoads = deliveredObj.modifiedLoads.length > 0 ? deliveredObj.modifiedLoads : deliveredObj.loads
    const linesDelivered = deliveredLoads.map(load =>
      <LoadSummaryLine
        showModal={this.props.showModalUpdates}
        loadId={this.props.loadId}
        key={load.id}
        {...load}
      ></LoadSummaryLine>
    )
    const deliveredProps = {
      lines: linesDelivered,
      title: deliveredObj.title,
      subTitle: deliveredObj.subTitle,
      total: deliveredObj.total,
      icon: <DeliveredSVG></DeliveredSVG>,
      bgImage: shippingCenterJpg,
      dataViewProps: {
        ...dataViewProps,
        rawData: deliveredLoads,
        sortField: deliveredObj.sortField,
        direction: deliveredObj.direction,
        updateData: this.props.updateModifiedLoadsDelivered,
        toggleDirection: this.props.toggleSortDirectionLoadsDelivered,
        setDirection: this.props.setSortDirectionLoadsDelivered,
        updateSortField: this.props.updateSortFieldLoadsDelivered
      },
      type: `delivered`,
      hasPermission: deliveredPerms
    }

    const futureObj = this.props.futureLoads
    const futureLoads = futureObj.modifiedLoads.length > 0 ? futureObj.modifiedLoads : futureObj.loads
    const linesFuture = futureLoads.map(load =>
      <LoadSummaryLine
        showModal={this.props.showModalUpdates}
        loadId={this.props.loadId}
        key={load.id}
        {...load}
      ></LoadSummaryLine>
    )
    const futureProps = {
      lines: linesFuture,
      title: futureObj.title,
      total: futureObj.total,
      icon: <FutureSVG></FutureSVG>,
      bgImage: trucksJpg,
      dataViewProps: {
        ...dataViewProps,
        rawData: futureLoads,
        sortField: futureObj.sortField,
        direction: futureObj.direction,
        updateData: this.props.updateModifiedLoadsFuture,
        toggleDirection: this.props.toggleSortDirectionLoadsFuture,
        setDirection: this.props.setSortDirectionLoadsFuture,
        updateSortField: this.props.updateSortFieldLoadsFuture
      },
      type: `future`,
      hasPermission: futurePerms
    }

    let showModal = this.props.showModalUpdates && this.props.loadId && this.props.screenWidth >= 1102 && this.props.pageTitle !== 'Load Summary' ? <ReceiveUpdatesModalContainer></ReceiveUpdatesModalContainer> : null
    
    return (
      <>
        <PageWithTitle
          title={`Load Summary`}
          bgContrast={'dark'}
        >
          <Switch>
            <Route
              path='/loads/today'
              render={() =>
                <LoadSummary
                  {...todaysProps}
                ></LoadSummary>
              }
            />
            <Route
              path='/loads/intransit'
              render={() =>
                <LoadSummary
                  {...transitProps}
                ></LoadSummary>
              }
            />
            <Route
              path='/loads/delivered'
              render={() =>
                <LoadSummary
                  {...deliveredProps}
                ></LoadSummary>
              }
            />
            <Route
              path='/loads/future'
              render={() =>
                <LoadSummary
                  {...futureProps}
                ></LoadSummary>
              }
            />
            <Route
              path='/'
              render={() =>
                <LoadSummaryLinksContainer></LoadSummaryLinksContainer>
              }
            />
          </Switch>
          {showModal}
        </PageWithTitle>
      </>
    )
  }
}

const mapStateToProps = state => ({
  futureLoads: state.loadsFuture,
  todaysLoads: state.loadsToday,
  inTransitLoads: state.loadsInTransit,
  deliveredLoads: state.loadsDelivered,
  viewportWidth: state.viewport.width,
  showModalUpdates: state.receiveUpdates.showModal,
  loadId: state.receiveUpdates.id,
  screenWidth: state.viewport.width,
  pageTitle: state.app.pageTitle,
  multiCustomerSelectedID: state.multiCustomer.selectedID
})

const mapDispatchToProps = dispatch => bindActionCreators({
  updateModifiedLoadsToday,
  updateSortFieldLoadsToday,
  toggleSortDirectionLoadsToday,
  setSortDirectionLoadsToday,
  updateModifiedLoadsInTransit,
  updateSortFieldLoadsInTransit,
  toggleSortDirectionLoadsInTransit,
  setSortDirectionLoadsInTransit,
  updateModifiedLoadsDelivered,
  updateSortFieldLoadsDelivered,
  toggleSortDirectionLoadsDelivered,
  setSortDirectionLoadsDelivered,
  updateModifiedLoadsFuture,
  updateSortFieldLoadsFuture,
  toggleSortDirectionLoadsFuture,
  setSortDirectionLoadsFuture
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoadSummaryContainer)