import React from 'react'
import { connect } from 'react-redux'

//import components
import LoadSummaryLinks from 'components/LoadSummaryLinks'

export class LoadSummaryLinksContainer extends React.Component {
  render(){
    return (
      <LoadSummaryLinks
        {...this.props}
        todayTotal={this.props.totalTodayLoads}
        inTransitTotal={this.props.totalInTransitLoads}
        deliveredTotal={this.props.totalDeliveredLoads}
        futureTotal={this.props.totalFutureLoads}
      ></LoadSummaryLinks>
    )
  }
}

const mapStateToProps = state => ({
  totalTodayLoads: state.loadsToday.total,
  totalInTransitLoads: state.loadsInTransit.total,
  totalDeliveredLoads: state.loadsDelivered.total,
  totalFutureLoads: state.loadsFuture.total
})

export default connect(
  mapStateToProps,
  null
)(LoadSummaryLinksContainer)