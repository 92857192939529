import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

//import components
import Header from 'components/Header'
import { LoadingGif } from '@intxlog/iuilib'
import LayoutVerticalResponsive from 'components/LayoutVerticalResponsive'

//import css
import styles from './styles.module.scss'

const BasicPageWithHeader = React.memo(({
  children,
  isLoading,
  bgImage
}) => {
  return (
    <>
      { bgImage && <div className={styles.bgDiv} style={{ backgroundImage: `url(${bgImage})` }}></div> }
      <div className={styles.container}>
        <div className={styles.headerWrapper}>
          <Header></Header>
        </div>
        <main className={
          classNames({
            [styles.pageWrapper]: true,
            [styles.loading]: isLoading
          })
        }>
          <LayoutVerticalResponsive
            minHeight={400}
            height={`auto`}
          >
            {children}
          </LayoutVerticalResponsive>
        </main>
        {isLoading &&
          <LoadingGif></LoadingGif>
        }
      </div>
    </>
  )
})

BasicPageWithHeader.propTypes = {
  children: PropTypes.object,
  isLoading: PropTypes.bool
}

export default BasicPageWithHeader
