import React from 'react'
import PropTypes from 'prop-types'

export class ServerSortAndFilter extends React.PureComponent {
  componentDidUpdate(prevProps) {
    //handle sorting
    if (prevProps.sortByField !== this.props.sortByField || prevProps.direction !== this.props.direction) {
      
      //Fixes the sort desc bug where clicking the down arrow
      //twice would result in an error due to a remaining "-"
      let dir = ``
      if (this.props.sortByField !== "") {
        dir = this.props.direction === `asc` ? `` : `-`
      }
      
      const sorts = `${dir}${this.props.sortByField}`
      this.props.updateSortParams(sorts)
    }

    //handle filtering
    if (prevProps.filters !== this.props.filters) {
      const filterParams = this.props.filters.map(f => {
        if (f.val.toLowerCase() === `ordered`) {
          return `${f.id}|${f.type}=Needs Carrier,Booked&`
        }
        return `${f.id}|${f.type}=${f.val}&`
      })
      this.props.updateFilterParams(filterParams.join(``))
    }

    //handle building the string
    if (prevProps.sortParams !== this.props.sortParams || prevProps.filterParams !== this.props.filterParams) {
      //If we dont have any more filterParams, we should remove the "&sort=" from the API query
      if (this.props.sortParams !== "") {
        this.props.updateParams(`${this.props.filterParams}sort=${this.props.sortParams}`)
      } else {
        this.props.updateParams(`${this.props.filterParams}`)
      }
    }
  }

  render(){
    return null
  }
}

ServerSortAndFilter.propTypes = {
  sortByField: PropTypes.string,
  direction: PropTypes.string,
  filters: PropTypes.array,
  sortParams: PropTypes.string,
  filterParams: PropTypes.string,
  updateSortParams: PropTypes.func,
  updateFilterParams: PropTypes.func,
  updateParams: PropTypes.func
}

export default ServerSortAndFilter
