import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

//import containers
import LoginFormContainer from 'containers/LoginFormContainer'
import CreatePasswordFormContainer from 'containers/CreatePasswordFormContainer'
import ForgotPasswordFormContainer from 'containers/ForgotPasswordFormContainer'
import SiteStatusBannerContainer from 'containers/SiteStatusBannerContainer'


//import components
import LoginPage from 'components/LoginPage'
import LoginBox from 'components/LoginBox'

//import actions
import {
  updateFormToShowLogin
} from 'modules/login'
import {
  updatePinCreatePasswordForm,
  updateEmailCreatePasswordForm,
  updateActionCreatePasswordForm
} from 'modules/createPasswordForm'

export class LoginContainer extends React.Component {
  componentDidMount = () => {
    //check for query string params
    const params = new URLSearchParams(this.props.location.search)
    if (params && params.has(`email`) && params.has(`pin`)) {
      const email = params.get(`email`)
      const pin = params.get(`pin`)
      const action = params.get(`action`)

      //send the data to store and set the logic
      this.props.updatePinCreatePasswordForm(pin)
      this.props.updateEmailCreatePasswordForm(email)
      if (action !== null) {
        this.props.updateActionCreatePasswordForm(action)
      }
      this.props.updateFormToShowLogin(`createPassword`)
    }
  }

  render() {
    let content = null

    switch (this.props.formToShow) {
      case `login`:
        content = <LoginFormContainer></LoginFormContainer>
        break
      case `createPassword`:
        content = <CreatePasswordFormContainer></CreatePasswordFormContainer>
        break
      case `requestChangePassword`:
        content = <ForgotPasswordFormContainer></ForgotPasswordFormContainer>
        break
      default:
        break
    }

    return (
      <>
        <SiteStatusBannerContainer></SiteStatusBannerContainer>
        <LoginPage>
          <LoginBox>
            {content}
          </LoginBox>
        </LoginPage>
      </>
    )
  }
}

const mapStateToProps = state => ({
  ...state.login,
  email: state.userInfo.email
})

const mapDispatchToProps = dispatch => bindActionCreators({
  updateFormToShowLogin,
  updatePinCreatePasswordForm,
  updateEmailCreatePasswordForm,
  updateActionCreatePasswordForm
}, dispatch)

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginContainer))