import React from 'react'
// import { Link } from './node_modules/react-router-dom'

//import css
import styles from './styles.module.scss'

const PayHistoryLine = ({
data
}) => {
	return (
		<div className={styles.container}>
			<div className={styles.summaryWrap}>
				<div className={styles.detailsWrap}>
					<span className={styles.detailsLabel}>IEL PO:</span> {data.ielPo}
					<br />
					<span className={styles.detailsLabel}>Customer PO:</span>{' '}
					{data.customerPo}
					<br />
					<span className={styles.detailsLabel}>Invoice Date:</span>{' '}
					{data.invoiceDate}
					<br />
				</div>
				<div className={styles.amountWrapper}>
					<span className={styles.detailsLabel}>Date Paid:</span>{' '}
					{data.datePaid}
					<br />
					<span className={styles.detailsLabel}>Amount Paid:</span> {data.amountPaid}
					<br />
				</div>
			</div>
			<div className={styles.payWrap}>
			</div>
		</div>
	)
}


export default PayHistoryLine
