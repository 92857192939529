import React from 'react'

import FormPayeezy from 'components/FormPayeezy'

import styles from './styles.module.scss'

const addInvoiceModal = ({
  removeInvoiceModal,
  payeezyArray,
  updateNewInvoice,
  updatePO,
  customer
}) => {
  return (
    <div className={styles.modalWrapper}>
      <div className={styles.modal}>
        <FormPayeezy
          customer={customer}
          removeInvoiceModal={removeInvoiceModal}
          payeezyArray={payeezyArray}
          updateNewInvoice={updateNewInvoice}
          updatePO={updatePO}
        ></FormPayeezy>
      </div>
    </div>
  )
}

export default addInvoiceModal

