export default function handleValidateEmail(emailList) {
  let emails = emailList.replace(/\s/g,'').split(",")
    let regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    let valid = true
    if (emailList !== '') {
      for (let i = 0; i < emails.length; i++) {
        if (emails[i] === "" || ! regex.test(emails[i])){
          valid = false
        }
      }
    }
    return valid
}