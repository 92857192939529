import React from 'react'
import ielLogo from 'assets/images/logo.png'
import PrivacyPolicyLink from 'components/PrivacyPolicyLink'
import {notes} from "../../data/notes"


//import css
import styles from './styles.module.scss'
import {Link} from "react-router-dom";

const FooterLinks = (props) => {
  return(
    <div className={styles.footerLinks}>
      <div className={styles.footerLinksContainer}>
        <nav>
          <ul>
            <li><img src={ielLogo} className={styles.footerLogo} alt="Integrity Express Logistics" /></li>
            <li>&copy; 2019</li>
            <li><PrivacyPolicyLink></PrivacyPolicyLink></li>
            <li><a target="_blank" rel="noopener noreferrer" href="https://www.ielfreight.com/wp-content/uploads/2019/10/IEL-Standard-Terms-of-Use.pdf">Terms of Use</a> </li>
            <li><a href="mailto:feedback@ielfreight.com">Feedback</a> </li>
            {
                props.userIsLoggedIn ? <Link to="/release" id={styles.version}>{notes[0].id}</Link> : ""
            }
          </ul>
        </nav>
      </div>
    </div>
  )
}

export default FooterLinks
