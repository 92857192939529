import { createAction } from 'redux-actions'

// Actions
export const UPDATE_EMAIL = 'customerPortal/loginForm/UPDATE_EMAIL'
export const UPDATE_PASSWORD = 'customerPortal/loginForm/UPDATE_PASSWORD'
export const UPDATE_ERROR_MESSAGE = 'customerPortal/loginForm/UPDATE_ERROR_MESASAGE'
export const INCREMENT_FORM_IS_SUBMITTED = 'customerPortal/loginForm/INCREMENT_FORM_IS_SUBMITTED'
export const UPDATE_EMAIL_IS_VALID = 'customerPortal/loginForm/UPDATE_EMAIL_IS_VALID'
export const UPDATE_PASSWORD_IS_VALID = 'customerPortal/loginForm/UPDATE_PASSWORD_IS_VALID'
export const SET_FORM_IS_VALID = 'customerPortal/loginForm/SET_FORM_IS_VALID'
export const UNSET_FORM_IS_VALID = 'customerPortal/loginForm/UNSET_FORM_IS_VALID'
export const RESET = 'customerPortal/loginForm/RESET'

const initialState = {
  emailAddress: null,
  password: null,
  emailIsValid: false,
  passwordIsValid: false,
  formIsValid: false,
  formSubmitted: 0,
  errorMessage: null
}

// Reducer
export default function loginForm (state = initialState, action) {
  switch (action.type) {
    case UPDATE_EMAIL:
      return {
        ...state,
        emailAddress: action.payload
      }
    case UPDATE_PASSWORD:
      return {
        ...state,
        password: action.payload
      }
    case UPDATE_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: action.payload
      }
    case UPDATE_EMAIL_IS_VALID:
      return {
        ...state,
        emailIsValid: action.payload
      }
    case UPDATE_PASSWORD_IS_VALID:
      return {
        ...state,
        passwordIsValid: action.payload
      }
    case SET_FORM_IS_VALID:
      return {
        ...state,
        formIsValid: true
      }
    case UNSET_FORM_IS_VALID:
      return {
        ...state,
        formIsValid: false
      }
    case INCREMENT_FORM_IS_SUBMITTED:
      return {
        ...state,
        formSubmitted: parseInt(state.formSubmitted) + 1
      }
    case RESET:
      return initialState
    default:
      return state
  }
}

// Action Creators
export const updateEmailLoginForm = createAction(UPDATE_EMAIL)
export const updatePasswordLoginForm = createAction(UPDATE_PASSWORD)
export const updateErrorMessageLoginForm = createAction(UPDATE_ERROR_MESSAGE)
export const updateEmailIsValidLoginForm = createAction(UPDATE_EMAIL_IS_VALID)
export const updatePasswordIsValidLoginForm = createAction(UPDATE_PASSWORD_IS_VALID)
export const setIsValidLoginForm = createAction(SET_FORM_IS_VALID)
export const unsetIsValidLoginForm = createAction(UNSET_FORM_IS_VALID)
export const incrementFormIsSubmittedLoginForm = createAction(INCREMENT_FORM_IS_SUBMITTED)
export const resetLoginForm = createAction(RESET)