import React from 'react'
import PropTypes from 'prop-types'

//import components
import SortButton from 'components/SortButton'

//import css
import styles from './styles.module.scss'

const DataViewSorters = React.memo(({
  schema,
  sortKey,
  direction,
  activeValue,
  onButtonClick
}) => {
  const sortButtons = schema.map((x, index) => {
    const requiresSort = x.sort ? true : false
    const sortValue = x[sortKey]
    if (requiresSort) {
      return <SortButton 
        key={index} 
        text={x.text}
        active={sortValue === activeValue ? direction : false}
        onClick={() => onButtonClick(sortValue, direction)}
      ></SortButton>
    } else {
      return null
    }
  })

  return (
    <div className={styles.container}>
      <div className={styles.buttonsWrapper}>
        {sortButtons}
      </div>
    </div>
  )
})

DataViewSorters.propTypes = {
  schema: PropTypes.array,
  sortKey: PropTypes.string,
  direction: PropTypes.string,
  activeValue: PropTypes.string,
  onButtonClick: PropTypes.func
}

export default DataViewSorters