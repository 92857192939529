import React, {useState} from 'react'
import { Link, Redirect} from 'react-router-dom'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'

//import components
import DataViewHeaderCell from 'components/DataViewHeaderCell'

//import helpers
import getColorFromStatus from 'helpers/getColorFromStatus'
import getColorFromAge from 'helpers/getColorFromAge'


//import css
import styles from './styles.module.scss'

const DataViewTable = React.memo(({
  data,
  schema,
  customFields,
  sortKey,
  sortField,
  direction,
  onSortClick,
  showModal,
  id,
  setEditRequest
}) => {

  let fields = []
  let highlight = true
  const[redirect, setRedirect] = useState(false);
  const[selectAll, setSelectAll] = useState(false);

  const header = schema.map((field, i) => {
    const requiresSort = field.sort ? true : false
    const sortValue = field[sortKey]
    const isCustom = field.custom ? true : false
    return (<DataViewHeaderCell
      key={i}
      text={field.text}
      value={sortValue}
      showSorters={requiresSort}
      active={sortValue === sortField ? direction : false}
      onClick={onSortClick}
      checkbox={isCustom && customFields['invoiceBoxes']}
      setSelectAll={setSelectAll}
    ></DataViewHeaderCell>)
  })

  const editPendingRequest = (id) => {
    setEditRequest(id);
    setRedirect(true);
  }

  //iterate over the data and print the fields that have the corresponding app value
  data.forEach(entry => {
    //iterate over the schema
    schema.forEach((field, fieldIndex) => {
      const key = field.appValue
      const isCustom = field.custom ? true : false
      const isAdminConsole = field.isAdminConsole ? true : false
      const reactKey = `${entry['id']}${fieldIndex}`

      var classes = null

      if (isAdminConsole) {
        classes = classNames(
          styles.cellCustomerAdmin,
          { [styles.highlighted]: highlight }
        )
      } else {
        classes = classNames(
          styles.cell,
          { [styles.highlighted]: highlight }
        )
      }

      //default value
      let content = entry[key]

      if (typeof content === 'number') {
        content = content.toFixed(2)
      }

      if (key === `status`) {
        if (entry['status'] === 'pending') {
          content = <button onDoubleClick={()=>{editPendingRequest(entry['id'])}} className={styles.statusButton}>PENDING</button>
        } else if (entry['status'] === 'accepted' || entry['status'] === 'declined' || entry['status'] === 'voided') {
          content = <button className={styles.nonEditableStatusButton} onDoubleClick={()=>{editPendingRequest(entry['id'])}}>{entry['status'].toUpperCase()}</button>
        } else {
          let textColor = getColorFromStatus(entry[key])
          content = <span style={{color: textColor, fontWeight: 900}}>{content}</span>
        }
      }

      if (key === 'age') {
        let ageColor = getColorFromAge(entry[key])
        content = <span style={{ color: ageColor, fontWeight: 600 }}>{content}</span>
      }

      if (key === `customerPO`) {
        let loadID = entry[`id`]
        content = <Link className={styles.ellipsis} title={content} to={`/load/${loadID}`}>{content}</Link>
      }

      if (key === 'checkcallnote' && content) {
        content =
        <div className={styles.notificationIcon} data-title={content}>
          <FontAwesomeIcon icon={faExclamationCircle} size="1x" />
        </div>

      }

      //check for custom fields
      if (isCustom) {
        const customObj = customFields[key]
        const CustomComponent = customObj.component
        const props = customObj.props
        content = <CustomComponent
          key={reactKey}
          {...entry}
          {...props}
          selectAll={selectAll}
        ></CustomComponent>
      }

      fields.push(
        <div key={reactKey} className={classes}>{content}</div>
      )
    })

    highlight = !highlight
  })

  if (redirect) {
    return <Redirect to="/buildLoadform"/>
  }
  return (
    <div className={styles.container} style={{ gridTemplateColumns: `repeat(${schema.length}, 1fr)`}}>
      {header}
      {fields}
    </div>
  )
})

DataViewTable.propTypes = {
  data: PropTypes.array,
  schema: PropTypes.array,
  customFields: PropTypes.object,
  sortKey: PropTypes.string,
  sortField: PropTypes.string,
  direction: PropTypes.string,
  onSortClick: PropTypes.func
}

DataViewTable.defaultProps = {
  data: [],
  customFields: {}
}

export default DataViewTable
