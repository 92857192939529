import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Input } from '@intxlog/iuilib'

//import components
import LoginFormWrapper from 'components/LoginFormWrapper'

//import actions
import {
  updatePasswordCreatePasswordForm,
  updatePasswordConfirmCreatePasswordForm,
  updatePasswordIsValidCreatePasswordForm,
  updatePasswordConfirmIsValidCreatePasswordForm,
  setIsValidCreatePasswordForm,
  unsetIsValidCreatePasswordForm,
  incrementFormIsSubmittedCreatePasswordForm
} from 'modules/createPasswordForm'

//import utils
import passwordCharactersValidation from 'utils/passwordCharactersValidation'
import IELAPIUserService from '../../services/IELAPIUserService'

export class CreatePasswordFormContainer extends React.Component {
  componentDidMount() {
    this.userService = new IELAPIUserService();
  }

  componentDidUpdate = (prevProps, prevState) => {
    //check to see if the pwd and the pwdConfirm both become valid
    if (this.props.passwordIsValid && this.props.passwordConfirmIsValid && !prevProps.formIsValid) {
      this.props.setIsValidCreatePasswordForm()
    }
    if ((!this.props.passwordIsValid || !this.props.passwordConfirmIsValid) && prevProps.formIsValid) {
      this.props.unsetIsValidCreatePasswordForm()
    }
  }

  handlePwdChange = (val) => {
    this.props.updatePasswordCreatePasswordForm(val)
  }

  handlePwdConfirmChange = (val) => {
    this.props.updatePasswordConfirmCreatePasswordForm(val)
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.props.incrementFormIsSubmittedCreatePasswordForm()

    //only submit the form if the form is valid
    if (this.props.formIsValid) {
      //determine the action we need to take
      this.userService.submitCreatePasswordForm(this.props.pin, this.props.email, this.props.password, this.props.passwordConfirm, this.props.action)
    }
  }

  render() {
    let title = `Create New Password`
    let buttonText = `Create New Password`
    const form = <>
      <label htmlFor="password">Password</label>
      <Input
        type={`password`}
        idForLabel={`password`}
        required
        formSubmitted={this.props.formSubmitted}
        onChange={this.handlePwdChange}
        isValid={this.props.updatePasswordIsValidCreatePasswordForm}
        customValidationFunc={() => this.props.passwordCharactersValidation(this.props.password)}
      ></Input>
      <label htmlFor="passwordConfirm">Confirm Password</label>
      <Input
        type={`password`}
        idForLabel={`passwordConfirm`}
        required
        formSubmitted={this.props.formSubmitted}
        onChange={this.handlePwdConfirmChange}
        isValid={this.props.updatePasswordConfirmIsValidCreatePasswordForm}
        customValidationFunc={() => this.props.passwordCharactersValidation(this.props.password)}
      ></Input>
    </>
    const passwordCriteria = <>
      <div>Passwords must include the following:</div>
      <ol>
        <li>At least 8 characters</li>
        <li>At least one Numeric character (0-9)</li>
        <li>At least one Lowercase alpha character (a-z)</li>
        <li>At least one Uppercase alpha character (A-Z)</li>
        <li>At least one of the following special characters:<b><br></br>&#126; &#96; &#64; &#35; &#36; &#37; &#94; &#38; &#42; &#40; &#41; &#45; &#95; &#61; &#43; &#91; &#93; &#123; &#125; &#59; &#58; &#39; &#34; &#44; &#60; &#46; &#62; &#47; &#63;</b></li>
      </ol>
    </>

    //change wording based on the action
    if (this.props.action === `reset`) {
      title = `Reset Password`
      buttonText = `Reset Password`
    }

    return (
      <LoginFormWrapper
        disabled={!this.props.passwordConfirmIsValid}
        title={title}
        formElements={form}
        buttonText={buttonText}
        errorMessage={this.props.errorMessage}
        onSubmit={this.handleSubmit}
        passwordCriteria={passwordCriteria}
      ></LoginFormWrapper>
    )
  }
}

const mapStateToProps = state => ({
  ...state.createPasswordForm,
  formSubmitted: state.createPasswordForm.formSubmitted
})

const mapDispatchToProps = dispatch => bindActionCreators({
  updatePasswordCreatePasswordForm,
  updatePasswordConfirmCreatePasswordForm,
  updatePasswordIsValidCreatePasswordForm,
  updatePasswordConfirmIsValidCreatePasswordForm,
  setIsValidCreatePasswordForm,
  unsetIsValidCreatePasswordForm,
  incrementFormIsSubmittedCreatePasswordForm,
  passwordCharactersValidation
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreatePasswordFormContainer)