import { createAction } from 'redux-actions'

// Actions
export const SAVE_LOAD_ID = 'customerPortal/createLoad/SAVE_LOAD_ID'
export const TOGGLE_MODAL = 'customerPortal/createLoad/TOGGLE_MODAL'
export const FORCE_CLOSE_MODAL = 'customerPortal/createLoad/FORCE_CLOSE_MODAL'



const initialState = {
  id: null,
  modal: false
}

// Reducer
export default function createLoad (state = initialState, action) {
  switch (action.type) {
    case SAVE_LOAD_ID:
      return {
        ...state,
        id: action.payload
      }
      case TOGGLE_MODAL:
      return {
        ...state,
        modal: !state.modal
      }
      case FORCE_CLOSE_MODAL: 
      return {
        ...state,
        modal: false
      }
    default:
      return state
  }
}

// Action Creators
export const saveCustomBuiltLoadId = createAction(SAVE_LOAD_ID)
export const forceCloseModal = createAction(FORCE_CLOSE_MODAL)
export const toggleLoadRequestModal = createAction(TOGGLE_MODAL)