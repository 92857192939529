import React from 'react'
import PropTypes from 'prop-types'

//import css
import styles from './styles.module.scss'

const LoginBox = ({
  children
}) => {
  return (
    <div className={styles.container}>
      {children}
    </div>
  )
}

LoginBox.propTypes = {
  children: PropTypes.object
}

export default LoginBox
