import React from 'react'
// import PropTypes from 'prop-types'

//import css
import styles from './styles.module.scss'

const ForgotPasswordSuccessMessage = () => {
  return (
    <div className={styles.message}>Reset password link sent. Please check your email. The link will expire in 1 hour.</div>
  )
}

// ForgotPasswordSuccessMessage.propTypes = {
// }

export default ForgotPasswordSuccessMessage