import currency from 'helpers/currency'
import inputBoxError from 'helpers/inputBoxError'

export default function invoiceTotal (inputArray) {
  let total = parseFloat(0)
  inputArray.map((x, key) => {
    //remove commas for conversion
    let xVal = x.val
    if (!inputBoxError(xVal)) {
      if (typeof xVal === 'string'){
        xVal = Number(xVal)
      }
      total += xVal
    }
    return total
  })
  
  total = currency(total)

  return total
}