import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

//import css
import styles from './styles.module.scss'

const LineItem = React.memo(({
  label,
  field,
  labelClassName,
  fieldClassName
}) => {
  let labelClasses = classNames({
    [styles.label]: true,
    [labelClassName]: labelClassName,
  })

  let fieldClasses = classNames({
    [styles.field]: true,
    [fieldClassName]: fieldClassName,
  })

  return (
    <>
      <div className={labelClasses}>{label}</div>
      <div className={fieldClasses}>{field}</div>
    </>
  )
})

//rules for props being passed in
LineItem.propTypes = {
  label: PropTypes.string.isRequired,
  field: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object
  ]),
  labelClassName: PropTypes.string,
  fieldClassName: PropTypes.string
}

export default LineItem