import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

//import css
import styles from './styles.module.scss'

const ButtonArrow = ({
  orientation,
  active,
  width,
  height,
  onClick
}) => {
  return (
    <button className={
      classNames(
        styles.container,
        {
          [styles.active]: active,
          [styles.right]: orientation === `right`,
          [styles.down]: orientation === `down`,
          [styles.left]: orientation === `left`,
        }
      )
    } style={{
      borderLeftWidth: height,
      borderRightWidth: height,
      borderBottomWidth: width
    }} onClick={onClick}></button>
  )
}

ButtonArrow.propTypes = {
  orientation: PropTypes.oneOf([`up`, `down`, `left`, `right`]),
  height: PropTypes.number,
  width: PropTypes.number,
  active: PropTypes.bool,
  onClick: PropTypes.func
}

ButtonArrow.defaultProps = {
  orientation: `up`,
  height: 5,
  width: 8
}

export default ButtonArrow