export default function getPageNameFromPath (path) {
  let page

  switch (path) {
    case `/`:
      page = `Load Summary`
      break
    case `/search`:
      page = `Load Search`
      break
    case `/ar/billed`:
      page = `Billed A/R`
      break
    case `/ar/unbilled`:
      page = `Unbilled Loads`
      break
    case `/paynow` :
      page = `Make A Payment`
      break
    case `/history` :
      page = `Payment History`
      break
    case `/loadhistory` :
      page = `Request History`
      break
    case '/buildLoadform':
      page = 'Load Request'
      break
    default:
      if (path.includes('/load/')) {
        page = 'Load Details'
      } else {
        page = ``
      }
      break
  }

  return page
}