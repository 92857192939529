import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

//import actions
import {
  setHeightViewport,
  setWidthViewport,
  resetViewport
} from 'modules/viewport'

export class ViewportTracker extends React.Component {
  componentDidMount = () => {
    this.props.setHeightViewport(window.innerHeight)
    this.props.setWidthViewport(window.innerWidth)

    //listener to report viewport sizes when the viewport size changes
    window.onresize = () => {
      this.props.setHeightViewport(window.innerHeight)
      this.props.setWidthViewport(window.innerWidth)
    }
  }
  
  render(){
    return null
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  setHeightViewport,
  setWidthViewport,
  resetViewport
}, dispatch)

export default connect(
  null,
  mapDispatchToProps
)(ViewportTracker)