import { createAction } from 'redux-actions'

// Actions
export const SET_GRAPH_IS_LOADING = 'customerPortal/billingPage/SET_GRAPH_IS_LOADING'
export const UNSET_GRAPH_IS_LOADING = 'customerPortal/billingPage/UNSET_GRAPH_IS_LOADING'
export const SHOW_GRAPH = 'customerPortal/billingPage/SHOW_GRAPH'
export const HIDE_GRAPH = 'customerPortal/billingPage/HIDE_GRAPH'
export const SET_SELECTED_AGE_VALUE = 'customerPortal/billingPage/SET_SELECTED_AGE_VALUE'
export const RESET = 'customerPortal/billingPage/RESET'

const initialState = {
  graphIsLoading: true,
  showGraph: false,
  selectedAgeValue: null
}
// Reducer
export default function billingPage (state = initialState, action) {
  switch (action.type) {
    case SET_GRAPH_IS_LOADING:
      return {
        ...state,
        graphIsLoading: true
      }
    case UNSET_GRAPH_IS_LOADING:
      return {
        ...state,
        graphIsLoading: false
      }
    case SHOW_GRAPH:
      return {
        ...state,
        showGraph: true
      }
    case HIDE_GRAPH:
      return {
        ...state,
        showGraph: false
      }
    case SET_SELECTED_AGE_VALUE:
        return {
          ...state,
          selectedAgeValue: action.payload
        }
    case RESET:
      return initialState
    default:
      return state
  }
}

// Action Creators
export const setGraphIsLoadingBillingPage = createAction(SET_GRAPH_IS_LOADING)
export const unsetGraphIsLoadingBillingPage = createAction(UNSET_GRAPH_IS_LOADING)
export const showGraphBillingPage = createAction(SHOW_GRAPH)
export const hideGraphBillingPage = createAction(HIDE_GRAPH)
export const selectedAgeValueBillingPage = createAction(SET_SELECTED_AGE_VALUE)
export const resetBillingPage = createAction(RESET)
