import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

//import containers
import AdjustPaymentContainer from 'containers/AdjustPaymentContainer'

//import helpers
import getNumberOfDaysOldInvoice from 'helpers/getNumberOfDaysOldInvoice'
import getColorFromAge from 'helpers/getColorFromAge'

//import css
import styles from './styles.module.scss'

const PayNowLine = ({
	id,
	totalBalance,
	outstandingBalance,
	amountPaid,
	invoiceDate,
	customerPO,
	IELPONumber,
	inputArray,
	paymentStatus,
	userValue
}) => {
	const ageOldInDays = Math.floor(getNumberOfDaysOldInvoice(invoiceDate))
	const colorDate = getColorFromAge(ageOldInDays)

	return (
		<div className={styles.container}>
			<div className={styles.summaryWrap}>
				<div className={styles.detailsWrap}>
					<span className={styles.detailsLabel}>IEL PO:</span> {IELPONumber}
					<br />
					<span className={styles.detailsLabel}>Customer PO:</span>{' '}
					<Link to={`/load/${id}`}>{customerPO}</Link>
					<br />
					<span className={styles.detailsLabel}>Invoice Date:</span>{' '}
					{invoiceDate}
					<br />
					<span className={styles.detailsLabel}>Days Old:</span>{' '}
					<span style={{ color: colorDate }}>{ageOldInDays}</span>
					<br />
				</div>
				<div className={styles.amountWrapper}>
					<span className={styles.detailsLabel}>Total Balance:</span>{' '}
					{totalBalance}
					<br />
					<span className={styles.detailsLabel}>Amount Paid:</span> {amountPaid}
					<br />
				</div>
			</div>
			<div className={styles.payWrap}>
				<div>
					<span className={styles.detailsLabel}>Outstanding:</span>{' '}
					{`$${outstandingBalance}`}
					<br />
					<span className={styles.detailsLabel}>Status:</span> {paymentStatus}
					<br />
					<span className={styles.detailsLabel}>Amount to Pay:</span>
				</div>
				<AdjustPaymentContainer
					id={id}
					initialValue={outstandingBalance}
					isDisabled={true}
					value={outstandingBalance}
					isChecked={false}
					inputArray={inputArray}
					outstandingBalance={outstandingBalance}
					userValue={userValue}
					IELPONumber={IELPONumber}
				></AdjustPaymentContainer>
			</div>
		</div>
	)
}

PayNowLine.propTypes = {
	id: PropTypes.number,
	outstandingBalance: PropTypes.number,
	amountPaid: PropTypes.number,
	invoiceDate: PropTypes.string,
	customerPO: PropTypes.string,
	IELPONumber: PropTypes.string
}

export default PayNowLine
